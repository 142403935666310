import {
  TableColumns,
  Table,
  PrestyledTableProps,
} from 'shared/components/Table';
import { useMemo } from 'react';

import { BankFeeStatementListResponseDataItem } from 'src/build/operations';
import { formatISO8601Timestamp } from 'shared/lib/formatting';

import { buildPath, ROUTES } from 'src/lib/routes';

type Props = PrestyledTableProps<BankFeeStatementListResponseDataItem>;

export const BankFeeStatementsTable = (props: Props) => {
  const { getRowProps, ...rest } = props;
  const columns = useMemo<
    TableColumns<BankFeeStatementListResponseDataItem>
  >(() => {
    return [
      {
        header: 'Period',
        expand: 1,
        contents: (program) => ({
          text: formatISO8601Timestamp(
            program.period_start,
            'month-year',
            'UTC'
          ),
          textWeight: 'medium',
          textColor: 'primary',
        }),
      },
      {
        header: 'Status',
        contents: (program) => ({
          text: program.status,
        }),
      },
    ];
  }, []);

  const defaultRowProps = (datum: BankFeeStatementListResponseDataItem) => {
    if (datum.status === 'open') {
      return {
        className: 'group',
      };
    }
    return {
      href: buildPath(ROUTES.BANK_FEE_STATEMENTS_DETAIL, {
        bankFeeStatementId: datum.id,
      }),
      className: 'hover:bg-main-hover cursor-pointer transition-all group',
    };
  };

  return (
    <Table<BankFeeStatementListResponseDataItem>
      columns={columns}
      getRowProps={getRowProps ?? defaultRowProps}
      {...rest}
    />
  );
};

import { compact } from 'lodash';
import { BadgeColor } from 'shared/components/Badge';
import {
  ListItem,
  PropertyList,
  PropertyListProps,
} from 'shared/components/PropertyList';
import { formatISO8601Timestamp } from 'shared/lib/formatting';
import {
  AccountNumberGetResponse,
  AccountNumberGetResponseAchDebitStatus,
  AccountNumberGetResponseInboundCheckStatus,
  AccountNumberGetResponseStatus,
} from 'src/build/operations';
import {
  useGroupPropertyRow,
  useAccountPropertyRow,
} from 'src/lib/propertyListHelpers';

export type AccountNumberPropertyListProps = {
  accountNumber: AccountNumberGetResponse;
} & Omit<PropertyListProps, 'items'>;

const statusBadgeColor: Record<AccountNumberGetResponseStatus, BadgeColor> = {
  active: 'green',
  disabled: 'gray',
  canceled: 'red',
};

const achDebitStatusBadgeColor: Record<
  AccountNumberGetResponseAchDebitStatus,
  BadgeColor
> = {
  allowed: 'green',
  blocked: 'yellow',
};

const inboundCheckDebitStatusBadgeColor: Record<
  AccountNumberGetResponseInboundCheckStatus,
  BadgeColor
> = {
  allowed: 'green',
  check_transfers_only: 'blue',
};

export const AccountNumberPropertyList = (
  props: AccountNumberPropertyListProps
) => {
  const { accountNumber, ...rest } = props;
  const groupRow = useGroupPropertyRow(accountNumber.group_id);
  const accountRow = useAccountPropertyRow(accountNumber.account_id);

  return (
    <PropertyList
      {...rest}
      items={compact<ListItem>([
        {
          label: 'ID',
          value: accountNumber.id,
          copyable: accountNumber.id,
        },
        {
          label: 'Routing Number',
          value: accountNumber.routing_number,
          copyable: accountNumber.routing_number,
        },
        {
          label: 'Account Number',
          value: accountNumber.account_number,
          copyable: accountNumber.account_number,
        },
        groupRow,
        accountRow,
        {
          label: 'Status',
          value: accountNumber.status,
          badgeColor: statusBadgeColor[accountNumber.status],
        },
        {
          label: 'Name',
          value: accountNumber.name,
        },
        {
          label: 'Created',
          value: formatISO8601Timestamp(accountNumber.created_at, 'medium'),
        },
        {
          label: 'ACH Debit Status',
          value: accountNumber.ach_debit_status,
          badgeColor: achDebitStatusBadgeColor[accountNumber.ach_debit_status],
        },
        {
          label: 'Allows Inbound Checks',
          value: accountNumber.inbound_check_status,
          badgeColor:
            inboundCheckDebitStatusBadgeColor[
              accountNumber.inbound_check_status
            ],
        },
      ])}
    />
  );
};

import React, { useState } from 'react';

type Props = {
  minimumHeight: number;
  minimumWidth: number;
  src: string;
  alt?: string;
};

export const ImageVerify = ({
  minimumWidth,
  minimumHeight,
  src,
  alt,
}: Props) => {
  const [naturalWidth, setNaturalWidth] = useState(0);
  const [naturalHeight, setNaturalHeight] = useState(0);

  const bigEnough =
    naturalHeight >= minimumHeight && naturalWidth >= minimumWidth;

  return (
    <div>
      {bigEnough ? (
        <span>
          Image is large enough: {naturalWidth}x{naturalHeight}
        </span>
      ) : (
        <strong style={{ color: 'red' }}>
          Image is not large enough: {naturalWidth}x{naturalHeight} (needs to
          be: {minimumWidth}x{minimumHeight}
        </strong>
      )}
      <div>
        {' '}
        <img
          style={{ maxWidth: '700px' }}
          onLoad={(e) => {
            setNaturalWidth(e.currentTarget.naturalWidth);

            setNaturalHeight(e.currentTarget.naturalHeight);
          }}
          src={src}
          alt={alt}
        />
      </div>
    </div>
  );
};

import classNames from 'classnames';
import { Caption } from './Text';

export const WarningNumber = (props: { count: number; className?: string }) => (
  <Caption
    color="inherit"
    contents={props.count}
    weight="medium"
    className={classNames(
      'flex flex-row items-center justify-center rounded-full border border-strong bg-strong px-1.5 text-subtle',
      props.className
    )}
  />
);

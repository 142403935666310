import {
  useGroupGateDelete,
  useGroupGatePost,
  useGroupGatesList,
  useGroupGet,
  useOperatorGet,
} from 'src/hooks/reactQuery';
import { GroupGatePostParametersFeature } from 'src/build/operations';
import { useCategoricals } from 'src/hooks/use-categoricals';
import { PageLayout } from './deprecated/PageLayout';
import { Section } from './deprecated/Section';
import { OperatorNotes } from './operator-notes';
import { PropertyList } from 'shared/components/PropertyList';
import { sortBy } from 'lodash';
import { useTypedParams } from 'react-router-typesafe-routes/dom';
import { ROUTES } from 'src/lib/routes';

export const Gates = () => {
  const { groupID } = useTypedParams(ROUTES.GROUP_GATES);
  const { data: group } = useGroupGet(groupID);
  const { data: gates, refetch } = useGroupGatesList(groupID);
  const { data: operator } = useOperatorGet({});

  const categoricals = useCategoricals();
  const groupGatePost = useGroupGatePost();
  const groupGateDelete = useGroupGateDelete();
  if (!group || !categoricals || !operator || !gates) {
    return <div>Loading...</div>;
  }
  const name = group.name;
  const title = name
    ? `${name} (${group.friendly_nickname})`
    : group.friendly_nickname;

  const toggleGate = async (
    feature: GroupGatePostParametersFeature,
    currentlyActive: boolean
  ): Promise<unknown> => {
    if (currentlyActive) {
      await groupGateDelete.mutateAsync([groupID, feature]);
    } else {
      await groupGatePost.mutateAsync([groupID, { feature }]);
    }
    return await refetch();
  };

  return (
    <PageLayout
      headline={`Gates for ${title}`}
      breadcrumbs={[{ text: 'Groups' }, { text: title }, { text: 'Gates' }]}
    >
      <>
        <Section header="Gates">
          <PropertyList
            items={sortBy(categoricals.gate, (g) => g.value).map((gate) => {
              const active = gates.gates.includes(gate.value);

              return {
                label: gate.value,
                value: active,
                valueLabel: gate.name,
                onToggle: () => toggleGate(gate.value, active),
              };
            })}
          />
        </Section>

        <Section header="Notes">
          <OperatorNotes modelId={group.id} />
        </Section>
      </>
    </PageLayout>
  );
};

import { useManualTaskList } from 'src/hooks/reactQuery';
import { PageLayout } from './deprecated/PageLayout';
import { ItemList } from './deprecated/ItemList';
import { ROUTES, buildPath } from 'src/lib/routes';
import { useState } from 'react';
import { TextInput } from 'shared/components/TextInput';

export const ManualTaskList = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const { data: manualTasks } = useManualTaskList({});
  if (!manualTasks) {
    return <>Loading...</>;
  }

  const lowercase = searchQuery.toLowerCase();
  const filteredTasks = manualTasks.data.filter(
    (mt) =>
      mt.name.toLowerCase().includes(lowercase) ||
      mt.description?.toLowerCase().includes(lowercase) ||
      mt.arguments.find((arg) => arg.name.toLowerCase().includes(lowercase))
  );

  return (
    <PageLayout
      headline="Manual Tasks"
      breadcrumbs={[{ text: 'Manual Tasks' }]}
    >
      <TextInput
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
        placeholder="Search for a manual task..."
      />
      <ItemList
        items={filteredTasks.map((mt) => ({
          text: mt.name.replace('Operations::ManualTask::', ''),
          id: mt.name,
          detail: mt.description ?? '',
          href: buildPath(ROUTES.MANUAL_TASK, { taskName: mt.name }),
        }))}
      />
    </PageLayout>
  );
};

import classNames from 'classnames';
import { Icon, IconType } from './Icon';

export type TextButtonStyle = 'default' | 'warning';
export type TextButtonSize = 'small' | 'medium' | 'large';
export type TextButtonIconPlacement = 'start' | 'end';

export type TextButtonProps = {
  style?: TextButtonStyle;
  icon?: IconType;
  iconPlacement?: TextButtonIconPlacement;
  text?: string;
  disabled?: boolean;
  size?: TextButtonSize;
  onClick?: () => void;
  className?: string;
};

const SIZE_CLASSES_BUTTON: Record<TextButtonSize, string> = {
  small: 'p-1',
  medium: 'p-1.5',
  large: 'p-2',
};

const STYLE_CLASSES_BUTTON: Record<TextButtonStyle, string> = {
  default:
    'bg-none hover:bg-main-hover active:bg-main-hover focus:ring-main focus-visible:bg-main-hover',
  warning:
    ' bg-none hover:bg-danger active:bg-danger focus:ring-danger focus-visible:bg-danger',
};

const SIZE_CLASSES_TEXT: Record<TextButtonSize, string> = {
  small: 'text-[12px] leading-[16px] font-[500]',
  medium: 'text-[14px] leading-[20px] font-[500]',
  large: 'text-[16px] leading-[20px] font-[500]',
};

const STYLE_CLASSES_TEXT: Record<TextButtonStyle, string> = {
  default: 'text-subtle',
  warning: 'text-danger',
};

const STYLE_CLASSES_ICON: Record<TextButtonStyle, string> = {
  default: 'stroke-subtle',
  warning: 'stroke-danger',
};

const STYLE_CLASSES_ICON_PLACEMENT: Record<TextButtonIconPlacement, string> = {
  start: '',
  end: 'flex-row-reverse',
};

const TextButton = ({
  text,
  onClick,
  disabled = false,
  style = 'default',
  icon,
  iconPlacement = 'start',
  size = 'medium',
  className,
}: TextButtonProps) => {
  return (
    <button
      type="button"
      className={classNames(
        'flex flex-row items-center gap-1 rounded border border-none',
        'transition focus:z-10 focus:outline-none focus:ring-[3px]',
        'disabled:opacity-50',
        STYLE_CLASSES_BUTTON[style],
        SIZE_CLASSES_BUTTON[size],
        STYLE_CLASSES_ICON_PLACEMENT[iconPlacement],
        className
      )}
      onClick={onClick}
      disabled={disabled}
    >
      {icon && (
        <Icon
          name={icon}
          className={classNames(STYLE_CLASSES_ICON[style], 'shrink-0')}
        />
      )}
      {text != null && (
        <div
          className={classNames(
            STYLE_CLASSES_TEXT[style],
            SIZE_CLASSES_TEXT[size]
          )}
        >
          {text}
        </div>
      )}
    </button>
  );
};

export { TextButton };

import Head from 'next/head';
import { Root } from 'src/components/root';

export default function Home() {
  const isDevelopment = process.env.NODE_ENV === 'development';
  const favicon = isDevelopment ? 'favicon-dev' : 'favicon';

  return (
    <>
      <Head>
        <title>Administration</title>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="icon" href={`/${favicon}.ico`} sizes="any" />
      </Head>
      <Root />
    </>
  );
}

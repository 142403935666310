import React, { useState } from 'react';
import { ImageToolInner, MultiImageTool } from './check-workflow/image-tool';
import { MICRResult, TranscribeMICR } from './check-workflow/transcribe-micr';
import { ImageThreshold } from './check-workflow/image-threshold';
import { SubmitReview } from './check-workflow/submit-review';
import { useNavigate } from 'react-router';
import {
  useCheckDepositGet,
  useCheckDepositReject,
} from 'src/hooks/reactQuery';
import { useServices } from 'src/hooks/use-services';
import { useCategoricals } from 'src/hooks/use-categoricals';
import {
  CheckDepositGetResponse,
  CheckDepositRejectDirectivePostParametersReason,
} from 'src/build/operations';
import { Select } from './deprecated/Select';
import { Button } from './deprecated/Button';
import { PageLayout } from './deprecated/PageLayout';
import { StyledLink } from './deprecated/StyledLink';
import { OperatorTags } from './operator-tags';
import { useTypedParams } from 'react-router-typesafe-routes/dom';
import { ROUTES, buildPath } from 'src/lib/routes';
import { addText, imageDataToCanvasImageSource } from 'src/lib/image-utility';
import { Box } from 'shared/components/Box';
import { Body, Subheading } from 'shared/components/Text';
import { useKeyPressEvent } from 'react-use';
import { PropertyList } from 'shared/components/PropertyList';
import { formatAmount } from 'shared/lib/formatting';

const TARGET_DPI = 240;
const SIZE_MARGIN = 1.15;

export const CHECK_IMAGE_MINIMUM_HEIGHT = Math.ceil(
  1.752 * TARGET_DPI * SIZE_MARGIN
);
export const CHECK_IMAGE_MINIMUM_WIDTH = Math.ceil(
  3 * TARGET_DPI * SIZE_MARGIN
);

const KeyListener: React.FC<{
  listenedKey: string;
  onKeyPress: (e: KeyboardEvent) => void;
}> = ({ listenedKey, onKeyPress }) => {
  useKeyPressEvent(listenedKey, onKeyPress);
  return <></>;
};

const RejectCheckForm = ({
  checkDeposit,
}: {
  checkDeposit: CheckDepositGetResponse;
}) => {
  const checkDepositReject = useCheckDepositReject();
  const navigate = useNavigate();
  const [rejectionReason, setRejectionReason] =
    useState<CheckDepositRejectDirectivePostParametersReason>(
      'poor_image_quality'
    );
  const categoricals = useCategoricals();

  const reject = () => {
    checkDepositReject
      .mutateAsync([
        checkDeposit.id,
        {
          reason: rejectionReason,
        },
      ])
      .then(() => {
        navigate(ROUTES.CHECK_DEPOSIT_REVIEW_LIST.buildPath({}));
      })
      .catch((err) => {
        alert(err);
      });
  };

  if (!categoricals) {
    return <div />;
  }

  const reasons = Object.values(
    CheckDepositRejectDirectivePostParametersReason
  ).map((r) => ({
    value: r,
    label: r,
  }));

  return (
    <Box>
      <Subheading contents="Or, reject this check:" />
      <div>
        <Select<CheckDepositRejectDirectivePostParametersReason>
          label="Rejection Reason (required)"
          data-testid="application.check-review.rejectionReasonInput"
          id={'application.check-review.rejectionReasonInput'}
          name="rejectionReason"
          value={rejectionReason}
          onChange={(s) => setRejectionReason(s)}
          options={reasons}
        />
      </div>
      <div>
        <Button
          data-testid="checkDepositInstruction.reject"
          id="checkDepositInstruction.reject"
          disabled={checkDepositReject.isLoading}
          onClick={reject}
        >
          Reject
        </Button>
      </div>
    </Box>
  );
};

const CheckDepositReviewSteps = ({
  checkDeposit,
  frontImageURL,
  backImageURL,
}: {
  checkDeposit: CheckDepositGetResponse;
  frontImageURL: string;
  backImageURL: string;
}) => {
  const [step, setStep] = useState(0);
  const [maxStepReached, setMaxStepReached] = useState(0);
  const incrementStep = () => {
    setStep(step + 1);
    setMaxStepReached(Math.max(maxStepReached, step + 1));
  };
  const decrementStep = () => setStep(step - 1);
  const [frontPixels, setFrontPixels] = useState<ImageData | null>(null);
  const [frontThreshold, setFrontThreshold] = useState(128);
  const [rearPixels, setRearPixels] = useState<ImageData | null>(null);
  const [rearThreshold, setRearThreshold] = useState(128);
  const [frontMonotonePixels, setFrontMonotonePixels] =
    useState<ImageData | null>(null);
  const [rearMonotonePixels, setRearMonotonePixels] =
    useState<ImageData | null>(null);
  const [micrPixels, setMICRPixels] = useState<ImageData | null>(null);
  const [micrResult, setMICRResult] = useState<MICRResult | null>(null);

  return (
    <PageLayout
      id="application.check-review"
      headline={<span>Review {checkDeposit.id}</span>}
      breadcrumbs={[{ text: 'Check Deposit Review' }]}
    >
      <StyledLink
        to={buildPath(ROUTES.GROUPS_DETAIL, {
          groupId: checkDeposit.check_deposit_instruction.group_id,
        })}
      >
        {checkDeposit.check_deposit_instruction.group_id}
      </StyledLink>
      <br />
      <StyledLink
        to={buildPath(ROUTES.ACCOUNTS_DETAIL, {
          accountId: checkDeposit.check_deposit_instruction.account_id,
        })}
      >
        {checkDeposit.check_deposit_instruction.account_id}
      </StyledLink>
      <OperatorTags modelId={checkDeposit.check_deposit_instruction.group_id} />
      <Button
        text="Previous Step"
        onClick={decrementStep}
        disabled={step <= 0}
      />
      <Button
        text="Next Step"
        onClick={incrementStep}
        disabled={step >= maxStepReached}
      />
      <div className="select-none">
        {(() => {
          switch (step) {
            case 0:
              return (
                <Box>
                  <Subheading contents="Step 1: Select the front of the check" />
                  <Body contents="Rotate the image until the check is facing up" />
                  <MultiImageTool
                    selectionMode="area"
                    url={frontImageURL}
                    minimumWidth={CHECK_IMAGE_MINIMUM_WIDTH}
                    minimumHeight={CHECK_IMAGE_MINIMUM_HEIGHT}
                    onPixelsExtracted={(pixels) => {
                      setFrontPixels(pixels);
                      incrementStep();
                    }}
                  />
                </Box>
              );
            case 1:
              return frontPixels ? (
                <Box>
                  <Subheading contents="Step 2: Adjust front pixels until the text is readable" />
                  <ImageThreshold
                    pixels={frontPixels}
                    initialThreshold={frontThreshold}
                    onLevelUpdated={(pixels, level) => {
                      setFrontMonotonePixels(pixels);
                      setFrontThreshold(level);
                      incrementStep();
                    }}
                  />
                </Box>
              ) : (
                <h2>Finish selecting front pixels</h2>
              );
            case 2:
              return (
                <Box>
                  <Subheading contents="Step 3: Select the back of the check" />
                  <Body contents="Rotate the image until the check is horizontal with the endorsement line on the right" />
                  <MultiImageTool
                    selectionMode="area"
                    url={backImageURL}
                    minimumWidth={CHECK_IMAGE_MINIMUM_WIDTH}
                    minimumHeight={CHECK_IMAGE_MINIMUM_HEIGHT}
                    onPixelsExtracted={(pixels) => {
                      setRearPixels(pixels);
                      incrementStep();
                    }}
                  />
                </Box>
              );
            case 3:
              return rearPixels ? (
                <Box>
                  <Subheading contents="Step 4: Adjust back pixels until the text is readable" />
                  <ImageThreshold
                    pixels={rearPixels}
                    initialThreshold={rearThreshold}
                    onLevelUpdated={(pixels, level) => {
                      setRearMonotonePixels(pixels);
                      setRearThreshold(level);
                      incrementStep();
                    }}
                  />
                </Box>
              ) : (
                <h2>Finish selecting rear pixels</h2>
              );
            case 4:
              return (
                <Box>
                  <Subheading contents="Step 5: select MICR Line" />
                  {frontPixels && (
                    <ImageToolInner
                      selectionMode="area"
                      imageSource={imageDataToCanvasImageSource(frontPixels)}
                      onPixelsExtracted={(pixels) => {
                        setMICRPixels(pixels);
                        incrementStep();
                      }}
                    />
                  )}
                </Box>
              );
            case 5:
              return micrPixels ? (
                <Box>
                  <Subheading contents="Step 6: Transcribe MICR Line" />
                  <TranscribeMICR
                    micrPixels={micrPixels}
                    micrResult={micrResult}
                    onUpdate={(newMicrResult) => {
                      setMICRResult(newMicrResult);
                      incrementStep();
                    }}
                  />
                </Box>
              ) : (
                <h2>Finish selecting MICR line</h2>
              );
            case 6:
              return (
                rearMonotonePixels && (
                  <Box>
                    <Subheading contents="Step 7 (optional): Add Endorsement" />
                    <Body>
                      If the check is not endorsed, click in the middle of the
                      endorsement line to add one. Press Enter to continue.
                    </Body>
                    <KeyListener
                      listenedKey="Enter"
                      onKeyPress={incrementStep}
                    />
                    <ImageToolInner
                      imageSource={imageDataToCanvasImageSource(
                        rearMonotonePixels
                      )}
                      selectionMode="point"
                      onPointSelected={(point) => {
                        setRearMonotonePixels(
                          addText(
                            rearMonotonePixels,
                            'For Deposit Only',
                            '42px Arial',
                            point
                          )
                        );
                      }}
                    />
                  </Box>
                )
              );
            case 7:
              return micrResult && frontMonotonePixels && rearMonotonePixels ? (
                <Box>
                  <Subheading contents="Step 8: Review and approve" />
                  <SubmitReview
                    checkDeposit={checkDeposit}
                    micr={micrResult}
                    front={frontMonotonePixels}
                    rear={rearMonotonePixels}
                  />
                </Box>
              ) : (
                <h2>
                  Finish selecting front pixels, rear pixels, and MICR line
                </h2>
              );
          }

          return <></>;
        })()}
      </div>
      <hr />
      <div className="w-1/3">
        <RejectCheckForm checkDeposit={checkDeposit} />
      </div>
    </PageLayout>
  );
};

export const CheckDepositReview = () => {
  const { checkDepositID } = useTypedParams(ROUTES.CHECK_DEPOSIT_REVIEW);

  const { operations } = useServices();
  const { data: checkDeposit } = useCheckDepositGet(checkDepositID);

  if (!checkDeposit) {
    return <h3>Loading</h3>;
  }

  const [frontImageURL, backImageURL] = [
    checkDeposit.check_deposit_instruction.front_image_file_id,
    checkDeposit.check_deposit_instruction.back_image_file_id,
  ].map((id) => `${operations}/api_files/${id}/view`);

  if (checkDeposit.status === 'pending_reviewing') {
    return (
      <CheckDepositReviewSteps
        checkDeposit={checkDeposit}
        frontImageURL={frontImageURL}
        backImageURL={backImageURL}
      />
    );
  }

  const acceptance =
    checkDeposit.check_deposit_instruction.check_deposit_acceptance;
  return (
    <PageLayout
      id="application.checkViewing"
      headline={
        <span>
          {checkDeposit.status} {checkDeposit.id}
        </span>
      }
      breadcrumbs={[{ text: 'Check Deposit Review' }]}
    >
      <StyledLink
        to={buildPath(ROUTES.GROUPS_DETAIL, {
          groupId: checkDeposit.check_deposit_instruction.group_id,
        })}
      >
        {checkDeposit.check_deposit_instruction.group_id}
      </StyledLink>
      <br />
      <StyledLink
        to={buildPath(ROUTES.ACCOUNTS_DETAIL, {
          accountId: checkDeposit.check_deposit_instruction.account_id,
        })}
      >
        {checkDeposit.check_deposit_instruction.account_id}
      </StyledLink>
      <OperatorTags modelId={checkDeposit.check_deposit_instruction.group_id} />
      {acceptance && (
        <PropertyList
          items={[
            { label: 'Amount', value: formatAmount(acceptance.amount, 'USD') },
            {
              label: 'Auxiliary on us',
              value: acceptance.auxiliary_on_us || '',
            },
            { label: 'Routing number', value: acceptance.routing_number },
            { label: 'Account number', value: acceptance.account_number },
            { label: 'Serial number', value: acceptance.serial_number || '' },
          ]}
        />
      )}

      <MultiImageTool url={frontImageURL} selectionMode="none" />
      <MultiImageTool url={backImageURL} selectionMode="none" />
    </PageLayout>
  );
};

import { compact } from 'lodash';
import { BadgeColor } from 'shared/components/Badge';
import {
  ListItem,
  PropertyList,
  PropertyListProps,
} from 'shared/components/PropertyList';
import { formatISO8601Timestamp, humanize } from 'shared/lib/formatting';
import {
  EntityGetResponse,
  EntityGetResponseAutomatedCustomerIdentificationProgramEvaluation,
  EntityGetResponseStatus,
} from 'src/build/operations';
import { useOperatorGet } from 'src/hooks/reactQuery';
import { useGroupPropertyRow } from 'src/lib/propertyListHelpers';

export type EntityPropertyListProps = {
  entity: EntityGetResponse;
} & Omit<PropertyListProps, 'items'>;

const statusBadgeColor: Record<EntityGetResponseStatus, BadgeColor> = {
  active: 'green',
  disabled: 'yellow',
  archived: 'gray',
};

const evaluationBadgeColor: Record<
  EntityGetResponseAutomatedCustomerIdentificationProgramEvaluation,
  BadgeColor
> = {
  valid: 'green',
  indeterminate: 'yellow',
  invalid: 'gray',
};

export const EntityPropertyList = (props: EntityPropertyListProps) => {
  const { entity: entity, ...rest } = props;

  const { data: operator } = useOperatorGet({});
  const groupRow = useGroupPropertyRow(entity.group_id);

  if (!operator) {
    return null;
  }

  return (
    <PropertyList
      {...rest}
      items={compact<ListItem>([
        {
          label: 'ID',
          value: entity.id,
          copyable: entity.id,
        },
        {
          label: 'Status',
          value: humanize(entity.status),
          badgeColor: statusBadgeColor[entity.status],
        },
        operator.role === 'internal' && {
          label: 'Internal status',
          value: humanize(
            entity.automated_customer_identification_program_evaluation
          ),
          badgeColor:
            evaluationBadgeColor[
              entity.automated_customer_identification_program_evaluation
            ],
        },
        operator.role === 'internal' && {
          label: 'Directly supervised?',
          value: entity.directly_supervised ? 'Yes' : 'No',
          tooltip:
            'Entities are either first-line supervised by Increase or their Group.',
        },
        {
          label: 'Structure',
          value: humanize(entity.structure),
        },

        groupRow,
        {
          label: 'Created',
          value: formatISO8601Timestamp(entity.created_at, 'medium'),
        },
      ])}
    />
  );
};

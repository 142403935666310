import { useMemo } from 'react';

import { BadgeColor } from 'shared/components/Badge';
import {
  PrestyledTableProps,
  Table,
  TableColumns,
} from 'shared/components/Table';
import { humanize } from 'shared/lib/formatting';
import { CustomerIdentificationProgramTestingBatchListResponseDataItem } from 'src/build/operations';
import { ROUTES, buildPath } from 'src/lib/routes';

type Props =
  PrestyledTableProps<CustomerIdentificationProgramTestingBatchListResponseDataItem>;

export const defaultRowProps = (
  datum: CustomerIdentificationProgramTestingBatchListResponseDataItem
) => ({
  href: buildPath(
    ROUTES.CUSTOMER_IDENTIFICATION_PROGRAM_TESTING_BATCHES_DETAILS,
    { batchId: datum.id }
  ),
  className: 'hover:bg-main-hover cursor-pointer transition-all group',
});

const batchStatusColors: Record<
  CustomerIdentificationProgramTestingBatchListResponseDataItem['status'],
  BadgeColor
> = {
  completed: 'green',
  in_progress: 'yellow',
};

export const CustomerIdentificationProgramTestingBatchTable = (
  props: Props
) => {
  const { getRowProps } = props;
  const columns = useMemo<
    TableColumns<CustomerIdentificationProgramTestingBatchListResponseDataItem>
  >(() => {
    return [
      {
        header: 'Period',
        expand: 1,
        contents: (batch) => {
          return {
            text: `${batch.period_start} through ${batch.period_end}`,
            textColor: 'emphasis',
            textWeight: 'medium',
          };
        },
      },
      {
        header: 'Bank',
        contents: (batch) => {
          return {
            text: batch.bank,
          };
        },
      },
      {
        header: 'Status',
        contents: (batch) => {
          return {
            text: humanize(batch.status),
            badgeColor: batchStatusColors[batch.status],
          };
        },
      },
    ];
  }, []);

  return (
    <Table
      {...props}
      getRowProps={getRowProps ?? defaultRowProps}
      columns={columns}
    />
  );
};

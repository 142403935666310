type PropertyListItemProps =
  | {
      label: string;
      value: string | React.ReactNode;
    }
  | {
      label: string;
      children: React.ReactNode;
    };

export const PropertyListItem = (props: PropertyListItemProps) => {
  const content = 'value' in props ? props.value : props.children;

  return (
    <div className="flex">
      <div className="flex w-1/2 max-w-[500px] grow-0 items-center py-2 pl-3 text-subtle">
        {props.label}
      </div>
      <div className="flex w-1/2 grow items-center py-2 pr-2 text-strong">
        {content}
      </div>
    </div>
  );
};

type PropertyListProps = {
  children: React.ReactNode | React.ReactNode[];
};

export const PropertyList = ({ children }: PropertyListProps) => {
  return (
    <div className="divide-y divide-main rounded border border-main text-sm shadow-sm">
      {children}
    </div>
  );
};

import { compact } from 'lodash';
import {
  ListItem,
  PropertyList,
  PropertyListProps,
} from 'shared/components/PropertyList';
import { formatISO8601Timestamp, humanize } from 'shared/lib/formatting';
import { UserGetResponse } from 'src/build/operations';

export type UserPropertyListProps = {
  user: UserGetResponse;
} & Omit<PropertyListProps, 'items'>;

export const UserPropertyList = (props: UserPropertyListProps) => {
  const { user, ...rest } = props;

  return (
    <PropertyList
      {...rest}
      items={compact<ListItem>([
        {
          label: 'ID',
          value: user.id,
          copyable: user.id,
        },
        {
          label: 'Email',
          value: user.email,
        },
        {
          label: 'Email verification status',
          value: humanize(user.email_verification_status),
        },
        {
          label: 'Created at',
          value: formatISO8601Timestamp(user.created_at, 'medium'),
        },
      ])}
    />
  );
};

import { compact } from 'lodash';
import { BadgeColor } from 'shared/components/Badge';
import {
  ListItem,
  PropertyList,
  PropertyListProps,
} from 'shared/components/PropertyList';
import { formatAddress, humanize } from 'shared/lib/formatting';
import {
  EntityGetResponseSetupSubmissionStructureCorporation,
  EntityGetResponseSetupSubmissionStructureCorporationAnyOfTaxIdValidationStatus,
} from 'src/build/operations';
import { useCategoricals } from 'src/hooks/use-categoricals';
import { nameFromMapping } from 'src/lib/name-from-mapping';

export type EntityPropertyListProps = {
  corporation: NonNullable<EntityGetResponseSetupSubmissionStructureCorporation>;
} & Omit<PropertyListProps, 'items'>;

export const taxIdValidationStatusBadgeColor: Record<
  EntityGetResponseSetupSubmissionStructureCorporationAnyOfTaxIdValidationStatus,
  BadgeColor
> = {
  pending_validating: 'blue',
  update_pending_validating: 'blue',
  requires_attention: 'yellow',
  failed: 'yellow',
  validated: 'green',
  not_provided: 'gray',
};

export const EntityCorporationPropertyList = (
  props: EntityPropertyListProps
) => {
  const { corporation: corporation, ...rest } = props;
  const categoricals = useCategoricals();

  if (!categoricals) {
    return null;
  }

  const industryName = corporation.industry_code
    ? nameFromMapping(categoricals.industry_code, corporation.industry_code)
    : null;

  const industryDisplay = industryName
    ? `${industryName} (${corporation.industry_code})`
    : corporation.industry_code || '-';

  return (
    <PropertyList
      {...rest}
      items={compact<ListItem>([
        {
          label: 'Name',
          value: corporation.submitted_name,
        },
        {
          label: 'Website',
          value: corporation.submitted_website ?? '-',
        },
        {
          label: 'Tax ID',
          value: corporation.submitted_tax_id ?? '-',
        },
        {
          label: 'Tax ID validation status',
          value: humanize(corporation.tax_id_validation_status),
          badgeColor:
            taxIdValidationStatusBadgeColor[
              corporation.tax_id_validation_status
            ],
        },
        {
          label: 'Incorporation state',
          value: corporation.submitted_state ?? '-',
        },
        {
          label: 'Address',
          value: formatAddress(corporation.submitted_address),
        },
        {
          label: 'Industry',
          value: industryDisplay,
        },
      ])}
    />
  );
};

import React from 'react';
import { PageLayout } from '../deprecated/PageLayout';
import { useControlConfigurationListInfinite } from 'src/hooks/reactQuery';
import { TableStateWrapper } from 'shared/components/TableStateWrapper';
import { ControlConfigurationsTable } from '../tables/ControlConfigurationsTable';
import {
  ControlConfigurationGetResponseResult,
  ControlConfigurationListCategoriesItem,
} from 'src/build/operations';
import { makeEnumFilter, makeGroupFilter } from 'src/lib/tableFilterHelpers';
import { ROUTES } from 'src/lib/routes';
import { useSearchParamsState } from 'shared/hooks/useTypedSearchParamsState';

export const ControlConfigurationsListPage = () => {
  const [results, setResults] = useSearchParamsState(
    ROUTES.CONTROLS_LIST,
    'results'
  );

  const [categories, setCategories] = useSearchParamsState(
    ROUTES.CONTROLS_LIST,
    'categories'
  );

  const [group, setGroup] = useSearchParamsState(ROUTES.CONTROLS_LIST, 'group');

  const controlConfigurationList = useControlConfigurationListInfinite({
    results: results.length === 0 ? undefined : results,
    categories: categories.length === 0 ? undefined : categories,
    groups: group.length === 0 ? undefined : group,
  });

  return (
    <PageLayout headline={'Controls'} breadcrumbs={[]}>
      <TableStateWrapper
        table={ControlConfigurationsTable}
        style="primary"
        {...controlConfigurationList}
        emptyTitle="No Controls"
        emptySubtitle="There are no controls to display"
        filters={[
          makeEnumFilter(
            results,
            setResults,
            'Result',
            ControlConfigurationGetResponseResult,
            'tick_circle'
          ),
          makeEnumFilter(
            categories,
            setCategories,
            'Category',
            ControlConfigurationListCategoriesItem,
            'account'
          ),
          makeGroupFilter(group, setGroup),
        ]}
      />
    </PageLayout>
  );
};

import classNames from 'classnames';

export type BadgeProps = {
  className?: string;
  children: React.ReactNode;
  color?: 'green' | 'blue' | 'red' | 'yellow' | 'gray';
};

export const Badge = ({ className, children, color = 'blue' }: BadgeProps) => {
  return (
    <div
      className={classNames(
        'flex items-center justify-center rounded-sm border px-1 py-0.5 text-xs font-medium',
        color === 'green' && 'border-success bg-success text-success',
        color === 'blue' && 'border-info bg-info text-info',
        color === 'red' && 'border-danger bg-danger text-danger',
        color === 'yellow' && 'border-warning bg-warning text-warning',
        color === 'gray' && 'border-disabled bg-disabled text-disabled',
        className
      )}
    >
      {children}
    </div>
  );
};

import type { AxiosRequestConfig } from 'axios';
import { useQuery } from '@tanstack/react-query';
import { useOperatorGet } from 'src/hooks/reactQuery';
import { useState, useCallback } from 'react';
import {
  limitsGet,
  limitsStatisticsGet,
  limitsChangeRequestList,
} from 'src/build/federalreservegateway/federalreservegateway';
import {
  LimitsChangeRequestPostParameters,
  LimitsChangeRequestListResponseLimitsChangeRequestsItem,
  LimitsStatisticsGetResponseFedwireCycleDatesItem,
  LimitsListResponseLimitsItem,
} from 'src/build/federalreservegateway/federalreservegateway.schemas';
import { useServices } from '../hooks/use-services';
import { PageLayout } from './deprecated/PageLayout';
import { Section } from './deprecated/Section';
import { Table } from './deprecated/Table';
import { Badge } from './deprecated/Badge';
import { Dialog } from '@headlessui/react';
import { PencilIcon } from '@heroicons/react/20/solid';
import { Button } from './deprecated/Button';
import {
  useFederalReserveGatewayRequestLimitChange,
  useFederalReserveGatewayConfirmLimitChange,
  useFederalReserveGatewayCancelLimitChange,
  FederalReserveGatewayLimitsQueryKey,
  FederalReserveGatewayRequestLimitsQueryKey,
} from 'src/hooks/reactQuery';
import { absurd } from 'src/lib/absurd';
import { formatAmount } from 'shared/lib/formatting';
import { PropertyList } from 'shared/components/PropertyList';
import { useTypedParams } from 'react-router-typesafe-routes/dom';
import { ROUTES } from 'src/lib/routes';
import { Box } from 'shared/components/Box';
import { Heading } from 'shared/components/Text';
import { AlertList } from 'shared/components/AlertList';

type LimitsEditorProps = {
  limitsId: string;
  onSubmitDone: () => void;
  currentUSDValue: number;
  title: string;
  postParameter: keyof LimitsChangeRequestPostParameters;
};

type LimitsHeaderProps = {
  routingNumber: string;
  limitsId: string;
  onSubmitDone: () => void;
};

type DisableFedwireButtonProps = {
  limitsId: string;
  onSubmitDone: () => void;
};

enum LimitRequestState {
  PENDING,
  CANCELED,
  CONFIRMED,
  EXPIRED,
}

function useAxiosOptions(): AxiosRequestConfig {
  const { federalreservegateway: baseURL } = useServices();
  return { baseURL };
}

const useLimitsChangeRequestsList = (
  limitsId: string,
  axiosOptions: AxiosRequestConfig
) => {
  return useQuery([FederalReserveGatewayRequestLimitsQueryKey, limitsId], () =>
    limitsChangeRequestList(limitsId, axiosOptions).then((result) => {
      return result.data;
    })
  );
};

const useLimitsGet = (limitsId: string, axiosOptions: AxiosRequestConfig) => {
  return useQuery([FederalReserveGatewayLimitsQueryKey, limitsId], () =>
    limitsGet(limitsId, axiosOptions).then((result) => {
      return result.data;
    })
  );
};

const limitsStatisticsQueryKey = 'federalreservegateway/limit-statistics';
const useLimitsStatisticsGet = (
  limitsId: string,
  axiosOptions: AxiosRequestConfig
) => {
  return useQuery([limitsStatisticsQueryKey, limitsId], () =>
    limitsStatisticsGet(limitsId, axiosOptions).then((result) => {
      return result.data;
    })
  );
};

const DisableFedwireButton = ({
  limitsId,
  onSubmitDone,
}: DisableFedwireButtonProps) => {
  const axiosOptions = useAxiosOptions();
  const [modalShowing, setModalShowing] = useState(false);
  const requestLimitChange = useFederalReserveGatewayRequestLimitChange();
  const submit = () => {
    requestLimitChange
      .mutateAsync([
        limitsId,
        { fedwire_cycle_date_limit_usd: 0 },
        axiosOptions,
      ])
      .then(() => {
        onSubmitDone();
        setModalShowing(false);
      });
  };

  return (
    <>
      <Button
        data-testid="showModalButton"
        className="ml-2 bg-danger"
        onClick={() => setModalShowing(true)}
      >
        Disable Fedwire
      </Button>

      <Dialog
        open={modalShowing}
        onClose={() => setModalShowing(false)}
        className={'relative z-50'}
      >
        <div className="fixed inset-0 flex items-center justify-center bg-inverse/50 p-4">
          <Dialog.Panel className="w-full max-w-sm rounded bg-main p-4">
            <Dialog.Title>Disable Fedwire?</Dialog.Title>
            <div>
              {requestLimitChange.error && (
                <AlertList
                  tasks={[
                    {
                      icon: 'info',
                      title: 'Error',
                      body: requestLimitChange.error.response?.data.message,
                      key: '0',
                      style: 'error',
                    },
                  ]}
                />
              )}
            </div>
            <div className="mt-2 flex items-center space-x-2">
              <Button
                onClick={() => setModalShowing(false)}
                disabled={requestLimitChange.isLoading}
              >
                Cancel
              </Button>
              <Button
                onClick={submit}
                disabled={requestLimitChange.isLoading}
                className="bg-danger"
              >
                Request
              </Button>
            </div>
          </Dialog.Panel>
        </div>
      </Dialog>
    </>
  );
};

const LimitsHeader = ({
  routingNumber,
  limitsId,
  onSubmitDone,
}: LimitsHeaderProps) => {
  return (
    <Box flex className="justify-between">
      <Heading>
        Limits for routing number:{' '}
        <span className="underline">{routingNumber}</span>
      </Heading>

      <DisableFedwireButton limitsId={limitsId} onSubmitDone={onSubmitDone} />
    </Box>
  );
};

function limitRequestState(
  limitRequest: LimitsChangeRequestListResponseLimitsChangeRequestsItem
): LimitRequestState {
  if (limitRequest.confirmed_by) {
    return LimitRequestState.CONFIRMED;
  }

  if (limitRequest.canceled_by) {
    return LimitRequestState.CANCELED;
  }

  if (new Date() > new Date(limitRequest.expires_at)) {
    return LimitRequestState.EXPIRED;
  }

  return LimitRequestState.PENDING;
}

const LimitsChangeRequestActions = ({
  limits,
  request,
  refetchLimits,
}: {
  limits: LimitsListResponseLimitsItem;
  request: LimitsChangeRequestListResponseLimitsChangeRequestsItem;
  refetchLimits: () => void;
}) => {
  const axiosOptions = useAxiosOptions();
  const { data: operator } = useOperatorGet({});
  const cancelLimitChange = useFederalReserveGatewayCancelLimitChange();
  const confirmLimitChange = useFederalReserveGatewayConfirmLimitChange();
  const cancelRequest = useCallback(
    async (limitsId: string, requestId: string) =>
      await cancelLimitChange.mutateAsync([limitsId, requestId, axiosOptions]),
    [cancelLimitChange, axiosOptions]
  );
  const confirmRequest = useCallback(
    async (limitsId: string, requestId: string) => {
      await confirmLimitChange.mutateAsync([limitsId, requestId, axiosOptions]);
      refetchLimits();
    },
    [confirmLimitChange, axiosOptions, refetchLimits]
  );

  const error = cancelLimitChange.error ?? confirmLimitChange.error;
  if (error) {
    return (
      <AlertList
        tasks={[
          {
            icon: 'info',
            title: 'Error',
            body: error.response?.data.message,
            key: '0',
            style: 'error',
          },
        ]}
      />
    );
  }

  const state = limitRequestState(request);
  switch (state) {
    case LimitRequestState.CONFIRMED:
      return (
        <Badge className="px-2" color="green">
          Confirmed
        </Badge>
      );
    case LimitRequestState.CANCELED:
      return (
        <Badge className="px-2" color="yellow">
          Canceled
        </Badge>
      );
    case LimitRequestState.EXPIRED:
      return (
        <Badge className="px-2" color="yellow">
          Expired
        </Badge>
      );
    case LimitRequestState.PENDING:
      return (
        <>
          <Button
            onClick={() => cancelRequest(limits.id, request.id)}
            data-testid="cancelButton"
          >
            Cancel
          </Button>
          <Button
            disabled={!operator || operator.email === request.requested_by}
            onClick={() => confirmRequest(limits.id, request.id)}
            data-testid="confirmButton"
            className="bg-success"
          >
            Confirm
          </Button>
        </>
      );
    default:
      absurd(state);
  }
};

const LimitsChangeRequestsTable = ({
  limits,
  refetchLimits,
}: {
  limits: LimitsListResponseLimitsItem;
  refetchLimits: () => void;
}) => {
  const axiosOptions = useAxiosOptions();
  const { data } = useLimitsChangeRequestsList(limits.id, axiosOptions);
  const tableColumns = [
    {
      key: 'limit_name',
      label: 'Limit',
      render: (
        request: LimitsChangeRequestListResponseLimitsChangeRequestsItem
      ) => request.which_limit,
    },
    {
      key: 'limit_value',
      label: '$ USD',
      render: (
        request: LimitsChangeRequestListResponseLimitsChangeRequestsItem
      ) => request.amount && formatAmount(request.amount, 'USD', false),
    },
    {
      key: 'requested_by',
      label: 'Requested by',
    },
    {
      key: 'actioned_by',
      label: 'Actioned by',
      render: (
        request: LimitsChangeRequestListResponseLimitsChangeRequestsItem
      ) => request.confirmed_by || request.canceled_by,
    },
    {
      key: 'actioned_at',
      label: 'Actioned at',
      render: (
        request: LimitsChangeRequestListResponseLimitsChangeRequestsItem
      ) => request.confirmed_at || request.canceled_at,
    },
    {
      key: 'action',
      label: 'Actions',
      render: (
        request: LimitsChangeRequestListResponseLimitsChangeRequestsItem
      ) => (
        <div className="flex items-center space-x-1">
          <LimitsChangeRequestActions
            refetchLimits={refetchLimits}
            limits={limits}
            request={request}
          />
        </div>
      ),
    },
  ];

  return (
    <>
      {data && (
        <Table columns={tableColumns} data={data.limits_change_requests} />
      )}
    </>
  );
};

const LimitsEditor = ({
  title,
  limitsId,
  currentUSDValue,
  postParameter,
  onSubmitDone,
}: LimitsEditorProps) => {
  const axiosOptions = useAxiosOptions();
  const [modalShowing, setModalShowing] = useState(false);
  const [newValue, setNewValue] = useState<number | null>(null);
  const requestLimitChange = useFederalReserveGatewayRequestLimitChange();

  const submit = () => {
    requestLimitChange
      .mutateAsync([
        limitsId,
        { [postParameter]: newValue as number },
        axiosOptions,
      ])
      .then(() => {
        onSubmitDone();
        setModalShowing(false);
        setNewValue(null);
      });
  };

  return (
    <>
      <Button
        data-testid="showModalButton"
        className="mb-1 ml-2"
        onClick={() => setModalShowing(true)}
      >
        <PencilIcon className="h-4 w-4" />
      </Button>

      <Dialog
        open={modalShowing}
        onClose={() => setModalShowing(false)}
        className={'relative z-50'}
      >
        <div className="fixed inset-0 flex items-center justify-center bg-inverse/50 p-4">
          <Dialog.Panel className="w-full max-w-sm rounded bg-main p-4">
            <Dialog.Title>
              <strong>Edit limit</strong>
            </Dialog.Title>
            <div>
              <>
                <PropertyList
                  items={[
                    {
                      label: 'Current',
                      value: currentUSDValue,
                      currency: 'USD',
                      minimum: 0,
                      onEdit: async (n: number) => setNewValue(n),
                    },
                  ]}
                />
                <div>
                  <div className="py-2">
                    {title}
                    <p>
                      <em>Current: {formatAmount(currentUSDValue, 'USD')}</em>
                    </p>
                  </div>
                  <div>
                    {/* <USDInput value={newValue} onChange={setNewValue} /> */}
                  </div>
                </div>

                {requestLimitChange.error && (
                  <AlertList
                    tasks={[
                      {
                        icon: 'info',
                        title: 'Error',
                        body: requestLimitChange.error.response?.data.message,
                        key: '0',
                        style: 'error',
                      },
                    ]}
                  />
                )}
              </>
            </div>
            <div className="mt-2 flex items-center space-x-2">
              <Button
                onClick={() => setModalShowing(false)}
                disabled={requestLimitChange.isLoading}
              >
                Cancel
              </Button>
              <Button
                onClick={submit}
                disabled={newValue === null || requestLimitChange.isLoading}
                className="bg-success"
              >
                Request
              </Button>
            </div>
          </Dialog.Panel>
        </div>
      </Dialog>
    </>
  );
};

const LimitsStatistics = ({
  limits,
}: {
  limits: LimitsListResponseLimitsItem;
}) => {
  const axiosOptions = useAxiosOptions();
  const { data } = useLimitsStatisticsGet(limits.id, axiosOptions);
  if (!data) {
    return <div>Loading...</div>;
  }

  const columns = [
    {
      key: 'cycle_date',
      label: 'Cycle date',
      render: (cycleDate: LimitsStatisticsGetResponseFedwireCycleDatesItem) =>
        cycleDate.date,
    },
    {
      key: 'sum',
      label: '$ USD',
      render: (cycleDate: LimitsStatisticsGetResponseFedwireCycleDatesItem) =>
        formatAmount(cycleDate.amount, 'USD', false),
    },
  ];

  const rows = data.fedwire_cycle_dates.map((cycleDate) => ({
    id: cycleDate.date,
    ...cycleDate,
  }));

  return <Table columns={columns} data={rows} />;
};

export const FederalReserveLimits = () => {
  const { limitsId } = useTypedParams(ROUTES.FEDERAL_RESERVE_LIMIT_DETAILS);

  const axiosOptions = useAxiosOptions();
  const { data: limits, refetch } = useLimitsGet(limitsId, axiosOptions);
  if (!limits) {
    return <div>Loading...</div>;
  }

  return (
    <PageLayout
      id="federal-reserve-limits"
      headline="Federal Reserve Limits"
      breadcrumbs={[]}
    >
      <Section
        header={
          <LimitsHeader
            limitsId={limits.id}
            routingNumber={limits.routing_number}
            onSubmitDone={refetch}
          />
        }
      >
        <Box>
          <Heading>Fedwire per-cycle limit</Heading>
          <div className="flex w-full items-center justify-end">
            {formatAmount(limits.fedwire_cycle_date_limit_usd, 'USD')}
            <LimitsEditor
              title="New per-cycle Fedwire limit"
              postParameter="fedwire_cycle_date_limit_usd"
              onSubmitDone={refetch}
              limitsId={limits.id}
              currentUSDValue={limits.fedwire_cycle_date_limit_usd}
            />
          </div>
        </Box>
      </Section>
      <Section header="Limit change requests">
        <LimitsChangeRequestsTable limits={limits} refetchLimits={refetch} />
      </Section>
      <Section header="Fedwire per-cycle volume">
        <LimitsStatistics limits={limits} />
      </Section>
    </PageLayout>
  );
};

import { compact } from 'lodash';
import {
  ListItem,
  PropertyList,
  PropertyListProps,
} from 'shared/components/PropertyList';
import {
  formatAmount,
  formatISO8601Timestamp,
  humanize,
} from 'shared/lib/formatting';
import { TransactionGetResponse } from 'src/build/operations';
import {
  useGroupPropertyRow,
  useAccountPropertyRow,
} from 'src/lib/propertyListHelpers';
import { ROUTES, buildPath } from 'src/lib/routes';

type TransactionPropertyListProps = {
  transaction: TransactionGetResponse;
} & Omit<PropertyListProps, 'items'>;

export const TransactionPropertyList = (
  props: TransactionPropertyListProps
) => {
  const { transaction, ...rest } = props;

  const groupRow = useGroupPropertyRow(transaction.group_id);
  const accountRow = useAccountPropertyRow(transaction.account_id);

  const dashboardUrl = `https://dashboard.increase.com/transactions/${transaction.id}`;

  return (
    <PropertyList
      {...rest}
      items={compact<ListItem>([
        {
          label: 'ID',
          value: transaction.id,
          copyable: transaction.id,
        },
        {
          label: 'Type',
          value: humanize(transaction.source_type.replaceAll('API::', '')),
        },
        {
          label: 'Amount',
          value: formatAmount(transaction.amount, transaction.currency),
        },
        {
          label: 'Sign',
          value:
            transaction.amount > 0
              ? 'Positive. Account balance increases.'
              : 'Negative. Account balance decreases.',
        },
        {
          label: 'Date',
          value: formatISO8601Timestamp(transaction.date, 'full'),
        },
        {
          label: 'Created at',
          value: formatISO8601Timestamp(transaction.created_at, 'full'),
        },
        groupRow,
        accountRow,
        transaction.route_id && transaction.route_type === 'API::Card'
          ? {
              label: 'Card',
              value: transaction.route_id,
              href: buildPath(ROUTES.CARD, {
                cardID: transaction.route_id,
              }),
            }
          : undefined,
        { label: 'Dashboard URL', value: 'Copy', copyable: dashboardUrl },
      ])}
    />
  );
};

import {
  TableColumns,
  Table,
  PrestyledTableProps,
} from 'shared/components/Table';
import {
  formatISO8601Timestamp,
  formatAmount,
  humanize,
} from 'shared/lib/formatting';
import { useMemo } from 'react';

import {
  AccountGetResponse,
  AccountGetResponseStatus,
} from 'src/build/operations';
import { ROUTES, buildPath } from 'src/lib/routes';

export type AccountsTableProps = {
  showProgram: boolean;
  showBank: boolean;
  showGroup: boolean;
} & PrestyledTableProps<AccountGetResponse>;

const readableStatus = (status: AccountGetResponseStatus): string =>
  ({ open: 'Open', closed: 'Closed' })[status];

export const AccountsTable = ({
  showBank,
  showProgram,
  showGroup,
  ...rest
}: AccountsTableProps) => {
  const columns = useMemo<TableColumns<AccountGetResponse>>(
    () => [
      {
        expand: 1,
        header: 'Account name',
        contents: (account) => ({
          text: account.name,
          textWeight: 'medium',
          textColor: 'emphasis',
        }),
      },
      showBank && {
        header: 'Bank',
        contents: (account) => ({
          text: humanize(account.bank),
        }),
      },
      showGroup && {
        header: 'Group',
        contents: (account) => ({
          text: account.group_id,
          href: buildPath(ROUTES.GROUPS_DETAIL, {
            groupId: account.group_id,
          }),
        }),
      },
      showProgram && {
        header: 'Program',
        contents: (account) => ({
          text: account.program_name,
          href: buildPath(ROUTES.PROGRAMS_DETAIL, {
            programId: account.program_id,
          }),
        }),
      },
      {
        header: 'Opened',
        contents: (account) => ({
          text: formatISO8601Timestamp(account.created_at, 'medium'),
        }),
      },
      {
        header: 'Status',
        contents: (account) => ({
          text: readableStatus(account.status),
          badgeColor: account.status === 'open' ? 'gray' : 'red',
        }),
      },
      {
        align: 'right',
        header: 'Balance',
        contents: (account) => ({
          text: formatAmount(account.current_balance, 'USD'),
          textWeight: 'bold',
          textColor: 'emphasis',
        }),
      },
    ],
    [showBank, showGroup, showProgram]
  );

  const getRowProps = (datum: AccountGetResponse) => ({
    href: buildPath(ROUTES.ACCOUNTS_DETAIL, {
      accountId: datum.id,
    }),
    className: 'hover:bg-main-hover cursor-pointer transition-all group',
  });

  return (
    <Table
      columns={columns}
      getRowProps={rest.getRowProps ?? getRowProps}
      {...rest}
    />
  );
};

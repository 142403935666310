import classNames from 'classnames';

export type TextInputProps = JSX.IntrinsicElements['input'] & {
  className?: string;
  hasError?: boolean;
  padding?: 'normal' | 'large';
} & { value?: string };

const SIZE_CLASSNAMES = {
  normal: 'text-[14px] leading-[20px] h-[32px] px-[12px] py-[6px] rounded',
  large: 'text-[14px] leading-[20px] h-[44px] px-[12px] py-[12px] rounded',
};

const TextInput = ({
  className,
  hasError,
  autoComplete,
  padding = 'normal',
  value,
  ...rest
}: TextInputProps) => {
  return (
    <input
      className={classNames(
        'relative',
        SIZE_CLASSNAMES[padding],
        'block w-full font-[440] text-strong outline-none placeholder:text-disabled',
        'input',
        hasError ? 'input-focus-error z-10' : '',
        className
      )}
      data-1p-ignore={autoComplete === 'off'}
      value={value ?? ''}
      {...rest}
    />
  );
};

export { TextInput };

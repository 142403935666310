import classNames from 'classnames';
import account from './Illustrations/iso-account.svg';
import bankStatement from './Illustrations/iso-bank-statement.svg';
import metrics from './Illustrations/iso-metrics.svg';
import protection from './Illustrations/iso-protection.svg';
import searchResult from './Illustrations/iso-search-result.svg';
import transactions2 from './Illustrations/iso-transactions-2.svg';
import transactions3 from './Illustrations/iso-transactions-3.svg';

const illustrations = {
  account,
  bankStatement,
  metrics,
  protection,
  searchResult,
  transactions2,
  transactions3,
};

export type IllustrationType = keyof typeof illustrations;

export const ALL_ILLUSTRATIONS = illustrations;

type Props = {
  name: IllustrationType;
  className?: string;
};

const Illustration = ({ name, className }: Props) => {
  const Component = illustrations[name];
  return (
    <Component className={classNames(className, 'fill-main stroke-main')} />
  );
};
export { Illustration };

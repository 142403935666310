import React, { useMemo, useState } from 'react';
import {
  useOperatorGet,
  useTransferReviewListInfinite,
  useTransferReviewingRejectDirectivePost,
  useTransferReviewingReleaseDirectivePost,
} from 'src/hooks/reactQuery';
import { PageLayout } from './deprecated/PageLayout';
import { TextInput } from './deprecated/TextInput';
import { TableStateWrapper } from 'shared/components/TableStateWrapper';
import { TransferReviewsTable } from './tables/TransferReviewsTable';
import { useToast } from './layout';
import { formatAmount, humanize } from 'shared/lib/formatting';
import { Subheading } from 'shared/components/Text';
import { Button } from 'shared/components/Button';
import {
  TransferReviewListReasonsItem,
  TransferReviewListResponseDataItem,
  TransferReviewListResponseDataItemTransferType,
  TransferReviewListReviewersItem,
} from 'src/build/operations';
import {
  makeBankFilter,
  makeEnumFilter,
  makeGroupFilter,
} from 'src/lib/tableFilterHelpers';
import { useSearchParamsState } from 'shared/hooks/useTypedSearchParamsState';
import { ROUTES } from 'src/lib/routes';

const BULK_BATCH_SIZE = 100;

const TransferReviewSummaryToast = ({
  selectedReviews,
  onReject,
  onRelease,
}: {
  selectedReviews: TransferReviewListResponseDataItem[];
  onReject: () => void;
  onRelease: () => void;
}) => {
  const { data } = useOperatorGet({});

  const releaseHandler = useTransferReviewingReleaseDirectivePost();
  const rejectHandler = useTransferReviewingRejectDirectivePost();
  const [note, setNote] = useState('');
  const [acting, setActing] = useState(false);

  const release = async () => {
    setActing(true);
    for (let i = 0; i < selectedReviews.length; i++) {
      const batch = selectedReviews.slice(i, i + BULK_BATCH_SIZE);
      await Promise.all(
        batch.map((review) =>
          releaseHandler.mutateAsync([
            review.transfer_id,
            note ? { note: note } : {},
          ])
        )
      );
    }

    onRelease();
    setActing(false);
  };

  const reject = async () => {
    setActing(true);
    await Promise.all(
      selectedReviews.map((review) =>
        rejectHandler.mutateAsync([
          review.transfer_id,
          note ? { note: note } : {},
        ])
      )
    );
    onReject();
    setActing(false);
  };

  return (
    <div className="flex items-center space-x-3">
      <div>
        <Subheading size="small" color="primary">
          {selectedReviews.length} transfer
          {selectedReviews.length > 1 ? 's' : ''} selected, totaling{' '}
          {formatAmount(
            selectedReviews.reduce((sum, r) => sum + r.amount, 0),
            'USD'
          )}{' '}
        </Subheading>
      </div>
      <div className="grow" />
      <TextInput
        placeholder="Optional note"
        value={note}
        onChange={setNote}
        disabled={acting || data?.role === 'partner_read_only'}
      />
      <Button
        style="warning"
        text="Reject"
        keyboardShortcut="⌘J"
        disabled={acting || data?.role === 'partner_read_only'}
        onClick={reject}
      />
      <Button
        style="primary"
        text="Release"
        keyboardShortcut="⌘K"
        disabled={acting || data?.role === 'partner_read_only'}
        onClick={release}
      />
    </div>
  );
};

const labeledTransferTypes: Record<
  TransferReviewListResponseDataItemTransferType,
  string
> = Object.entries(TransferReviewListResponseDataItemTransferType).reduce(
  (acc, [key, value]) => ({ ...acc, [value]: humanize(key) }),
  {} as Record<TransferReviewListResponseDataItemTransferType, string>
);

const labeledReasons: Record<TransferReviewListReasonsItem, string> = {
  transfer_amount_absolute_value: 'Transfer amount absolute value',
  combined_rolling_24_hour_transfer_volume:
    'Combined rolling 24 hour transfer volume',
  rolling_24_hour_volume_for_transfer_type:
    'Rolling 24 hour volume for transfer type',
  credit_exposure: 'Credit exposure',
};

const labeledReviewers: Record<TransferReviewListReviewersItem, string> = {
  grasshopper: 'Grasshopper Bank',
  increase: 'Increase',
};

export const TransferReviewing = () => {
  const { data: operator } = useOperatorGet({});

  const [groupFilter, setGroupFilter] = useSearchParamsState(
    ROUTES.TRANSFER_REVIEWING,
    'group'
  );

  const [transferTypeFilter, setTransferTypeFilter] = useSearchParamsState(
    ROUTES.TRANSFER_REVIEWING,
    'transfer_type'
  );

  const [reasonFilter, setReasonFilter] = useSearchParamsState(
    ROUTES.TRANSFER_REVIEWING,
    'reason'
  );
  const [reviewerFilter, setReviewerFilter] = useSearchParamsState(
    ROUTES.TRANSFER_REVIEWING,
    'reviewer'
  );
  const [bankFilter, setBankFilter] = useSearchParamsState(
    ROUTES.TRANSFER_REVIEWING,
    'bank'
  );

  const transferReviewListResult = useTransferReviewListInfinite({
    groups: groupFilter.length > 0 ? groupFilter : undefined,
    transfer_types:
      transferTypeFilter.length > 0 ? transferTypeFilter : undefined,
    reasons: reasonFilter.length > 0 ? reasonFilter : undefined,
    reviewers: reviewerFilter.length > 0 ? reviewerFilter : undefined,
    banks: bankFilter.length > 0 ? bankFilter : undefined,
  });

  const [selections, setSelections] = useState<string[]>([]);
  const { showToast, closeToast } = useToast();
  const transferReviews = transferReviewListResult.data?.pages.flatMap(
    (page) => page.data
  );

  const handleRowSelection = (selected: string[]) => {
    if (!transferReviews) {
      return;
    }
    const selectedReviews = transferReviews.filter((review) =>
      selected.includes(review.id)
    );
    if (selectedReviews.length > 0) {
      showToast(
        <TransferReviewSummaryToast
          selectedReviews={selectedReviews}
          onReject={() => {
            closeToast();
            setSelections([]);
          }}
          onRelease={() => {
            closeToast();
            setSelections([]);
          }}
        />
      );
    } else {
      closeToast();
    }
    setSelections(selected);
  };

  const filters = useMemo(
    () => [
      makeGroupFilter(groupFilter, setGroupFilter),
      makeBankFilter(bankFilter, setBankFilter),
      makeEnumFilter(
        transferTypeFilter,
        setTransferTypeFilter,
        'Transfer Type',
        labeledTransferTypes,
        'transfer'
      ),
      makeEnumFilter(
        reasonFilter,
        setReasonFilter,
        'Reason Type',
        labeledReasons,
        'info'
      ),
      makeEnumFilter(
        reviewerFilter,
        setReviewerFilter,
        'Reviewer',
        labeledReviewers,
        'user'
      ),
    ],
    [
      groupFilter,
      setGroupFilter,
      bankFilter,
      setBankFilter,
      transferTypeFilter,
      setTransferTypeFilter,
      reasonFilter,
      setReasonFilter,
      reviewerFilter,
      setReviewerFilter,
    ]
  );

  const selectable = useMemo(
    () =>
      (row: TransferReviewListResponseDataItem): boolean =>
        !operator?.bank
          ? row.reviewer === 'increase'
          : operator.role === 'partner_read_write',
    [operator]
  );

  return (
    <PageLayout headline="Transfers pending manual review">
      <TableStateWrapper
        {...transferReviewListResult}
        style="primary"
        table={TransferReviewsTable}
        emptyTitle="No transfers pending review"
        emptySubtitle="There are no transfers pending review."
        filters={filters}
        selectedRows={selections}
        onSelectedRowsChange={handleRowSelection}
        selectable={selectable}
      />
    </PageLayout>
  );
};

import {
  TableColumns,
  Table,
  PrestyledTableProps,
} from 'shared/components/Table';
import { useMemo } from 'react';

import { BankFeeListResponseDataItem } from 'src/build/operations';
import {
  formatAmount,
  formatISO8601Timestamp,
  humanize,
} from 'shared/lib/formatting';

type Props = PrestyledTableProps<BankFeeListResponseDataItem>;

export const BankFeesTable = (props: Props) => {
  const { ...rest } = props;
  const columns = useMemo<TableColumns<BankFeeListResponseDataItem>>(() => {
    return [
      {
        header: 'Date',
        contents: (fee) => ({
          text: formatISO8601Timestamp(fee.date, 'medium'),
        }),
      },
      {
        header: 'Identifier',
        expand: 1,
        contents: (fee) => ({
          text: fee.id,
          textWeight: 'medium',
          textColor: 'primary',
        }),
      },
      {
        header: 'Type',
        expand: 1,
        contents: (fee) => ({
          text: humanize(fee.fee_type),
        }),
      },

      {
        header: 'Amount',
        align: 'right',
        contents: (fee) => ({
          text: formatAmount(parseFloat(fee.amount), 'USD', true, 3),
          textWeight: 'bold',
          textColor: 'primary',
        }),
      },
    ];
  }, []);

  return <Table<BankFeeListResponseDataItem> columns={columns} {...rest} />;
};

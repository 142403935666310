import { useMemo } from 'react';

import { BadgeColor } from 'shared/components/Badge';
import {
  PrestyledTableProps,
  Table,
  TableColumns,
} from 'shared/components/Table';
import { formatISO8601Timestamp, humanize } from 'shared/lib/formatting';
import {
  PlatformUnusualActivityReportListResponseDataItem,
  PlatformUnusualActivityReportListResponseDataItemIncreaseReviewStatus,
} from 'src/build/operations';
import { buildPath, ROUTES } from 'src/lib/routes';

type PlatformComplaintTableProps =
  PrestyledTableProps<PlatformUnusualActivityReportListResponseDataItem>;

const defaultRowProps = (
  datum: PlatformUnusualActivityReportListResponseDataItem
) => {
  return {
    href: buildPath(ROUTES.UNUSUAL_ACTIVITY_REPORTS_FOR_PLATFORM_DETAIL, {
      unusualActivityReportId: datum.id,
    }),
    className: 'hover:bg-main-hover cursor-pointer transition-all group',
  };
};

export const increaseReviewStatusBadgeColor: Record<
  PlatformUnusualActivityReportListResponseDataItemIncreaseReviewStatus,
  BadgeColor
> = {
  reviewing: 'blue',
  reviewed: 'green',
  skipped: 'gray',
};

export const PlatformUnusualActivityReportsTable = (
  props: PlatformComplaintTableProps
) => {
  const { getRowProps, ...rest } = props;
  const columns = useMemo<
    TableColumns<PlatformUnusualActivityReportListResponseDataItem>
  >(() => {
    return [
      {
        header: 'Group',
        expand: 1,
        contents: (report) => {
          return {
            text: report.group_id,
            href: buildPath(ROUTES.GROUPS_DETAIL, {
              groupId: report.group_id,
            }),
          };
        },
      },
      {
        header: 'Identifier',
        expand: 1,
        contents: (report) => {
          return {
            text: report.id,
            textWeight: 'mono',
          };
        },
      },
      {
        header: 'Increase Review Status',
        align: 'right',
        contents: (log) => ({
          text: humanize(log.increase_review_status),
          badgeColor:
            increaseReviewStatusBadgeColor[log.increase_review_status],
        }),
      },
      {
        header: 'Submitted to Increase',
        align: 'right',
        contents: (log) => ({
          text: formatISO8601Timestamp(log.created_at, 'medium'),
        }),
      },
    ];
  }, []);

  return (
    <Table
      {...rest}
      getRowProps={getRowProps ?? defaultRowProps}
      columns={columns}
    />
  );
};

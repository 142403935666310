import { PageLayout } from './deprecated/PageLayout';
import { Table } from './deprecated/Table';
import { Button } from './deprecated/Button';
import { useState, useEffect } from 'react';
import { useOperatorGet } from 'src/hooks/reactQuery';
import {
  complianceDocumentList,
  complianceDocumentDeleteDirectivePost,
  ComplianceDocumentListResponse,
  ComplianceDocumentListResponseDataItem,
} from 'src/build/operations';
import { InternalOperatorOnly } from './internal-operator-only';
import { useServices } from 'src/hooks/use-services';
import { StyledLink } from './deprecated/StyledLink';
import { ROUTES, buildPath } from 'src/lib/routes';

interface ComplianceDocument {
  id: string;
  name: string;
  category: string;
}

export const ComplianceDocuments = () => {
  const [data, setData] = useState<ComplianceDocumentListResponse['data']>([]);
  const { operations } = useServices();
  const { data: operator } = useOperatorGet({});

  useEffect(() => {
    const fetchData = async () => {
      const response = await complianceDocumentList();
      setData(response.data.data);
    };

    fetchData();
  }, []);

  if (!operator) {
    return <div>Loading...</div>;
  }

  const deleteComplianceDocument = async (documentId: string) => {
    await complianceDocumentDeleteDirectivePost(documentId);
    setData(data.filter((item) => item.id !== documentId));
  };

  const groupByCategory = (
    documents: ComplianceDocumentListResponse['data']
  ) => {
    return documents.reduce(
      (
        accumulator: { [key: string]: ComplianceDocumentListResponse['data'] },
        currentItem
      ) => {
        (accumulator[currentItem.category_pretty_name] =
          accumulator[currentItem.category_pretty_name] || []).push(
          currentItem
        );
        return accumulator;
      },
      {}
    );
  };

  const groupedData = groupByCategory(data);

  const columns = [
    {
      key: 'document_name',
      label: 'Policy',
      render: (item: ComplianceDocument) => item.name,
    },
    {
      key: 'download',
      label: 'Download',
      render: (item: ComplianceDocument) => (
        <a
          href={`${operations}/compliance_documents/${item.id}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          Download
        </a>
      ),
    },
  ];

  const isInternalOperator = operator.role === 'internal';

  if (isInternalOperator) {
    columns.push({
      key: 'delete',
      label: 'Delete (internal only)',
      render: (item: ComplianceDocument) => (
        <Button onClick={() => deleteComplianceDocument(item.id)}>
          Delete
        </Button>
      ),
    });
  }

  return (
    <PageLayout
      headline="Increase Compliance Documents"
      action={
        <InternalOperatorOnly>
          <StyledLink
            to={buildPath(ROUTES.MANUAL_TASK, {
              taskName:
                'Operations::ManualTask::CreateBankFacingComplianceDocument',
            })}
          >
            Add Compliance Document
          </StyledLink>
        </InternalOperatorOnly>
      }
      breadcrumbs={[]}
    >
      {Object.entries(groupedData).map(([category_pretty_name, items]) => (
        <div key={category_pretty_name}>
          <h2>{category_pretty_name}</h2>
          <Table
            data={items
              .sort((a, b) => a.name.localeCompare(b.name))
              .map((item: ComplianceDocumentListResponseDataItem) => ({
                id: item.id,
                name: item.name,
                category: item.category,
              }))}
            columns={columns}
          />
        </div>
      ))}
    </PageLayout>
  );
};

import {
  useAccountBalanceGet,
  useAccountGet,
  useAccountNumberListInfinite,
  useCardListInfinite,
  useCompletedTransactionListInfinite,
  usePendingTransactionListInfinite,
} from 'src/hooks/reactQuery';
import { PageLayout } from '../deprecated/PageLayout';
import { SideBySide } from 'shared/components/SideBySide';
import { Loading } from 'shared/components/Loading';
import { TableStateWrapper } from 'shared/components/TableStateWrapper';
import { CompletedTransactionsTable } from '../tables/CompletedTransactionTable';
import { AccountPropertyList } from '../property-lists/AccountPropertyList';
import { Box } from 'shared/components/Box';
import { Heading } from 'shared/components/Text';
import { OperatorNotes } from '../operator-notes';
import { PendingTransactionsTable } from '../tables/PendingTransactionsTable';
import { CardsTable } from '../tables/CardsTable';
import { AccountNumbersTable } from '../tables/AccountNumbersTable';
import { useState } from 'react';

import { DateTime } from 'luxon';
import { ListItem, PropertyList } from 'shared/components/PropertyList';
import { DateInput } from 'shared/components/DateInput';
import { formatAmount, formatPercentage } from 'shared/lib/formatting';
import { AssociatedManualTasks } from '../associated-manual-tasks';
import { compact } from 'lodash';
import { useTypedParams } from 'react-router-typesafe-routes/dom';
import { ROUTES, buildPath } from 'src/lib/routes';

export const AccountDetailPage = () => {
  const { accountId } = useTypedParams(ROUTES.ACCOUNTS_DETAIL);
  const { data: account } = useAccountGet(accountId);

  const cardList = useCardListInfinite({
    account_ids: [accountId],
    limit: 3,
  });

  const accountNumberList = useAccountNumberListInfinite({
    account_ids: [accountId],
    limit: 3,
  });

  const pendingTransactionList = usePendingTransactionListInfinite({
    account_ids: [accountId],
    limit: 5,
  });

  const completedTransactionList = useCompletedTransactionListInfinite({
    accounts: [accountId],
    limit: 10,
  });

  const [dateForDailyBalanceCheck, setDateForDailyBalanceCheck] = useState(
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    DateTime.utc().toISODate()!
  );

  const { data: accountBalance } = useAccountBalanceGet({
    account_id: accountId,
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    date: DateTime.fromISO(dateForDailyBalanceCheck, { zone: 'utc' })
      .plus({ days: 1 })
      .toISO()!,
  });

  if (!account) {
    return <Loading />;
  }

  return (
    <PageLayout headline={`Account • ${account.name}`} breadcrumbs={[]}>
      <SideBySide
        mode="rightDrawer"
        right={
          <>
            <AccountPropertyList
              account={account}
              layoutHorizontallyWhenPossible={false}
              title="Summary"
            />
            <AssociatedManualTasks objectId={accountId} />
          </>
        }
        left={
          <>
            <Box>
              <PropertyList
                title="End of Day Balances"
                action={
                  <DateInput
                    value={dateForDailyBalanceCheck}
                    name="date"
                    onChange={(e) =>
                      setDateForDailyBalanceCheck(e.target.value)
                    }
                  />
                }
                items={compact<ListItem>([
                  {
                    label: 'Current Balance',
                    value: accountBalance
                      ? formatAmount(
                          accountBalance.current_balance,
                          accountBalance.currency
                        )
                      : '',
                  },
                  {
                    label: 'Available Balance',
                    value: accountBalance
                      ? formatAmount(
                          accountBalance.available_balance,
                          accountBalance.currency
                        )
                      : '',
                  },
                  {
                    label: 'Interest Rate',
                    value: accountBalance
                      ? formatPercentage(
                          parseFloat(accountBalance.interest_rate)
                        )
                      : '',
                  },
                ])}
              />
            </Box>
            <TableStateWrapper
              {...pendingTransactionList}
              table={PendingTransactionsTable}
              style="detail"
              title="Pending Transactions"
              emptyTitle="No pending transactions"
              emptySubtitle="There are no pending transactions."
              showAccount={false}
              showGroup={false}
              showRoute={true}
              viewMoreHref={buildPath(
                ROUTES.PENDING_TRANSACTIONS_LIST,
                {},
                { account: [accountId] }
              )}
            />
            <TableStateWrapper
              {...completedTransactionList}
              table={CompletedTransactionsTable}
              style="detail"
              title="Completed Transactions"
              emptyTitle="No transactions"
              emptySubtitle="There are no transactions."
              showAccount={false}
              showGroup={false}
              showRoute={true}
              viewMoreHref={buildPath(
                ROUTES.TRANSACTIONS_LIST,
                {},
                { account: [accountId] }
              )}
            />
            <TableStateWrapper
              {...accountNumberList}
              table={AccountNumbersTable}
              style="detail"
              title="Account Numbers"
              emptyTitle="No account numbers"
              emptySubtitle="There are no account numbers in this account."
              showAccount={false}
              showGroup={false}
              viewMoreHref={buildPath(
                ROUTES.ACCOUNT_NUMBERS_LIST,
                {},
                { account: [accountId] }
              )}
            />
            <TableStateWrapper
              {...cardList}
              table={CardsTable}
              style="detail"
              title="Cards"
              emptyTitle="No cards"
              emptySubtitle="There are no cards in this account."
              showAccount={false}
              showGroup={false}
              viewMoreHref={buildPath(
                ROUTES.CARDS_LIST,
                {},
                { account: [accountId] }
              )}
            />

            <Box>
              <Heading>Operator Notes</Heading>
              <OperatorNotes modelId={account.id} />
            </Box>
          </>
        }
      />
    </PageLayout>
  );
};

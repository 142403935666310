import { Body } from './Text';

export type ErrorMessageProps = {
  message: string;
};

export const ErrorMessage = ({ message }: ErrorMessageProps) => {
  return (
    <div className="rounded border border-danger bg-danger p-2">
      <Body color="red">{message}</Body>
    </div>
  );
};

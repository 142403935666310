import {
  TableColumns,
  Table,
  PrestyledTableProps,
} from 'shared/components/Table';
import { useMemo } from 'react';

import {
  ResultStatusListResponseDataItem,
  ResultStatusListResponseDataItemStatus,
} from 'src/build/operations';
import moment from 'moment';
import { buildPath, ROUTES } from 'src/lib/routes';

export type ResultsStatusTableProps =
  PrestyledTableProps<ResultStatusListResponseDataItem>;

const readableStatus = (
  status: ResultStatusListResponseDataItemStatus
): string =>
  ({ succeeding: 'Succeeding', failing: 'Failing', retired: 'Retired' })[
    status
  ];

export const ResultsTable = ({ ...rest }: ResultsStatusTableProps) => {
  const columns = useMemo<TableColumns<ResultStatusListResponseDataItem>>(
    () => [
      {
        header: 'Service',
        contents: (result) => ({
          text: result.service,
        }),
      },
      {
        expand: 1,
        header: 'Name',
        contents: (result) => ({
          text: result.name,
          textWeight: 'medium',
          textColor: 'emphasis',
        }),
      },
      {
        header: 'Failing since',
        contents: (result) => ({
          text: result.failing_since
            ? moment(result.failing_since).fromNow()
            : '',
        }),
      },
      {
        header: 'Status',
        contents: (result) => ({
          text: readableStatus(result.status),
          badgeColor: result.status === 'failing' ? 'red' : 'gray',
        }),
      },
    ],
    []
  );

  const getRowProps = (datum: ResultStatusListResponseDataItem) => ({
    href: buildPath(ROUTES.RESULT_STATUS, { resultStatusID: datum.id }),
    className: 'hover:bg-main-hover cursor-pointer transition-all group',
  });

  return (
    <Table<ResultStatusListResponseDataItem>
      columns={columns}
      getRowProps={getRowProps}
      {...rest}
    />
  );
};

import { usePlatformComplaintGet } from 'src/hooks/reactQuery';
import { PageLayout } from '../deprecated/PageLayout';
import { PlatformComplaintPropertyList } from '../property-lists/PlatformComplaintPropertyList';
import { Box } from 'shared/components/Box';
import { Heading } from 'shared/components/Text';
import { OperatorNotes } from '../operator-notes';
import { useTypedParams } from 'react-router-typesafe-routes/dom';
import { ROUTES } from 'src/lib/routes';

export const PlatformComplaintDetailPage = () => {
  const { platformComplaintId } = useTypedParams(
    ROUTES.PLATFORM_COMPLAINTS_DETAIL
  );

  const { data: platformComplaint } =
    usePlatformComplaintGet(platformComplaintId);

  if (!platformComplaint) {
    return <h3>Loading</h3>;
  }

  return (
    <PageLayout headline={'Platform Complaint'} breadcrumbs={[]}>
      <PlatformComplaintPropertyList platformComplaint={platformComplaint} />
      <Box>
        <Heading>Operator Notes</Heading>
        <OperatorNotes modelId={platformComplaint.id} />
      </Box>
    </PageLayout>
  );
};

import React, { useMemo } from 'react';
import { useOAuthApplicationListInfinite } from 'src/hooks/reactQuery';
import { PageLayout } from '../deprecated/PageLayout';
import { TableStateWrapper } from 'shared/components/TableStateWrapper';
import { OAuthApplicationsTable } from '../tables/OAuthApplicationsTable';
import { makeGroupFilter } from 'src/lib/tableFilterHelpers';
import { ROUTES } from 'src/lib/routes';
import { useSearchParamsState } from 'shared/hooks/useTypedSearchParamsState';

export const OAuthApplicationListPage = () => {
  const [groupFilter, setGroupFilter] = useSearchParamsState(
    ROUTES.ENTITIES_LIST,
    'group'
  );

  const listResult = useOAuthApplicationListInfinite({
    groups: groupFilter.length === 0 ? undefined : groupFilter,
  });

  const filters = useMemo(
    () => [makeGroupFilter(groupFilter, setGroupFilter)],
    [groupFilter, setGroupFilter]
  );

  return (
    <PageLayout headline="OAuth Applications" breadcrumbs={[]}>
      <TableStateWrapper
        table={OAuthApplicationsTable}
        {...listResult}
        style="primary"
        emptyTitle="No OAuth Applications"
        emptySubtitle="There are no OAuth Applications to display"
        filters={filters}
      />
    </PageLayout>
  );
};

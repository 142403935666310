import {
  InSearchParams,
  OutSearchParams,
  Route,
  useTypedSearchParams,
} from 'react-router-typesafe-routes/dom';

export const useSearchParamsState = <
  TPath extends string,
  TPathTypes,
  TSearchTypes,
  THash extends string[],
  TStateTypes,
  K extends keyof OutSearchParams<TSearchTypes>,
>(
  route: Route<TPath, TPathTypes, TSearchTypes, THash, TStateTypes>,
  name: K
): [
  OutSearchParams<TSearchTypes>[K],
  (value: InSearchParams<TSearchTypes>[K] | null) => void,
] => {
  const [params, setParams] = useTypedSearchParams(route);

  const setParameter = (
    value: InSearchParams<TSearchTypes>[K] | null
  ): void => {
    const update: Partial<InSearchParams<TSearchTypes>> = {};
    if (value !== null && value !== undefined) {
      update[name] = value;
    } else {
      update[name] = undefined;
    }
    setParams((original) => ({ ...original, ...update }));
  };

  return [params[name], setParameter];
};

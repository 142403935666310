import classNames from 'classnames';
import { Icon } from './Icon';
import { ReactNode, useEffect, useState } from 'react';
import { useCopyToClipboard } from '../hooks/useCopyToClipboard';
import { Tooltip, TooltipDirection } from './Tooltip';
import { Body } from './Text';
import { isNumber, isString } from 'lodash';

export type CopyButtonColor = 'emphasis' | 'primary' | 'secondary';

type Props = {
  valueToDisplay: string | ReactNode;
  tooltipLabel?: string;
  valueToCopy: string;
  tooltipDirection?: TooltipDirection;
  className?: string;
  color?: CopyButtonColor;
};

const STYLE_CLASS: Record<CopyButtonColor, string> = {
  emphasis: 'text-strong',
  primary: 'text-main',
  secondary: 'text-subtle',
};

const CopyButton = ({
  valueToCopy,
  valueToDisplay,
  tooltipDirection = 'bottom',
  className,
  tooltipLabel = 'Copy to clipboard',
  color = 'primary',
}: Props) => {
  const [copying, setCopying] = useState(false);
  const [, copy] = useCopyToClipboard();
  const hide = () => setCopying(false);

  useEffect(() => {
    if (copying) {
      const timer = setTimeout(hide, 2000);
      return () => clearTimeout(timer);
    }
  }, [copying]);

  return (
    <Tooltip
      content={copying ? 'Copied' : tooltipLabel}
      direction={tooltipDirection}
      className="max-w-full"
      icon={copying ? 'tick' : undefined}
    >
      <button
        type="button"
        className={classNames(
          className
            ? className
            : 'text-main underline decoration-dotted underline-offset-2 hover:bg-strong-hover hover:decoration-solid focus-visible:stroke-main focus-visible:outline-none focus-visible:ring-[3px] focus-visible:ring-main',
          'inline-flex max-w-full items-center space-x-1 rounded-sm transition-all focus-visible:outline-[1px] disabled:opacity-70',
          STYLE_CLASS[color]
        )}
        onClick={(event) => {
          event?.stopPropagation();
          event.preventDefault();
          setCopying(true);
          copy(valueToCopy);
        }}
      >
        {isString(valueToDisplay) || isNumber(valueToDisplay) ? (
          <Body
            color="inherit"
            contents={valueToDisplay}
            weight="normal"
            className="max-w-[calc(100%-20px)] truncate"
          />
        ) : (
          <div className="max-w-[calc(100%-20px)] truncate">
            {valueToDisplay}
          </div>
        )}
        <Icon name="copy" className={classNames('ease-out-quint')} />
      </button>
    </Tooltip>
  );
};

export { CopyButton };

import { compact } from 'lodash';
import {
  ListItem,
  PropertyList,
  PropertyListProps,
} from 'shared/components/PropertyList';
import {
  formatISO8601Timestamp,
  formatInteger,
  formatPercentage,
  humanize,
} from 'shared/lib/formatting';
import { ProgramGetResponse } from 'src/build/operations';
import { useGroupPropertyRow } from 'src/lib/propertyListHelpers';
import { buildPath, ROUTES } from 'src/lib/routes';

export type ProgramPropertyListProps = {
  program: ProgramGetResponse;
} & Omit<PropertyListProps, 'items'>;

export const ProgramPropertyList = (props: ProgramPropertyListProps) => {
  const { program, ...rest } = props;
  const groupRow = useGroupPropertyRow(program.group_id);

  return (
    <PropertyList
      {...rest}
      items={compact<ListItem>([
        {
          label: 'ID',
          value: program.id,
          copyable: program.id,
        },
        {
          label: 'Name',
          value: program.name,
        },
        {
          label: 'Bank',
          value: humanize(program.bank),
        },
        groupRow,
        {
          label: 'Opened at',
          value: formatISO8601Timestamp(program.created_at, 'medium'),
        },
        program.interest_rate && {
          label: 'Interest rate',
          value: formatPercentage(
            parseFloat(program.interest_rate.current_value)
          ),
          tooltip: program.interest_rate.summary,
        },
        {
          label: 'Number of open accounts',
          value: formatInteger(program.count_accounts),
        },
        {
          label: 'Number of entities',
          value: formatInteger(program.count_entities),
        },
        program.billing_account_id && {
          label: 'Billing account',
          value: program.billing_account_id,
          href: buildPath(ROUTES.ACCOUNTS_DETAIL, {
            accountId: program.billing_account_id,
          }),
        },
        program.interest_account_id && {
          label: 'Interest account',
          value: program.interest_account_id,
          href: buildPath(ROUTES.ACCOUNTS_DETAIL, {
            accountId: program.interest_account_id,
          }),
        },
        program.reserve_account_id && {
          label: 'Reserve account',
          value: program.reserve_account_id,
          href: buildPath(ROUTES.ACCOUNTS_DETAIL, {
            accountId: program.reserve_account_id,
          }),
        },
        {
          label: 'Allows new accounts?',
          value: program.allow_account_creation ? 'Yes' : 'No',
        },
      ])}
    />
  );
};

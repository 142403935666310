import { DateTime } from 'luxon';
import { useState } from 'react';
import { Body, Caption } from './Text';
import classNames from 'classnames';
import { Icon } from './Icon';
import { must } from '../lib/must';

export type DateRangePickerProps = {
  startDate: DateTime | undefined;
  endDate: DateTime | undefined;
  startDateLabel: string;
  endDateLabel: string;
  selectableRange?: 'any' | 'past' | 'future';
  onChange: (o: {
    startDate: DateTime | undefined;
    endDate: DateTime | undefined;
  }) => void;
};

export const DateRangePicker = ({
  startDate,
  endDate,
  startDateLabel,
  endDateLabel,
  selectableRange = 'any',
  onChange,
}: DateRangePickerProps) => {
  const [currentDisplayMonth, setCurrentDisplayMonth] = useState<DateTime>(
    endDate ? endDate : DateTime.now()
  );
  const isDisplayingCurrentMonth =
    DateTime.now().month === currentDisplayMonth.month &&
    DateTime.now().year === currentDisplayMonth.year;

  const [mode, setMode] = useState<'startDate' | 'endDate'>('startDate');
  const [hoverDate, setHoverDate] = useState<DateTime | undefined>(undefined);
  const today = DateTime.now().startOf('day');
  const onlyPastDates = selectableRange === 'past';
  const onlyFutureDates = selectableRange === 'future';
  const anyDates = selectableRange === 'any';

  const handleDateClick = (date: DateTime) => {
    const isBeforeStartDate =
      startDate && date.toMillis() < startDate.toMillis();
    const isAfterEndDate = endDate && date.toMillis() > endDate.toMillis();
    const isCurrentMonth = date.month === currentDisplayMonth.month;
    if (onlyPastDates && date > today) {
      return; // Do nothing if the date is after today
    }
    if (onlyFutureDates && date < today) {
      return; // Do nothing if the date is after today
    }
    if (!isCurrentMonth) {
      return; // Do nothing if the date is after today
    }
    if (mode === 'startDate') {
      onChange({
        startDate: date,
        endDate: isAfterEndDate ? undefined : endDate,
      });
      setMode(isAfterEndDate ? 'startDate' : 'endDate');
    } else {
      onChange({
        startDate: isBeforeStartDate ? date : startDate,
        endDate: isBeforeStartDate ? undefined : date,
      });
      setMode(isBeforeStartDate ? 'endDate' : 'startDate');
    }
  };

  const firstMonthDay = currentDisplayMonth.startOf('month');
  const daysInMonth = must(currentDisplayMonth.daysInMonth);

  const firstSunday =
    firstMonthDay.weekday !== 7
      ? firstMonthDay.minus({ days: firstMonthDay.weekday })
      : firstMonthDay;

  const lengthOfMonth =
    firstMonthDay.weekday !== 7
      ? firstMonthDay.weekday + daysInMonth
      : daysInMonth;

  return (
    <div className="space-y-4">
      <div className="space-y-1">
        <div className="flex items-center">
          <Body className="grow" weight="medium" color="primary">
            {startDateLabel}
          </Body>
          <Body className="grow" weight="medium" color="primary">
            {endDateLabel}
          </Body>
        </div>
        <div className="flex flex-row gap-2">
          <div
            onClick={() => setMode('startDate')}
            className={classNames(
              'flex items-center justify-between',
              'h-[32px] w-full cursor-pointer rounded border border-strong pl-2 text-[14px] font-[440] leading-[20px] tracking-[.014em] outline-none transition',
              mode === 'startDate'
                ? 'z-10 bg-main shadow-sm'
                : 'bg-strong opacity-50 hover:bg-main hover:opacity-100'
            )}
          >
            <div>
              <Body color={startDate ? 'primary' : 'secondary'}>
                {startDate?.toFormat('MMM d, yyyy') ?? 'None'}
              </Body>
            </div>
            <div
              className={classNames(
                'cursor-pointer rounded p-[6px] transition-opacity hover:bg-main-hover',
                startDate && mode === 'startDate' ? 'opacity-100' : 'opacity-0'
              )}
              onClick={() => onChange({ endDate, startDate: undefined })}
            >
              <Icon name="close" />
            </div>
          </div>
          <div
            onClick={() => setMode('endDate')}
            className={classNames(
              'flex items-center justify-between',
              'h-[32px] w-full cursor-pointer rounded border border-strong pl-2 text-[14px] font-[440] leading-[20px] tracking-[.014em] outline-none transition',
              mode === 'endDate'
                ? 'z-10 bg-main shadow-sm'
                : 'bg-strong opacity-50 hover:bg-main hover:opacity-100',
              endDate ? 'text-strong' : 'text-disabled'
            )}
          >
            <div>
              <Body color={endDate ? 'primary' : 'secondary'}>
                {endDate?.toFormat('MMM d, yyyy') ?? 'None'}
              </Body>
            </div>
            <div
              className={classNames(
                'cursor-pointer rounded p-[6px] transition-opacity hover:bg-main-hover',
                endDate && mode === 'endDate' ? 'opacity-100' : 'opacity-0'
              )}
              onClick={() =>
                endDate && onChange({ startDate, endDate: undefined })
              }
            >
              <Icon name="close" />
            </div>
          </div>
        </div>
      </div>

      <div className="select-none">
        <div className="flex items-center justify-between">
          <div
            className="cursor-pointer rounded p-2 hover:bg-main-hover"
            onClick={() => {
              if (
                !isDisplayingCurrentMonth ||
                (isDisplayingCurrentMonth && !onlyFutureDates)
              ) {
                setCurrentDisplayMonth(
                  currentDisplayMonth.minus({ months: 1 })
                );
              }
            }}
          >
            <Icon name="caret_left" />
          </div>
          <div className="text-center">
            <Body color="primary" weight="bold">
              {currentDisplayMonth.toFormat('MMMM, yyyy')}
            </Body>
          </div>
          <div
            className="cursor-pointer rounded p-2 hover:bg-main-hover"
            onClick={() => {
              if (
                !isDisplayingCurrentMonth ||
                (isDisplayingCurrentMonth && !onlyPastDates)
              ) {
                setCurrentDisplayMonth(currentDisplayMonth.plus({ months: 1 }));
              }
            }}
          >
            <Icon name="caret_right" />
          </div>
        </div>
        <div className="grid grid-cols-7 py-2">
          <div className="text-center">
            <Caption color="secondary" weight="medium">
              Su
            </Caption>
          </div>
          <div className="text-center">
            <Caption color="secondary" weight="medium">
              Mo
            </Caption>
          </div>
          <div className="text-center">
            <Caption color="secondary" weight="medium">
              Tu
            </Caption>
          </div>
          <div className="text-center">
            <Caption color="secondary" weight="medium">
              We
            </Caption>
          </div>
          <div className="text-center">
            <Caption color="secondary" weight="medium">
              Th
            </Caption>
          </div>
          <div className="text-center">
            <Caption color="secondary" weight="medium">
              Fr
            </Caption>
          </div>
          <div className="text-center">
            <Caption color="secondary" weight="medium">
              Sa
            </Caption>
          </div>
        </div>
        <div className="grid grid-cols-7 gap-y-1">
          {Array.from({ length: lengthOfMonth > 35 ? 42 : 35 }).map(
            (_, idx) => {
              const date = firstSunday.plus({ days: idx }).startOf('day');
              const isCurrentMonth = date.month === currentDisplayMonth.month;
              const isSelectable =
                (onlyPastDates && date <= today && isCurrentMonth) ||
                (onlyFutureDates && date >= today && isCurrentMonth) ||
                (anyDates && isCurrentMonth);
              const isToday = today.hasSame(date, 'day');
              const isStartDate = startDate && startDate.hasSame(date, 'day');
              const isEndDate = endDate && endDate.hasSame(date, 'day');
              const isSelected = isStartDate || isEndDate;
              const isHoverDate = hoverDate && hoverDate.hasSame(date, 'day');
              const isAfterStartDate =
                (startDate && startDate.diff(date, 'day').days <= 0) ||
                !startDate;
              const isBeforeEndDate =
                (endDate && endDate.diff(date, 'day').days >= 0) || !endDate;
              const isWithinRange =
                (startDate || endDate) &&
                isAfterStartDate &&
                isBeforeEndDate &&
                isSelectable;
              return (
                <div
                  key={idx}
                  className={classNames(
                    'aspect-square',
                    isWithinRange && 'bg-main-selected',
                    isStartDate && 'rounded',
                    isEndDate && 'rounded'
                  )}
                  onClick={() => handleDateClick(date)}
                  onMouseEnter={() => setHoverDate(date)}
                  onMouseLeave={() =>
                    hoverDate === date && setHoverDate(undefined)
                  }
                >
                  <div
                    className={classNames(
                      'relative flex h-full w-full select-none items-center justify-center',
                      isSelected && isSelectable && 'rounded bg-brand',
                      isHoverDate &&
                        isSelectable &&
                        'cursor-pointer rounded border border-brand'
                    )}
                  >
                    <Body
                      color={
                        isSelected
                          ? 'offset'
                          : !isSelectable
                            ? 'disabled'
                            : 'primary'
                      }
                      weight={'medium'}
                    >
                      {isCurrentMonth && date.toFormat('d')}
                    </Body>
                    <div
                      className={classNames(
                        !isToday && 'hidden',
                        isSelected ? 'bg-main' : 'bg-brand',
                        'absolute bottom-1/2 left-1/2 h-1 w-1 -translate-x-1/2 translate-y-4 rounded-full'
                      )}
                    />
                  </div>
                </div>
              );
            }
          )}
        </div>
      </div>
    </div>
  );
};

import React from 'react';
import { PageLayout } from '../deprecated/PageLayout';
import { useDocumentRequestGet } from 'src/hooks/reactQuery';
import { SideBySide } from 'shared/components/SideBySide';
import { useTypedParams } from 'react-router-typesafe-routes/dom';
import { buildPath, ROUTES } from 'src/lib/routes';
import { PropertyList } from 'shared/components/PropertyList';
import {
  useGroupPropertyRow,
  useProgramPropertyRow,
} from 'src/lib/propertyListHelpers';
import { formatISO8601Timestamp, humanize } from 'shared/lib/formatting';
import { AlertList } from 'shared/components/AlertList';
import { Box } from 'shared/components/Box';
import { documentRequestStatusBadgeColor } from '../tables/DocumentRequestsTable';
import { compact, isEmpty, values } from 'lodash';

const NonDocumentPropertyList = (props: {
  submissionId: string;
  title: string;
  href: string;
}) => {
  const { href, submissionId, title } = props;
  return (
    <PropertyList
      title={title}
      items={[
        {
          label: 'ID',
          value: submissionId,
          href: href,
        },
        {
          label: 'Leave a review',
          value: 'Manual task',
          href: `/manual_tasks/Operations::ManualTask::ReviewDocumentRequestSubmission?submission_id=${submissionId}`,
        },
      ]}
    />
  );
};

export const DocumentRequestDetailPage = () => {
  const { documentRequestId } = useTypedParams(ROUTES.DOCUMENT_REQUEST_DETAIL);

  const { data } = useDocumentRequestGet(documentRequestId);

  const groupRow = useGroupPropertyRow(data?.group_id);
  const programRow = useProgramPropertyRow(data?.program_id);

  if (!data) {
    return null;
  }

  return (
    <PageLayout headline={data.description} breadcrumbs={[]}>
      <SideBySide
        mode="rightDrawer"
        left={
          <Box>
            {data.document_submissions.map((submission, idx) => (
              <PropertyList
                title={`Submission ${idx + 1}`}
                key={submission.id}
                items={[
                  {
                    label: 'Submission created',
                    value: formatISO8601Timestamp(
                      submission.created_at,
                      'full'
                    ),
                  },
                  {
                    label: 'File',
                    value: submission.file_name,
                    href: `https://operations.increase.com/api_files/${submission.file_id}/view`,
                  },
                  {
                    label: 'Leave a review',
                    value: 'Manual task',
                    href: `/manual_tasks/Operations::ManualTask::ReviewDocumentRequestSubmission?submission_id=${submission.id}`,
                  },
                ]}
              />
            ))}

            {data.document_submissions.length === 0 &&
              isEmpty(compact(values(data.nondocument_submissions))) && (
                <AlertList
                  tasks={[
                    {
                      icon: 'alert',
                      title: 'No submissions yet.',
                      key: '0',
                      style: 'error',
                    },
                  ]}
                />
              )}

            {data.nondocument_submissions
              .platform_complaint_list_submission_id && (
              <NonDocumentPropertyList
                title="Platform complaint list submission"
                submissionId={
                  data.nondocument_submissions
                    .platform_complaint_list_submission_id
                }
                href={buildPath(
                  ROUTES.PLATFORM_COMPLAINT_LIST_SUBMISSION_DETAIL,
                  {
                    submissionID:
                      data.nondocument_submissions
                        .platform_complaint_list_submission_id,
                  }
                )}
              />
            )}

            {data.nondocument_submissions
              .platform_vendor_list_submission_id && (
              <NonDocumentPropertyList
                title="Platform vendor list submission"
                submissionId={
                  data.nondocument_submissions
                    .platform_vendor_list_submission_id
                }
                href={buildPath(
                  ROUTES.PLATFORM_VENDOR_LIST_SUBMISSIONS_DETAIL,
                  {
                    submissionID:
                      data.nondocument_submissions
                        .platform_vendor_list_submission_id,
                  }
                )}
              />
            )}

            {data.nondocument_submissions
              .platform_compliance_metrics_submission_id && (
              <NonDocumentPropertyList
                title="Platform compliance metrics submission"
                submissionId={
                  data.nondocument_submissions
                    .platform_compliance_metrics_submission_id
                }
                href={buildPath(
                  ROUTES.PLATFORM_COMPLIANCE_METRICS_SUBMISSIONS_DETAIL,
                  {
                    submissionID:
                      data.nondocument_submissions
                        .platform_compliance_metrics_submission_id,
                  }
                )}
              />
            )}

            {data.nondocument_submissions
              .platform_financial_report_submission_id && (
              <NonDocumentPropertyList
                title="Platform financial report submission"
                submissionId={
                  data.nondocument_submissions
                    .platform_financial_report_submission_id
                }
                href={buildPath(
                  ROUTES.PLATFORM_FINANCIAL_REPORT_SUBMISSIONS_DETAIL,
                  {
                    submissionID:
                      data.nondocument_submissions
                        .platform_financial_report_submission_id,
                  }
                )}
              />
            )}

            {data.reviews.map((review, idx) => (
              <PropertyList
                title={`Review ${idx + 1}`}
                key={review.id}
                items={[
                  {
                    label: 'Review created',
                    value: formatISO8601Timestamp(review.created_at, 'full'),
                  },
                  {
                    label: 'Comment',
                    value: review.comment,
                  },
                  {
                    label: 'New status',
                    value: humanize(review.result),
                  },
                ]}
              />
            ))}
          </Box>
        }
        right={
          <PropertyList
            title="Details"
            items={[
              groupRow,
              programRow,
              {
                label: 'Status',
                value: humanize(data.status),
                badgeColor: documentRequestStatusBadgeColor[data.status],
              },
              {
                label: 'Request created',
                value: formatISO8601Timestamp(data.created_at, 'full'),
              },
              {
                label: 'Cancel this document request',
                value: 'Manual task',
                href: `/manual_tasks/Operations::ManualTask::CancelDocumentRequest?document_request_id=${data.id}`,
              },
            ]}
            layoutHorizontallyWhenPossible={false}
          />
        }
      />
    </PageLayout>
  );
};

import { ROUTES, buildPath } from 'src/lib/routes';
import { PageLayout } from './deprecated/PageLayout';
import { Table } from './deprecated/Table';
import { useState, useEffect } from 'react';
import { attestationList, AttestationListResponse } from 'src/build/operations';

export const Attestations = () => {
  const [data, setData] = useState<AttestationListResponse['data']>([]);

  useEffect(() => {
    const fetchData = async () => {
      const response = await attestationList();
      setData(response.data.data);
    };

    fetchData();
  }, []);

  return (
    <PageLayout headline="My Attestations" breadcrumbs={[]}>
      <Table
        data={data.sort(
          (a, b) =>
            new Date(a.created_at).getTime() - new Date(b.created_at).getTime()
        )}
        columns={[
          {
            key: 'attested_to',
            label: 'Attestation',
            render: (item) => (
              <a
                href={buildPath(ROUTES.ATTESTATIONS_DETAIL, {
                  attestationId: item.id,
                })}
              >
                {item.pretty_name}
              </a>
            ),
          },
          {
            key: 'attested_at',
            label: 'Attested At',
          },
        ]}
      />
    </PageLayout>
  );
};

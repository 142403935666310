import React from 'react';
import { PageLayout } from '../deprecated/PageLayout';
import {
  useDocumentRequestScheduleListEntire,
  useDocumentRequestSchedulePost,
  useProgramGet,
} from 'src/hooks/reactQuery';
import { SideBySide } from 'shared/components/SideBySide';
import { useTypedParams } from 'react-router-typesafe-routes/dom';
import { buildPath, ROUTES } from 'src/lib/routes';
import { PropertyList } from 'shared/components/PropertyList';
import {
  useGroupPropertyRow,
  useProgramPropertyRow,
} from 'src/lib/propertyListHelpers';
import { fromPairs, values } from 'lodash';
import {
  DocumentRequestSchedulePostResponseCadence,
  DocumentRequestSchedulePostResponseCategory,
} from 'src/build/operations';
import { humanize } from 'shared/lib/formatting';
import { StyledLink } from 'shared/components/StyledLink';
import { Body, Heading } from 'shared/components/Text';
import { Box } from 'shared/components/Box';
import { Select } from 'shared/components/Select';

type RowProps = {
  category: DocumentRequestSchedulePostResponseCategory;
  cadence: DocumentRequestSchedulePostResponseCadence;
  programId: string;
};

const Row = ({ category, programId, cadence }: RowProps) => {
  const mutate = useDocumentRequestSchedulePost();

  return (
    <div className="grid max-w-[600px] grid-cols-2">
      <StyledLink
        style="underline"
        href={buildPath(
          ROUTES.DOCUMENT_REQUESTS_LIST,
          {},
          {
            program: [programId],
            categories: [
              category === 'financial_report'
                ? 'platform_financial_report_submission_request'
                : category === 'platform_compliance_metrics'
                  ? 'platform_compliance_metrics_submission_request'
                  : 'platform_periodic_templated_spreadsheet_report_request',
            ],
            subcategories:
              category === 'financial_report' ||
              category === 'platform_compliance_metrics'
                ? []
                : [category],
          }
        )}
      >
        <Body>{humanize(category)}</Body>
      </StyledLink>
      <Select
        sections={[
          {
            options: [
              { label: 'Monthly', value: 'monthly' },
              { label: 'Quarterly', value: 'quarterly' },
              { label: 'Never', value: 'never' },
            ],
          },
        ]}
        value={cadence}
        onUpdate={(newCadence) =>
          mutate.mutateAsync([
            {
              program_id: programId,
              category: category as DocumentRequestSchedulePostResponseCategory,
              cadence: newCadence,
            },
          ])
        }
      />
    </div>
  );
};

export const DocumentRequestSchedulePage = () => {
  const { programId } = useTypedParams(ROUTES.DOCUMENT_REQUEST_SCHEDULE);
  const { data: program } = useProgramGet(programId);

  const { data } = useDocumentRequestScheduleListEntire({
    program_id: programId,
  });

  const groupRow = useGroupPropertyRow(program?.group_id);
  const programRow = useProgramPropertyRow(programId);

  if (!data) {
    return null;
  }

  const cadences = fromPairs(
    data.map((schedule) => [schedule.category, schedule.cadence])
  );

  return (
    <PageLayout headline={'Document request schedule'} breadcrumbs={[]}>
      <SideBySide
        mode="rightDrawer"
        left={
          <Box>
            <Heading>Select which reports you'd like to send</Heading>
            {values(DocumentRequestSchedulePostResponseCategory).map(
              (category) => (
                <Row
                  key={category}
                  category={category}
                  programId={programId}
                  cadence={
                    cadences[category] ||
                    DocumentRequestSchedulePostResponseCadence.never
                  }
                />
              )
            )}
          </Box>
        }
        right={
          <PropertyList
            title="Details"
            items={[groupRow, programRow]}
            layoutHorizontallyWhenPossible={false}
          />
        }
      />
    </PageLayout>
  );
};

import { compact } from 'lodash';
import {
  ListItem,
  PropertyList,
  PropertyListProps,
} from 'shared/components/PropertyList';
import { formatISO8601Timestamp, humanize } from 'shared/lib/formatting';
import { PlatformComplaintGetResponse } from 'src/build/operations';

export type PlatformComplaintPropertyListProps = {
  platformComplaint: PlatformComplaintGetResponse;
} & Omit<PropertyListProps, 'items'>;

export const PlatformComplaintPropertyList = (
  props: PlatformComplaintPropertyListProps
) => {
  const { platformComplaint, ...rest } = props;

  return (
    <PropertyList
      {...rest}
      items={compact<ListItem>([
        {
          label: 'ID',
          value: platformComplaint.id,
          copyable: platformComplaint.id,
        },
        {
          label: 'Name',
          value: platformComplaint.name,
        },
        {
          label: 'Type',
          value: platformComplaint.complaint_type,
        },
        {
          label: 'Received On',
          value: formatISO8601Timestamp(
            platformComplaint.received_on,
            'medium'
          ),
        },
        {
          label: 'Resolved On',
          value: platformComplaint.resolved_on
            ? formatISO8601Timestamp(platformComplaint.resolved_on, 'medium')
            : 'Unresolved',
        },
        {
          label: 'Reported to Increase On',
          value: formatISO8601Timestamp(platformComplaint.created_at, 'medium'),
        },
        {
          label: 'Source',
          value: humanize(platformComplaint.source),
        },
        {
          label: 'Description',
          value: platformComplaint.description,
        },
      ])}
    />
  );
};

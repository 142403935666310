import { useState } from 'react';

import { useRegisterPost } from 'src/hooks/reactQuery';
import { TextInput } from './deprecated/TextInput';

type Props = {
  invitationCode: string;
};

export const Register = ({ invitationCode }: Props) => {
  const registerPost = useRegisterPost();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const register = () => {
    registerPost.mutate([
      {
        email,
        password,
        code: invitationCode,
      },
    ]);
  };

  return (
    <div className="flex h-screen w-full items-center justify-center">
      <div className="w-full max-w-md space-y-4 text-center">
        <h1 className="text-xl">Administration Register</h1>
        <p className="text-lg">Create an account to continue</p>
        <form
          className="space-y-2"
          method="post"
          onSubmit={(submitEvent) => {
            submitEvent.preventDefault();
            register();
          }}
        >
          <TextInput
            type="email"
            name="email"
            placeholder="Email address"
            value={email}
            onChange={(e) => setEmail(e)}
          />
          <TextInput
            type="password"
            name="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e)}
          />

          {registerPost.error && (
            <div className="text-danger">
              {registerPost.error.response?.data.message}
            </div>
          )}

          <button
            className="w-full rounded bg-info-strong p-1 text-inverse shadow-sm hover:opacity-80"
            type="submit"
          >
            Create a new administration account
          </button>
        </form>
      </div>
    </div>
  );
};

import React from 'react';

export type SelectOption<T> = {
  label: string;
  value: T;
};

export type Props<T> = {
  disabled?: boolean;
  id?: string;
  name?: string;
  label?: string;
  options: SelectOption<T>[];
  value: T;
  defaultValue?: T;
  onChange: (value: T) => void;
};
export function Select<T extends string = string>(props: Props<T>) {
  const { label, options, onChange, ...rest } = props;
  return (
    <div>
      {label && <label className="block text-sm text-strong">{label}</label>}
      <select
        {...rest}
        className="block w-full rounded border-0 py-0.5 pl-3 pr-10 text-sm text-strong shadow-sm ring-1 ring-inset focus:ring-2 focus:ring-main"
        value={props.value}
        onChange={(e) => onChange(e.target.value as T)}
      >
        {options.map((option) => {
          return (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          );
        })}
      </select>
    </div>
  );
}

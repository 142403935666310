import {
  TableColumns,
  Table,
  PrestyledTableProps,
} from 'shared/components/Table';
import { formatISO8601Timestamp } from 'shared/lib/formatting';
import { useMemo } from 'react';

import { RampBusinessAccountOnboardingSurveyGetResponse } from 'src/build/operations';
import { buildPath, ROUTES } from 'src/lib/routes';

type Props =
  PrestyledTableProps<RampBusinessAccountOnboardingSurveyGetResponse>;

export const RampBusinessAccountOnboardingSurveyTable = (props: Props) => {
  const { getRowProps, ...rest } = props;
  const columns = useMemo<
    TableColumns<RampBusinessAccountOnboardingSurveyGetResponse>
  >(() => {
    return [
      {
        header: 'Account',
        expand: 1,
        contents: (survey) => ({
          text: survey.account_id,
          href: buildPath(ROUTES.ACCOUNTS_DETAIL, {
            accountId: survey.account_id,
          }),
        }),
      },
      {
        header: 'Entity',
        expand: 1,
        contents: (survey) => ({
          text: survey.entity_name || '',
          href: survey.entity_id
            ? buildPath(ROUTES.ENTITY, {
                entityId: survey.entity_id,
              })
            : '',
        }),
      },
      {
        header: 'Submitted',
        align: 'right',
        contents: (survey) => ({
          text: formatISO8601Timestamp(survey.created_at, 'medium'),
        }),
      },
    ];
  }, []);

  const defaultRowProps = (
    datum: RampBusinessAccountOnboardingSurveyGetResponse
  ) => ({
    href: buildPath(ROUTES.RAMP_BUSINESS_ACCOUNT_ONBOARDING_SURVEYS_DETAILS, {
      id: datum.id,
    }),
    className: 'hover:bg-main-hover cursor-pointer transition-all group',
  });

  return (
    <Table<RampBusinessAccountOnboardingSurveyGetResponse>
      columns={columns}
      getRowProps={getRowProps ?? defaultRowProps}
      {...rest}
    />
  );
};

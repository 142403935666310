/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * FederalReserveGateway API
 * OpenAPI spec version: 0.0.1
 */
export type LimitsForRoutingNumberGetParams = {
  routing_number: string;
};

export type FedwireDeleteEndpoint =
  (typeof FedwireDeleteEndpoint)[keyof typeof FedwireDeleteEndpoint];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FedwireDeleteEndpoint = {
  MMQFMP22: "MMQFMP22",
  MMQFMC22: "MMQFMC22",
  MFP00073: "MFP00073",
  MFC00073: "MFC00073",
} as const;

export type FedwireDeleteQueue =
  (typeof FedwireDeleteQueue)[keyof typeof FedwireDeleteQueue];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FedwireDeleteQueue = {
  FNPRFROMDI074920909A1: "FNPR.FROMDI.074920909.A1",
  FNPRFROMDI074920909A1TEST: "FNPR.FROMDI.074920909.A1.TEST",
  FNPATODI074920909A1R: "FNPA.TODI.074920909.A1.R",
  FNPATODI074920909A1TESTR: "FNPA.TODI.074920909.A1.TEST.R",
  FNPNTODI074920909A1R: "FNPN.TODI.074920909.A1.R",
  FNPSTODI074920909A1R: "FNPS.TODI.074920909.A1.R",
  FNPSTODI074920909A1TESTR: "FNPS.TODI.074920909.A1.TEST.R",
  FNPBTODI074920909A1R: "FNPB.TODI.074920909.A1.R",
  FNCRFROMDI074920909A1: "FNCR.FROMDI.074920909.A1",
  FNCRFROMDI074920909A1TEST: "FNCR.FROMDI.074920909.A1.TEST",
  FNCATODI074920909A1R: "FNCA.TODI.074920909.A1.R",
  FNCATODI074920909A1TESTR: "FNCA.TODI.074920909.A1.TEST.R",
  FNCNTODI074920909A1R: "FNCN.TODI.074920909.A1.R",
  FNCSTODI074920909A1R: "FNCS.TODI.074920909.A1.R",
  FNCSTODI074920909A1TESTR: "FNCS.TODI.074920909.A1.TEST.R",
  FNCBTODI074920909A1R: "FNCB.TODI.074920909.A1.R",
  FNPRFROMDI026015244A1: "FNPR.FROMDI.026015244.A1",
  FNPRFROMDI026015244A1TEST: "FNPR.FROMDI.026015244.A1.TEST",
  FNPATODI026015244A1R: "FNPA.TODI.026015244.A1.R",
  FNPATODI026015244A1TESTR: "FNPA.TODI.026015244.A1.TEST.R",
  FNPNTODI026015244A1R: "FNPN.TODI.026015244.A1.R",
  FNPSTODI026015244A1R: "FNPS.TODI.026015244.A1.R",
  FNPSTODI026015244A1TESTR: "FNPS.TODI.026015244.A1.TEST.R",
  FNPBTODI026015244A1R: "FNPB.TODI.026015244.A1.R",
  FNCRFROMDI026015244A1: "FNCR.FROMDI.026015244.A1",
  FNCRFROMDI026015244A1TEST: "FNCR.FROMDI.026015244.A1.TEST",
  FNCATODI026015244A1R: "FNCA.TODI.026015244.A1.R",
  FNCATODI026015244A1TESTR: "FNCA.TODI.026015244.A1.TEST.R",
  FNCNTODI026015244A1R: "FNCN.TODI.026015244.A1.R",
  FNCSTODI026015244A1R: "FNCS.TODI.026015244.A1.R",
  FNCSTODI026015244A1TESTR: "FNCS.TODI.026015244.A1.TEST.R",
  FNCBTODI026015244A1R: "FNCB.TODI.026015244.A1.R",
} as const;

export type FedwireDeleteEnvironment =
  (typeof FedwireDeleteEnvironment)[keyof typeof FedwireDeleteEnvironment];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FedwireDeleteEnvironment = {
  depository_institution_test: "depository_institution_test",
  production: "production",
} as const;

export type FedwireDeleteParams = {
  operation_run: string;
  environment: FedwireDeleteEnvironment;
  queue: FedwireDeleteQueue;
  endpoint: FedwireDeleteEndpoint;
};

export type FedwireGetQueue =
  (typeof FedwireGetQueue)[keyof typeof FedwireGetQueue];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FedwireGetQueue = {
  FNPRFROMDI074920909A1: "FNPR.FROMDI.074920909.A1",
  FNPRFROMDI074920909A1TEST: "FNPR.FROMDI.074920909.A1.TEST",
  FNPATODI074920909A1R: "FNPA.TODI.074920909.A1.R",
  FNPATODI074920909A1TESTR: "FNPA.TODI.074920909.A1.TEST.R",
  FNPNTODI074920909A1R: "FNPN.TODI.074920909.A1.R",
  FNPSTODI074920909A1R: "FNPS.TODI.074920909.A1.R",
  FNPSTODI074920909A1TESTR: "FNPS.TODI.074920909.A1.TEST.R",
  FNPBTODI074920909A1R: "FNPB.TODI.074920909.A1.R",
  FNCRFROMDI074920909A1: "FNCR.FROMDI.074920909.A1",
  FNCRFROMDI074920909A1TEST: "FNCR.FROMDI.074920909.A1.TEST",
  FNCATODI074920909A1R: "FNCA.TODI.074920909.A1.R",
  FNCATODI074920909A1TESTR: "FNCA.TODI.074920909.A1.TEST.R",
  FNCNTODI074920909A1R: "FNCN.TODI.074920909.A1.R",
  FNCSTODI074920909A1R: "FNCS.TODI.074920909.A1.R",
  FNCSTODI074920909A1TESTR: "FNCS.TODI.074920909.A1.TEST.R",
  FNCBTODI074920909A1R: "FNCB.TODI.074920909.A1.R",
  FNPRFROMDI026015244A1: "FNPR.FROMDI.026015244.A1",
  FNPRFROMDI026015244A1TEST: "FNPR.FROMDI.026015244.A1.TEST",
  FNPATODI026015244A1R: "FNPA.TODI.026015244.A1.R",
  FNPATODI026015244A1TESTR: "FNPA.TODI.026015244.A1.TEST.R",
  FNPNTODI026015244A1R: "FNPN.TODI.026015244.A1.R",
  FNPSTODI026015244A1R: "FNPS.TODI.026015244.A1.R",
  FNPSTODI026015244A1TESTR: "FNPS.TODI.026015244.A1.TEST.R",
  FNPBTODI026015244A1R: "FNPB.TODI.026015244.A1.R",
  FNCRFROMDI026015244A1: "FNCR.FROMDI.026015244.A1",
  FNCRFROMDI026015244A1TEST: "FNCR.FROMDI.026015244.A1.TEST",
  FNCATODI026015244A1R: "FNCA.TODI.026015244.A1.R",
  FNCATODI026015244A1TESTR: "FNCA.TODI.026015244.A1.TEST.R",
  FNCNTODI026015244A1R: "FNCN.TODI.026015244.A1.R",
  FNCSTODI026015244A1R: "FNCS.TODI.026015244.A1.R",
  FNCSTODI026015244A1TESTR: "FNCS.TODI.026015244.A1.TEST.R",
  FNCBTODI026015244A1R: "FNCB.TODI.026015244.A1.R",
} as const;

export type FedwireGetEnvironment =
  (typeof FedwireGetEnvironment)[keyof typeof FedwireGetEnvironment];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FedwireGetEnvironment = {
  depository_institution_test: "depository_institution_test",
  production: "production",
} as const;

export type FedwireGetParams = {
  environment: FedwireGetEnvironment;
  queue: FedwireGetQueue;
};

export type EchoGetParams = {
  text: string;
};

export interface Error {
  message: string;
  status: number;
}

export type LimitsStatisticsGetResponseFedwireCycleDatesItem = {
  amount: number;
  date: string;
};

export interface LimitsStatisticsGetResponse {
  fedwire_cycle_dates: LimitsStatisticsGetResponseFedwireCycleDatesItem[];
}

export type LimitsListResponseLimitsItem = {
  ach_credit_forward_origination_processing_date_limit_usd: number;
  ach_debit_forward_origination_processing_date_limit_usd: number;
  fedwire_cycle_date_limit_usd: number;
  id: string;
  routing_number: string;
};

export interface LimitsListResponse {
  limits: LimitsListResponseLimitsItem[];
}

export interface LimitsGetResponse {
  ach_credit_forward_origination_processing_date_limit_usd: number;
  ach_debit_forward_origination_processing_date_limit_usd: number;
  fedwire_cycle_date_limit_usd: number;
  id: string;
  routing_number: string;
}

export interface LimitsForRoutingNumberGetResponse {
  ach_credit_forward_origination_processing_date_limit_usd: number;
  ach_debit_forward_origination_processing_date_limit_usd: number;
  fedwire_cycle_date_limit_usd: number;
  id: string;
  routing_number: string;
}

export type LimitsEventListResponseLimitsEventsItemEventOperatorEmail =
  | string
  | null;

export type LimitsEventListResponseLimitsEventsItem = {
  amount: number;
  event_operator_email: LimitsEventListResponseLimitsEventsItemEventOperatorEmail;
  event_time: string;
  event_type: string;
  limit_change_request_id: string;
  which_limit: string;
};

export interface LimitsEventListResponse {
  limits_events: LimitsEventListResponseLimitsEventsItem[];
}

export interface LimitsChangeRequestPostResponse {
  limits_change_requests: LimitsChangeRequestPostResponseLimitsChangeRequestsItem[];
}

export type LimitsChangeRequestPostResponseLimitsChangeRequestsItemConfirmedBy =
  string | null;

export type LimitsChangeRequestPostResponseLimitsChangeRequestsItemConfirmedAt =
  string | null;

export type LimitsChangeRequestPostResponseLimitsChangeRequestsItemCanceledBy =
  | string
  | null;

export type LimitsChangeRequestPostResponseLimitsChangeRequestsItemCanceledAt =
  | string
  | null;

export type LimitsChangeRequestPostResponseLimitsChangeRequestsItem = {
  amount: number;
  canceled_at: LimitsChangeRequestPostResponseLimitsChangeRequestsItemCanceledAt;
  canceled_by: LimitsChangeRequestPostResponseLimitsChangeRequestsItemCanceledBy;
  confirmed_at: LimitsChangeRequestPostResponseLimitsChangeRequestsItemConfirmedAt;
  confirmed_by: LimitsChangeRequestPostResponseLimitsChangeRequestsItemConfirmedBy;
  created_at: string;
  expires_at: string;
  id: string;
  requested_by: string;
  routing_number: string;
  which_limit: string;
};

export interface LimitsChangeRequestPostParameters {
  ach_credit_forward_origination_processing_date_limit_usd?: number;
  ach_debit_forward_origination_processing_date_limit_usd?: number;
  fedwire_cycle_date_limit_usd?: number;
}

export interface LimitsChangeRequestListResponse {
  limits_change_requests: LimitsChangeRequestListResponseLimitsChangeRequestsItem[];
}

export type LimitsChangeRequestListResponseLimitsChangeRequestsItemConfirmedBy =
  string | null;

export type LimitsChangeRequestListResponseLimitsChangeRequestsItemConfirmedAt =
  string | null;

export type LimitsChangeRequestListResponseLimitsChangeRequestsItemCanceledBy =
  | string
  | null;

export type LimitsChangeRequestListResponseLimitsChangeRequestsItemCanceledAt =
  | string
  | null;

export type LimitsChangeRequestListResponseLimitsChangeRequestsItem = {
  amount: number;
  canceled_at: LimitsChangeRequestListResponseLimitsChangeRequestsItemCanceledAt;
  canceled_by: LimitsChangeRequestListResponseLimitsChangeRequestsItemCanceledBy;
  confirmed_at: LimitsChangeRequestListResponseLimitsChangeRequestsItemConfirmedAt;
  confirmed_by: LimitsChangeRequestListResponseLimitsChangeRequestsItemConfirmedBy;
  created_at: string;
  expires_at: string;
  id: string;
  requested_by: string;
  routing_number: string;
  which_limit: string;
};

export interface LimitsChangeRequestConfirmPostResponse {
  confirmed_by: string;
  id: string;
  routing_number: string;
}

export interface LimitsChangeRequestCancelPostResponse {
  canceled_by: string;
  id: string;
  routing_number: string;
}

export interface FedwirePostResponse {
  mq_message_identifier: string;
}

export type FedwirePostParametersQueue =
  (typeof FedwirePostParametersQueue)[keyof typeof FedwirePostParametersQueue];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FedwirePostParametersQueue = {
  FNPRFROMDI074920909A1: "FNPR.FROMDI.074920909.A1",
  FNPRFROMDI074920909A1TEST: "FNPR.FROMDI.074920909.A1.TEST",
  FNPATODI074920909A1R: "FNPA.TODI.074920909.A1.R",
  FNPATODI074920909A1TESTR: "FNPA.TODI.074920909.A1.TEST.R",
  FNPNTODI074920909A1R: "FNPN.TODI.074920909.A1.R",
  FNPSTODI074920909A1R: "FNPS.TODI.074920909.A1.R",
  FNPSTODI074920909A1TESTR: "FNPS.TODI.074920909.A1.TEST.R",
  FNPBTODI074920909A1R: "FNPB.TODI.074920909.A1.R",
  FNCRFROMDI074920909A1: "FNCR.FROMDI.074920909.A1",
  FNCRFROMDI074920909A1TEST: "FNCR.FROMDI.074920909.A1.TEST",
  FNCATODI074920909A1R: "FNCA.TODI.074920909.A1.R",
  FNCATODI074920909A1TESTR: "FNCA.TODI.074920909.A1.TEST.R",
  FNCNTODI074920909A1R: "FNCN.TODI.074920909.A1.R",
  FNCSTODI074920909A1R: "FNCS.TODI.074920909.A1.R",
  FNCSTODI074920909A1TESTR: "FNCS.TODI.074920909.A1.TEST.R",
  FNCBTODI074920909A1R: "FNCB.TODI.074920909.A1.R",
  FNPRFROMDI026015244A1: "FNPR.FROMDI.026015244.A1",
  FNPRFROMDI026015244A1TEST: "FNPR.FROMDI.026015244.A1.TEST",
  FNPATODI026015244A1R: "FNPA.TODI.026015244.A1.R",
  FNPATODI026015244A1TESTR: "FNPA.TODI.026015244.A1.TEST.R",
  FNPNTODI026015244A1R: "FNPN.TODI.026015244.A1.R",
  FNPSTODI026015244A1R: "FNPS.TODI.026015244.A1.R",
  FNPSTODI026015244A1TESTR: "FNPS.TODI.026015244.A1.TEST.R",
  FNPBTODI026015244A1R: "FNPB.TODI.026015244.A1.R",
  FNCRFROMDI026015244A1: "FNCR.FROMDI.026015244.A1",
  FNCRFROMDI026015244A1TEST: "FNCR.FROMDI.026015244.A1.TEST",
  FNCATODI026015244A1R: "FNCA.TODI.026015244.A1.R",
  FNCATODI026015244A1TESTR: "FNCA.TODI.026015244.A1.TEST.R",
  FNCNTODI026015244A1R: "FNCN.TODI.026015244.A1.R",
  FNCSTODI026015244A1R: "FNCS.TODI.026015244.A1.R",
  FNCSTODI026015244A1TESTR: "FNCS.TODI.026015244.A1.TEST.R",
  FNCBTODI026015244A1R: "FNCB.TODI.026015244.A1.R",
} as const;

export type FedwirePostParametersEnvironment =
  (typeof FedwirePostParametersEnvironment)[keyof typeof FedwirePostParametersEnvironment];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FedwirePostParametersEnvironment = {
  depository_institution_test: "depository_institution_test",
  production: "production",
} as const;

export type FedwirePostParametersEndpoint =
  (typeof FedwirePostParametersEndpoint)[keyof typeof FedwirePostParametersEndpoint];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FedwirePostParametersEndpoint = {
  MMQFMP22: "MMQFMP22",
  MMQFMC22: "MMQFMC22",
  MFP00073: "MFP00073",
  MFC00073: "MFC00073",
} as const;

export interface FedwirePostParameters {
  body: string;
  endpoint: FedwirePostParametersEndpoint;
  environment: FedwirePostParametersEnvironment;
  operation_run: string;
  operations_id: string;
  queue: FedwirePostParametersQueue;
}

export interface FedwireGetResponse {
  body: string;
  mq_message_identifier: string;
}

export interface FedwireDeleteResponse {
  body: string;
}

export interface EchoGetResponse {
  text: string;
}

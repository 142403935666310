import React, { useMemo } from 'react';
import { PageLayout } from '../deprecated/PageLayout';
import { useBookkeepingAccountListInfinite } from 'src/hooks/reactQuery';
import { TableStateWrapper } from 'shared/components/TableStateWrapper';
import { compact } from 'lodash';
import { TableFilter } from 'shared/components/Table/filters';
import { BookkeepingAccountListComplianceCategoriesItem } from 'src/build/operations';
import { makeGroupFilter, makeEnumFilter } from 'src/lib/tableFilterHelpers';
import { BookkeepingAccountsTable } from '../tables/BookkeepingAccountsTable';
import { ROUTES } from 'src/lib/routes';
import { useSearchParamsState } from 'shared/hooks/useTypedSearchParamsState';

const cardStatuses: Record<
  BookkeepingAccountListComplianceCategoriesItem,
  string
> = {
  commingled_cash: 'Commingled Cash',
  customer_balance: 'Customer Balance',
} as const;

export const BookkeepingAccountListPage = () => {
  const [groupFilter, setGroupFilter] = useSearchParamsState(
    ROUTES.BOOKKEEPING_ACCOUNTS_LIST,
    'group'
  );
  const [complianceCategories, setComplianceCategories] = useSearchParamsState(
    ROUTES.BOOKKEEPING_ACCOUNTS_LIST,
    'compliance_categories'
  );

  const filters: TableFilter[] = useMemo(
    () =>
      compact([
        makeEnumFilter(
          complianceCategories,
          setComplianceCategories,
          'Compliance category',
          cardStatuses,
          'tag'
        ),
        makeGroupFilter(groupFilter, setGroupFilter),
      ]),
    [groupFilter, setGroupFilter, setComplianceCategories, complianceCategories]
  );
  const listResult = useBookkeepingAccountListInfinite({
    compliance_categories:
      complianceCategories.length > 0 ? complianceCategories : undefined,
    groups: groupFilter.length > 0 ? groupFilter : undefined,
  });

  return (
    <PageLayout headline={'Bookkeeping Accounts'} breadcrumbs={[]}>
      <TableStateWrapper
        table={BookkeepingAccountsTable}
        style="primary"
        {...listResult}
        emptyTitle="No Bookkeeping Accounts"
        emptySubtitle="There are no bookkeeping accounts to display"
        filters={filters}
      />
    </PageLayout>
  );
};

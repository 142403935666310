import { ReactNode } from 'react';
import { Body, Subheading } from './Text';
import { Illustration, IllustrationType } from './Illustration';

export type EmptyStateStyle = 'expanded' | 'minimized';

type Props = {
  title: string;
  children: string | ReactNode;
  illustration?: IllustrationType;
  style?: EmptyStateStyle;
};

const EmptyState = ({
  title,
  children,
  illustration = 'searchResult',
  style = 'expanded',
}: Props) => {
  return style === 'minimized' ? (
    <div className="flex w-full flex-col items-center gap-2 rounded border border-main bg-strong px-3 py-1 md:flex-row md:px-0">
      <Illustration name={illustration} className="scale-75" />
      <div className="mb-5 max-w-[550px] text-center md:mb-0 md:text-start">
        <Body weight="bold">{title}</Body>
        <Body color="secondary">{children}</Body>
      </div>
    </div>
  ) : (
    <div className="mt-24 flex w-full max-w-[300px] flex-col items-center space-y-6 md:max-w-[350px]">
      <div>
        <Illustration name={illustration} />
      </div>
      <div className="flex flex-col space-y-2 text-center">
        <Subheading color="primary" size="medium" className="mb-1">
          {title}
        </Subheading>
        <Body color="secondary">{children}</Body>
      </div>
    </div>
  );
};

export { EmptyState };

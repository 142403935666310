import React from 'react';
import { OperationPatchParametersStatus } from 'src/build/operations';
import {
  useOperationsList,
  useOperationsPatch,
  useOperationsPost,
} from 'src/hooks/reactQuery';
import { PageLayout } from './deprecated/PageLayout';
import { PropertyList } from 'shared/components/PropertyList';
import { Button } from 'shared/components/Button';

export const Operations = () => {
  const { data } = useOperationsList({});
  const operations = data?.data || [];

  const post = useOperationsPost();
  const patch = useOperationsPatch();

  return (
    <PageLayout
      id="application.operations"
      headline="Operations"
      breadcrumbs={[]}
    >
      <PropertyList
        items={operations.map((operation) => {
          if (operation.status === null) {
            return {
              label: operation.name,
              valueNode: (
                <Button
                  text="Create"
                  size="small"
                  style="secondary"
                  onClick={() => post.mutateAsync([{ name: operation.name }])}
                />
              ),
            };
          } else {
            return {
              label: operation.name,
              value: operation.status === 'active',
              onToggle: async (newValue) => {
                return patch.mutateAsync([
                  operation.name,
                  {
                    status: newValue
                      ? OperationPatchParametersStatus.active
                      : OperationPatchParametersStatus.disabled,
                  },
                ]);
              },
            };
          }
        })}
      />
    </PageLayout>
  );
};

import {
  TableColumns,
  Table,
  PrestyledTableProps,
} from 'shared/components/Table';
import { formatISO8601Timestamp, humanize } from 'shared/lib/formatting';
import { useMemo } from 'react';
import {
  DocumentRequestListResponseDataItem,
  DocumentRequestListResponseDataItemStatus,
} from 'src/build/operations';
import { BadgeColor } from 'shared/components/Badge';
import { buildPath, ROUTES } from 'src/lib/routes';
import { useOperatorGet } from 'src/hooks/reactQuery';

export type DocumentRequestsTableProps =
  PrestyledTableProps<DocumentRequestListResponseDataItem>;

export const documentRequestStatusBadgeColor: Record<
  DocumentRequestListResponseDataItemStatus,
  BadgeColor
> = {
  awaiting_submission: 'blue',
  awaiting_review: 'yellow',
  accepted: 'gray',
  canceled: 'gray',
  provisionally_accepted: 'gray',
};

export const DocumentRequestsTable = ({
  getRowProps,
  ...rest
}: DocumentRequestsTableProps) => {
  const { data: operator } = useOperatorGet({});
  const isInternal = operator && operator.role === 'internal';

  const columns = useMemo<TableColumns<DocumentRequestListResponseDataItem>>(
    () => [
      {
        header: 'Group',
        contents: (documentRequest) => ({
          text: documentRequest.group_name || documentRequest.group_id,
          href: buildPath(ROUTES.GROUPS_DETAIL, {
            groupId: documentRequest.group_id,
          }),
        }),
      },
      {
        header: 'Program',
        contents: (documentRequest) => ({
          text: documentRequest.program_name,
          href: buildPath(ROUTES.PROGRAMS_DETAIL, {
            programId: documentRequest.program_id,
          }),
        }),
      },
      {
        header: 'Description',
        expand: 1,
        contents: (documentRequest) => ({
          text: documentRequest.description,
          textWeight: 'medium',
          textColor: 'emphasis',
        }),
      },
      {
        header: 'Status',
        contents: (documentRequest) => ({
          text: humanize(documentRequest.status),
          badgeColor: documentRequestStatusBadgeColor[documentRequest.status],
        }),
      },
      {
        header: 'Created At',
        contents: (documentRequest) => ({
          text: formatISO8601Timestamp(documentRequest.created_at, 'medium'),
        }),
      },
    ],
    []
  );

  const defaultRowProps = (datum: DocumentRequestListResponseDataItem) => ({
    href: isInternal
      ? buildPath(ROUTES.DOCUMENT_REQUEST_DETAIL, {
          documentRequestId: datum.id,
        })
      : undefined,
    className: 'hover:bg-main-hover cursor-pointer transition-all group',
  });

  return (
    <Table<DocumentRequestListResponseDataItem>
      columns={columns}
      getRowProps={getRowProps ?? defaultRowProps}
      {...rest}
    />
  );
};

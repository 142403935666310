import { Switch } from '@headlessui/react';

type ToggleProps = {
  'data-testid'?: string;
  enabled: boolean;
  onChange: (enabled: boolean) => void;
  className?: string;
};

export function Toggle(props: ToggleProps) {
  return (
    <Switch
      data-testid={props['data-testid']}
      checked={props.enabled}
      onChange={props.onChange}
      className={`${
        props.enabled ? 'bg-brand' : 'bg-disabled-strong'
      } relative inline-flex h-6 w-11 items-center rounded-full`}
    >
      <span className="sr-only"></span>
      <span
        className={`${
          props.enabled ? 'translate-x-6' : 'translate-x-1'
        } inline-block h-4 w-4 rounded-full bg-main transition`}
      />
    </Switch>
  );
}

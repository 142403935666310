import React from 'react';
import { PageLayout } from './deprecated/PageLayout';
import { useValidPhysicalCardImageFileList } from '../hooks/reactQuery';
import { TableStateWrapper } from 'shared/components/TableStateWrapper';
import {
  PrestyledTableProps,
  Table,
  TableColumns,
} from 'shared/components/Table';
import { ValidPhysicalCardImageFileListResponseDataItem } from 'src/build/operations';
import { formatISO8601Timestamp, humanize } from 'shared/lib/formatting';

const PhysicalCardImageTable = (
  props: PrestyledTableProps<ValidPhysicalCardImageFileListResponseDataItem>
) => {
  const columns: TableColumns<ValidPhysicalCardImageFileListResponseDataItem> =
    [
      {
        header: 'Image',
        CellComponent: ({ datum }) => (
          <img
            alt={datum.id}
            className="w-96 border border-strong shadow-md"
            src={`data:image/png;base64,${datum.image_content}`}
          />
        ),
      },
      {
        header: 'Group name',
        contents: (record) => ({ text: record.group_name || '-' }),
      },
      {
        header: 'Created at',
        contents: (record) => ({
          text: formatISO8601Timestamp(record.created_at, 'full'),
        }),
      },
      {
        header: 'Image type',
        contents: (record) => ({ text: humanize(record.image_type) }),
      },
      {
        header: 'Image type',
        contents: (record) => ({ text: record.id }),
      },
    ];

  return <Table {...props} columns={columns} />;
};

export const PhysicalCardImages = () => {
  const listResponse = useValidPhysicalCardImageFileList({});

  return (
    <PageLayout
      headline="Physical card images"
      breadcrumbs={[{ text: 'Physical Card Images' }]}
    >
      <TableStateWrapper
        table={PhysicalCardImageTable}
        {...listResponse}
        style="detail"
        title="Images"
        emptyTitle="No images"
        emptySubtitle="There are no images to display"
        viewMoreHref=""
      />
    </PageLayout>
  );
};

import classNames from 'classnames';
import { Button, ButtonProps } from './Button';
import { ChevronRightIcon } from '@heroicons/react/20/solid';
import { ForwardRefExoticComponent, SVGProps, RefAttributes } from 'react';

export type Item = {
  id: string;
  text: string;
  detail?: string;
  rightText?: string;
  rightDetail?: string;
  icon?: ForwardRefExoticComponent<
    Omit<SVGProps<SVGSVGElement>, 'ref'> & {
      title?: string | undefined;
      titleId?: string | undefined;
    } & RefAttributes<SVGSVGElement>
  >;
  iconClassName?: string;
  href?: string;
  rightButton?: ButtonProps;
};

export type ItemListProps = {
  items: Item[];
};

export const ItemList = ({ items }: ItemListProps) => {
  return (
    <div className="flex flex-col divide-y divide-main rounded border border-main shadow-sm">
      {items.map((item) => {
        const content = (
          <div
            className={classNames(
              'flex flex-row items-center px-3 py-2',
              item.href && 'cursor-pointer hover:bg-main-hover'
            )}
          >
            {item.icon && (
              <item.icon
                className={classNames(
                  'mr-2 flex h-5 w-5 shrink-0 grow-0',
                  item.iconClassName ?? 'text-disabled'
                )}
              />
            )}
            <div className="flex grow flex-col">
              <div className="text-sm text-strong">{item.text}</div>
              <div className="text-xs text-subtle">{item.detail}</div>
            </div>
            <div className="flex shrink-0 grow-0 flex-col">
              <div className="text-right text-sm text-subtle">
                {item.rightText}
              </div>
              {item.rightDetail && (
                <div className="text-right text-xs text-subtle">
                  {item.rightDetail}
                </div>
              )}
            </div>
            {item.rightButton && (
              <Button className="self-center" {...item.rightButton} />
            )}
            {item.href && (
              <ChevronRightIcon className="ml-3 h-5 w-5 shrink-0 grow-0 text-disabled" />
            )}
          </div>
        );

        return item.href ? (
          <a href={item.href} key={item.id}>
            {content}
          </a>
        ) : (
          content
        );
      })}
    </div>
  );
};

import React, { useCallback, useEffect, useState } from 'react';
import { must } from 'src/lib/must';

type Props = {
  pixels: ImageData;
  maxWidth?: string;
};

export const ImageDataView = ({ pixels, maxWidth = '800px' }: Props) => {
  const [bitmap, setBitmap] = useState<ImageBitmap | null>(null);
  const [canvas, setCanvas] = useState<HTMLCanvasElement | null>(null);

  const canvasRef = useCallback((node: HTMLCanvasElement | null) => {
    if (node) {
      setCanvas(node);
    }
  }, []);

  useEffect(() => {
    createImageBitmap(pixels).then(setBitmap);
  }, [pixels]);

  useEffect(() => {
    if (!canvas || !bitmap) {
      return;
    }

    canvas.width = pixels.width;
    canvas.height = pixels.height;

    const ctx = must(canvas.getContext('2d'));
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    ctx.drawImage(bitmap, 0, 0, canvas.width, canvas.height);
  }, [bitmap, pixels, canvas]);

  return <canvas ref={canvasRef} style={{ maxWidth }}></canvas>;
};

import { useCheckDepositList } from 'src/hooks/reactQuery';
import { PageLayout } from './deprecated/PageLayout';
import { ItemList } from './deprecated/ItemList';
import { CheckDepositListResponse } from 'src/build/operations';
import moment from 'moment';
import { ROUTES, buildPath } from 'src/lib/routes';

const CheckDepositReviewListItems = ({
  checkDeposits,
}: {
  checkDeposits?: CheckDepositListResponse;
}) => {
  if (!checkDeposits) {
    return <div>Loading...</div>;
  }

  if (checkDeposits.data.length === 0) {
    return <p className="text-lg">No check deposits to review.</p>;
  }

  return (
    <ItemList
      items={checkDeposits.data.map((item) => ({
        href: buildPath(ROUTES.CHECK_DEPOSIT_REVIEW, {
          checkDepositID: item.id,
        }),
        id: item.id,
        text: `${item.id} [${item.check_deposit_instruction.group_id}]`,
        detail: moment(item.check_deposit_instruction.created_at).format(
          'LLLL'
        ),
      }))}
    />
  );
};

export const CheckDepositReviewList = () => {
  const { data } = useCheckDepositList({});
  return (
    <PageLayout
      id="application.check-review-list"
      headline="Check Deposit Review"
      breadcrumbs={[{ text: 'Check Deposit Review' }]}
    >
      <CheckDepositReviewListItems checkDeposits={data} />
    </PageLayout>
  );
};

import {
  useResultEvaluationListInfinite,
  useResultRetryPost,
  useResultStatusGet,
} from 'src/hooks/reactQuery';
import { PageLayout } from '../deprecated/PageLayout';
import { Loading } from 'shared/components/Loading';
import { ListItem, PropertyList } from 'shared/components/PropertyList';
import { humanize } from 'shared/lib/formatting';
import { compact } from 'lodash';
import moment from 'moment';
import { TableStateWrapper } from 'shared/components/TableStateWrapper';
import { ResultEvaluationsTable } from '../tables/ResultEvaluationsTable';
import { Heading } from 'shared/components/Text';
import { SideBySide } from 'shared/components/SideBySide';
import { Box } from 'shared/components/Box';
import { Button } from 'shared/components/Button';
import { SuppressButton } from '../results';
import { useTypedParams } from 'react-router-typesafe-routes/dom';
import { ROUTES } from 'src/lib/routes';

export const ResultDetailPage = () => {
  const { resultStatusID } = useTypedParams(ROUTES.RESULT_STATUS);
  const { data: resultStatus } = useResultStatusGet(resultStatusID);
  const listResult = useResultEvaluationListInfinite({
    result_status_id: resultStatusID,
  });
  const retryPost = useResultRetryPost();

  if (!resultStatus) {
    return <Loading />;
  }

  return (
    <PageLayout headline={resultStatus.name} breadcrumbs={[]}>
      <SideBySide
        left={
          <>
            <PropertyList
              title={'Result'}
              items={compact<ListItem>([
                {
                  value: resultStatus.name,
                  label: 'Name',
                  copyable: resultStatus.name,
                },
                {
                  value: humanize(resultStatus.status),
                  label: 'Status',
                  badgeColor:
                    resultStatus.status === 'failing' ? 'red' : 'green',
                },
                { value: humanize(resultStatus.service), label: 'Service' },
                { value: humanize(resultStatus.activity), label: 'Activity' },
                resultStatus.latest_created_at && {
                  value: moment(resultStatus.latest_created_at).fromNow(),
                  label: 'Last executed',
                },
                resultStatus.latest_created_at && {
                  value: resultStatus.latest_created_at,
                  label: 'Last executed at',
                },
                resultStatus.suppression_expires_at && {
                  value: resultStatus.suppression_expires_at,
                  label: 'Suppressed until',
                },
              ])}
            />
            {resultStatus.latest_failure_reason && (
              <>
                <PropertyList
                  title="Failure details"
                  items={compact<ListItem>([
                    resultStatus.failing_since && {
                      value: moment(resultStatus.failing_since).fromNow(),
                      label: 'Failing since',
                    },
                    resultStatus.failing_since && {
                      value: resultStatus.failing_since,
                      label: 'Failure time',
                    },
                    resultStatus.latest_failure_reason && {
                      value: 'Copy message',
                      label: 'Failure reason',
                      copyable: resultStatus.latest_failure_reason,
                    },
                  ])}
                />
                <div className="whitespace-pre-wrap bg-strong p-3 font-mono">
                  {resultStatus.latest_failure_reason}
                </div>
              </>
            )}
          </>
        }
        right={
          <>
            <Heading>Actions</Heading>
            <Box flex>
              <Button
                text={retryPost.data ? 'Pending' : 'Retry'}
                icon="arrow_refresh"
                disabled={retryPost.isLoading || !!retryPost.data}
                onClick={() =>
                  retryPost.mutateAsync([{ result_id: resultStatus.id }])
                }
              />
              <SuppressButton
                name={resultStatus.name}
                service={resultStatus.service}
                activity={resultStatus.activity}
              />
            </Box>
            <Heading>History</Heading>
            <TableStateWrapper
              table={ResultEvaluationsTable}
              style="primary"
              {...listResult}
              emptyTitle="No evaluations"
              emptySubtitle="There are no evaluations to display"
              filters={[]}
            />
          </>
        }
        mode={'rightDrawer'}
      />
    </PageLayout>
  );
};

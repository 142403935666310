import {
  TableColumns,
  Table,
  PrestyledTableProps,
} from 'shared/components/Table';
import { formatISO8601Timestamp, humanize } from 'shared/lib/formatting';
import { useMemo } from 'react';

import { ControlRecordGetResponse } from 'src/build/operations';
import { absurd } from 'src/lib/absurd';
import { buildPath, ROUTES } from 'src/lib/routes';

type Props = PrestyledTableProps<ControlRecordGetResponse>;

export const controlRecordLink = (controlRecord: ControlRecordGetResponse) => {
  switch (controlRecord.record_type) {
    case 'bookkeeping_account':
      return buildPath(ROUTES.BOOKKEEPING_ACCOUNTS_DETAIL, {
        bookkeepingAccountID: controlRecord.record_id,
      });
    case 'entity':
      return buildPath(ROUTES.ENTITY, {
        entityId: controlRecord.record_id,
      });
    case 'account':
      return buildPath(ROUTES.ACCOUNTS_DETAIL, {
        accountId: controlRecord.record_id,
      });
    default:
      absurd(controlRecord.record_type);
  }
};

export const ControlRecordsTable = (props: Props) => {
  const { getRowProps, ...rest } = props;
  const columns = useMemo<TableColumns<ControlRecordGetResponse>>(() => {
    return [
      {
        header: 'Status',
        contents: (control) => ({
          text: humanize(control.result),
          badgeColor: control.result === 'success' ? 'green' : 'red',
        }),
      },
      {
        header: 'Associated object',
        contents: (control) => ({
          text: control.record_name,
          href: controlRecordLink(control),
        }),
      },
      {
        header: 'Evaluated at',
        contents: (control) => ({
          text: control.evaluated_at
            ? formatISO8601Timestamp(control.evaluated_at, 'full')
            : '-',
        }),
      },
    ];
  }, []);

  const defaultRowProps = (datum: ControlRecordGetResponse) => ({
    className: 'hover:bg-main-hover cursor-pointer transition-all group',
    href: buildPath(ROUTES.CONTROL_RECORDS_DETAILS, { id: datum.id }),
  });

  return (
    <Table<ControlRecordGetResponse>
      columns={columns}
      getRowProps={getRowProps ?? defaultRowProps}
      {...rest}
    />
  );
};

import React, { useMemo } from 'react';
import { usePlatformUnusualActivityReportListInfinite } from 'src/hooks/reactQuery';
import { TableStateWrapper } from 'shared/components/TableStateWrapper';
import { PlatformUnusualActivityReportsTable } from '../tables/PlatformUnusualActivityReportsTable';
import { compact } from 'lodash';
import { TableFilter } from 'shared/components/Table/filters';
import {
  makeGroupFilter,
  makeTimeRangeFilter,
} from 'src/lib/tableFilterHelpers';
import { DateTime } from 'luxon';
import { useSearchParamsState } from 'shared/hooks/useTypedSearchParamsState';
import { ROUTES } from 'src/lib/routes';
import { useTypedCreatedAtRangeParams } from 'src/hooks/timeSearchParameters';

export const PlatformUnusualActivityReportListTab = () => {
  const [groupFilter, setGroupFilter] = useSearchParamsState(
    ROUTES.UNUSUAL_ACTIVITY_REPORTS.PLATFORM,
    'group'
  );
  const [{ startAt, endAt }, setTimeRange] = useTypedCreatedAtRangeParams(
    ROUTES.UNUSUAL_ACTIVITY_REPORTS.PLATFORM
  );

  const startAtDateTime = startAt
    ? DateTime.fromISO(startAt, { zone: 'utc' })
    : undefined;
  const endAtDateTime = endAt
    ? DateTime.fromISO(endAt, { zone: 'utc' })
    : undefined;

  const filters: TableFilter[] = useMemo(
    () =>
      compact([
        makeGroupFilter(groupFilter, setGroupFilter),
        makeTimeRangeFilter(
          { startAt, endAt },
          setTimeRange,
          'Reported To Increase'
        ),
      ]),
    [endAt, groupFilter, setGroupFilter, setTimeRange, startAt]
  );

  const listResult = usePlatformUnusualActivityReportListInfinite({
    groups: groupFilter.length > 0 ? groupFilter : undefined,
    created_at_or_after: startAtDateTime?.startOf('day').toISO() ?? undefined,
    created_at_or_before: endAtDateTime?.endOf('day').toISO() ?? undefined,
  });

  return (
    <TableStateWrapper
      table={PlatformUnusualActivityReportsTable}
      style="primary"
      {...listResult}
      emptyTitle="No platform unusual activity reports"
      emptySubtitle="There are no platform unusual activity reports to display"
      filters={filters}
    />
  );
};

import React, { useMemo } from 'react';
import { PageLayout } from '../deprecated/PageLayout';
import { useAccountListInfinite, useOperatorGet } from 'src/hooks/reactQuery';
import { TableStateWrapper } from 'shared/components/TableStateWrapper';
import { AccountsTable } from '../tables/AccountsTable';
import {
  makeBankFilter,
  makeEntityFilter,
  makeGroupFilter,
  makeKeywordFilter,
  makeProgramFilter,
  makeStatusFilter,
  makeTimeRangeFilter,
} from 'src/lib/tableFilterHelpers';
import { compact } from 'lodash';
import { TableFilter } from 'shared/components/Table/filters';
import { DateTime } from 'luxon';
import { ROUTES } from 'src/lib/routes';
import { useSearchParamsState } from 'shared/hooks/useTypedSearchParamsState';
import { useTypedCreatedAtRangeParams } from 'src/hooks/timeSearchParameters';

const accountStatuses = {
  open: 'Open',
  closed: 'Closed',
} as const;

export const AccountListPage = () => {
  const { data: operator } = useOperatorGet({});

  const [groupFilter, setGroupFilter] = useSearchParamsState(
    ROUTES.ACCOUNTS_LIST,
    'group'
  );
  const [entityFilter, setEntityFilter] = useSearchParamsState(
    ROUTES.ACCOUNTS_LIST,
    'entity'
  );
  const [programFilter, setProgramFilter] = useSearchParamsState(
    ROUTES.ACCOUNTS_LIST,
    'program'
  );
  const [statuses, setStatuses] = useSearchParamsState(
    ROUTES.ACCOUNTS_LIST,
    'statuses'
  );

  const [bankFilter, setBankFilter] = useSearchParamsState(
    ROUTES.ACCOUNTS_LIST,
    'bank'
  );
  const [keywordFilter, setKeywordFilter] = useSearchParamsState(
    ROUTES.ACCOUNTS_LIST,
    'keyword'
  );

  const [{ startAt, endAt }, setTimeRange] = useTypedCreatedAtRangeParams(
    ROUTES.ACCOUNTS_LIST
  );

  const startAtDateTime = startAt
    ? DateTime.fromISO(startAt, { zone: 'utc' })
    : undefined;
  const endAtDateTime = endAt
    ? DateTime.fromISO(endAt, { zone: 'utc' })
    : undefined;

  const filters: TableFilter[] = useMemo(
    () =>
      compact([
        makeGroupFilter(groupFilter, setGroupFilter),
        makeProgramFilter(programFilter, setProgramFilter),
        makeEntityFilter(entityFilter, setEntityFilter),
        makeStatusFilter(statuses, setStatuses, accountStatuses),
        makeTimeRangeFilter({ startAt, endAt }, setTimeRange, 'Created'),
        operator?.role === 'internal' &&
          makeBankFilter(bankFilter, setBankFilter),
        makeKeywordFilter(keywordFilter, setKeywordFilter),
      ]),
    [
      bankFilter,
      endAt,
      entityFilter,
      groupFilter,
      keywordFilter,
      operator?.role,
      programFilter,
      setBankFilter,
      setEntityFilter,
      setGroupFilter,
      setKeywordFilter,
      setProgramFilter,
      setStatuses,
      setTimeRange,
      startAt,
      statuses,
    ]
  );
  const listResult = useAccountListInfinite({
    statuses: statuses.length > 0 ? statuses : undefined,
    groups: groupFilter.length > 0 ? groupFilter : undefined,
    entity_ids: entityFilter.length > 0 ? entityFilter : undefined,
    program_ids: programFilter.length > 0 ? programFilter : undefined,
    created_at_or_after: startAtDateTime?.startOf('day').toISO() ?? undefined,
    created_at_or_before: endAtDateTime?.endOf('day').toISO() ?? undefined,
    banks: bankFilter.length > 0 ? bankFilter : undefined,
    keyword: keywordFilter ? keywordFilter : undefined,
  });

  return (
    <PageLayout headline={'Accounts'} breadcrumbs={[]}>
      <TableStateWrapper
        table={AccountsTable}
        style="primary"
        {...listResult}
        emptyTitle="No Accounts"
        emptySubtitle="There are no accounts to display"
        filters={filters}
        showBank
        showGroup
        showProgram
      />
    </PageLayout>
  );
};

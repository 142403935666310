import { useInboundMailItemList } from 'src/hooks/reactQuery';
import { PageLayout } from './deprecated/PageLayout';
import { ItemList } from './deprecated/ItemList';
import { InboundMailItemListResponse } from 'src/build/operations';
import moment from 'moment';
import { StyledLink } from 'shared/components/StyledLink';

const InboundMailItemListItems = ({
  inboundMailItems,
}: {
  inboundMailItems?: InboundMailItemListResponse;
}) => {
  if (!inboundMailItems) {
    return <div>Loading...</div>;
  }

  if (inboundMailItems.data.length === 0) {
    return <p className="text-lg">No mail items to review 🎉</p>;
  }

  return (
    <ItemList
      items={inboundMailItems.data.map((item) => ({
        href: `/manual_tasks/Operations::ManualTask::AllocateStableMailboxMailItem?stable_mailbox_mail_item_id=${item.id}`,
        id: item.id,
        text: item.id,
        detail: moment(item.created_at).format('LLLL'),
      }))}
    />
  );
};

export const InboundMailItemList = () => {
  const { data } = useInboundMailItemList({});
  return (
    <PageLayout
      headline="Inbound mail item review"
      breadcrumbs={[{ text: 'Inbound mail item review' }]}
    >
      <StyledLink
        href="https://paper.dropbox.com/doc/Lockbox-runbook--CQ5Rdt0uFlOx9slwak8oxt_AAg-PrffJNlh7El6UipGnWYif"
        style="underline"
      >
        Runbook
      </StyledLink>
      <InboundMailItemListItems inboundMailItems={data} />
    </PageLayout>
  );
};

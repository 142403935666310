// istanbul ignore file

import { must } from 'src/lib/must';
import Tesseract, { createWorker } from 'tesseract.js';
import { set } from 'idb-keyval';
import { getDataURL } from './image-utility';

type MICRResult = {
  confidence: number;
  text: string;
  auxiliaryOnUs?: string;
  serialNumber?: string;
  routingNumber?: string;
  accountNumber?: string;
};

const CONFIDENCE_THRESHOLD = 70;
const TRANSIT_SYMBOL = '⑆';
const ON_US_SYMBOL = '⑈';

const AUXILIARY_ON_US_REGEX = /⑈(\d+)⑈/;
const ROUTING_NUMBER_REGEX = /⑆(\d+)⑆/;
const ACCOUNT_NUMBER_REGEX = /⑆(\d+)⑈/;

const MICR_MAPPING: Record<string, string> = {
  '[': TRANSIT_SYMBOL,
  '@': ON_US_SYMBOL,
  '-': ON_US_SYMBOL,
};

function filter(word: Tesseract.Word) {
  return word.symbols
    .filter((s) => s.confidence > CONFIDENCE_THRESHOLD)
    .map((s) => s.text)
    .join('')
    .replace(/\D/g, '');
}

let workerPromise: Promise<Tesseract.Worker> | null = null;
const loadWorker = () => {
  if (!workerPromise) {
    workerPromise = fetch('/tesseract/micr.traineddata').then(async (r) => {
      const trainedData = await r.arrayBuffer();
      await set('./micr.traineddata', trainedData);
      const langPath = `${document.location.protocol}//${document.location.host}/tesseract`;
      const worker = await createWorker({
        langPath,
        // eslint-disable-next-line no-console
        logger: console.debug,
      });
      await worker.loadLanguage('micr');
      await worker.initialize('micr');
      return worker;
    });
  }
  return workerPromise;
};

export async function parseMICRwithOCR(
  imageData: ImageData
): Promise<MICRResult> {
  const worker = await loadWorker();
  const res = await worker.recognize(getDataURL(imageData), {
    rotateAuto: true,
  });

  const {
    data: { words },
  } = res;

  const text = res.data.symbols
    .map((s) => {
      if (s.confidence < CONFIDENCE_THRESHOLD) {
        return ' ';
      }
      if (MICR_MAPPING[s.text]) {
        return MICR_MAPPING[s.text];
      }
      if (s.text.match(/\d/)) {
        return s.text;
      }
      return ' ';
    })
    .join('');

  let serialNumber;
  let auxiliaryOnUs;
  let routingNumber;
  let accountNumber;

  if (words.length > 3 || words.length === 1) {
    const auxiliaryOnUsMatch = AUXILIARY_ON_US_REGEX.exec(text);
    if (auxiliaryOnUsMatch) {
      auxiliaryOnUs = auxiliaryOnUsMatch[1];
    }

    const routingNumberMatch = ROUTING_NUMBER_REGEX.exec(text);
    if (routingNumberMatch) {
      routingNumber = routingNumberMatch[1];
    }

    const accountNumberMatch = ACCOUNT_NUMBER_REGEX.exec(text);
    if (accountNumberMatch) {
      accountNumber = accountNumberMatch[1];
    }

    return {
      text,
      auxiliaryOnUs,
      routingNumber,
      accountNumber,
      confidence: res.data.confidence,
    };
  }

  if (words.length === 3) {
    if (text.startsWith(ON_US_SYMBOL)) {
      auxiliaryOnUs = filter(must(words.shift()));
    } else {
      serialNumber = filter(must(words.pop()));
    }
  }

  if (words.length === 2) {
    routingNumber = filter(words[0]);
    accountNumber = filter(words[1]);
  }

  return {
    confidence: res.data.confidence,
    text,
    auxiliaryOnUs,
    serialNumber,
    routingNumber,
    accountNumber,
  };
}

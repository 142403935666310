import {
  TableColumns,
  Table,
  PrestyledTableProps,
} from 'shared/components/Table';
import { useMemo } from 'react';

import { ResultEvaluationListResponseDataItem } from 'src/build/operations';
import { formatISO8601Timestamp } from 'shared/lib/formatting';

export type ResultsStatusTableProps =
  PrestyledTableProps<ResultEvaluationListResponseDataItem>;

export const ResultEvaluationsTable = ({
  ...rest
}: ResultsStatusTableProps) => {
  const columns = useMemo<TableColumns<ResultEvaluationListResponseDataItem>>(
    () => [
      {
        header: 'Created at',
        contents: (result) => ({
          text: formatISO8601Timestamp(result.created_at, 'full'),
        }),
      },
      {
        header: 'Status',
        contents: (result) => ({
          text: result.success ? 'Succeeded' : 'Failed',
          badgeColor: result.success ? 'gray' : 'red',
        }),
      },
    ],
    []
  );

  const getRowProps = (_datum: ResultEvaluationListResponseDataItem) => ({
    className: 'hover:bg-main-hover cursor-pointer transition-all group',
  });

  return (
    <Table<ResultEvaluationListResponseDataItem>
      columns={columns}
      getRowProps={getRowProps}
      {...rest}
    />
  );
};

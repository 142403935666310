import { StyledLink } from './StyledLink';
import { Heading, Body } from './Text';

type StackOfLinksProps = {
  title?: string;
  links: {
    href: string;
    text: string;
  }[];
};

export const StackOfLinks = ({
  links,
  title = 'Quick Links',
}: StackOfLinksProps) => {
  return (
    <div className="space-y-3">
      <Heading contents={title} />
      <div className="h-px border-t border-main" />
      {links.map((link) => (
        <Body key={link.href} className="truncate">
          <StyledLink style="underline" href={link.href}>
            {link.text}
          </StyledLink>
        </Body>
      ))}
    </div>
  );
};

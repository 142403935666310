import { Header } from './Header';

type SectionProps = {
  header?: string | React.ReactNode;
  children: React.ReactNode;
};

export const Section = (props: SectionProps) => {
  return (
    <div className="space-y-2">
      <div>{props.header && <Header>{props.header}</Header>}</div>
      <div>{props.children}</div>
    </div>
  );
};

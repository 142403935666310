import React, { useEffect, useState } from 'react';
import { QueryClientProvider, QueryClient } from '@tanstack/react-query';
import { Authentication } from './authentication';
import axios from 'axios';
import { useServices } from '../hooks/use-services';
import { BrowserRouter } from 'react-router-dom';

const defaultQueryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
    },
  },
});

export const Root = () => {
  const [loaded, setLoaded] = useState(false);
  const { operations } = useServices();

  useEffect(() => {
    const baseURLInterceptor = axios.interceptors.request.use((config) => ({
      baseURL: operations,
      withCredentials: true,
      ...config,
    }));

    setLoaded(true);

    return () => {
      axios.interceptors.request.eject(baseURLInterceptor);
    };
  }, [operations, setLoaded]);

  return (
    <QueryClientProvider client={defaultQueryClient}>
      {loaded && (
        <BrowserRouter>
          <Authentication />
        </BrowserRouter>
      )}
    </QueryClientProvider>
  );
};

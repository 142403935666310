import {
  TableColumns,
  Table,
  PrestyledTableProps,
} from 'shared/components/Table';
import { useMemo } from 'react';

import { BeneficialOwnerGetResponseMicrobiltItem } from 'src/build/operations';
import { compact } from 'lodash';

type Props = PrestyledTableProps<BeneficialOwnerGetResponseMicrobiltItem>;

export const MicrobiltSearchResultsTable = (props: Props) => {
  const { getRowProps, ...rest } = props;
  const columns = useMemo<
    TableColumns<BeneficialOwnerGetResponseMicrobiltItem>
  >(() => {
    return [
      {
        header: 'Name',
        contents: (result) => ({
          text: compact([
            result.first_name,
            result.middle_name,
            result.last_name,
          ]).join(' '),
        }),
      },
      {
        header: 'Tax ID Last 4',
        contents: (result) => ({
          text: result.tax_id_last_4 ?? '-',
        }),
      },
      {
        header: 'Date of birth',
        contents: (result) => ({
          text: result.birth_date ?? '-',
        }),
      },
      {
        header: 'Date of death',
        contents: (result) => ({
          text: result.death_date ?? '-',
        }),
      },
    ];
  }, []);

  const defaultRowProps = (
    _datum: BeneficialOwnerGetResponseMicrobiltItem
  ) => ({
    className: 'hover:bg-main-hover cursor-pointer transition-all group',
  });

  return (
    <Table<BeneficialOwnerGetResponseMicrobiltItem>
      columns={columns}
      getRowProps={getRowProps ?? defaultRowProps}
      {...rest}
    />
  );
};

import { useMemo } from 'react';
import {
  PrestyledTableProps,
  Table,
  TableColumns,
} from 'shared/components/Table';
import { humanize } from 'shared/lib/formatting';
import { BeneficialOwnerRelationshipListResponseDataItem } from 'src/build/operations';

type EntitySetupCorporationBeneficialOwnerRelationshipsTableProps =
  PrestyledTableProps<BeneficialOwnerRelationshipListResponseDataItem> & {
    title: string;
  };

export const EntitySetupCorporationBeneficialOwnerRelationshipsTable = (
  props: EntitySetupCorporationBeneficialOwnerRelationshipsTableProps
) => {
  const { ...rest } = props;
  const columns = useMemo<
    TableColumns<BeneficialOwnerRelationshipListResponseDataItem>
  >(() => {
    return [
      {
        header: 'Entity',
        contents: (relationship) => {
          return {
            text: relationship.entity_setup_corporation_submission_name,
            href: '/entities/' + relationship.entity_id,
          };
        },
      },
      {
        header: 'Prong',
        contents: (relationship) => {
          return {
            text: humanize(relationship.prong),
          };
        },
      },
      {
        header: 'Status',
        contents: (relationship) => {
          return {
            text: relationship.archived ? 'Archived' : 'Active',
          };
        },
      },
    ];
  }, []);

  return <Table columns={columns} {...rest} />;
};

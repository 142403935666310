import {
  TableColumns,
  Table,
  PrestyledTableProps,
} from 'shared/components/Table';
import { useMemo } from 'react';

import { OAuthApplicationListResponseDataItem } from 'src/build/operations';
import { formatISO8601Timestamp } from 'shared/lib/formatting';
import { buildPath, ROUTES } from 'src/lib/routes';

type Props = PrestyledTableProps<OAuthApplicationListResponseDataItem>;

export const OAuthApplicationsTable = (props: Props) => {
  const { getRowProps, ...rest } = props;
  const columns = useMemo<
    TableColumns<OAuthApplicationListResponseDataItem>
  >(() => {
    return [
      {
        header: 'Name',
        expand: 1,
        contents: (oauthapplication) => ({
          text: oauthapplication.name,
          textWeight: 'medium',
          textColor: 'emphasis',
        }),
      },
      {
        header: 'Group',
        expand: 1,
        contents: (oauthapplication) => ({
          text: oauthapplication.group_id,
          href: buildPath(ROUTES.GROUPS_DETAIL, {
            groupId: oauthapplication.group_id,
          }),
        }),
      },
      {
        header: 'Created',
        align: 'right',
        contents: (oauthapplication) => ({
          text: formatISO8601Timestamp(oauthapplication.created_at, 'medium'),
        }),
      },
    ];
  }, []);

  const defaultRowProps = (datum: OAuthApplicationListResponseDataItem) => ({
    href: buildPath(ROUTES.OAUTH_APPLICATION_DETAIL, {
      oauthApplicationID: datum.id,
    }),
    className: 'hover:bg-main-hover cursor-pointer transition-all group',
  });

  return (
    <Table<OAuthApplicationListResponseDataItem>
      columns={columns}
      getRowProps={getRowProps ?? defaultRowProps}
      {...rest}
    />
  );
};

import React, { useState } from 'react';
import { PageLayout } from '../deprecated/PageLayout';
import {
  useSanctionsScreeningTriggerWordsListInfinite,
  useSanctionsScreeningTriggerWordsPost,
} from 'src/hooks/reactQuery';
import { TableStateWrapper } from 'shared/components/TableStateWrapper';
import { SanctionsScreeningTriggerWordsTable } from '../tables/SanctionsScreeningTriggerWordsTable';
import { Button } from 'shared/components/Button';
import { useDrawer } from 'shared/components/Modal';
import { DrawerLayout } from 'shared/components/DrawerLayout';
import { Box } from 'shared/components/Box';
import { Body } from 'shared/components/Text';
import { TextInput } from 'shared/components/TextInput';

const Drawer = (props: { onClose: () => void }) => {
  const mutate = useSanctionsScreeningTriggerWordsPost();
  const [token, setToken] = useState('');

  return (
    <DrawerLayout
      title="Create new word or phrase"
      body={
        <Box>
          <Body color="secondary">
            Adding a word or phrase to the OFAC triggering words list will cause
            future screening to stop on any transaction that contains the word
            or phrase. Capitalization does not matter.
          </Body>

          <TextInput value={token} onChange={(e) => setToken(e.target.value)} />
        </Box>
      }
      rightButton={
        <Button
          icon="create"
          text="Save"
          onClick={async () => {
            await mutate.mutateAsync([{ token }]);
            props.onClose();
          }}
          style="warning"
          disabled={mutate.isLoading || token.length === 0}
        />
      }
    />
  );
};

export const SanctionsScreeningTriggerWordsListPage = () => {
  const listResult = useSanctionsScreeningTriggerWordsListInfinite({});
  const { showDrawer, closeDrawer } = useDrawer();

  return (
    <PageLayout headline={'OFAC Triggering Words'} breadcrumbs={[]}>
      <TableStateWrapper
        action={
          <Button
            style="primary"
            text="Create"
            icon="edit"
            onClick={() => showDrawer(<Drawer onClose={closeDrawer} />)}
          />
        }
        table={SanctionsScreeningTriggerWordsTable}
        style="primary"
        {...listResult}
        emptyTitle="No OFAC triggering words"
        emptySubtitle="Configure some words!"
        filters={[]}
      />
    </PageLayout>
  );
};

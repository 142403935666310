import { useAccountNumberListInfinite } from 'src/hooks/reactQuery';
import { PageLayout } from '../deprecated/PageLayout';
import { TableStateWrapper } from 'shared/components/TableStateWrapper';
import { AccountNumbersTable } from '../tables/AccountNumbersTable';
import { compact } from 'lodash';
import { useMemo } from 'react';
import { TableFilter } from 'shared/components/Table/filters';
import { AccountNumberGetResponseStatus } from 'src/build/operations';
import {
  makeStatusFilter,
  makeGroupFilter,
  makeAccountFilter,
  makeKeywordFilter,
  makeTimeRangeFilter,
} from 'src/lib/tableFilterHelpers';
import { DateTime } from 'luxon';
import { ROUTES } from 'src/lib/routes';
import { useSearchParamsState } from 'shared/hooks/useTypedSearchParamsState';
import { useTypedCreatedAtRangeParams } from 'src/hooks/timeSearchParameters';

const accountNumberStatuses: Record<AccountNumberGetResponseStatus, string> = {
  active: 'Active',
  disabled: 'Disabled',
  canceled: 'Canceled',
} as const;

export const AccountNumberListPage = () => {
  const [groupFilter, setGroupFilter] = useSearchParamsState(
    ROUTES.ACCOUNT_NUMBERS_LIST,
    'group'
  );
  const [statuses, setStatuses] = useSearchParamsState(
    ROUTES.ACCOUNT_NUMBERS_LIST,
    'statuses'
  );

  const [accountFilter, setAccountFilter] = useSearchParamsState(
    ROUTES.ACCOUNT_NUMBERS_LIST,
    'account'
  );
  const [keywordFilter, setKeywordFilter] = useSearchParamsState(
    ROUTES.ACCOUNT_NUMBERS_LIST,
    'keyword'
  );
  const [{ startAt, endAt }, setTimeRange] = useTypedCreatedAtRangeParams(
    ROUTES.ACCOUNT_NUMBERS_LIST
  );

  const startAtDateTime = startAt
    ? DateTime.fromISO(startAt, { zone: 'utc' })
    : undefined;
  const endAtDateTime = endAt
    ? DateTime.fromISO(endAt, { zone: 'utc' })
    : undefined;

  const filters: TableFilter[] = useMemo(
    () =>
      compact([
        makeStatusFilter(statuses, setStatuses, accountNumberStatuses),
        makeGroupFilter(groupFilter, setGroupFilter),
        makeAccountFilter(accountFilter, setAccountFilter),
        makeKeywordFilter(keywordFilter, setKeywordFilter),
        makeTimeRangeFilter({ startAt, endAt }, setTimeRange, 'Created'),
      ]),
    [
      accountFilter,
      endAt,
      groupFilter,
      keywordFilter,
      setAccountFilter,
      setGroupFilter,
      setKeywordFilter,
      setStatuses,
      setTimeRange,
      startAt,
      statuses,
    ]
  );
  const listResult = useAccountNumberListInfinite({
    statuses: statuses.length > 0 ? statuses : undefined,
    groups: groupFilter.length > 0 ? groupFilter : undefined,
    account_ids: accountFilter.length > 0 ? accountFilter : undefined,
    keyword: keywordFilter ? keywordFilter : undefined,
    created_at_or_after: startAtDateTime?.startOf('day').toISO() ?? undefined,
    created_at_or_before: endAtDateTime?.endOf('day').toISO() ?? undefined,
  });

  return (
    <PageLayout
      id="application.operations"
      headline={<span>Account Numbers</span>}
      breadcrumbs={[]}
    >
      <TableStateWrapper
        {...listResult}
        style="primary"
        table={AccountNumbersTable}
        emptyTitle="No account numbers"
        emptySubtitle="There are no account numbers."
        showAccount
        showGroup
        filters={filters}
      />
    </PageLayout>
  );
};

import React from 'react';
import { PageLayout } from '../deprecated/PageLayout';
import {
  useBookkeepingAccountGet,
  useBookkeepingEntryListInfinite,
} from 'src/hooks/reactQuery';
import { SideBySide } from 'shared/components/SideBySide';
import { BookkeepingAccountPropertyList } from '../property-lists/BookkeepingAccountPropertyList';
import { BookkeepingEntriesTable } from '../tables/BookkeepingEntriesTable';
import { TableStateWrapper } from 'shared/components/TableStateWrapper';
import { useTypedParams } from 'react-router-typesafe-routes/dom';
import { ROUTES } from 'src/lib/routes';

export const BookkeepingAccountDetailPage = () => {
  const { bookkeepingAccountID: bookkeepingAccountId } = useTypedParams(
    ROUTES.BOOKKEEPING_ACCOUNTS_DETAIL
  );

  const bookkeepingEntryList = useBookkeepingEntryListInfinite({
    bookkeeping_account_ids: [bookkeepingAccountId],
  });

  const { data: bookkeepingAccount } =
    useBookkeepingAccountGet(bookkeepingAccountId);

  if (!bookkeepingAccount) {
    return null;
  }

  return (
    <PageLayout headline={'Bookkeeping Account'} breadcrumbs={[]}>
      <SideBySide
        mode="rightDrawer"
        right={
          <BookkeepingAccountPropertyList
            bookkeepingAccount={bookkeepingAccount}
            layoutHorizontallyWhenPossible={false}
            title="Details"
          />
        }
        left={
          <TableStateWrapper
            table={BookkeepingEntriesTable}
            style="detail"
            {...bookkeepingEntryList}
            title="Entries"
            emptyTitle="No entries"
            emptySubtitle="There are no entries to display"
            viewMoreHref=""
          />
        }
      />
    </PageLayout>
  );
};

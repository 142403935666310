import { useMemo } from 'react';
import { BadgeColor } from 'shared/components/Badge';
import {
  PrestyledTableProps,
  Table,
  TableColumns,
} from 'shared/components/Table';
import {
  formatInteger,
  formatISO8601Timestamp,
  humanize,
} from 'shared/lib/formatting';
import {
  SanctionsScreeningReviewListResponseDataItem,
  SanctionsScreeningReviewListResponseDataItemStatus,
} from 'src/build/operations';
import { useOperatorGet } from 'src/hooks/reactQuery';

type SanctionsScreeningReviewsTableProps = {
  showRecordId: boolean;
} & PrestyledTableProps<SanctionsScreeningReviewListResponseDataItem>;

const statusBadgeColors: Record<
  SanctionsScreeningReviewListResponseDataItemStatus,
  BadgeColor
> = {
  false_positive: 'green',
  pending_reviewing: 'yellow',
  true_positive: 'red',
};

export const SanctionsScreeningReviewsTable = (
  props: SanctionsScreeningReviewsTableProps
) => {
  const { showRecordId, ...rest } = props;

  const { data: operator } = useOperatorGet({});
  const isIncreaseOperator = operator?.role === 'internal';

  const columns = useMemo<
    TableColumns<SanctionsScreeningReviewListResponseDataItem>
  >(() => {
    return [
      showRecordId && {
        header: 'Record ID',
        contents: (review) => ({
          text: review.record_id,
          textColor: 'emphasis',
          textWeight: 'medium',
        }),
      },
      showRecordId && {
        header: 'Record type',
        contents: (review) => {
          return {
            text: humanize(review.record_type),
          };
        },
      },
      !showRecordId && {
        header: 'Review ID',
        expand: 2,
        contents: (review) => ({
          text: review.id,
          textColor: 'emphasis',
          textWeight: 'medium',
        }),
      },
      isIncreaseOperator && {
        header: 'Reviewer',
        contents: (review) => ({ text: humanize(review.reviewer) }),
      },
      {
        header: '# of hits',
        contents: (review) => {
          return {
            text: formatInteger(review.hits_count),
          };
        },
        align: 'right',
      },
      {
        header: 'Status',
        expand: 1,
        contents: (review) => {
          return {
            text: humanize(review.status),
            badgeColor: statusBadgeColors[review.status],
          };
        },
      },
      {
        header: 'Created',
        contents: (review) => {
          return {
            text: formatISO8601Timestamp(review.created_at, 'medium'),
          };
        },
      },
    ];
  }, [showRecordId, isIncreaseOperator]);

  return <Table columns={columns} {...rest} />;
};

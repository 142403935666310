import { usePlatformComplaintListSubmissionGet } from 'src/hooks/reactQuery';
import { PageLayout } from './deprecated/PageLayout';
import { Table } from './deprecated/Table';
import { useTypedParams } from 'react-router-typesafe-routes/dom';
import { ROUTES } from 'src/lib/routes';

export const PlatformComplaintListSubmission = () => {
  const { submissionID } = useTypedParams(
    ROUTES.PLATFORM_COMPLAINT_LIST_SUBMISSION_DETAIL
  );

  const { data: submission } =
    usePlatformComplaintListSubmissionGet(submissionID);

  if (!submission) {
    return <></>;
  }

  return (
    <PageLayout
      id="platform.complaint.list-submission"
      headline="Platform Complaint List Submission"
      breadcrumbs={[
        { text: 'Platform Complaint List Submissions' },
        { text: submissionID },
      ]}
    >
      {submission.complaints.length === 0 && (
        <div className="bg-strong p-4 text-sm">
          The platform reports no complaint in the period{' '}
          {submission.period_start} - {submission.period_end}.
        </div>
      )}
      {submission.complaints.length > 0 && (
        <Table
          data={submission.complaints}
          columns={[
            {
              key: 'name',
              label: 'Name',
            },
            {
              key: 'received_on',
              label: 'Received On',
            },
            {
              key: 'resolved_on',
              label: 'Resolved On',
            },
            {
              key: 'source',
              label: 'Source',
            },
            {
              key: 'complaint_type',
              label: 'Complaint Type',
            },
          ]}
          canExpandRows={true}
          renderExpandedRow={(row) => (
            <div className="space-y-2 bg-strong p-4 text-sm">
              {row.description}
            </div>
          )}
        />
      )}
    </PageLayout>
  );
};

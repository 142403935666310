import React from 'react';
import { useGroupGet } from 'src/hooks/reactQuery';
import { Badge } from './deprecated/Badge';

type Props = { groupID: string };

export const GroupLockedBadge = (props: Props) => {
  const { data } = useGroupGet(props.groupID);

  return data?.locked ? <Badge color="red">LOCKED</Badge> : <></>;
};

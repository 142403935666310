type HeaderProps =
  | {
      text: string;
    }
  | {
      children: React.ReactNode;
    };

export const Header = (props: HeaderProps) => {
  return (
    <div className="text-xl font-medium text-main">
      {'text' in props ? props.text : props.children}
    </div>
  );
};

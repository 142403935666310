import React, { useState } from 'react';
import { useManagementInformationSystemVolumesList } from 'src/hooks/reactQuery';
import { ProgramVolumes } from './program-volumes';
import { useSearchParamsState } from 'shared/hooks/useTypedSearchParamsState';
import { ROUTES } from 'src/lib/routes';
import { Switch } from 'shared/components/Switch';
import { Body } from 'shared/components/Text';
import { Box } from 'shared/components/Box';

export const VolumesPage = () => {
  const [atTime] = useSearchParamsState(
    ROUTES.MANAGEMENT_INFORMATION_SYSTEM,
    'at_time'
  );

  const [period] = useSearchParamsState(
    ROUTES.MANAGEMENT_INFORMATION_SYSTEM,
    'lookback_period'
  );

  const [volumes, setVolumes] = useState(true);

  const { data: volumeData } = useManagementInformationSystemVolumesList({
    at_time: atTime,
    period: period,
  });

  return (
    <>
      <div className="flex justify-end">
        <Box flex>
          <Switch
            checked={volumes}
            onChange={setVolumes}
            label="Volume metrics"
            labelDirection="left"
          />
          <Body color="primary" contents={'Count metrics'} />
        </Box>
      </div>
      {volumeData ? (
        <ProgramVolumes
          metric={volumes ? 'volume' : 'count'}
          volumes={volumeData.data.volumes}
          totals={volumeData.data.totals}
        />
      ) : (
        <h3>Loading</h3>
      )}
    </>
  );
};

import account from './icons/account.svg';
import alert from './icons/alert.svg';
import application from './icons/terminal.svg';
import arrow_down from './icons/arrow-down.svg';
import arrow_left from './icons/arrow-left.svg';
import arrow_reverse from './icons/arrow-reverse.svg';
import arrow_right from './icons/arrow-right.svg';
import arrow_up from './icons/arrow-up.svg';
import arrow_circle_in_left from './icons/arrow-circle-in-left.svg';
import arrow_circle_in_right from './icons/arrow-circle-in-right.svg';
import arrow_circle_out_left from './icons/arrow-circle-out-left.svg';
import arrow_circle_out_right from './icons/arrow-circle-out-right.svg';
import bank from './icons/bank.svg';
import book_open from './icons/book-open.svg';
import building from './icons/building.svg';
import calendar from './icons/calendar.svg';
import card from './icons/card.svg';
import card_check from './icons/card-check.svg';
import card_lock from './icons/card-lock.svg';
import caret_left from './icons/chevron-left.svg';
import caret_right from './icons/chevron-right.svg';
import caret_up from './icons/chevron-up.svg';
import caret_down from './icons/chevron-down.svg';
import chart from './icons/chart.svg';
import clock from './icons/clock.svg';
import close from './icons/close.svg';
import close_circle from './icons/close-circle.svg';
import code from './icons/code.svg';
import copy from './icons/copy.svg';
import create from './icons/plus.svg';
import cube from './icons/cube.svg';
import currency from './icons/currency.svg';
import edit from './icons/edit.svg';
import email from './icons/mail.svg';
import file_add from './icons/file-add.svg';
import filter from './icons/filter.svg';
import folder from './icons/folder.svg';
import gift from './icons/gift.svg';
import heart_square from './icons/heart-square.svg';
import help from './icons/help.svg';
import home from './icons/home.svg';
import info from './icons/info.svg';
import lock from './icons/lock.svg';
import mastercard from './icons/mastercard.svg';
import menu from './icons/menu.svg';
import message_bubble from './icons/message-bubble.svg';
import minus_circle from './icons/minus-circle.svg';
import more from './icons/more.svg';
import page from './icons/page.svg';
import path from './icons/path.svg';
import pile from './icons/pile.svg';
import pin from './icons/pin.svg';
import plus_circle from './icons/plus-circle.svg';
import remove from './icons/minus.svg';
import route from './icons/route.svg';
import search from './icons/search.svg';
import select from './icons/select.svg';
import select_down from './icons/select-down.svg';
import select_up from './icons/select-up.svg';
import settings from './icons/settings.svg';
import download from './icons/export.svg';
import signout from './icons/sign-out.svg';
import slash_circle from './icons/slash-circle.svg';
import star from './icons/star.svg';
import tag from './icons/tag.svg';
import tick from './icons/check.svg';
import tick_circle from './icons/check-circle.svg';
import transfer from './icons/transfer.svg';
import trash from './icons/trash.svg';
import arrows_switch from './icons/arrows-switch.svg';
import user from './icons/user.svg';
import users from './icons/users.svg';
import view from './icons/view.svg';
import visa from './icons/visa.svg';
import file_writing from './icons/file-writing.svg';
import check_deposit from './icons/check-deposit.svg';
import globe from './icons/globe.svg';
import key from './icons/key.svg';
import percentage from './icons/percentage.svg';
import pie_chart from './icons/pie-chart.svg';
import coins from './icons/coins.svg';
import toggle_left from './icons/toggle-left.svg';
import toggle_right from './icons/toggle-right.svg';
import arrow_refresh from './icons/arrow_refresh.svg';
import arrow_instant from './icons/arrow-instant.svg';
import arrow_square_right from './icons/arrow-square-right.svg';
import arrows_right from './icons/arrows-right.svg';
import brackets from './icons/brackets.svg';
import calendar_date from './icons/calendar-date.svg';
import car from './icons/car.svg';
import chart_bars from './icons/chart-bars.svg';
import chart_variance from './icons/chart-variance.svg';
import check_square from './icons/check-square.svg';
import checks from './icons/checks.svg';
import chevron_right_double from './icons/chevron-right-double.svg';
import clock_check from './icons/clock-check.svg';
import coins_swap from './icons/coins-swap.svg';
import card_edit from './icons/card-edit.svg';
import card_plus from './icons/card-plus.svg';
import currency_movement from './icons/currency-movement.svg';
import cursor_click from './icons/cursor-click.svg';
import data from './icons/data.svg';
import dataflow from './icons/dataflow.svg';
import fingerprint from './icons/fingerprint.svg';
import image_check from './icons/image-check.svg';
import infinity from './icons/infinity.svg';
import lightning from './icons/lightning.svg';
import list from './icons/list.svg';
import navigation_pointer from './icons/navigation-pointer.svg';
import network from './icons/network.svg';
import notification_text from './icons/notification-text.svg';
import phone from './icons/phone.svg';
import send from './icons/send.svg';
import server from './icons/server.svg';
import shield_tick from './icons/shield-tick.svg';
import shield from './icons/shield.svg';
import sparkle from './icons/sparkle.svg';
import table from './icons/table.svg';
import tool from './icons/tool.svg';
import user_circle from './icons/user-circle.svg';
import wallet from './icons/wallet.svg';
import pentagon from './icons/pentagon.svg';
import arrow_corner_left_up from './icons/arrow-corner-left-up.svg';
import transaction from './icons/transaction.svg';
import file_search from './icons/file-search.svg';
import upload from './icons/upload.svg';
import move_money from './icons/move-money.svg';
import message_alert from './icons/message-alert.svg';
import file_check from './icons/file-check.svg';
import file_question from './icons/file-question.svg';
import file_x from './icons/file-x.svg';
import activity from './icons/activity.svg';
import hide from './icons/hide.svg';
import link from './icons/link.svg';
import navigate from './icons/navigate.svg';
import file_spreadsheet from './icons/file-spreadsheet.svg';
import file_document from './icons/file-document.svg';
import file_pdf from './icons/file-pdf.svg';
import file_generic from './icons/file-generic.svg';

const icons = {
  account,
  alert,
  application,
  arrow_down,
  arrow_left,
  arrow_reverse,
  arrow_right,
  arrow_up,
  bank,
  book_open,
  building,
  calendar,
  card,
  card_check,
  card_lock,
  caret_left,
  caret_right,
  caret_up,
  caret_down,
  chart,
  clock,
  close_circle,
  close,
  code,
  copy,
  create,
  cube,
  currency,
  edit,
  email,
  file_add,
  filter,
  folder,
  gift,
  heart_square,
  help,
  home,
  info,
  lock,
  mastercard,
  menu,
  message_bubble,
  minus_circle,
  more,
  page,
  path,
  pile,
  pin,
  plus_circle,
  remove,
  route,
  search,
  select_down,
  select_up,
  select,
  settings,
  download,
  signout,
  slash_circle,
  star,
  tag,
  tick_circle,
  tick,
  transfer,
  trash,
  arrow_circle_out_left,
  arrow_circle_out_right,
  arrow_circle_in_left,
  arrow_circle_in_right,
  arrow_corner_left_up,
  arrows_switch,
  user,
  users,
  view,
  visa,
  file_writing,
  check_deposit,
  globe,
  key,
  percentage,
  pie_chart,
  coins,
  toggle_left,
  toggle_right,
  arrow_refresh,
  arrow_instant,
  arrow_square_right,
  arrows_right,
  brackets,
  calendar_date,
  car,
  chart_bars,
  chart_variance,
  check_square,
  checks,
  chevron_right_double,
  clock_check,
  coins_swap,
  card_edit,
  card_plus,
  currency_movement,
  cursor_click,
  data,
  dataflow,
  fingerprint,
  image_check,
  infinity,
  lightning,
  list,
  navigation_pointer,
  network,
  notification_text,
  phone,
  send,
  server,
  shield_tick,
  shield,
  sparkle,
  table,
  tool,
  user_circle,
  wallet,
  pentagon,
  transaction,
  file_search,
  upload,
  move_money,
  file_check,
  file_question,
  file_x,
  message_alert,
  activity,
  hide,
  link,
  navigate,
  file_spreadsheet,
  file_document,
  file_pdf,
  file_generic,
} as const;

// For storybook
export const ALL_ICONS = icons;

export type IconType = keyof typeof icons;

type Props = {
  name: IconType;
  className?: string;
  size?: 'normal' | 'large';
};

const Icon = ({ name, className, size = 'normal' }: Props) => {
  const Component = icons[name];
  const inlineStyles =
    size === 'large'
      ? {
          height: 16,
          width: 16,
          viewBox: '0 0 16 16',
        }
      : {};
  return (
    <Component className={className ?? 'stroke-strong'} {...inlineStyles} />
  );
};
export { Icon };

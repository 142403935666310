import React, { useState } from 'react';
import { PageLayout } from './deprecated/PageLayout';
import {
  useGroupGet,
  useLateReturnRequestPost,
  useTransactionsByACHTraceNumberList,
} from 'src/hooks/reactQuery';
import { TextInput } from './deprecated/TextInput';
import { useDropzone } from 'react-dropzone';
import { Button } from './deprecated/Button';
import classNames from 'classnames';
import _, { compact } from 'lodash';
import { DateTime } from 'luxon';
import { useNavigate } from 'react-router';
import { ROUTES, buildPath } from 'src/lib/routes';

type ACHSearchQuery = {
  traceNumber: string;
  amount: string;
  transferId: string | null;
};

type ACHTransferSearchProps = {
  query: ACHSearchQuery;
  setQuery: (query: ACHSearchQuery) => void;
};

const GroupName = ({ groupId }: { groupId: string }) => {
  const { data } = useGroupGet(groupId);

  return <div>{data ? data.name : ''}</div>;
};

const ACHTransferSearch = ({ query, setQuery }: ACHTransferSearchProps) => {
  const { data: matchingACHTransactions } = useTransactionsByACHTraceNumberList(
    {
      trace_number: query.traceNumber,
    },
    {
      enabled: !!query.traceNumber,
    }
  );

  const matchingACHTransfers =
    query.amount && matchingACHTransactions
      ? matchingACHTransactions.data.filter(
          (transfer) =>
            Math.abs(transfer.amount) === Math.abs(parseInt(query.amount))
        )
      : null;

  const updateTransferId = (transferId: string) => {
    let value = null;
    if (transferId !== '') {
      value = transferId;
    }
    setQuery({ ...query, transferId: value });
  };

  return (
    <div>
      <div className="flex max-w-xl items-center space-x-6">
        <TextInput
          label="Trace number"
          placeholder="012345678901234"
          value={query.traceNumber}
          onChange={(traceNumber) => setQuery({ ...query, traceNumber })}
          wrapperClassName="grow"
        />
        <TextInput
          label="Amount in cents"
          placeholder="1000"
          type="number"
          value={query.amount}
          onChange={(amount) => setQuery({ ...query, amount })}
          wrapperClassName="grow"
        />
      </div>
      <div className="max-w-xl space-y-2">
        {matchingACHTransfers &&
          (matchingACHTransfers.length === 0 ? (
            <span>no results</span>
          ) : (
            matchingACHTransfers.map((transaction) => {
              const transferId = (
                transaction.source as unknown as Record<string, string>
              ).transfer_id;

              const selected = transferId === query.transferId;

              return (
                <div
                  key={transaction.id}
                  className={classNames(
                    'flex cursor-pointer items-center justify-between space-x-2 rounded border border-main p-2 text-sm',
                    selected
                      ? 'bg-stronger text-strong'
                      : 'bg-main hover:bg-main-hover'
                  )}
                  onClick={() => updateTransferId(transferId)}
                >
                  <div className="italic">{transaction.description}</div>
                  <GroupName groupId={transaction.group_id} />
                  <div>{transaction.date}</div>
                </div>
              );
            })
          ))}
      </div>
      <div>
        <TextInput
          label="Transfer ID"
          placeholder="ach_transfer_m37updeonobkyt9ocyj3"
          value={query.transferId || ''}
          onChange={(transferId) => updateTransferId(transferId)}
        />
      </div>
    </div>
  );
};

const MultipleACHTransferSearch = ({
  searches,
  setSearches,
}: {
  searches: ACHSearchQuery[];
  setSearches: (searches: ACHSearchQuery[]) => void;
}) => {
  return (
    <div>
      {searches.map((s, index) => (
        <div className="mt-8" key={index}>
          <ACHTransferSearch
            query={s}
            setQuery={(updated) => {
              const copy = [...searches];
              copy[index] = updated;
              setSearches(copy);
            }}
          />
          <Button
            onClick={() =>
              setSearches(
                compact(searches.filter((x, ii) => (ii === index ? null : x)))
              )
            }
          >
            Delete
          </Button>
        </div>
      ))}

      <Button
        className="mt-8"
        onClick={() =>
          setSearches(
            searches.concat([{ traceNumber: '', amount: '', transferId: null }])
          )
        }
      >
        Add another ACH Transfer
      </Button>
    </div>
  );
};

export const LateReturnRequestCreate = () => {
  const [contactEmail, setContactEmail] = React.useState<string>('');
  const [contactFax, setContactFax] = React.useState<string>('');
  const [contactName, setContactName] = React.useState<string>('');
  const [claimNumber, setClaimNumber] = React.useState<string>('');
  const [files, setFiles] = React.useState<File[]>([]);
  const [searches, setSearches] = useState<ACHSearchQuery[]>([
    { traceNumber: '', amount: '', transferId: '' },
  ]);

  const navigate = useNavigate();

  const [dueOn, setDueOn] = useState<string>(
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    DateTime.now().plus({ days: 14 }).startOf('day').toISO({
      suppressMilliseconds: true,
      suppressSeconds: true,
      includeOffset: false,
    })!
  );
  const uniqueACHTransferIds = _.uniq(
    searches.flatMap((s) => (s.transferId ? [s.transferId] : []))
  );

  const { mutate } = useLateReturnRequestPost();
  const [errorMessage, setErrorMessage] = useState('');

  const create = () => {
    mutate(
      [
        {
          ach_transfer_ids: uniqueACHTransferIds,
          contact_email: contactEmail || undefined,
          contact_fax: contactFax || undefined,
          contact_name: contactName || undefined,
          claim_number: claimNumber || undefined,
          file: files[0],
          file_1: files[1],
          file_2: files[2],
          due_on: dueOn,
        },
      ],
      {
        onSuccess: () => {
          navigate(buildPath(ROUTES.LATE_RETURN_REQUESTS_LIST, {}));
        },
        onError: (error) => {
          setErrorMessage(
            error.response?.data.message ||
              'Error creating late return request.'
          );
        },
      }
    );
  };

  const { getRootProps, getInputProps } = useDropzone({
    maxFiles: 3,
    onDrop: (acceptedFiles) => setFiles(acceptedFiles),
  });

  return (
    <PageLayout
      headline="Late Return Requests"
      breadcrumbs={[{ text: 'Late Return Requests' }, { text: 'Create' }]}
    >
      <div className="space-y-3">
        <div className="text-lg font-medium">
          Step 1: Search for ACH Transfers by ID and amount
        </div>
        <MultipleACHTransferSearch
          searches={searches}
          setSearches={setSearches}
        />
      </div>

      <div className="max-w-xl space-y-8">
        <div className="text-lg font-medium">
          Step 2: Create a late return request
        </div>
        <div>
          For ACH Transfers:
          <ul>
            {uniqueACHTransferIds.map((i) => (
              <li key={i}>• {i}</li>
            ))}
          </ul>
        </div>
        <TextInput
          label="Claim number"
          placeholder="11223344556677889900"
          value={claimNumber}
          onChange={setClaimNumber}
        />
        <TextInput
          label="Contact Name"
          placeholder="Jane Smith"
          value={contactName}
          onChange={setContactName}
        />
        <TextInput
          label="Contact Fax (eleven digits, starts with 1, no spaces)"
          placeholder="18885551234"
          value={contactFax}
          onChange={setContactFax}
        />
        <TextInput
          label="Contact Email"
          placeholder="example@chase.com"
          value={contactEmail}
          onChange={setContactEmail}
        />
        <TextInput
          label="Due on"
          type="datetime-local"
          value={dueOn}
          onChange={setDueOn}
        />
        <div>
          <label>Files from receiving institution</label>
          <ul className="list-inside list-disc">
            {files.map((file) => (
              <li key={file.name} className="text-xs">
                {file.name}
              </li>
            ))}
          </ul>
          <div
            data-testid="dropzone"
            {...getRootProps()}
            className="rounded border-dashed border-main bg-strong p-12 text-subtle"
          >
            <input {...getInputProps()} />

            <p>Drag up to 3 files here, or click to select</p>
          </div>
        </div>

        <Button
          disabled={
            files.length < 1 ||
            uniqueACHTransferIds.length === 0 ||
            !(contactEmail || contactFax)
          }
          onClick={create}
        >
          Create Late Return Request
        </Button>
      </div>
      {errorMessage && <p className="text-danger">{errorMessage}</p>}
    </PageLayout>
  );
};

// istanbul ignore file
import {
  QueryFunction,
  QueryKey,
  useInfiniteQuery,
  UseInfiniteQueryOptions,
  UseInfiniteQueryResult,
  useMutation,
  UseMutationOptions,
  UseMutationResult,
  useQuery,
  useQueryClient,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { isEmpty } from 'lodash';
import { useCallback } from 'react';

type Empty = {
  [key: string]: unknown;
};

export const useQueryWrapper = <TParameters, TResponse>(
  queryFn: (params: TParameters) => Promise<AxiosResponse<TResponse>>,
  params: TParameters,
  baseQueryKey: string,
  queryOptions?: UseQueryOptions<TResponse, AxiosError<Error>>
): UseQueryResult<TResponse, AxiosError<Error>> => {
  const queryKey: QueryKey = isEmpty(params)
    ? [baseQueryKey]
    : [baseQueryKey, params];
  return useQuery(
    queryKey,
    () =>
      queryFn(params).then((result) => {
        return result.data;
      }),
    queryOptions
  );
};

export const buildQueryWrapper = <TParameters, TResponse>(
  queryFn: (params: TParameters) => Promise<AxiosResponse<TResponse>>,
  baseQueryKey: string
): ((
  params: TParameters,
  queryOptions?: UseQueryOptions<TResponse, AxiosError<Error>>
) => UseQueryResult<TResponse, AxiosError<Error>>) => {
  return (
    params: TParameters,
    queryOptions?: UseQueryOptions<TResponse, AxiosError<Error>>
  ) =>
    useQueryWrapper<TParameters, TResponse>(
      queryFn,
      params,
      baseQueryKey,
      queryOptions
    );
};

const INFINITE_QUERY_KEY_SUFFIX = '_infinite';

const noArgs = <TParameters>(params: TParameters): boolean =>
  isEmpty(params) && params !== '';

export const useInfiniteQueryWrapper = <
  TParameters extends { cursor?: string },
  TResponse extends { next_cursor: string | null },
>(
  queryFn: (params: TParameters) => Promise<AxiosResponse<TResponse>>,
  params: TParameters,
  baseQueryKey: string,
  queryOptions?: UseInfiniteQueryOptions
): UseInfiniteQueryResult<TResponse, AxiosError<Error>> => {
  const listPage: QueryFunction<TResponse> = async ({
    pageParam = undefined,
  }) => {
    return queryFn({ ...params, cursor: pageParam }).then(
      (result) => result.data
    );
  };
  const options = (queryOptions ?? {}) as UseInfiniteQueryOptions<
    TResponse,
    AxiosError<Error>
  >;
  const queryKey: QueryKey = noArgs(params)
    ? [baseQueryKey, INFINITE_QUERY_KEY_SUFFIX]
    : [baseQueryKey, INFINITE_QUERY_KEY_SUFFIX, params];
  const query = useInfiniteQuery(queryKey, listPage, {
    ...options,
    getNextPageParam: (lastPage) => lastPage.next_cursor || undefined,
  });
  // Redeclare fetchNextPage without any arguments
  // See warning about fetchNextPage in https://tanstack.com/query/v4/docs/guides/infinite-queries
  const { fetchNextPage: oldFetchNextPage } = query;
  const nextPageCallback = useCallback(
    () => oldFetchNextPage({ cancelRefetch: false }),
    [oldFetchNextPage]
  );
  return { ...query, fetchNextPage: nextPageCallback };
};

export const buildInfiniteQueryWrapper = <
  TParameters extends { cursor?: string },
  TResponse extends { next_cursor: string | null },
>(
  queryFn: (params: TParameters) => Promise<AxiosResponse<TResponse>>,
  baseQueryKey: string
): ((
  params: TParameters,
  queryOptions?: UseInfiniteQueryOptions
) => UseInfiniteQueryResult<TResponse, AxiosError<Error>>) => {
  return (params, queryOptions) =>
    useInfiniteQueryWrapper(queryFn, params, baseQueryKey, queryOptions);
};

const UNROLL_LIMIT = 10000;
const ENTIRE_LIST_QUERY_KEY_SUFFIX = '_entire';

const useEntireListQueryWrapper = <
  TObject,
  TParameters extends { cursor?: string },
  TResponse extends { next_cursor: string | null; data: TObject[] },
>(
  queryFn: (params: TParameters) => Promise<AxiosResponse<TResponse>>,
  params: TParameters,
  baseQueryKey: string,
  queryOptions?: UseQueryOptions<TObject[], AxiosError<Error>>
) => {
  const getData = (parameters: TParameters): Promise<TResponse> =>
    queryFn(parameters).then((x) => x.data);

  const fetchUntilEndOrLimit = (
    parameters: TParameters,
    remaining: number
  ): Promise<TObject[]> =>
    remaining <= 0
      ? Promise.reject(`Too many queries in ${baseQueryKey}`)
      : getData(parameters).then(({ data, next_cursor }) =>
          next_cursor
            ? fetchUntilEndOrLimit(
                { ...parameters, cursor: next_cursor },
                remaining - data.length
              ).then((rows) => [...data, ...rows])
            : data
        );

  const queryKey: QueryKey = noArgs(params)
    ? [baseQueryKey, ENTIRE_LIST_QUERY_KEY_SUFFIX]
    : [baseQueryKey, ENTIRE_LIST_QUERY_KEY_SUFFIX, params];

  return useQuery(
    queryKey,
    () => fetchUntilEndOrLimit(params, UNROLL_LIMIT),
    queryOptions
  );
};

export const buildEntireListQueryWrapper = <
  TObject,
  TParameters extends { cursor?: string },
  TResponse extends { next_cursor: string | null; data: TObject[] },
>(
  queryFn: (params: TParameters) => Promise<AxiosResponse<TResponse>>,
  baseQueryKey: string
): ((
  params: TParameters,
  queryOptions?: UseQueryOptions<TObject[], AxiosError<Error>>
) => UseQueryResult<TObject[], AxiosError<Error>>) => {
  return (
    params: TParameters,
    queryOptions?: UseQueryOptions<TObject[], AxiosError<Error>>
  ) =>
    useEntireListQueryWrapper<TObject, TParameters, TResponse>(
      queryFn,
      params,
      baseQueryKey,
      queryOptions
    );
};

const hasId = (response: unknown): response is { id: string } => {
  return typeof response === 'object' && response !== null && 'id' in response;
};

type MutationOptions<TData, TError> = Omit<
  UseMutationOptions<TData, TError, unknown, unknown>,
  'mutationFn'
>;
export const usePostMutationWrapper = <TParameters, TResponse>(
  mutationFn: (params: TParameters) => Promise<AxiosResponse<TResponse>>,
  baseQueryKey: string,
  additionalBaseQueryKeys: string[] = [],
  mutationOptions?: MutationOptions<TResponse, AxiosError<Error>>
): UseMutationResult<TResponse, AxiosError<Error>, TParameters> => {
  const queryClient = useQueryClient();

  return useMutation(
    (params: TParameters) => mutationFn(params).then((result) => result.data),
    {
      ...mutationOptions,

      onSuccess: async (data, variables, context) => {
        await Promise.all(
          [baseQueryKey, ...additionalBaseQueryKeys].map((k) =>
            queryClient.invalidateQueries([k])
          )
        );
        if (hasId(data)) {
          queryClient.setQueryData([baseQueryKey, { id: data.id }], data);
        } else {
          queryClient.setQueryData([baseQueryKey], data);
        }
        return mutationOptions?.onSuccess?.(data, variables, context);
      },
    }
  );
};

export const usePatchMutationWrapper = <
  TParameters,
  TResponse extends { id: string },
>(
  mutationFn: (
    id: string,
    params: TParameters
  ) => Promise<AxiosResponse<TResponse>>,
  baseQueryKey: string,
  additionalBaseQueryKeys: string[] = []
): UseMutationResult<
  TResponse,
  AxiosError<Error>,
  { id: string; params: TParameters }
> => {
  const queryClient = useQueryClient();
  return useMutation(
    (args: { id: string; params: TParameters }) =>
      mutationFn(args.id, args.params).then((result) => result.data),
    {
      onSuccess: async (data) => {
        queryClient.setQueryData([baseQueryKey, { id: data.id }], data);
        await Promise.all(
          [baseQueryKey, ...additionalBaseQueryKeys].map((k) =>
            queryClient.invalidateQueries([k])
          )
        );
      },
    }
  );
};

export const useSingletonPatchMutationWrapper = <TParameters, TResponse>(
  mutationFn: (params: TParameters) => Promise<AxiosResponse<TResponse>>,
  baseQueryKey: string,
  additionalBaseQueryKeys: string[] = [],
  mutationOptions?: MutationOptions<TResponse, AxiosError<Error>>
): UseMutationResult<TResponse, AxiosError<Error>, TParameters> => {
  const queryClient = useQueryClient();
  return useMutation(
    (params: TParameters) => mutationFn(params).then((result) => result.data),
    {
      ...mutationOptions,

      onSuccess: async (data, variables, context) => {
        queryClient.setQueryData([baseQueryKey], data);
        await Promise.all(
          [baseQueryKey, ...additionalBaseQueryKeys].map((k) =>
            queryClient.invalidateQueries([k])
          )
        );
        return mutationOptions?.onSuccess?.(data, variables, context);
      },
    }
  );
};

export const useDeleteMutationWrapper = (
  mutationFn: (id: string) => Promise<AxiosResponse<Empty>>,
  baseQueryKey: string,
  additionalBaseQueryKeys: string[] = []
): UseMutationResult<Empty, AxiosError<Error>, string> => {
  const queryClient = useQueryClient();
  return useMutation(
    (id: string) => mutationFn(id).then((result) => result.data),
    {
      onSuccess: async () => {
        await Promise.all(
          [baseQueryKey, ...additionalBaseQueryKeys].map((k) =>
            queryClient.invalidateQueries([k])
          )
        );
      },
    }
  );
};

export const buildMutationWrapper = <
  TParameters extends Array<unknown>,
  TResponse,
>(
  mutationFn: (
    ...args: TParameters
  ) => Promise<AxiosResponse<TResponse, AxiosError<Record<string, unknown>>>>,
  ...queryKeys: string[]
) => {
  return (): UseMutationResult<
    TResponse,
    AxiosError<Error, unknown>,
    TParameters,
    unknown
  > => {
    const queryClient = useQueryClient();
    return useMutation(
      (args: TParameters) => mutationFn(...args).then((r) => r.data),
      {
        onSuccess: async () => {
          await Promise.all(
            queryKeys.map((queryKey) =>
              queryClient.invalidateQueries([queryKey])
            )
          );
        },
      }
    );
  };
};

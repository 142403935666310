import { useState } from 'react';
import { useOperatorNoteList, useOperatorNotePost } from 'src/hooks/reactQuery';
import { Body, Caption } from 'shared/components/Text';
import { TextArea } from 'shared/components/TextArea';
import { Button } from 'shared/components/Button';
import { getBankIconString } from 'src/lib/getBankIconString';
import { formatISO8601Timestamp } from 'shared/lib/formatting';

type Props = {
  modelId: string;
  placeholder?: string;
  readOnly?: boolean;
};

export const OperatorNotes = ({
  modelId,
  placeholder = 'Note',
  readOnly = false,
}: Props) => {
  const { data: operatorNotes, refetch } = useOperatorNoteList({
    model_id: modelId,
  });
  const operatorNotePost = useOperatorNotePost();
  const [content, setContent] = useState('');

  const createOperatorNote = (text: string) => {
    operatorNotePost.mutateAsync([{ model_id: modelId, text }]).then(() => {
      refetch();
      setContent('');
    });
  };

  return (
    <div>
      {operatorNotes &&
        operatorNotes.data.map((operatorNote) => {
          const bankIcon = getBankIconString(operatorNote.operator_bank);
          return (
            <div key={operatorNote.id}>
              <div className="flex gap-x-3 border-t border-main py-3">
                <div className="mt-1.5 h-8 w-8 flex-none overflow-hidden rounded-full bg-inverse">
                  <img
                    className="h-auto w-full"
                    alt="Bank Icon"
                    src={bankIcon}
                  />
                </div>
                <div className="flex w-full flex-col gap-y-0.5">
                  <div className="flex items-center justify-between text-xs">
                    <Body
                      weight="medium"
                      color="primary"
                      contents={operatorNote.operator_email}
                    />
                    <Caption
                      weight="normal"
                      color="secondary"
                      contents={formatISO8601Timestamp(
                        operatorNote.date,
                        'medium'
                      )}
                    />
                  </div>
                  <Body
                    color="secondary"
                    className="max-w-[650px] whitespace-pre-wrap"
                    contents={operatorNote.text}
                  />
                </div>
              </div>
            </div>
          );
        })}
      {!readOnly && (
        <div>
          <form
            onSubmit={(submitEvent) => {
              submitEvent.preventDefault();
              createOperatorNote(content);
            }}
          >
            <TextArea
              name="text"
              placeholder={placeholder}
              value={content}
              rows={3}
              onChange={(s) => {
                setContent(s.target.value);
              }}
              onKeyDown={(e) => {
                if (e.key === 'Enter' && e.shiftKey) {
                  e.preventDefault(); // Prevent the default action to avoid inserting a new line
                  createOperatorNote(content);
                }
              }}
            />
            <div className="flex w-full justify-end">
              <Button
                type="submit"
                style="secondary"
                className="mt-2"
                text="⇧ + Enter to submit"
                disabled={operatorNotePost.isLoading || !content}
              />
            </div>
          </form>
        </div>
      )}
    </div>
  );
};

import { useEffect, useState } from 'react';

function useIsLoaded(): boolean {
  const [loaded, setLoaded] = useState(false);
  useEffect(() => {
    setLoaded(true);
  }, [setLoaded]);
  return loaded;
}

export { useIsLoaded };

import { InternalOperatorOnly } from './internal-operator-only';
import { OperatorFiles } from './operator-files';
import { useServices } from 'src/hooks/use-services';
import {
  useAccountListInfinite,
  useEntityClusterList,
  useEntityGet,
  useEntityPatch,
  useOperatorGet,
  useSanctionsScreeningReviewListInfinite,
} from 'src/hooks/reactQuery';
import { useCategoricals } from 'src/hooks/use-categoricals';
import { EntityGetResponse } from 'src/build/operations';
import { AssociatedManualTasks } from './associated-manual-tasks';
import { Badge } from 'shared/components/Badge';
import { PageLayout } from './deprecated/PageLayout';
import { Section } from './deprecated/Section';
import { Button } from 'shared/components/Button';
import { StyledLink } from './deprecated/StyledLink';
import { Table } from './deprecated/Table';
import { absurd } from 'src/lib/absurd';
import { EntitySetupSupplementalDocumentSubmissionEvaluation } from './entity-setup-supplemental-document-submission-evaluation';
import { SideBySide } from 'shared/components/SideBySide';
import { EntityPropertyList } from './property-lists/EntityPropertyList';
import { TableStateWrapper } from 'shared/components/TableStateWrapper';
import { EntityCorporationBeneficialOwnersTable } from './tables/EntityCorporationBeneficialOwnersTable';
import { EntityCorporationPropertyList } from './property-lists/EntityCorporationPropertyList';
import { StackOfLinks } from 'shared/components/StackOfLinks';
import { Box } from 'shared/components/Box';
import { OperatorNotes } from './operator-notes';
import { Heading } from 'shared/components/Text';
import { EntityNaturalPersonPropertyList } from './property-lists/EntityNaturalPersonPropertyList';
import { IdentityDocumentsTable } from './tables/IdentityDocumentsTable';
import { AccountsTable } from './tables/AccountsTable';
import { EntityTrustPropertyList } from './property-lists/EntityTrustPropertyList';
import { MicrobiltSearchResultsTable } from './tables/MicrobiltSearchResultsTable';
import { AlertList } from 'shared/components/AlertList';
import { humanize } from 'shared/lib/formatting';
import { useTypedParams } from 'react-router-typesafe-routes/dom';
import { ROUTES, buildPath } from 'src/lib/routes';
import { SanctionsScreeningReviewsTable } from './tables/SanctionScreeningReviewsTable';

export const EntityActions = (props: { entity: EntityGetResponse }) => {
  const { entity } = props;
  const entityPatch = useEntityPatch();

  /* istanbul ignore next */
  switch (entity.review_status) {
    case 'approved':
    case 'approved_by_platform':
    case 'review_not_required':
    case 'returned_to_user':
      return null;
    case 'pending_reviewing':
    case 'requires_attention':
      return (
        <InternalOperatorOnly>
          <Button
            text="Return to user"
            style="warning"
            onClick={() =>
              entityPatch.mutateAsync([
                entity.id,
                { status: 'returned_to_user' },
              ])
            }
          />
          <Button
            text="Approve"
            style="secondary"
            onClick={() =>
              entityPatch.mutateAsync([entity.id, { status: 'approved' }])
            }
          />
        </InternalOperatorOnly>
      );
    default:
      absurd(entity.review_status);
  }
};

export const SupplementalDocumentList = ({
  entity,
}: {
  entity: EntityGetResponse;
}) => {
  const { operations } = useServices();
  return (
    <Table
      data={entity.supplemental_documents}
      canExpandRows
      renderExpandedRow={(row) => (
        <EntitySetupSupplementalDocumentSubmissionEvaluation
          entitySetupSupplementalDocumentSubmissionId={row.id}
        />
      )}
      columns={[
        {
          key: 'file.id',
          label: 'ID',
          accessor: (d) => d.file.id,
        },
        {
          key: 'file.filename',
          label: 'Filename',
          accessor: (d) => d.file.filename,
        },
        {
          key: 'file.mime',
          label: 'Mimetype',
          accessor: (d) => d.file.mime,
        },
        {
          key: 'file.description',
          label: 'Description',
          accessor: (d) => d.file.description,
        },
        {
          key: 'review_status',
          label: 'Review Status',
        },
        {
          key: 'created_at',
          label: 'Created At',
          accessor: (d) => d.created_at,
        },
        {
          key: 'view',
          label: 'View',
          render: (doc) => {
            const viewURL = operations + '/api_files/' + doc.file.id + '/view';
            return (
              <StyledLink to={viewURL} target="_blank">
                view
              </StyledLink>
            );
          },
        },
      ]}
    />
  );
};

export const Entity = () => {
  const { entityId } = useTypedParams(ROUTES.ENTITY);
  const { data: entity } = useEntityGet(entityId);
  const categoricals = useCategoricals();
  const { data: entityClustersPage } = useEntityClusterList({
    entity_id: entityId,
  });
  const { data: operator } = useOperatorGet({});

  const beneficialOwnerRecordIds =
    entity?.setup_submission_structure.corporation?.entity_setup_corporation_beneficial_owner_relationships.map(
      (relationship) => relationship.entity_setup_beneficial_owner_submission.id
    ) ?? [];
  const corporationRecordIds = entity?.setup_submission_structure.corporation
    ? [entity.setup_submission_structure.corporation.id]
    : [];
  const naturalPersonIds = entity?.setup_submission_structure.natural_person
    ? [entity.setup_submission_structure.natural_person.id]
    : [];

  const recordIdsForOfac = [
    ...beneficialOwnerRecordIds,
    ...corporationRecordIds,
    ...naturalPersonIds,
  ];

  const operatorIsInternalOrGrasshopper =
    !!operator &&
    (operator.role === 'internal' || operator.bank === 'grasshopper_bank');

  const sanctionsScreeningReviewsListResult =
    useSanctionsScreeningReviewListInfinite(
      {
        record_ids: recordIdsForOfac,
        limit: 10,
      },
      {
        enabled: recordIdsForOfac.length > 0 && operatorIsInternalOrGrasshopper,
      }
    );

  const accountList = useAccountListInfinite({
    entity_ids: [entityId],
    limit: 5,
  });

  if (!entity || !categoricals) {
    return <>Loading...</>;
  }

  const entityClusters = entityClustersPage?.data || [];

  const entityClusterLinks = entityClusters.map((cluster) => ({
    text: `${humanize(cluster.category)} - ${cluster.entities.length} entities`,
    href: buildPath(ROUTES.ENTITY_CLUSTER_DETAIL, {
      entityClusterID: cluster.id,
    }),
  }));

  const dangerousClusters = entityClusters.filter(
    (cluster) =>
      cluster.entities.filter((clusterEntry) =>
        ['rejected', 'offboarded'].includes(clusterEntry.group_status)
      ).length > 0
  );

  const operatorNoteParameters = entity.setup_submission_structure.corporation
    ? {
        modelType: 'API::EntitySetupCorporationSubmission',
        modelId: entity.setup_submission_structure.corporation.id,
      }
    : entity.setup_submission_structure.natural_person
      ? {
          modelType: 'API::EntitySetupNaturalPersonSubmission',
          modelId: entity.setup_submission_structure.natural_person.id,
        }
      : entity.setup_submission_structure.joint
        ? {
            modelType: 'API::Entity',
            modelId: entity.id,
          }
        : entity.setup_submission_structure.trust
          ? {
              modelType: 'API::EntitySetupTrustSubmission',
              modelId: entity.setup_submission_structure.trust.id,
            }
          : null;

  return (
    <PageLayout
      id="application.service.entity"
      headline={entity.name}
      action={<EntityActions entity={entity} />}
      breadcrumbs={[{ text: 'Entities' }, { text: entityId }]}
    >
      <SideBySide
        mode="rightDrawer"
        right={
          <>
            <EntityPropertyList
              entity={entity}
              layoutHorizontallyWhenPossible={false}
              title="Basics"
            />
            <AssociatedManualTasks objectId={entity.id} />
            {entityClusterLinks.length > 0 && (
              <InternalOperatorOnly additionalRoles={['transaction_monitor']}>
                <StackOfLinks
                  title="Entity clusters"
                  links={entityClusterLinks}
                />
              </InternalOperatorOnly>
            )}
          </>
        }
        left={
          <>
            {entity.customer_information_policy_problems.length > 0 && (
              <InternalOperatorOnly>
                <AlertList
                  tasks={entity.customer_information_policy_problems.map(
                    (problem, index) => ({
                      icon: 'alert',
                      title: humanize(problem),
                      key: `${index}${problem}`,
                    })
                  )}
                />
              </InternalOperatorOnly>
            )}

            <InternalOperatorOnly>
              {dangerousClusters.length > 0 && (
                <AlertList
                  tasks={dangerousClusters.map((cluster) => ({
                    key: cluster.id,
                    icon: 'alert',
                    title: `Entity has common data with an offboarded group (${humanize(
                      cluster.category
                    ).toLowerCase()}).`,
                    link: buildPath(ROUTES.ENTITY_CLUSTER_DETAIL, {
                      entityClusterID: cluster.id,
                    }),
                  }))}
                />
              )}
            </InternalOperatorOnly>
            {/* Corporation entities */}
            {entity.setup_submission_structure.corporation && (
              <>
                <EntityCorporationPropertyList
                  title="Details"
                  corporation={entity.setup_submission_structure.corporation}
                />

                <TableStateWrapper
                  action={
                    entity.setup_submission_structure.corporation
                      .beneficial_ownership_exemption && (
                      <Badge
                        color="green"
                        label={`Exempt: ${entity.setup_submission_structure.corporation.beneficial_ownership_exemption}`}
                      />
                    )
                  }
                  table={EntityCorporationBeneficialOwnersTable}
                  style="detail"
                  title="Beneficial owners"
                  emptySubtitle="No beneficial owners found"
                  emptyTitle="No beneficial owners found"
                  viewMoreHref=""
                  data={
                    entity.setup_submission_structure.corporation
                      .entity_setup_corporation_beneficial_owner_relationships
                  }
                  hasNextPage={false}
                  error={null}
                />
              </>
            )}

            {/* Natural person entities */}
            {entity.setup_submission_structure.natural_person && (
              <>
                <EntityNaturalPersonPropertyList
                  title="Details"
                  naturalPerson={
                    entity.setup_submission_structure.natural_person
                  }
                />
                <TableStateWrapper
                  table={IdentityDocumentsTable}
                  data={
                    entity.setup_submission_structure.natural_person
                      .identity_document_submissions
                  }
                  hasNextPage={false}
                  error={null}
                  style="detail"
                  title="Identity documents"
                  emptySubtitle="No identity documents found"
                  emptyTitle="No identity documents found"
                  viewMoreHref=""
                />
                <TableStateWrapper
                  table={MicrobiltSearchResultsTable}
                  data={
                    entity.setup_submission_structure.natural_person.microbilt
                  }
                  hasNextPage={false}
                  error={null}
                  style="detail"
                  title="MicroBilt searches"
                  emptySubtitle="No MicroBilt search results found"
                  emptyTitle="No MicroBilt search results found"
                  viewMoreHref=""
                />
              </>
            )}

            {/* Trust entities */}
            {entity.setup_submission_structure.trust && (
              <>
                <EntityTrustPropertyList
                  title="Details"
                  trust={entity.setup_submission_structure.trust}
                />
                {entity.setup_submission_structure.trust.trustees.map(
                  (trustee) => (
                    <EntityNaturalPersonPropertyList
                      key={trustee.id}
                      title="Trustee"
                      naturalPerson={trustee}
                    />
                  )
                )}
                {entity.setup_submission_structure.trust.grantor && (
                  <EntityNaturalPersonPropertyList
                    title="Grantor"
                    naturalPerson={
                      entity.setup_submission_structure.trust.grantor
                    }
                  />
                )}
              </>
            )}

            {/* Joint entities */}
            {entity.setup_submission_structure.joint && (
              <>
                {entity.setup_submission_structure.joint.map(
                  (person, index) => (
                    <EntityNaturalPersonPropertyList
                      key={index}
                      title="Joint owner"
                      naturalPerson={person}
                    />
                  )
                )}
              </>
            )}

            <TableStateWrapper
              table={AccountsTable}
              {...accountList}
              style="detail"
              title="Accounts"
              emptySubtitle="No accounts found"
              emptyTitle="No accounts found"
              showBank
              showGroup={false}
              showProgram
              viewMoreHref={buildPath(
                ROUTES.ACCOUNTS_LIST,
                {},
                { entity: [entityId] }
              )}
            />

            {recordIdsForOfac.length > 0 && (
              <TableStateWrapper
                table={SanctionsScreeningReviewsTable}
                {...sanctionsScreeningReviewsListResult}
                style="detail"
                title="Office of Foreign Asset Control hits"
                emptySubtitle="No sanctions screening hits"
                emptyTitle="No sanctions screening hits"
                viewMoreHref={buildPath(
                  ROUTES.SANCTIONS_SCREENING_REVIEWS_LIST,
                  {},
                  { records: recordIdsForOfac }
                )}
                showRecordId={false}
              />
            )}

            {entity.supplemental_documents.length > 0 && (
              <Section header="Supplemental Documents">
                <SupplementalDocumentList entity={entity} />
              </Section>
            )}

            <InternalOperatorOnly>
              <Box>
                <Heading>Operator Files</Heading>
                <OperatorFiles modelId={entity.id} />
              </Box>
            </InternalOperatorOnly>

            {operatorNoteParameters && (
              <InternalOperatorOnly>
                <Box>
                  <Heading>Operator Notes</Heading>
                  <OperatorNotes {...operatorNoteParameters} />
                </Box>
              </InternalOperatorOnly>
            )}
          </>
        }
      />
    </PageLayout>
  );
};

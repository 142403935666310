import { useEffect, useRef, useState } from 'react';
import { useIntersectionObserver } from '../../hooks/useIntersectionObserver';

type Props = {
  onVisible: () => void;
};

const IntersectionObserver = ({ onVisible }: Props) => {
  const ref = useRef(null);
  const entry = useIntersectionObserver(ref, {});
  const inView = !!entry?.isIntersecting;
  const [visible, setVisible] = useState(false);
  if (inView && !visible) {
    setVisible(true);
  } else if (!inView && visible) {
    setVisible(false);
  }

  useEffect(() => {
    if (visible) {
      onVisible();
    }
  }, [visible, onVisible]);

  return <div className="h-[1px] w-[1px] opacity-0" ref={ref} />;
};
export { IntersectionObserver };

import classNames from 'classnames';
import { ReactNode } from 'react';
import { Caption } from '../Text';
import styles from './index.module.css';

type Props = {
  children: ReactNode;
  action?: ReactNode;
  padding?: 'normal' | 'large';
  className?: string;
};

const Note = ({ children, action, padding = 'normal', className }: Props) => {
  return (
    <div
      className={classNames(
        styles.note,
        'flex items-center justify-between rounded border border-main bg-strong px-4 text-main',
        padding === 'normal' && 'py-2',
        padding === 'large' && 'py-4',
        className
      )}
    >
      <Caption color="primary">{children}</Caption>
      {action && <div className="ml-4">{action}</div>}
    </div>
  );
};

export { Note };

import classNames from 'classnames';
import { ReactNode } from 'react';

type Props = {
  flex?: boolean;
  center?: boolean;
  justifyCenter?: boolean;
  padding?: '0' | '6';
  gap?: '0' | '0.5' | '1' | '2' | '4' | '6' | '8' | '10';
  children: ReactNode;
  className?: string;
};

const Box = ({
  flex = false,
  center = false,
  padding = '0',
  gap = '4',
  children,
  justifyCenter,
  className,
}: Props) => {
  const gapClass = {
    '0': 'gap-0',
    '0.5': 'gap-0.5',
    '1': 'gap-1',
    '2': 'gap-2',
    '4': 'gap-4',
    '6': 'gap-6',
    '8': 'gap-8',
    '10': 'gap-10',
  }[gap];
  const paddingClass = {
    '0': 'p-0',
    '6': 'p-6',
  }[padding];
  return (
    <div
      className={classNames(
        'flex',
        gapClass,
        paddingClass,
        flex ? 'flex-row' : 'flex-col',
        center && 'place-items-center',
        justifyCenter && 'h-full justify-center',
        className
      )}
    >
      {children}
    </div>
  );
};

export { Box };

import { compact } from 'lodash';
import { BadgeColor } from 'shared/components/Badge';
import {
  ListItem,
  PropertyList,
  PropertyListProps,
} from 'shared/components/PropertyList';
import { formatAddress, humanize } from 'shared/lib/formatting';
import {
  EntityGetResponseSetupSubmissionStructureNaturalPerson,
  EntityGetResponseSetupSubmissionStructureNaturalPersonAnyOfIdentityVerificationStatus,
  EntityGetResponseSetupSubmissionStructureNaturalPersonAnyOfTaxIdValidationStatus,
} from 'src/build/operations';
import { useCategoricals } from 'src/hooks/use-categoricals';

export type EntityPropertyListProps = {
  naturalPerson: NonNullable<EntityGetResponseSetupSubmissionStructureNaturalPerson>;
} & Omit<PropertyListProps, 'items'>;

export const identityVerificationStatusBadgeColor: Record<
  EntityGetResponseSetupSubmissionStructureNaturalPersonAnyOfIdentityVerificationStatus,
  BadgeColor
> = {
  pending_verification: 'blue',
  update_pending_verification: 'blue',
  verified: 'green',
  falsified: 'yellow',
  no_match: 'yellow',
  verifying: 'blue',
  requires_attention: 'yellow',
  not_performed: 'gray',
};

export const taxIdValidationStatusBadgeColor: Record<
  EntityGetResponseSetupSubmissionStructureNaturalPersonAnyOfTaxIdValidationStatus,
  BadgeColor
> = {
  pending_validating: 'blue',
  update_pending_validating: 'blue',
  requires_attention: 'yellow',
  failed: 'yellow',
  validated: 'green',
  not_provided: 'gray',
};

export const EntityNaturalPersonPropertyList = (
  props: EntityPropertyListProps
) => {
  const { naturalPerson, ...rest } = props;
  const categoricals = useCategoricals();

  if (!categoricals) {
    return null;
  }

  return (
    <PropertyList
      {...rest}
      items={compact<ListItem>([
        {
          label: 'Name',
          value: naturalPerson.submitted_name,
        },
        {
          label: 'Date of Birth',
          value: naturalPerson.submitted_date_of_birth,
        },
        {
          label: 'Address',
          value: formatAddress(naturalPerson.submitted_address),
        },
        {
          label: 'Verification Status',
          value: humanize(naturalPerson.identity_verification_status),
          badgeColor:
            identityVerificationStatusBadgeColor[
              naturalPerson.identity_verification_status
            ],
        },
        {
          label: 'Identification Number Kind',
          value: naturalPerson.submitted_identification_method,
        },
        {
          label: 'Identification Number Last 4',
          value: naturalPerson.submitted_identification_number_last4,
        },
        {
          label: 'SSN Validation Status',
          value: humanize(naturalPerson.tax_id_validation_status),
          badgeColor:
            taxIdValidationStatusBadgeColor[
              naturalPerson.tax_id_validation_status
            ],
        },
      ])}
    />
  );
};

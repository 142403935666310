import React from 'react';
import { InternalOperatorOnly } from './internal-operator-only';
import { useComplaintEntriesListInfinite } from 'src/hooks/reactQuery';
import { ComplaintEntryListResponseDataItem } from 'src/build/operations';
import { TableStateWrapper } from 'shared/components/TableStateWrapper';
import {
  PrestyledTableProps,
  Table,
  TableColumns,
} from 'shared/components/Table';
import { formatISO8601Timestamp } from 'shared/lib/formatting';
import { buildPath, ROUTES } from 'src/lib/routes';

const ComplaintEntryTable = (
  props: PrestyledTableProps<ComplaintEntryListResponseDataItem>
) => {
  const columns: TableColumns<ComplaintEntryListResponseDataItem> = [
    {
      header: 'Month / Year',
      contents: (row) => {
        return { text: formatISO8601Timestamp(row.created_at, 'month-year') };
      },
    },
    {
      header: 'ID',
      contents: (row) => ({ text: row.id }),
    },
    {
      header: 'Received on',
      contents: (row) => ({
        text: row.received_on
          ? formatISO8601Timestamp(row.received_on, 'day-month-year')
          : '',
      }),
    },
    {
      header: 'Resolved on',
      contents: (row) => ({
        text: row.resolved_on
          ? formatISO8601Timestamp(row.resolved_on, 'day-month-year')
          : '',
      }),
    },
    {
      header: 'Group',
      contents: (row) => ({
        text: row.group_id,
        href: buildPath(ROUTES.GROUPS_DETAIL, { groupId: row.group_id }),
      }),
    },
    {
      header: 'Type',
      contents: (r) => ({ text: r.complaint_type }),
    },
    {
      header: 'Source',
      contents: (r) => ({ text: r.source }),
    },
    { header: 'Description', contents: (r) => ({ text: r.description }) },
  ];

  return <Table {...props} columns={columns} />;
};

export const DirectComplaintEntries = () => {
  const listResponse = useComplaintEntriesListInfinite({});

  return (
    <InternalOperatorOnly>
      <TableStateWrapper
        {...listResponse}
        table={ComplaintEntryTable}
        emptyTitle="Complaints"
        emptySubtitle="No complaints."
        style="primary"
        filters={[]}
      />
    </InternalOperatorOnly>
  );
};

import { PageLayout } from '../deprecated/PageLayout';
import { SegmentedControl } from 'shared/components/SegmentedControl';
import { Outlet, useNavigate } from 'react-router';

export const TransactionsWrapperPage = () => {
  const navigate = useNavigate();

  const segments = [
    {
      slug: '/transactions',
      title: 'Completed',
    },
    {
      slug: '/pending_transactions',
      title: 'Pending',
    },
  ] as const;

  const [, segmentIndex] = location.pathname.includes('pending')
    ? [segments[1], 1]
    : [segments[0], 0];

  const commonFilterParameterNames = [
    'group',
    'account',
    'card',
    'account_number',
    'on_or_after',
    'on_or_before',
    'amount_or_greater',
    'amount_or_less',
    'exact_amount',
  ];

  return (
    <PageLayout
      breadcrumbs={[]}
      headline={'Transactions'}
      segmentedControl={
        <SegmentedControl
          segments={segments}
          selectedIndex={segmentIndex}
          onSelectRequest={(idx) => {
            const currentFilterParameters = new URLSearchParams(
              window.location.search
            );
            const newFilteredParameters: URLSearchParams =
              new URLSearchParams();
            currentFilterParameters.forEach((value, key) => {
              if (commonFilterParameterNames.includes(key)) {
                newFilteredParameters.append(key, value);
              }
            });
            const newSearchString: string = newFilteredParameters.toString();
            navigate(`${segments[idx].slug}?${newSearchString}`);
          }}
        />
      }
    >
      <Outlet />
    </PageLayout>
  );
};

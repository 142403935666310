import React, { useMemo } from 'react';
import { PageLayout } from '../deprecated/PageLayout';
import { usePlatformComplaintListSubmissionListInfinite } from 'src/hooks/reactQuery';
import { TableStateWrapper } from 'shared/components/TableStateWrapper';
import {
  ComplianceSubmissionTable,
  ComplianceSubmissionTableProps,
  Item,
} from '../tables/ComplianceSubmissionTable';
import { TableFilter } from 'shared/components/Table/filters';
import { makeProgramFilter } from 'src/lib/tableFilterHelpers';
import { useSearchParamsState } from 'shared/hooks/useTypedSearchParamsState';
import { ROUTES } from 'src/lib/routes';

export const PlatformComplaintListSubmissionListPage = () => {
  const [programFilter, setProgramFilter] = useSearchParamsState(
    ROUTES.PLATFORM_COMPLAINT_LIST_SUBMISSIONS_LIST,
    'program'
  );

  const filters: TableFilter[] = useMemo(
    () => [makeProgramFilter(programFilter, setProgramFilter)],
    [programFilter, setProgramFilter]
  );

  const listResult = usePlatformComplaintListSubmissionListInfinite({
    program_ids: programFilter.length > 0 ? programFilter : undefined,
  });

  return (
    <PageLayout
      headline={'Platform complaint list submissions'}
      breadcrumbs={[]}
    >
      <TableStateWrapper<Item, ComplianceSubmissionTableProps>
        {...listResult}
        table={ComplianceSubmissionTable}
        style="primary"
        emptyTitle="No details"
        emptySubtitle="There are no details to display"
        filters={filters}
        hrefPrefix="platform_complaint_list_submissions/"
      />
    </PageLayout>
  );
};

import {
  TableColumns,
  Table,
  PrestyledTableProps,
} from 'shared/components/Table';
import { formatISO8601Timestamp } from 'shared/lib/formatting';
import { useMemo } from 'react';

import { CommercialOnboardingSurveyGetResponse } from 'src/build/operations';
import { buildPath, ROUTES } from 'src/lib/routes';

type Props = PrestyledTableProps<CommercialOnboardingSurveyGetResponse>;

export const CommercialOnboardingSurveyTable = (props: Props) => {
  const { getRowProps, ...rest } = props;
  const columns = useMemo<
    TableColumns<CommercialOnboardingSurveyGetResponse>
  >(() => {
    return [
      {
        header: 'Program',
        expand: 1,
        contents: (survey) => ({
          text: survey.program_id,
          href: buildPath(ROUTES.PROGRAMS_DETAIL, {
            programId: survey.program_id,
          }),
        }),
      },
      {
        header: 'Entity',
        expand: 1,
        contents: (survey) => ({
          text: survey.entity_name || '',
          href: survey.entity_id
            ? buildPath(ROUTES.ENTITY, {
                entityId: survey.entity_id,
              })
            : '',
        }),
      },
      {
        header: 'Submitted',
        align: 'right',
        contents: (survey) => ({
          text: formatISO8601Timestamp(survey.created_at, 'medium'),
        }),
      },
    ];
  }, []);

  const defaultRowProps = (datum: CommercialOnboardingSurveyGetResponse) => ({
    href: buildPath(ROUTES.COMMERCIAL_ONBOARDING_SURVEYS_DETAILS, {
      id: datum.id,
    }),
    className: 'hover:bg-main-hover cursor-pointer transition-all group',
  });

  return (
    <Table<CommercialOnboardingSurveyGetResponse>
      columns={columns}
      getRowProps={getRowProps ?? defaultRowProps}
      {...rest}
    />
  );
};

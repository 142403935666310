import classNames from 'classnames';
import { Body, Caption } from './Text';
import { Icon, IconType } from './Icon';

export type BadgeColor =
  | 'red'
  | 'blue'
  | 'green'
  | 'yellow'
  | 'purple'
  | 'gray'
  | 'none';

type Props = {
  label: string;
  color: BadgeColor;
  size?: 'small' | 'medium' | 'large';
  icon?: IconType;
};

const COLOR_CLASSES: Record<BadgeColor, string> = {
  red: 'bg-danger border border-danger text-danger',
  blue: 'bg-info border border-info text-info',
  green: 'bg-success border border-success text-success',
  yellow: 'bg-warning border border-warning text-warning',
  purple: 'bg-assistive border border-assistive text-assistive',
  gray: 'bg-disabled border border-disabled text-subtle',
  none: 'text-subtle',
};

const ICON_COLOR_CLASSES: Record<BadgeColor, string> = {
  red: 'text-danger',
  blue: 'text-info',
  green: 'text-success',
  yellow: 'text-warning',
  purple: 'text-assistive',
  gray: 'text-subtle',
  none: 'text-subtle',
};

export const Badge = ({ label, color, size = 'small', icon }: Props) => {
  if (size !== 'small') {
    return (
      <div
        className={classNames(
          'flex items-center space-x-1 truncate rounded-sm',
          size === 'large' && 'px-2 py-[5px]',
          size === 'medium' && 'px-1.5 py-[1px]',
          COLOR_CLASSES[color]
        )}
      >
        {icon && (
          <Icon
            name={icon}
            className={classNames('shrink-0', ICON_COLOR_CLASSES[color])}
          />
        )}
        <Body
          color="inherit"
          className={classNames('truncate')}
          weight={color === 'none' ? 'normal' : 'medium'}
          contents={label}
        />
      </div>
    );
  }
  return (
    <div
      className={classNames(
        'flex items-center space-x-1 rounded-sm px-1 py-[1px]',
        COLOR_CLASSES[color]
      )}
    >
      {icon && (
        <Icon
          name={icon}
          className={classNames('', ICON_COLOR_CLASSES[color])}
        />
      )}
      {label && (
        <Caption
          color="inherit"
          className={classNames('')}
          weight={color === 'none' ? 'normal' : 'medium'}
          contents={label}
        />
      )}
    </div>
  );
};

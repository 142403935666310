import classNames from 'classnames';
import { Link } from 'react-router-dom';

type Props = {
  to: string;
  className?: string;
  children: React.ReactNode;
  target?: '_blank';
};

export const StyledLink = ({ to, children, className, target }: Props) => {
  const classes = classNames('text-info hover:underline', className);
  const rel = target ? 'noreferrer' : undefined;

  return to.startsWith('http') ? (
    <a href={to} className={classes} target={target} rel={rel}>
      {children}
    </a>
  ) : (
    <Link to={to} className={classes}>
      {children}
    </Link>
  );
};

import {
  ManagementInformationSystemBalancesListResponseDataBalancesItem,
  ManagementInformationSystemRevenuesListResponseDataRevenuesItem,
  ManagementInformationSystemVolumesListResponseDataVolumesItem,
} from 'src/build/operations';

type BalanceTableDataRow =
  ManagementInformationSystemBalancesListResponseDataBalancesItem & {
    id: string;
  };

export type VolumeTableDataRow =
  ManagementInformationSystemVolumesListResponseDataVolumesItem & {
    id: string;
  };

export type RevenueTableDataRow =
  ManagementInformationSystemRevenuesListResponseDataRevenuesItem & {
    id: string;
  };

export type CombinedDataRow = BalanceTableDataRow & {
  total_volume: number;
  lookback_total_volume: number;
  revenue: number;
  previous_revenue: number;
};

export const transformData = <T extends { program_id: string }>(
  data: T[],
  suffix: string
): Array<[string, T & { id: string }]> => {
  return data.map((item) => [
    item.program_id,
    { ...item, id: `${item.program_id}-${suffix}` },
  ]);
};

import { usePlatformFinancialReportSubmissionGet } from 'src/hooks/reactQuery';
import { PageLayout } from './deprecated/PageLayout';

import { formatAmount, formatISO8601Timestamp } from 'shared/lib/formatting';
import { useTypedParams } from 'react-router-typesafe-routes/dom';
import { ROUTES } from 'src/lib/routes';
import { PropertyList } from 'shared/components/PropertyList';
import { useGroupPropertyRow } from 'src/lib/propertyListHelpers';

export const PlatformFinancialReportSubmission = () => {
  const { submissionID } = useTypedParams(
    ROUTES.PLATFORM_FINANCIAL_REPORT_SUBMISSIONS_DETAIL
  );

  const { data: submission } =
    usePlatformFinancialReportSubmissionGet(submissionID);

  const groupRow = useGroupPropertyRow(submission?.group_id);

  if (!submission) {
    return <></>;
  }

  return (
    <PageLayout headline="Platform Financial Report Submission">
      <PropertyList
        items={[
          { label: 'Submission ID', value: submissionID },
          groupRow,
          {
            label: 'Submission at',
            value: formatISO8601Timestamp(submission.created_at, 'full'),
          },
          {
            label: 'Period',
            value: `${submission.period_start} - ${submission.period_end}`,
          },
          {
            label: 'Balance sheet',
            value: submission.period_balance_sheet_file_id,
            href: `https://operations.increase.com/api_files/${submission.period_balance_sheet_file_id}/view`,
          },
          {
            label: 'Income statement',
            value: submission.period_income_statement_file_id,
            href: `https://operations.increase.com/api_files/${submission.period_income_statement_file_id}/view`,
          },
          {
            label: 'Cash on hand',
            value: formatAmount(
              submission.cash_on_hand_last_day_of_period,
              'USD'
            ),
          },
          {
            label: 'Period net income',
            value: formatAmount(submission.total_net_income_for_period, 'USD'),
          },
          {
            label: 'Fewer than 4 quarters of runway left?',
            value: submission.fewer_than_four_quarters_of_runway ? 'Yes' : 'No',
            badgeColor: submission.fewer_than_four_quarters_of_runway
              ? 'red'
              : undefined,
          },
        ]}
      ></PropertyList>
    </PageLayout>
  );
};

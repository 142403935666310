import { usePlatformUnusualActivityReportGet } from 'src/hooks/reactQuery';
import { PageLayout } from '../deprecated/PageLayout';
import { SideBySide } from 'shared/components/SideBySide';
import { PlatformUnusualActivityReportPropertyList } from 'src/components/property-lists/PlatformUnusualActivityReportPropertyList';
import { formatISO8601Timestamp } from 'shared/lib/formatting';
import { Loading } from 'shared/components/Loading';
import { PropertyList } from 'shared/components/PropertyList';
import { useServices } from 'src/hooks/use-services';
import { OperatorNotes } from '../operator-notes';
import { Box } from 'shared/components/Box';
import { Body, Heading } from 'shared/components/Text';
import { useTypedParams } from 'react-router-typesafe-routes/dom';
import { ROUTES, buildPath } from 'src/lib/routes';

export const PlatformUnusualActivityReportDetailPage = () => {
  const { unusualActivityReportId } = useTypedParams(
    ROUTES.UNUSUAL_ACTIVITY_REPORTS.PLATFORM.DETAILS
  );

  const { data: report } = usePlatformUnusualActivityReportGet(
    unusualActivityReportId
  );
  const { operations } = useServices();

  if (!report) {
    return <Loading />;
  }

  return (
    <PageLayout headline={'Platform Unusual Activity Report'}>
      <SideBySide
        mode="rightDrawer"
        right={
          <PlatformUnusualActivityReportPropertyList
            unusualActivityReport={report}
            layoutHorizontallyWhenPossible={false}
            title="Summary"
          />
        }
        left={
          <>
            <Box>
              <Heading>Narrative</Heading>
              <Body color="primary" className="whitespace-pre-line">
                {report.narrative}
              </Body>
            </Box>

            {report.related_accounts.length > 0 && (
              <PropertyList
                title="Related Accounts"
                layoutHorizontallyWhenPossible={true}
                items={report.related_accounts.map((id, idx) => ({
                  label: `Account ${idx + 1}`,
                  value: id,
                  href: buildPath(ROUTES.ACCOUNTS_DETAIL, {
                    accountId: id,
                  }),
                }))}
              />
            )}
            {report.related_account_numbers.length > 0 && (
              <PropertyList
                title="Related Account Numbers"
                layoutHorizontallyWhenPossible={true}
                items={report.related_account_numbers.map((id, idx) => ({
                  label: `Account Number ${idx + 1}`,
                  value: id,
                  href: buildPath(ROUTES.ACCOUNT_NUMBERS_DETAILS, {
                    accountNumberId: id,
                  }),
                }))}
              />
            )}
            {report.related_cards.length > 0 && (
              <PropertyList
                title="Related Cards"
                layoutHorizontallyWhenPossible={true}
                items={report.related_cards.map((id, idx) => ({
                  label: `Card ${idx + 1}`,
                  value: id,
                  href: buildPath(ROUTES.CARD, {
                    cardID: id,
                  }),
                }))}
              />
            )}
            {report.related_entities.length > 0 && (
              <PropertyList
                title="Related Entities"
                layoutHorizontallyWhenPossible={true}
                items={report.related_entities.map((id, idx) => ({
                  label: `Entity ${idx + 1}`,
                  value: id,
                  href: buildPath(ROUTES.ENTITY, {
                    entityId: id,
                  }),
                }))}
              />
            )}
            {report.related_transactions.length > 0 && (
              <PropertyList
                title="Related Transactions"
                layoutHorizontallyWhenPossible={true}
                items={report.related_transactions.map((id, idx) => ({
                  label: `Transaction ${idx + 1}`,
                  value: id,
                  href: buildPath(ROUTES.TRANSACTION_DETAIL, {
                    transactionID: id,
                  }),
                }))}
              />
            )}
            {report.related_declined_transactions.length > 0 && (
              <PropertyList
                title="Related Declined Transactions"
                layoutHorizontallyWhenPossible={true}
                items={report.related_declined_transactions.map((id, idx) => ({
                  label: `Declined Transaction ${idx + 1}`,
                  value: id,
                  href: buildPath(ROUTES.DECLINED_TRANSACTION_DETAIL, {
                    declinedTransactionID: id,
                  }),
                }))}
              />
            )}
            {report.file_attachments.length > 0 && (
              <PropertyList
                title="File Attachments"
                layoutHorizontallyWhenPossible={true}
                items={report.file_attachments.map((file) => ({
                  label: `${file.filename} (Uploaded ${formatISO8601Timestamp(
                    file.created_at,
                    'month-day-hour-minute'
                  )})`,

                  value: file.id,
                  href: `${operations}/api_files/${file.id}/view`,
                }))}
              />
            )}
            <Box>
              <Heading>Operator Notes</Heading>
              <OperatorNotes modelId={report.id} />
            </Box>
          </>
        }
      />
    </PageLayout>
  );
};

import React, { useMemo } from 'react';
import { PageLayout } from '../deprecated/PageLayout';
import { useConsumerOnboardingSurveyListInfinite } from 'src/hooks/reactQuery';
import { TableStateWrapper } from 'shared/components/TableStateWrapper';
import { ConsumerOnboardingSurveyTable } from '../tables/ConsumerOnboardingSurveyTable';
import { TableFilter } from 'shared/components/Table/filters';
import {
  makeProgramFilter,
  makeTimeRangeFilter,
} from 'src/lib/tableFilterHelpers';
import { DateTime } from 'luxon';
import { ROUTES } from 'src/lib/routes';
import { useTypedCreatedAtRangeParams } from 'src/hooks/timeSearchParameters';
import { useSearchParamsState } from 'shared/hooks/useTypedSearchParamsState';

export const ConsumerOnboardingSurveyListPage = () => {
  const [{ startAt, endAt }, setTimeRange] = useTypedCreatedAtRangeParams(
    ROUTES.RAMP_BUSINESS_ACCOUNT_ONBOARDING_SURVEYS_LIST
  );

  const [programs, setPrograms] = useSearchParamsState(
    ROUTES.COMMERCIAL_ONBOARDING_SURVEYS_LIST,
    'program'
  );

  const startAtDateTime = startAt
    ? DateTime.fromISO(startAt, { zone: 'utc' })
    : undefined;
  const endAtDateTime = endAt
    ? DateTime.fromISO(endAt, { zone: 'utc' })
    : undefined;

  const listResult = useConsumerOnboardingSurveyListInfinite({
    created_at_or_after: startAtDateTime?.startOf('day').toISO() ?? undefined,
    created_at_or_before: endAtDateTime?.endOf('day').toISO() ?? undefined,
    programs: programs.length > 0 ? programs : undefined,
  });

  const filters: TableFilter[] = useMemo(
    () => [
      makeTimeRangeFilter({ startAt, endAt }, setTimeRange, 'Created'),
      makeProgramFilter(programs, setPrograms),
    ],
    [startAt, endAt, setTimeRange, programs, setPrograms]
  );

  return (
    <PageLayout headline={'Consumer Onboarding Surveys'} breadcrumbs={[]}>
      <TableStateWrapper
        table={ConsumerOnboardingSurveyTable}
        style="primary"
        {...listResult}
        emptyTitle="No Surveys"
        emptySubtitle="There are no surveys to display"
        filters={filters}
      />
    </PageLayout>
  );
};

import { usePlatformComplianceSubmissionProgramListInfinite } from 'src/hooks/reactQuery';
import { PageLayout } from './deprecated/PageLayout';
import { PlatformComplianceSubmissionProgramListResponseDataItem } from 'src/build/operations';
import { TableStateWrapper } from 'shared/components/TableStateWrapper';
import {
  PrestyledTableProps,
  Table,
  TableColumns,
} from 'shared/components/Table';
import { buildPath, ROUTES } from 'src/lib/routes';

const ProgramTable = (
  props: PrestyledTableProps<PlatformComplianceSubmissionProgramListResponseDataItem>
) => {
  const columns: TableColumns<PlatformComplianceSubmissionProgramListResponseDataItem> =
    [
      {
        header: 'Group',
        contents: (row) => ({
          text: row.group_name || row.group_id,
          href: buildPath(ROUTES.GROUPS_DETAIL, { groupId: row.group_id }),
        }),
      },
      {
        header: 'Program',
        contents: (row) => ({
          text: row.name,
          href: buildPath(ROUTES.PROGRAMS_DETAIL, {
            programId: row.id,
          }),
        }),
      },
      {
        header: 'Compliance metrics',
        contents: (row) => ({
          text: 'Metrics',
          href: buildPath(
            ROUTES.PLATFORM_COMPLIANCE_METRICS_SUBMISSIONS_LIST,
            {},
            { program: [row.id] }
          ),
        }),
      },
      {
        header: 'Complaints',
        contents: (row) => ({
          text: 'Complaints',
          href: buildPath(
            ROUTES.PLATFORM_COMPLAINT_LIST_SUBMISSIONS_LIST,
            {},
            { program: [row.id] }
          ),
        }),
      },
      {
        header: 'Vendor list',
        contents: (row) => ({
          text: 'Vendors',
          href: buildPath(
            ROUTES.PLATFORM_VENDOR_LIST_SUBMISSIONS_LIST,
            {},
            { program: [row.id] }
          ),
        }),
      },
    ];

  return <Table {...props} columns={columns} />;
};

export const PlatformComplianceSubmissions = () => {
  const programs = usePlatformComplianceSubmissionProgramListInfinite({});

  return (
    <PageLayout
      id="platform-compliance-submission"
      headline="All platform compliance submissions"
      breadcrumbs={[{ text: 'Platform Compliance Submissions' }]}
    >
      <TableStateWrapper
        {...programs}
        table={ProgramTable}
        style="primary"
        emptyTitle="No programs"
        emptySubtitle="There are no programs with compliance submissions"
        filters={[]}
      />
    </PageLayout>
  );
};

import {
  PrestyledTableProps,
  Table,
  TableColumns,
} from 'shared/components/Table';
import React, { useMemo } from 'react';

import { EntityClusterListResponseDataItem } from 'src/build/operations';
import { uniq } from 'lodash';
import { buildPath, ROUTES } from 'src/lib/routes';
import { formatInteger, humanize } from 'shared/lib/formatting';

export type EntityClustersTableProps =
  PrestyledTableProps<EntityClusterListResponseDataItem>;

export const EntityClustersTable = ({ ...rest }: EntityClustersTableProps) => {
  const columns = useMemo<
    TableColumns<EntityClusterListResponseDataItem>
  >(() => {
    return [
      {
        header: 'ID',
        contents: (entityCluster) => ({
          text: entityCluster.id,
        }),
      },
      {
        header: 'Category',
        contents: (entityCluster) => ({
          text: humanize(entityCluster.category),
        }),
      },
      {
        header: 'Count',
        contents: (entityCluster) => ({
          text: formatInteger(entityCluster.entities.length),
        }),
      },
      {
        header: 'Count Groups',
        contents: (entityCluster) => ({
          text: formatInteger(
            uniq(entityCluster.entities.map((entity) => entity.group_id)).length
          ),
        }),
      },
      {
        header: 'Status',
        contents: (entityCluster) => ({
          text: humanize(entityCluster.status),
          badgeColor: entityCluster.status === 'reviewed' ? 'gray' : 'yellow',
        }),
      },
    ];
  }, []);

  const getRowProps = (datum: EntityClusterListResponseDataItem) => ({
    href: buildPath(ROUTES.ENTITY_CLUSTER_DETAIL, {
      entityClusterID: datum.id,
    }),
    className: 'hover:bg-main-hover cursor-pointer transition-all group',
  });

  return (
    <Table
      columns={columns}
      getRowProps={rest.getRowProps ?? getRowProps}
      {...rest}
    />
  );
};

import { useTypedParams } from 'react-router-typesafe-routes/dom';
import { PageLayout } from './deprecated/PageLayout';
import { Table } from './deprecated/Table';
import { useObligationDocumentGet } from 'src/hooks/reactQuery';
import { ROUTES } from 'src/lib/routes';

const isUrl = (url: string) => {
  try {
    new URL(url);
    return true;
  } catch (_) {
    return false;
  }
};

export const ObligationDocumentDetail = () => {
  const { documentName } = useTypedParams(ROUTES.OBLIGATION_DOCUMENTS_DETAILS);

  const { data: obligationDocument } = useObligationDocumentGet(documentName);
  if (!obligationDocument) {
    return <>Loading...</>;
  }

  return (
    <PageLayout
      headline={obligationDocument.data.document_name}
      breadcrumbs={[]}
    >
      <Table
        data={[
          {
            id: 'document_name',
            document_name: obligationDocument.data.document_name,
            document_partner: obligationDocument.data.document_partner,
            document_link: obligationDocument.data.document_link,
          },
        ]}
        columns={[
          {
            key: 'document_name',
            label: 'Name',
          },
          {
            key: 'document_partner',
            label: 'Partner',
          },
          {
            key: 'document_link',
            label: 'Source',
            render: (record) => (
              <a
                href={record.document_link}
                target="_blank"
                rel="noopener noreferrer"
              >
                {record.document_link}
              </a>
            ),
          },
        ]}
      />

      <Table
        data={obligationDocument.data.audits.map((item) => ({
          id: item.audit_name,
          audit_name: item.audit_name,
          date_completed: item.date_completed,
          audit_report: isUrl(item.audit_report) ? (
            <a
              href={item.audit_report}
              target="_blank"
              rel="noopener noreferrer"
            >
              {item.audit_report}
            </a>
          ) : (
            item.audit_report
          ),
        }))}
        columns={[
          {
            key: 'audit_name',
            label: 'Audit',
          },
          {
            key: 'date_completed',
            label: 'Date Completed',
          },
          {
            key: 'audit_report',
            label: 'Report',
          },
        ]}
      />

      <Table
        data={obligationDocument.data.obligations.map((obligation) => ({
          id: obligation.obligation_description,
          latest_result: obligation.latest_result ? '✅' : '❌',
          obligation_description: obligation.obligation_description
            .split('\n')
            .map((item, key, arr) => {
              return (
                <span key={key}>
                  {item}
                  {key < arr.length - 1 ? <br /> : null}
                </span>
              );
            }),
          fulfillment_method_description:
            obligation.fulfillment_method_description
              .split('\\n')
              .map((item, key, arr) => {
                return (
                  <span key={key}>
                    {isUrl(item) ? (
                      <a href={item} target="_blank" rel="noopener noreferrer">
                        {item}
                      </a>
                    ) : (
                      item
                    )}
                    {key < arr.length - 1 ? <br /> : null}
                  </span>
                );
              }),
        }))}
        columns={[
          {
            key: 'latest_result',
            label: 'Latest Result',
          },
          {
            key: 'obligation_description',
            label: 'Obligation',
          },
          {
            key: 'fulfillment_method_description',
            label: 'Fulfillment Method',
          },
        ]}
      />
    </PageLayout>
  );
};

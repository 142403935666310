import classNames from 'classnames';

export type ButtonProps =
  | {
      'data-testid'?: string;
      id?: string;
      type?: 'submit' | 'reset' | 'button';
      className?: string;
      disabled?: boolean;
      text: string;
      onClick?: () => void;
    }
  | {
      'data-testid'?: string;
      id?: string;
      type?: 'submit' | 'reset' | 'button';
      className?: string;
      disabled?: boolean;
      children: React.ReactNode;
      onClick?: () => void;
    };

export const Button = (props: ButtonProps) => {
  return (
    <button
      id={props.id}
      className={classNames(
        'rounded border border-strong px-2 py-0.5 text-sm font-medium text-strong shadow-sm enabled:hover:shadow',
        props.disabled && 'opacity-50',
        !props.disabled && 'active:bg-main-selected',
        props.className
      )}
      disabled={props.disabled}
      type={props.type}
      onClick={props.onClick}
      data-testid={props['data-testid']}
    >
      {'text' in props ? props.text : props.children}
    </button>
  );
};

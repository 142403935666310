import type { AxiosRequestConfig } from 'axios';
import { Navigate } from 'react-router';
import { useFederalReserveGatewayLimitsList } from 'src/hooks/reactQuery';
import { PageLayout } from './deprecated/PageLayout';
import { useServices } from '../hooks/use-services';
import { ROUTES, buildPath } from 'src/lib/routes';

function useAxiosOptions(): AxiosRequestConfig {
  const { federalreservegateway: baseURL } = useServices();
  return { baseURL };
}

export const FederalReserveLimitsRedirector = () => {
  const { data, error } = useFederalReserveGatewayLimitsList(useAxiosOptions());
  const loading = !error && !data;
  return (
    <PageLayout
      id="application.service.federal-reserve-limits-redirector"
      headline="Redirecting..."
      breadcrumbs={[]}
    >
      {loading && <div>Loading...</div>}
      {error && (
        <div
          id="application.service.application.service.federal-reserve-limits-redirector.error"
          className="relative rounded border border-danger bg-danger px-4 py-3 text-danger"
        >
          Error: {error.message}
        </div>
      )}
      {data && (
        <>
          <div>Redirecting...</div>
          <Navigate
            to={buildPath(ROUTES.FEDERAL_RESERVE_LIMIT_DETAILS, {
              limitsId: data.limits[0].id,
            })}
          />
        </>
      )}
    </PageLayout>
  );
};

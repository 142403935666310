import classNames from 'classnames';
import { Icon, IconType } from './Icon';
import {
  MouseEventHandler,
  Ref,
  forwardRef,
  useContext,
  useEffect,
  useId,
} from 'react';
import tinykeys from 'tinykeys';
import { Caption } from './Text';
import { DialogContext } from './Modal';

export type ButtonStyle = 'primary' | 'secondary' | 'warning' | 'transparent';

export type ButtonSize = 'small' | 'medium' | 'large' | 'xlarge';

const ALLOWED_SHORTCUTS = {
  '⌘K': '$mod+k',
  '⌘I': '$mod+i',
  '⌘J': '$mod+j',
  E: 'e',
  C: 'c',
  F: 'f',
} as const;

export type ButtonProps = {
  className?: string;
  icon?: IconType;
  iconPlacement?: 'left' | 'right';
  iconClassName?: string;
  style?: ButtonStyle;
  size?: ButtonSize;
  text?: string;
  textClassName?: string;
  type?: 'button' | 'submit';
  disabled?: boolean;
  onClick?: MouseEventHandler;
  form?: string;
  autoFocus?: boolean;
  keyboardShortcut?: keyof typeof ALLOWED_SHORTCUTS;
};

const SIZE_CLASSES_BUTTON: Record<ButtonSize, string> = {
  small:
    'px-[8px] py-[4px] rounded gap-x-1 h-[28px] flex flex-row justify-center',
  medium:
    'px-[10px] py-[6px] rounded gap-x-1 h-[32px] flex flex-row justify-center',
  large:
    'px-[12px] py-[8px] rounded gap-x-2 h-[40px] flex flex-row justify-center',
  xlarge:
    'px-[12px] py-[12px] h-[44px] rounded gap-x-2 flex flex-row justify-center',
};

const STYLE_CLASSES_BUTTON: Record<ButtonStyle, string> = {
  primary:
    'bg-brand hover:bg-brand-hover active:bg-brand-hover border border-brand focus-visible:ring-main shadow-xs',
  secondary:
    'bg-main border border-strong border-t-strong/60 dark:border-strong/60 dark:bg-subtle dark:border-t-strong hover:bg-main-hover active:bg-main-hover focus-visible:ring-main shadow-xs ',
  warning:
    'bg-main border border-strong border-t-strong/60 dark:border-strong/60 dark:bg-subtle dark:border-t-strong hover:bg-danger active:bg-danger focus-visible:ring-danger shadow-xs',
  transparent:
    'hover:bg-main-hover active:bg-main-hover focus-visible:ring-main',
};

const SIZE_CLASSES_TEXT: Record<ButtonSize, string> = {
  small: 'text-[12px] leading-[20px] font-[580]',
  medium: 'text-[14px] leading-[20px] font-[580]',
  large: 'text-[14px] leading-[20px] font-[580]',
  xlarge: 'text-[16px] leading-[20px] font-[580]',
};

const STYLE_CLASSES_TEXT: Record<ButtonStyle, string> = {
  primary: 'text-inverse',
  secondary: 'text-strong',
  transparent: 'text-subtle',
  warning: 'text-danger',
};

const STYLE_CLASSES_ICON: Record<ButtonStyle, string> = {
  primary: 'stroke-inverse',
  secondary: 'stroke-strong',
  transparent: 'stroke-subtle',
  warning: 'stroke-danger',
};

const STYLE_CLASSES_SHORTCUT: Record<ButtonStyle, string> = {
  primary: 'bg-main/10 text-disabled',
  secondary: 'bg-strong text-subtle border border-strong',
  transparent: 'border border-strong text-subtle',
  warning: 'bg-danger text-danger border-danger',
};

const Button = forwardRef(function Button(
  {
    className,
    icon,
    text,
    iconPlacement = 'left',
    iconClassName,
    size = 'medium',
    style = 'primary',
    textClassName,
    onClick,
    type = 'button',
    disabled = false,
    autoFocus = false,
    form,
    keyboardShortcut,
  }: ButtonProps,
  ref: Ref<HTMLButtonElement>
) {
  const renderIcon = (iconName: IconType) => (
    <Icon
      name={iconName}
      size={'normal'}
      className={classNames(
        'relative',
        STYLE_CLASSES_ICON[style],
        iconClassName,
        keyboardShortcut && 'md:hidden'
      )}
    />
  );

  const id = useId();

  const { modalState, searchOpen } = useContext(DialogContext);

  useEffect(() => {
    if (!keyboardShortcut) {
      return;
    }

    const anyDialogIsOpen = modalState !== 'none' || searchOpen;

    const unsubscribe = tinykeys(window, {
      [ALLOWED_SHORTCUTS[keyboardShortcut]]: (e) => {
        // Check if the active element is an input, textarea, or any element that accepts user input.
        const activeElement = document.activeElement;
        const isInputActive =
          activeElement &&
          (activeElement.tagName === 'INPUT' ||
            activeElement.tagName === 'TEXTAREA' ||
            (activeElement as HTMLElement).isContentEditable);

        // If an input element is active, do not trigger the shortcut.
        if (isInputActive) {
          return;
        }
        if (anyDialogIsOpen) {
          // If any dialog is open, do not execute the shortcut
          return;
        }
        // We're already forwarding the ref to the button, so we can just
        // click it directly. We need to stop propagation so that the
        // keyboard event doesn't keep bubbling up, e.g. by filling out the input
        // in table filters
        e.stopPropagation();
        e.preventDefault();
        document.getElementById(id)?.click();
      },
    });
    return () => {
      unsubscribe();
    };
  }, [keyboardShortcut, id, modalState, searchOpen]);

  return (
    <button
      type={type}
      className={classNames(
        'inline-flex select-none items-center whitespace-nowrap font-interphases antialiased transition-colors',
        'focus-visible:outline-none focus-visible:ring-[3px] focus-visible:ring-main',
        'disabled:opacity-50',
        STYLE_CLASSES_BUTTON[style],
        SIZE_CLASSES_BUTTON[size],
        className
      )}
      disabled={disabled}
      onClick={onClick}
      form={form}
      autoFocus={autoFocus}
      id={id}
      ref={ref}
    >
      {size === 'xlarge' ? (
        <>
          {text != null && (
            <div
              className={classNames(
                STYLE_CLASSES_TEXT[style],
                SIZE_CLASSES_TEXT[size],
                textClassName
              )}
            >
              {text}
            </div>
          )}
          {icon && renderIcon(icon)}
        </>
      ) : (
        <>
          {icon && iconPlacement === 'left' && renderIcon(icon)}
          {text != null && (
            <div
              className={classNames(
                STYLE_CLASSES_TEXT[style],
                SIZE_CLASSES_TEXT[size],
                textClassName
              )}
            >
              {text}
            </div>
          )}{' '}
          {iconPlacement === 'right' && icon && renderIcon(icon)}
          {keyboardShortcut && (
            <div
              className={classNames(
                STYLE_CLASSES_SHORTCUT[style],
                'hidden translate-x-0.5 rounded-sm px-1 md:block'
              )}
            >
              <Caption weight="medium" color="inherit">
                {keyboardShortcut}
              </Caption>
            </div>
          )}
        </>
      )}
    </button>
  );
});

export { Button };

import { OperatorNoteListResponseDataItemOperatorBank } from 'src/build/operations';

export const getBankIconString = (
  bank: OperatorNoteListResponseDataItemOperatorBank
): string => {
  let bankIcon:
    | 'increase'
    | 'first_internet_bank'
    | 'grasshopper_bank'
    | 'global_innovations_bank'
    | 'blue_ridge_bank' = 'increase';
  if (bank) {
    bankIcon = bank;
  }

  const bankIconString =
    bankIcon === 'first_internet_bank'
      ? '/assets/img/fib-icon.png'
      : bankIcon === 'grasshopper_bank'
        ? '/assets/img/grasshopper-icon.png'
        : bankIcon === 'blue_ridge_bank'
          ? '/assets/img/brb-icon.png'
          : '/assets/img/increase-icon.png';

  return bankIconString;
};

import Head from 'next/head';
import { isString } from 'lodash';
import { Heading } from 'shared/components/Text';

type Breadcrumb = {
  text: string;
  link?: string;
};

type PageLayoutProps = {
  id?: string;
  'data-testid'?: string;
  headline: string | React.ReactNode;
  action?: React.ReactNode;
  children: React.ReactNode;
  breadcrumbs?: Breadcrumb[];
  segmentedControl?: React.ReactNode;
};

export const PageLayout = (props: PageLayoutProps) => {
  return (
    <div className="relative" data-testid={props['data-testid']} id={props.id}>
      <Head>{isString(props.headline) && <title>{props.headline}</title>}</Head>
      <div className="space-y-8 p-3 md:p-8 lg:px-8">
        <div className="flex items-center justify-between">
          <Heading size="large" color="primary">
            {props.headline}
          </Heading>
          <div className="flex items-center space-x-3">{props.action}</div>
        </div>
        <div className="space-y-6">
          {props.segmentedControl}
          {/* Body */}
          {props.children}
        </div>
      </div>
    </div>
  );
};

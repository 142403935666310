import { compact } from 'lodash';
import { BadgeColor } from 'shared/components/Badge';
import {
  ListItem,
  PropertyList,
  PropertyListProps,
} from 'shared/components/PropertyList';
import { formatISO8601Timestamp, formatYearMonth } from 'shared/lib/formatting';
import { CardGetResponse, CardGetResponseStatus } from 'src/build/operations';
import { useEntityGet } from 'src/hooks/reactQuery';
import {
  useGroupPropertyRow,
  useAccountPropertyRow,
} from 'src/lib/propertyListHelpers';
import { buildPath, ROUTES } from 'src/lib/routes';

export type CardPropertyListProps = {
  card: CardGetResponse;
} & Omit<PropertyListProps, 'items'>;

const statusBadgeColor: Record<CardGetResponseStatus, BadgeColor> = {
  active: 'green',
  disabled: 'gray',
  canceled: 'red',
};

export const CardPropertyList = (props: CardPropertyListProps) => {
  const { card: card, ...rest } = props;
  const { data: entity } = useEntityGet(card.entity_id || '', {
    enabled: !!card.entity_id,
  });

  const groupRow = useGroupPropertyRow(card.group_id);
  const accountRow = useAccountPropertyRow(card.account_id);

  return (
    <PropertyList
      {...rest}
      items={compact<ListItem>([
        {
          label: 'ID',
          value: card.id,
          copyable: card.id,
        },
        groupRow,
        {
          label: 'Status',
          value: card.status,
          badgeColor: statusBadgeColor[card.status],
        },
        {
          label: 'Created',
          value: formatISO8601Timestamp(card.created_at, 'medium'),
        },

        {
          label: 'Expiration',
          value: formatYearMonth({
            month: card.expiration_month,
            year: card.expiration_year,
          }),
        },
        {
          label: 'Last 4',
          value: card.last4,
        },
        accountRow,
        entity && {
          label: 'Entity',
          value: entity.name,
          href: buildPath(ROUTES.ENTITY, {
            entityId: entity.id,
          }),
        },
      ])}
    />
  );
};

import { compact } from 'lodash';
import { BadgeColor } from 'shared/components/Badge';
import {
  ListItem,
  PropertyList,
  PropertyListProps,
} from 'shared/components/PropertyList';
import { formatAddress, humanize } from 'shared/lib/formatting';
import {
  BeneficialOwnerGetResponse,
  BeneficialOwnerGetResponseIdentityVerificationStatus,
} from 'src/build/operations';

export type EntityPropertyListProps = {
  beneficialOwner: NonNullable<BeneficialOwnerGetResponse>;
} & Omit<PropertyListProps, 'items'>;

export const identityVerificationStatusBadgeColor: Record<
  BeneficialOwnerGetResponseIdentityVerificationStatus,
  BadgeColor
> = {
  pending_verification: 'blue',
  update_pending_verification: 'blue',
  verified: 'green',
  falsified: 'yellow',
  no_match: 'yellow',
  verifying: 'blue',
  requires_attention: 'yellow',
  not_performed: 'gray',
};

export const EntityBeneficialOwnerPropertyList = (
  props: EntityPropertyListProps
) => {
  const { beneficialOwner, ...rest } = props;

  return (
    <PropertyList
      {...rest}
      items={compact<ListItem>([
        {
          label: 'Name',
          value: beneficialOwner.submitted_name,
        },
        {
          label: 'Date of birth',
          value: beneficialOwner.submitted_date_of_birth,
        },
        {
          label: 'Tax ID',
          value: beneficialOwner.submitted_identification_number_last4 ?? '-',
        },
        {
          label: 'Tax ID type',
          value: beneficialOwner.submitted_identification_method ?? '-',
        },
        {
          label: 'Identity validation status',
          value: humanize(beneficialOwner.identity_verification_status),
          badgeColor:
            identityVerificationStatusBadgeColor[
              beneficialOwner.identity_verification_status
            ],
        },
        {
          label: 'Address',
          value: formatAddress(beneficialOwner.submitted_address),
        },
      ])}
    />
  );
};

export function useServices(): {
  accounting: string;
  administration: string;
  dashboard: string;
  operations: string;
  federalreservegateway: string;
} {
  switch (process.env.NODE_ENV) {
    case 'development':
      return {
        accounting: 'http://accounting.bank.localhost:30004',
        administration: 'http://administration.bank.localhost:3001/',
        dashboard: 'http://dashboard.bank.localhost:3010/',
        operations: 'http://operations.bank.localhost:30002',
        federalreservegateway:
          'http://federalreservegateway.bank.localhost:30003',
      };
    case 'test':
      return {
        accounting: '',
        administration: '',
        dashboard: '',
        operations: '',
        federalreservegateway: '',
      };
    case 'production':
      return {
        accounting: `https://accounting.increase.com`,
        administration: `https://administration.increase.com`,
        dashboard: `https://dashboard.increase.com`,
        operations: `https://operations.increase.com`,
        federalreservegateway: `https://federalreservegateway.increase.com`,
      };
    default:
      throw new Error('Environment not known: ' + process.env.NODE_ENV);
  }
}

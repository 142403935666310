/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * Operations API
 * OpenAPI spec version: 0.0.1
 */
import axios from "axios";
import type { AxiosRequestConfig, AxiosResponse } from "axios";
import type {
  ACHCompanyIDLookupGetParams,
  ACHCompanyIDLookupGetResponse,
  ACHHistoricalOriginationVolumeForRoutingNumberGetParams,
  ACHHistoricalOriginationVolumeForRoutingNumberGetResponse,
  ACHOriginationVolumeMonitoringGetParams,
  ACHOriginationVolumeMonitoringGetResponse,
  ACHTransferLookupGetParams,
  ACHTransferLookupGetResponse,
  APIInboundFileViewDirectiveGetResponse,
  AccountBalanceGetParams,
  AccountBalanceGetResponse,
  AccountByAccountNumberGetParams,
  AccountByAccountNumberGetResponse,
  AccountGetResponse,
  AccountListParams,
  AccountListResponse,
  AccountNumberGetResponse,
  AccountNumberListParams,
  AccountNumberListResponse,
  AttestationGetResponse,
  AttestationListParams,
  AttestationListResponse,
  AttestationPatchParameters,
  AttestationPatchResponse,
  AuthenticationPostParameters,
  AuthenticationPostResponse,
  AuthorizationMessagePostParameters,
  AuthorizationMessagePostResponse,
  BankFeeListParams,
  BankFeeListResponse,
  BankFeeStatementGetResponse,
  BankFeeStatementListParams,
  BankFeeStatementListResponse,
  BankProgramCurrentFeePlanGetResponse,
  BankProgramGetResponse,
  BankProgramListParams,
  BankProgramListResponse,
  BeneficialOwnerGetResponse,
  BeneficialOwnerRelationshipListParams,
  BeneficialOwnerRelationshipListResponse,
  BookkeepingAccountGetResponse,
  BookkeepingAccountListParams,
  BookkeepingAccountListResponse,
  BookkeepingEntryListParams,
  BookkeepingEntryListResponse,
  CardDeclineGetResponse,
  CardGetResponse,
  CardListParams,
  CardListResponse,
  CardPaymentGetResponse,
  CashReconciliationOverviewGetParams,
  CashReconciliationOverviewGetResponse,
  CashReconciliationUnreconciledExpectedCashTransactionTupleDetailsGetParams,
  CashReconciliationUnreconciledExpectedCashTransactionTupleDetailsGetResponse,
  CashReconciliationUnreconciledExpectedCashTransactionTupleListParams,
  CashReconciliationUnreconciledExpectedCashTransactionTupleListResponse,
  ChallengePostParameters,
  ChallengePostResponse,
  CheckDepositApproveDirectivePostParameters,
  CheckDepositApproveDirectivePostResponse,
  CheckDepositContextGetParams,
  CheckDepositContextGetResponse,
  CheckDepositGetResponse,
  CheckDepositListParams,
  CheckDepositListResponse,
  CheckDepositRejectDirectivePostParameters,
  CheckDepositRejectDirectivePostResponse,
  CheckItemAllocationInformationGetResponse,
  CheckItemAllocationPostParameters,
  CheckItemAllocationPostResponse,
  CheckItemAllocationQueueItemListParams,
  CheckItemAllocationQueueItemListResponse,
  CheckItemAllocationReturnDirectivePostParameters,
  CheckItemAllocationReturnDirectivePostResponse,
  CheckItemAllocationSearchDirectiveGetParams,
  CheckItemAllocationSearchDirectiveGetResponse,
  CheckItemImageViewDataViewDirectiveGetResponse,
  CheckTransferLookupGetParams,
  CheckTransferLookupGetResponse,
  ClearingMessagePostParameters,
  ClearingMessagePostResponse,
  CommercialOnboardingSurveyGetResponse,
  CommercialOnboardingSurveyListParams,
  CommercialOnboardingSurveyListResponse,
  ComplaintEntryGetResponse,
  ComplaintEntryListParams,
  ComplaintEntryListResponse,
  CompletedTransactionListParams,
  CompletedTransactionListResponse,
  ComplianceDocumentDeleteDirectivePostResponse,
  ComplianceDocumentDownloadResponse,
  ComplianceDocumentListParams,
  ComplianceDocumentListResponse,
  ConsolidatedScreeningListEntryGetResponse,
  ConsumerOnboardingSurveyGetResponse,
  ConsumerOnboardingSurveyListParams,
  ConsumerOnboardingSurveyListResponse,
  ControlConfigurationGetResponse,
  ControlConfigurationListParams,
  ControlConfigurationListResponse,
  ControlEvaluationListParams,
  ControlEvaluationListResponse,
  ControlRecordGetResponse,
  ControlRecordListParams,
  ControlRecordListResponse,
  CustomerIdentificationProgramTestingBatchGetResponse,
  CustomerIdentificationProgramTestingBatchListParams,
  CustomerIdentificationProgramTestingBatchListResponse,
  CustomerIdentificationProgramTestingBatchSummaryGetResponse,
  CustomerIdentificationProgramTestingEntryGetResponse,
  CustomerIdentificationProgramTestingEntryListParams,
  CustomerIdentificationProgramTestingEntryListResponse,
  DeclinedTransactionGetResponse,
  DigitalWalletImageFileApprovePostParameters,
  DigitalWalletImageFileApprovePostResponse,
  DigitalWalletImageFileListParams,
  DigitalWalletImageFileListResponse,
  DigitalWalletImageFileRejectPostResponse,
  DocumentRequestCancelResponse,
  DocumentRequestGetResponse,
  DocumentRequestListParams,
  DocumentRequestListResponse,
  DocumentRequestScheduleListParams,
  DocumentRequestScheduleListResponse,
  DocumentRequestSchedulePostParameters,
  DocumentRequestSchedulePostResponse,
  EmailPreviewGetParams,
  EmailPreviewGetResponse,
  EntityByNameListParams,
  EntityByNameListResponse,
  EntityClusterGetResponse,
  EntityClusterListParams,
  EntityClusterListResponse,
  EntityClusterReviewPostParameters,
  EntityClusterReviewPostResponse,
  EntityGetResponse,
  EntityPatchParameters,
  EntityPatchResponse,
  EntityReferenceListParams,
  EntityReferenceListResponse,
  EntitySupplementalDocumentSubmissionEvaluationGetResponse,
  FeePlanListParams,
  FeePlanListResponse,
  FeeStatementListParams,
  FeeStatementListResponse,
  FlagsGetResponse,
  GroupByNicknameGetParams,
  GroupByNicknameGetResponse,
  GroupGateDeleteResponse,
  GroupGateListResponse,
  GroupGatePostParameters,
  GroupGatePostResponse,
  GroupGetResponse,
  GroupListParams,
  GroupListResponse,
  GroupPatchParameters,
  GroupPatchResponse,
  IdentityDocumentGetResponse,
  IdentityDocumentListParams,
  IdentityDocumentListResponse,
  IdentityDocumentPatchParameters,
  IdentityDocumentPatchResponse,
  InboundCheckGetResponse,
  InboundMailItemListResponse,
  InitialStateGetResponse,
  InternalFileViewDirectiveGetResponse,
  LateReturnRequestGetResponse,
  LateReturnRequestListParams,
  LateReturnRequestListResponse,
  LateReturnRequestPostParameters,
  LateReturnRequestPostResponse,
  ManagementInformationSystemAccountsListParams,
  ManagementInformationSystemAccountsListResponse,
  ManagementInformationSystemBalancesListParams,
  ManagementInformationSystemBalancesListResponse,
  ManagementInformationSystemProgramCountsListParams,
  ManagementInformationSystemProgramCountsListResponse,
  ManagementInformationSystemRevenuesListParams,
  ManagementInformationSystemRevenuesListResponse,
  ManagementInformationSystemRiskMetricsListParams,
  ManagementInformationSystemRiskMetricsListResponse,
  ManagementInformationSystemVolumesListParams,
  ManagementInformationSystemVolumesListResponse,
  ManualAssuranceGetResponse,
  ManualTaskListResponse,
  ManualTaskPreviewGetParams,
  ManualTaskPreviewGetResponse,
  ManualTaskRunGetResponse,
  ManualTaskRunListParams,
  ManualTaskRunListResponse,
  ManualTaskRunPostParameters,
  ManualTaskRunPostResponse,
  OAuthApplicationGetResponse,
  OAuthApplicationListParams,
  OAuthApplicationListResponse,
  OAuthConnectionListParams,
  OAuthConnectionListResponse,
  ObjectGetResponse,
  ObligationDocumentGetResponse,
  ObligationDocumentListResponse,
  OperationListResponse,
  OperationPatchParameters,
  OperationPatchResponse,
  OperationPostParameters,
  OperationPostResponse,
  OperatorConfirmOneTimePasswordPostParameters,
  OperatorConfirmOneTimePasswordPostResponse,
  OperatorDeleteResponse,
  OperatorExportPostParameters,
  OperatorExportPostResponse,
  OperatorFileListParams,
  OperatorFileListResponse,
  OperatorFilePostParameters,
  OperatorFilePostResponse,
  OperatorFileViewDirectiveGetResponse,
  OperatorGetResponse,
  OperatorListParams,
  OperatorListResponse,
  OperatorManualQueueItemListParams,
  OperatorManualQueueItemListResponse,
  OperatorManualQueueItemPatchParameters,
  OperatorManualQueueItemPatchResponse,
  OperatorNoteListParams,
  OperatorNoteListResponse,
  OperatorNotePostParameters,
  OperatorNotePostResponse,
  OperatorOneTimePasswordProvisioningURIGetResponse,
  OperatorTagListParams,
  OperatorTagListResponse,
  OperatorTagPostParameters,
  OperatorTagPostResponse,
  OutboundACHFilesOverviewGetResponse,
  PartnerInvitationDeleteResponse,
  PartnerInvitationListParams,
  PartnerInvitationListResponse,
  PartnerInvitationPostParameters,
  PartnerInvitationPostResponse,
  PendingTransactionGetResponse,
  PendingTransactionListParams,
  PendingTransactionListResponse,
  PhysicalCardImageFileApprovePostResponse,
  PhysicalCardImageFileListParams,
  PhysicalCardImageFileListResponse,
  PhysicalCardImageFileRejectPostResponse,
  PlatformComplaintGetResponse,
  PlatformComplaintListParams,
  PlatformComplaintListResponse,
  PlatformComplaintListSubmissionGetResponse,
  PlatformComplaintListSubmissionListParams,
  PlatformComplaintListSubmissionListResponse,
  PlatformComplianceMetricsSubmissionGetResponse,
  PlatformComplianceMetricsSubmissionListParams,
  PlatformComplianceMetricsSubmissionListResponse,
  PlatformComplianceSubmissionProgramListParams,
  PlatformComplianceSubmissionProgramListResponse,
  PlatformFinancialReportSubmissionGetResponse,
  PlatformUnusualActivityReportGetResponse,
  PlatformUnusualActivityReportListParams,
  PlatformUnusualActivityReportListResponse,
  PlatformVendorGetResponse,
  PlatformVendorListParams,
  PlatformVendorListResponse,
  PlatformVendorListSubmissionGetResponse,
  PlatformVendorListSubmissionListParams,
  PlatformVendorListSubmissionListResponse,
  ProgramACHReturnRateGetResponse,
  ProgramACHReturnRateListParams,
  ProgramACHReturnRateListResponse,
  ProgramBalanceGetParams,
  ProgramBalanceGetResponse,
  ProgramCreditExposureGetResponse,
  ProgramGetResponse,
  ProgramListParams,
  ProgramListResponse,
  ProgramPatchParameters,
  ProgramPatchResponse,
  ProofOfAuthorizationRequestSubmissionPatchParameters,
  ProofOfAuthorizationRequestSubmissionPatchResponse,
  RampBusinessAccountOnboardingSurveyGetResponse,
  RampBusinessAccountOnboardingSurveyListParams,
  RampBusinessAccountOnboardingSurveyListResponse,
  RealTimePaymentsPrefundedPositionOverviewGetParams,
  RealTimePaymentsPrefundedPositionOverviewGetResponse,
  RegisterPostParameters,
  RegisterPostResponse,
  ResultDeleteParams,
  ResultDeleteResponse,
  ResultEvaluationListParams,
  ResultEvaluationListResponse,
  ResultListResponse,
  ResultPostParameters,
  ResultPostResponse,
  ResultRetryDirectivePostParameters,
  ResultRetryDirectivePostResponse,
  ResultStatusGetResponse,
  ResultStatusListParams,
  ResultStatusListResponse,
  ResultSummaryGetResponse,
  ResultSuppressDirectivePostParameters,
  ResultSuppressDirectivePostResponse,
  RoleListParams,
  RoleListResponse,
  RoutingNumberListParams,
  RoutingNumberListResponse,
  RoutingNumberPatchParameters,
  RoutingNumberPatchResponse,
  SanctionsScreeningHitListParams,
  SanctionsScreeningHitListResponse,
  SanctionsScreeningHitPatchParameters,
  SanctionsScreeningHitPatchResponse,
  SanctionsScreeningReviewGetResponse,
  SanctionsScreeningReviewListParams,
  SanctionsScreeningReviewListResponse,
  SanctionsScreeningReviewPatchParameters,
  SanctionsScreeningReviewPatchResponse,
  SanctionsScreeningReviewRecordGetParams,
  SanctionsScreeningReviewRecordGetResponse,
  SanctionsScreeningTriggerTokenArchiveDirectivePostResponse,
  SanctionsScreeningTriggerTokenListParams,
  SanctionsScreeningTriggerTokenListResponse,
  SanctionsScreeningTriggerTokenPostParameters,
  SanctionsScreeningTriggerTokenPostResponse,
  SessionDeleteResponse,
  SessionGetResponse,
  SessionPostParameters,
  SessionPostResponse,
  TokenizationMessagePostParameters,
  TokenizationMessagePostResponse,
  TransactionGetResponse,
  TransactionsByACHTraceNumberListParams,
  TransactionsByACHTraceNumberListResponse,
  TransferGetResponse,
  TransferReviewListParams,
  TransferReviewListResponse,
  TransferReviewingRejectDirectivePostParameters,
  TransferReviewingRejectDirectivePostResponse,
  TransferReviewingReleaseDirectivePostParameters,
  TransferReviewingReleaseDirectivePostResponse,
  TransfersByCheckNumberListParams,
  TransfersByCheckNumberListResponse,
  UnusualActivityReportGetResponse,
  UnusualActivityReportListParams,
  UnusualActivityReportListResponse,
  UserGetResponse,
  UserImpersonatePostResponse,
  UserListParams,
  UserListResponse,
  UserSessionListParams,
  UserSessionListResponse,
  ValidPhysicalCardImageFileListParams,
  ValidPhysicalCardImageFileListResponse,
} from "./operations.schemas";
import { generateFormData } from "../../lib/orval-form-data-generator";

/**
 * @summary AuthenticationPost
 */
export const authenticationPost = <
  TData extends AxiosResponse<AuthenticationPostResponse>,
>(
  authenticationPostParameters: AuthenticationPostParameters,
  options?: AxiosRequestConfig,
): Promise<TData> => {
  return axios.post(
    `/3ds/authentication`,
    authenticationPostParameters,
    options,
  );
};

/**
 * @summary ChallengePost
 */
export const challengePost = <TData extends AxiosResponse<ChallengePostResponse>>(
  challengePostParameters: ChallengePostParameters,
  options?: AxiosRequestConfig,
): Promise<TData> => {
  return axios.post(`/3ds/challenge`, challengePostParameters, options);
};

/**
 * @summary AccountNumberList
 */
export const accountNumberList = <
  TData extends AxiosResponse<AccountNumberListResponse>,
>(
  params?: AccountNumberListParams,
  options?: AxiosRequestConfig,
): Promise<TData> => {
  return axios.get(`/account-numbers`, {
    ...options,
    params: { ...params, ...options?.params },
  });
};

/**
 * @summary AccountNumberGet
 */
export const accountNumberGet = <
  TData extends AxiosResponse<AccountNumberGetResponse>,
>(
  accountNumberId: string,
  options?: AxiosRequestConfig,
): Promise<TData> => {
  return axios.get(`/account-numbers/${accountNumberId}`, options);
};

/**
 * @summary AccountBalanceGet
 */
export const accountBalanceGet = <
  TData extends AxiosResponse<AccountBalanceGetResponse>,
>(
  params: AccountBalanceGetParams,
  options?: AxiosRequestConfig,
): Promise<TData> => {
  return axios.get(`/account_balance`, {
    ...options,
    params: { ...params, ...options?.params },
  });
};

/**
 * @summary AccountByAccountNumberGet
 */
export const accountByAccountNumberGet = <
  TData extends AxiosResponse<AccountByAccountNumberGetResponse>,
>(
  params: AccountByAccountNumberGetParams,
  options?: AxiosRequestConfig,
): Promise<TData> => {
  return axios.get(`/account_by_account_number`, {
    ...options,
    params: { ...params, ...options?.params },
  });
};

/**
 * @summary AccountList
 */
export const accountList = <TData extends AxiosResponse<AccountListResponse>>(
  params?: AccountListParams,
  options?: AxiosRequestConfig,
): Promise<TData> => {
  return axios.get(`/accounts`, {
    ...options,
    params: { ...params, ...options?.params },
  });
};

/**
 * @summary AccountGet
 */
export const accountGet = <TData extends AxiosResponse<AccountGetResponse>>(
  accountId: string,
  options?: AxiosRequestConfig,
): Promise<TData> => {
  return axios.get(`/accounts/${accountId}`, options);
};

/**
 * @summary ACHHistoricalOriginationVolumeForRoutingNumberGet
 */
export const aCHHistoricalOriginationVolumeForRoutingNumberGet = <
  TData extends AxiosResponse<ACHHistoricalOriginationVolumeForRoutingNumberGetResponse>,
>(
  params: ACHHistoricalOriginationVolumeForRoutingNumberGetParams,
  options?: AxiosRequestConfig,
): Promise<TData> => {
  return axios.get(`/ach-origination-volume-for-routing-number-get`, {
    ...options,
    params: { ...params, ...options?.params },
  });
};

/**
 * @summary ACHOriginationVolumeMonitoringGet
 */
export const aCHOriginationVolumeMonitoringGet = <
  TData extends AxiosResponse<ACHOriginationVolumeMonitoringGetResponse>,
>(
  params: ACHOriginationVolumeMonitoringGetParams,
  options?: AxiosRequestConfig,
): Promise<TData> => {
  return axios.get(`/ach-origination-volume-monitoring`, {
    ...options,
    params: { ...params, ...options?.params },
  });
};

/**
 * @summary ACHCompanyIDLookupGet
 */
export const aCHCompanyIDLookupGet = <
  TData extends AxiosResponse<ACHCompanyIDLookupGetResponse>,
>(
  params: ACHCompanyIDLookupGetParams,
  options?: AxiosRequestConfig,
): Promise<TData> => {
  return axios.get(`/ach_company_id_lookup`, {
    ...options,
    params: { ...params, ...options?.params },
  });
};

/**
 * @summary ACHTransferLookupGet
 */
export const aCHTransferLookupGet = <
  TData extends AxiosResponse<ACHTransferLookupGetResponse>,
>(
  params: ACHTransferLookupGetParams,
  options?: AxiosRequestConfig,
): Promise<TData> => {
  return axios.get(`/ach_transfer_lookup`, {
    ...options,
    params: { ...params, ...options?.params },
  });
};

/**
 * @summary APIInboundFileViewDirectiveGet
 */
export const aPIInboundFileViewDirectiveGet = <
  TData extends AxiosResponse<APIInboundFileViewDirectiveGetResponse>,
>(
  fileId: string,
  options?: AxiosRequestConfig,
): Promise<TData> => {
  return axios.get(`/api_files/${fileId}/view`, options);
};

/**
 * @summary AttestationList
 */
export const attestationList = <TData extends AxiosResponse<AttestationListResponse>>(
  params?: AttestationListParams,
  options?: AxiosRequestConfig,
): Promise<TData> => {
  return axios.get(`/attestations`, {
    ...options,
    params: { ...params, ...options?.params },
  });
};

/**
 * @summary AttestationGet
 */
export const attestationGet = <TData extends AxiosResponse<AttestationGetResponse>>(
  attestationId: string,
  options?: AxiosRequestConfig,
): Promise<TData> => {
  return axios.get(`/attestations/${attestationId}`, options);
};

/**
 * @summary AttestationPatch
 */
export const attestationPatch = <
  TData extends AxiosResponse<AttestationPatchResponse>,
>(
  attestationId: string,
  attestationPatchParameters: AttestationPatchParameters,
  options?: AxiosRequestConfig,
): Promise<TData> => {
  return axios.patch(
    `/attestations/${attestationId}`,
    attestationPatchParameters,
    options,
  );
};

/**
 * @summary AuthorizationMessagePost
 */
export const authorizationMessagePost = <
  TData extends AxiosResponse<AuthorizationMessagePostResponse>,
>(
  authorizationMessagePostParameters: AuthorizationMessagePostParameters,
  options?: AxiosRequestConfig,
): Promise<TData> => {
  return axios.post(
    `/authorization-message`,
    authorizationMessagePostParameters,
    options,
  );
};

/**
 * @summary BankFeeStatementList
 */
export const bankFeeStatementList = <
  TData extends AxiosResponse<BankFeeStatementListResponse>,
>(
  params: BankFeeStatementListParams,
  options?: AxiosRequestConfig,
): Promise<TData> => {
  return axios.get(`/bank_fee_statements`, {
    ...options,
    params: { ...params, ...options?.params },
  });
};

/**
 * @summary BankFeeStatementGet
 */
export const bankFeeStatementGet = <
  TData extends AxiosResponse<BankFeeStatementGetResponse>,
>(
  bankFeeStatementId: string,
  options?: AxiosRequestConfig,
): Promise<TData> => {
  return axios.get(`/bank_fee_statements/${bankFeeStatementId}`, options);
};

/**
 * @summary BankFeeList
 */
export const bankFeeList = <TData extends AxiosResponse<BankFeeListResponse>>(
  params: BankFeeListParams,
  options?: AxiosRequestConfig,
): Promise<TData> => {
  return axios.get(`/bank_fees`, {
    ...options,
    params: { ...params, ...options?.params },
  });
};

/**
 * @summary BankProgramList
 */
export const bankProgramList = <TData extends AxiosResponse<BankProgramListResponse>>(
  params?: BankProgramListParams,
  options?: AxiosRequestConfig,
): Promise<TData> => {
  return axios.get(`/bank_programs`, {
    ...options,
    params: { ...params, ...options?.params },
  });
};

/**
 * @summary BankProgramGet
 */
export const bankProgramGet = <TData extends AxiosResponse<BankProgramGetResponse>>(
  bankProgramId: string,
  options?: AxiosRequestConfig,
): Promise<TData> => {
  return axios.get(`/bank_programs/${bankProgramId}`, options);
};

/**
 * @summary BankProgramCurrentFeePlanGet
 */
export const bankProgramCurrentFeePlanGet = <
  TData extends AxiosResponse<BankProgramCurrentFeePlanGetResponse>,
>(
  bankProgramId: string,
  options?: AxiosRequestConfig,
): Promise<TData> => {
  return axios.get(`/bank_programs/${bankProgramId}/current_fee_plan`, options);
};

/**
 * @summary BeneficialOwnerGet
 */
export const beneficialOwnerGet = <
  TData extends AxiosResponse<BeneficialOwnerGetResponse>,
>(
  beneficialOwnerId: string,
  options?: AxiosRequestConfig,
): Promise<TData> => {
  return axios.get(`/beneficial_owner/${beneficialOwnerId}`, options);
};

/**
 * @summary BeneficialOwnerRelationshipList
 */
export const beneficialOwnerRelationshipList = <
  TData extends AxiosResponse<BeneficialOwnerRelationshipListResponse>,
>(
  params: BeneficialOwnerRelationshipListParams,
  options?: AxiosRequestConfig,
): Promise<TData> => {
  return axios.get(`/beneficial_owner_relationships`, {
    ...options,
    params: { ...params, ...options?.params },
  });
};

/**
 * @summary BookkeepingAccountList
 */
export const bookkeepingAccountList = <
  TData extends AxiosResponse<BookkeepingAccountListResponse>,
>(
  params?: BookkeepingAccountListParams,
  options?: AxiosRequestConfig,
): Promise<TData> => {
  return axios.get(`/bookkeeping_accounts`, {
    ...options,
    params: { ...params, ...options?.params },
  });
};

/**
 * @summary BookkeepingAccountGet
 */
export const bookkeepingAccountGet = <
  TData extends AxiosResponse<BookkeepingAccountGetResponse>,
>(
  bookkeepingAccountId: string,
  options?: AxiosRequestConfig,
): Promise<TData> => {
  return axios.get(`/bookkeeping_accounts/${bookkeepingAccountId}`, options);
};

/**
 * @summary BookkeepingEntryList
 */
export const bookkeepingEntryList = <
  TData extends AxiosResponse<BookkeepingEntryListResponse>,
>(
  params?: BookkeepingEntryListParams,
  options?: AxiosRequestConfig,
): Promise<TData> => {
  return axios.get(`/bookkeeping_entries`, {
    ...options,
    params: { ...params, ...options?.params },
  });
};

/**
 * @summary CardDeclineGet
 */
export const cardDeclineGet = <TData extends AxiosResponse<CardDeclineGetResponse>>(
  cardDeclineId: string,
  options?: AxiosRequestConfig,
): Promise<TData> => {
  return axios.get(`/card_declines/${cardDeclineId}`, options);
};

/**
 * @summary CardPaymentGet
 */
export const cardPaymentGet = <TData extends AxiosResponse<CardPaymentGetResponse>>(
  cardPaymentId: string,
  options?: AxiosRequestConfig,
): Promise<TData> => {
  return axios.get(`/card_payments/${cardPaymentId}`, options);
};

/**
 * @summary CardList
 */
export const cardList = <TData extends AxiosResponse<CardListResponse>>(
  params?: CardListParams,
  options?: AxiosRequestConfig,
): Promise<TData> => {
  return axios.get(`/cards`, {
    ...options,
    params: { ...params, ...options?.params },
  });
};

/**
 * @summary CardGet
 */
export const cardGet = <TData extends AxiosResponse<CardGetResponse>>(
  cardId: string,
  options?: AxiosRequestConfig,
): Promise<TData> => {
  return axios.get(`/cards/${cardId}`, options);
};

/**
 * @summary CashReconciliationOverviewGet
 */
export const cashReconciliationOverviewGet = <
  TData extends AxiosResponse<CashReconciliationOverviewGetResponse>,
>(
  params: CashReconciliationOverviewGetParams,
  options?: AxiosRequestConfig,
): Promise<TData> => {
  return axios.get(`/cash-reconciliation-overview`, {
    ...options,
    params: { ...params, ...options?.params },
  });
};

/**
 * @summary CashReconciliationUnreconciledExpectedCashTransactionTupleDetailsGet
 */
export const cashReconciliationUnreconciledExpectedCashTransactionTupleDetailsGet =
  <
    TData extends AxiosResponse<CashReconciliationUnreconciledExpectedCashTransactionTupleDetailsGetResponse>,
  >(
    params: CashReconciliationUnreconciledExpectedCashTransactionTupleDetailsGetParams,
    options?: AxiosRequestConfig,
  ): Promise<TData> => {
    return axios.get(
      `/cash-reconciliation-unreconciled-expected-cash-transaction-tuple-details`,
      {
        ...options,
        params: { ...params, ...options?.params },
      },
    );
  };

/**
 * @summary CashReconciliationUnreconciledExpectedCashTransactionTupleList
 */
export const cashReconciliationUnreconciledExpectedCashTransactionTupleList = <
  TData extends AxiosResponse<CashReconciliationUnreconciledExpectedCashTransactionTupleListResponse>,
>(
  params: CashReconciliationUnreconciledExpectedCashTransactionTupleListParams,
  options?: AxiosRequestConfig,
): Promise<TData> => {
  return axios.get(
    `/cash-reconciliation-unreconciled-expected-cash-transaction-tuples`,
    {
      ...options,
      params: { ...params, ...options?.params },
    },
  );
};

/**
 * @summary CheckItemAllocationInformationGet
 */
export const checkItemAllocationInformationGet = <
  TData extends AxiosResponse<CheckItemAllocationInformationGetResponse>,
>(
  checkItemId: string,
  options?: AxiosRequestConfig,
): Promise<TData> => {
  return axios.get(
    `/check-item-allocation/information/${checkItemId}`,
    options,
  );
};

/**
 * @summary CheckItemAllocationSearchDirectiveGet
 */
export const checkItemAllocationSearchDirectiveGet = <
  TData extends AxiosResponse<CheckItemAllocationSearchDirectiveGetResponse>,
>(
  params: CheckItemAllocationSearchDirectiveGetParams,
  options?: AxiosRequestConfig,
): Promise<TData> => {
  return axios.get(`/check-item-allocation/search`, {
    ...options,
    params: { ...params, ...options?.params },
  });
};

/**
 * @summary CheckItemAllocationPost
 */
export const checkItemAllocationPost = <
  TData extends AxiosResponse<CheckItemAllocationPostResponse>,
>(
  checkItemId: string,
  checkItemAllocationPostParameters: CheckItemAllocationPostParameters,
  options?: AxiosRequestConfig,
): Promise<TData> => {
  return axios.post(
    `/check-item-allocation/${checkItemId}`,
    checkItemAllocationPostParameters,
    options,
  );
};

/**
 * @summary CheckItemAllocationReturnDirectivePost
 */
export const checkItemAllocationReturnDirectivePost = <
  TData extends AxiosResponse<CheckItemAllocationReturnDirectivePostResponse>,
>(
  checkItemId: string,
  checkItemAllocationReturnDirectivePostParameters: CheckItemAllocationReturnDirectivePostParameters,
  options?: AxiosRequestConfig,
): Promise<TData> => {
  return axios.post(
    `/check-item-allocation/${checkItemId}/return`,
    checkItemAllocationReturnDirectivePostParameters,
    options,
  );
};

/**
 * @summary CheckDepositContextGet
 */
export const checkDepositContextGet = <
  TData extends AxiosResponse<CheckDepositContextGetResponse>,
>(
  params: CheckDepositContextGetParams,
  options?: AxiosRequestConfig,
): Promise<TData> => {
  return axios.get(`/check_deposit_context`, {
    ...options,
    params: { ...params, ...options?.params },
  });
};

/**
 * @summary CheckDepositList
 */
export const checkDepositList = <
  TData extends AxiosResponse<CheckDepositListResponse>,
>(
  params?: CheckDepositListParams,
  options?: AxiosRequestConfig,
): Promise<TData> => {
  return axios.get(`/check_deposits`, {
    ...options,
    params: { ...params, ...options?.params },
  });
};

/**
 * @summary CheckDepositGet
 */
export const checkDepositGet = <TData extends AxiosResponse<CheckDepositGetResponse>>(
  checkDepositId: string,
  options?: AxiosRequestConfig,
): Promise<TData> => {
  return axios.get(`/check_deposits/${checkDepositId}`, options);
};

/**
 * @summary CheckDepositRejectDirectivePost
 */
export const checkDepositRejectDirectivePost = <
  TData extends AxiosResponse<CheckDepositRejectDirectivePostResponse>,
>(
  checkDepositId: string,
  checkDepositRejectDirectivePostParameters: CheckDepositRejectDirectivePostParameters,
  options?: AxiosRequestConfig,
): Promise<TData> => {
  return axios.post(
    `/check_deposits/${checkDepositId}/reject`,
    checkDepositRejectDirectivePostParameters,
    options,
  );
};

/**
 * @summary CheckDepositApproveDirectivePost
 */
export const checkDepositApproveDirectivePost = <
  TData extends AxiosResponse<CheckDepositApproveDirectivePostResponse>,
>(
  checkDepositId: string,
  checkDepositApproveDirectivePostParameters: CheckDepositApproveDirectivePostParameters,
  options?: AxiosRequestConfig,
): Promise<TData> => {
  return axios.post(
    `/check_deposits/${checkDepositId}/review`,
    checkDepositApproveDirectivePostParameters,
    options,
  );
};

/**
 * @summary CheckItemAllocationQueueItemList
 */
export const checkItemAllocationQueueItemList = <
  TData extends AxiosResponse<CheckItemAllocationQueueItemListResponse>,
>(
  params?: CheckItemAllocationQueueItemListParams,
  options?: AxiosRequestConfig,
): Promise<TData> => {
  return axios.get(`/check_item_allocation_queue_items`, {
    ...options,
    params: { ...params, ...options?.params },
  });
};

/**
 * @summary CheckItemImageViewDataViewDirectiveGet
 */
export const checkItemImageViewDataViewDirectiveGet = <
  TData extends AxiosResponse<CheckItemImageViewDataViewDirectiveGetResponse>,
>(
  imageViewDataId: string,
  options?: AxiosRequestConfig,
): Promise<TData> => {
  return axios.get(
    `/check_item_image_view_data/${imageViewDataId}/view`,
    options,
  );
};

/**
 * @summary CheckTransferLookupGet
 */
export const checkTransferLookupGet = <
  TData extends AxiosResponse<CheckTransferLookupGetResponse>,
>(
  params: CheckTransferLookupGetParams,
  options?: AxiosRequestConfig,
): Promise<TData> => {
  return axios.get(`/check_transfer_lookup`, {
    ...options,
    params: { ...params, ...options?.params },
  });
};

/**
 * @summary ClearingMessagePost
 */
export const clearingMessagePost = <
  TData extends AxiosResponse<ClearingMessagePostResponse>,
>(
  clearingMessagePostParameters: ClearingMessagePostParameters,
  options?: AxiosRequestConfig,
): Promise<TData> => {
  return axios.post(
    `/clearing-message`,
    clearingMessagePostParameters,
    options,
  );
};

/**
 * @summary CommercialOnboardingSurveyList
 */
export const commercialOnboardingSurveyList = <
  TData extends AxiosResponse<CommercialOnboardingSurveyListResponse>,
>(
  params?: CommercialOnboardingSurveyListParams,
  options?: AxiosRequestConfig,
): Promise<TData> => {
  return axios.get(`/commercial_onboarding_surveys`, {
    ...options,
    params: { ...params, ...options?.params },
  });
};

/**
 * @summary CommercialOnboardingSurveyGet
 */
export const commercialOnboardingSurveyGet = <
  TData extends AxiosResponse<CommercialOnboardingSurveyGetResponse>,
>(
  commercialOnboardingSurveyId: string,
  options?: AxiosRequestConfig,
): Promise<TData> => {
  return axios.get(
    `/commercial_onboarding_surveys/${commercialOnboardingSurveyId}`,
    options,
  );
};

/**
 * @summary ComplaintEntryList
 */
export const complaintEntryList = <
  TData extends AxiosResponse<ComplaintEntryListResponse>,
>(
  params?: ComplaintEntryListParams,
  options?: AxiosRequestConfig,
): Promise<TData> => {
  return axios.get(`/complaint-entries`, {
    ...options,
    params: { ...params, ...options?.params },
  });
};

/**
 * @summary ComplaintEntryGet
 */
export const complaintEntryGet = <
  TData extends AxiosResponse<ComplaintEntryGetResponse>,
>(
  complaintEntryId: string,
  options?: AxiosRequestConfig,
): Promise<TData> => {
  return axios.get(`/complaint_entries/${complaintEntryId}`, options);
};

/**
 * @summary CompletedTransactionList
 */
export const completedTransactionList = <
  TData extends AxiosResponse<CompletedTransactionListResponse>,
>(
  params?: CompletedTransactionListParams,
  options?: AxiosRequestConfig,
): Promise<TData> => {
  return axios.get(`/completed-transactions`, {
    ...options,
    params: { ...params, ...options?.params },
  });
};

/**
 * @summary ComplianceDocumentList
 */
export const complianceDocumentList = <
  TData extends AxiosResponse<ComplianceDocumentListResponse>,
>(
  params?: ComplianceDocumentListParams,
  options?: AxiosRequestConfig,
): Promise<TData> => {
  return axios.get(`/compliance_documents`, {
    ...options,
    params: { ...params, ...options?.params },
  });
};

/**
 * @summary ComplianceDocumentDownload
 */
export const complianceDocumentDownload = <
  TData extends AxiosResponse<ComplianceDocumentDownloadResponse>,
>(
  complianceDocumentId: string,
  options?: AxiosRequestConfig,
): Promise<TData> => {
  return axios.get(`/compliance_documents/${complianceDocumentId}`, options);
};

/**
 * @summary ComplianceDocumentDeleteDirectivePost
 */
export const complianceDocumentDeleteDirectivePost = <
  TData extends AxiosResponse<ComplianceDocumentDeleteDirectivePostResponse>,
>(
  complianceDocumentId: string,
  options?: AxiosRequestConfig,
): Promise<TData> => {
  return axios.post(
    `/compliance_documents/${complianceDocumentId}/delete`,
    undefined,
    options,
  );
};

/**
 * @summary ConsolidatedScreeningListEntryGet
 */
export const consolidatedScreeningListEntryGet = <
  TData extends AxiosResponse<ConsolidatedScreeningListEntryGetResponse>,
>(
  entryId: string,
  options?: AxiosRequestConfig,
): Promise<TData> => {
  return axios.get(`/consolidated_screening_list_entries/${entryId}`, options);
};

/**
 * @summary ConsumerOnboardingSurveyList
 */
export const consumerOnboardingSurveyList = <
  TData extends AxiosResponse<ConsumerOnboardingSurveyListResponse>,
>(
  params?: ConsumerOnboardingSurveyListParams,
  options?: AxiosRequestConfig,
): Promise<TData> => {
  return axios.get(`/consumer_onboarding_surveys`, {
    ...options,
    params: { ...params, ...options?.params },
  });
};

/**
 * @summary ConsumerOnboardingSurveyGet
 */
export const consumerOnboardingSurveyGet = <
  TData extends AxiosResponse<ConsumerOnboardingSurveyGetResponse>,
>(
  consumerOnboardingSurveyId: string,
  options?: AxiosRequestConfig,
): Promise<TData> => {
  return axios.get(
    `/consumer_onboarding_surveys/${consumerOnboardingSurveyId}`,
    options,
  );
};

/**
 * @summary ControlConfigurationList
 */
export const controlConfigurationList = <
  TData extends AxiosResponse<ControlConfigurationListResponse>,
>(
  params?: ControlConfigurationListParams,
  options?: AxiosRequestConfig,
): Promise<TData> => {
  return axios.get(`/control_configurations`, {
    ...options,
    params: { ...params, ...options?.params },
  });
};

/**
 * @summary ControlConfigurationGet
 */
export const controlConfigurationGet = <
  TData extends AxiosResponse<ControlConfigurationGetResponse>,
>(
  controlConfigurationId: string,
  options?: AxiosRequestConfig,
): Promise<TData> => {
  return axios.get(
    `/control_configurations/${controlConfigurationId}`,
    options,
  );
};

/**
 * @summary ControlEvaluationList
 */
export const controlEvaluationList = <
  TData extends AxiosResponse<ControlEvaluationListResponse>,
>(
  params: ControlEvaluationListParams,
  options?: AxiosRequestConfig,
): Promise<TData> => {
  return axios.get(`/control_evaluations`, {
    ...options,
    params: { ...params, ...options?.params },
  });
};

/**
 * @summary ControlRecordList
 */
export const controlRecordList = <
  TData extends AxiosResponse<ControlRecordListResponse>,
>(
  params?: ControlRecordListParams,
  options?: AxiosRequestConfig,
): Promise<TData> => {
  return axios.get(`/control_records`, {
    ...options,
    params: { ...params, ...options?.params },
  });
};

/**
 * @summary ControlRecordGet
 */
export const controlRecordGet = <
  TData extends AxiosResponse<ControlRecordGetResponse>,
>(
  controlRecordId: string,
  options?: AxiosRequestConfig,
): Promise<TData> => {
  return axios.get(`/control_records/${controlRecordId}`, options);
};

/**
 * @summary CustomerIdentificationProgramTestingBatchSummaryGet
 */
export const customerIdentificationProgramTestingBatchSummaryGet = <
  TData extends AxiosResponse<CustomerIdentificationProgramTestingBatchSummaryGetResponse>,
>(
  batchId: string,
  options?: AxiosRequestConfig,
): Promise<TData> => {
  return axios.get(
    `/customer-identification-program-testing-batch-summaries/${batchId}`,
    options,
  );
};

/**
 * @summary CustomerIdentificationProgramTestingBatchList
 */
export const customerIdentificationProgramTestingBatchList = <
  TData extends AxiosResponse<CustomerIdentificationProgramTestingBatchListResponse>,
>(
  params?: CustomerIdentificationProgramTestingBatchListParams,
  options?: AxiosRequestConfig,
): Promise<TData> => {
  return axios.get(`/customer-identification-program-testing-batches`, {
    ...options,
    params: { ...params, ...options?.params },
  });
};

/**
 * @summary CustomerIdentificationProgramTestingBatchGet
 */
export const customerIdentificationProgramTestingBatchGet = <
  TData extends AxiosResponse<CustomerIdentificationProgramTestingBatchGetResponse>,
>(
  batchId: string,
  options?: AxiosRequestConfig,
): Promise<TData> => {
  return axios.get(
    `/customer-identification-program-testing-batches/${batchId}`,
    options,
  );
};

/**
 * @summary CustomerIdentificationProgramTestingEntryList
 */
export const customerIdentificationProgramTestingEntryList = <
  TData extends AxiosResponse<CustomerIdentificationProgramTestingEntryListResponse>,
>(
  params?: CustomerIdentificationProgramTestingEntryListParams,
  options?: AxiosRequestConfig,
): Promise<TData> => {
  return axios.get(`/customer-identification-program-testing-entries`, {
    ...options,
    params: { ...params, ...options?.params },
  });
};

/**
 * @summary CustomerIdentificationProgramTestingEntryGet
 */
export const customerIdentificationProgramTestingEntryGet = <
  TData extends AxiosResponse<CustomerIdentificationProgramTestingEntryGetResponse>,
>(
  entryId: string,
  options?: AxiosRequestConfig,
): Promise<TData> => {
  return axios.get(
    `/customer-identification-program-testing-entries/${entryId}`,
    options,
  );
};

/**
 * @summary DeclinedTransactionGet
 */
export const declinedTransactionGet = <
  TData extends AxiosResponse<DeclinedTransactionGetResponse>,
>(
  declinedTransactionId: string,
  options?: AxiosRequestConfig,
): Promise<TData> => {
  return axios.get(`/declined-transactions/${declinedTransactionId}`, options);
};

/**
 * @summary DigitalWalletImageFileList
 */
export const digitalWalletImageFileList = <
  TData extends AxiosResponse<DigitalWalletImageFileListResponse>,
>(
  params?: DigitalWalletImageFileListParams,
  options?: AxiosRequestConfig,
): Promise<TData> => {
  return axios.get(`/digital_wallet_image_files`, {
    ...options,
    params: { ...params, ...options?.params },
  });
};

/**
 * @summary DigitalWalletImageFileApprovePost
 */
export const digitalWalletImageFileApprovePost = <
  TData extends AxiosResponse<DigitalWalletImageFileApprovePostResponse>,
>(
  digitalWalletImageFileId: string,
  digitalWalletImageFileApprovePostParameters: DigitalWalletImageFileApprovePostParameters,
  options?: AxiosRequestConfig,
): Promise<TData> => {
  return axios.post(
    `/digital_wallet_image_files/${digitalWalletImageFileId}/approve`,
    digitalWalletImageFileApprovePostParameters,
    options,
  );
};

/**
 * @summary DigitalWalletImageFileRejectPost
 */
export const digitalWalletImageFileRejectPost = <
  TData extends AxiosResponse<DigitalWalletImageFileRejectPostResponse>,
>(
  digitalWalletImageFileId: string,
  options?: AxiosRequestConfig,
): Promise<TData> => {
  return axios.post(
    `/digital_wallet_image_files/${digitalWalletImageFileId}/reject`,
    undefined,
    options,
  );
};

/**
 * @summary DocumentRequestCancel
 */
export const documentRequestCancel = <
  TData extends AxiosResponse<DocumentRequestCancelResponse>,
>(
  documentRequestId: string,
  options?: AxiosRequestConfig,
): Promise<TData> => {
  return axios.post(
    `/document-requests/${documentRequestId}/cancel`,
    undefined,
    options,
  );
};

/**
 * @summary DocumentRequestScheduleList
 */
export const documentRequestScheduleList = <
  TData extends AxiosResponse<DocumentRequestScheduleListResponse>,
>(
  params: DocumentRequestScheduleListParams,
  options?: AxiosRequestConfig,
): Promise<TData> => {
  return axios.get(`/document_request_schedules`, {
    ...options,
    params: { ...params, ...options?.params },
  });
};

/**
 * @summary DocumentRequestSchedulePost
 */
export const documentRequestSchedulePost = <
  TData extends AxiosResponse<DocumentRequestSchedulePostResponse>,
>(
  documentRequestSchedulePostParameters: DocumentRequestSchedulePostParameters,
  options?: AxiosRequestConfig,
): Promise<TData> => {
  return axios.post(
    `/document_request_schedules`,
    documentRequestSchedulePostParameters,
    options,
  );
};

/**
 * @summary DocumentRequestList
 */
export const documentRequestList = <
  TData extends AxiosResponse<DocumentRequestListResponse>,
>(
  params?: DocumentRequestListParams,
  options?: AxiosRequestConfig,
): Promise<TData> => {
  return axios.get(`/document_requests`, {
    ...options,
    params: { ...params, ...options?.params },
  });
};

/**
 * @summary DocumentRequestGet
 */
export const documentRequestGet = <
  TData extends AxiosResponse<DocumentRequestGetResponse>,
>(
  documentRequestId: string,
  options?: AxiosRequestConfig,
): Promise<TData> => {
  return axios.get(`/document_requests/${documentRequestId}`, options);
};

/**
 * @summary EmailPreviewGet
 */
export const emailPreviewGet = <TData extends AxiosResponse<EmailPreviewGetResponse>>(
  params: EmailPreviewGetParams,
  options?: AxiosRequestConfig,
): Promise<TData> => {
  return axios.get(`/email_preview`, {
    ...options,
    params: { ...params, ...options?.params },
  });
};

/**
 * @summary EntityGet
 */
export const entityGet = <TData extends AxiosResponse<EntityGetResponse>>(
  entityId: string,
  options?: AxiosRequestConfig,
): Promise<TData> => {
  return axios.get(`/entities/${entityId}`, options);
};

/**
 * @summary EntityPatch
 */
export const entityPatch = <TData extends AxiosResponse<EntityPatchResponse>>(
  entityId: string,
  entityPatchParameters: EntityPatchParameters,
  options?: AxiosRequestConfig,
): Promise<TData> => {
  return axios.patch(`/entities/${entityId}`, entityPatchParameters, options);
};

/**
 * @summary EntityByNameList
 */
export const entityByNameList = <
  TData extends AxiosResponse<EntityByNameListResponse>,
>(
  params: EntityByNameListParams,
  options?: AxiosRequestConfig,
): Promise<TData> => {
  return axios.get(`/entities_by_name`, {
    ...options,
    params: { ...params, ...options?.params },
  });
};

/**
 * @summary EntityReferenceList
 */
export const entityReferenceList = <
  TData extends AxiosResponse<EntityReferenceListResponse>,
>(
  params?: EntityReferenceListParams,
  options?: AxiosRequestConfig,
): Promise<TData> => {
  return axios.get(`/entity-references`, {
    ...options,
    params: { ...params, ...options?.params },
  });
};

/**
 * @summary EntityClusterList
 */
export const entityClusterList = <
  TData extends AxiosResponse<EntityClusterListResponse>,
>(
  params?: EntityClusterListParams,
  options?: AxiosRequestConfig,
): Promise<TData> => {
  return axios.get(`/entity_clusters`, {
    ...options,
    params: { ...params, ...options?.params },
  });
};

/**
 * @summary EntityClusterGet
 */
export const entityClusterGet = <
  TData extends AxiosResponse<EntityClusterGetResponse>,
>(
  entityClusterId: string,
  options?: AxiosRequestConfig,
): Promise<TData> => {
  return axios.get(`/entity_clusters/${entityClusterId}`, options);
};

/**
 * @summary EntityClusterReviewPost
 */
export const entityClusterReviewPost = <
  TData extends AxiosResponse<EntityClusterReviewPostResponse>,
>(
  entityClusterId: string,
  entityClusterReviewPostParameters: EntityClusterReviewPostParameters,
  options?: AxiosRequestConfig,
): Promise<TData> => {
  return axios.post(
    `/entity_clusters/${entityClusterId}/review`,
    entityClusterReviewPostParameters,
    options,
  );
};

/**
 * @summary EntitySupplementalDocumentSubmissionEvaluationGet
 */
export const entitySupplementalDocumentSubmissionEvaluationGet = <
  TData extends AxiosResponse<EntitySupplementalDocumentSubmissionEvaluationGetResponse>,
>(
  entitySupplementalDocumentSubmissionId: string,
  options?: AxiosRequestConfig,
): Promise<TData> => {
  return axios.get(
    `/entity_supplemental_document_submission_evaluation/${entitySupplementalDocumentSubmissionId}`,
    options,
  );
};

/**
 * @summary FeePlanList
 */
export const feePlanList = <TData extends AxiosResponse<FeePlanListResponse>>(
  params: FeePlanListParams,
  options?: AxiosRequestConfig,
): Promise<TData> => {
  return axios.get(`/fee_plans`, {
    ...options,
    params: { ...params, ...options?.params },
  });
};

/**
 * @summary FeeStatementList
 */
export const feeStatementList = <
  TData extends AxiosResponse<FeeStatementListResponse>,
>(
  params: FeeStatementListParams,
  options?: AxiosRequestConfig,
): Promise<TData> => {
  return axios.get(`/fee_statements`, {
    ...options,
    params: { ...params, ...options?.params },
  });
};

/**
 * @summary FlagsGet
 */
export const flagsGet = <TData extends AxiosResponse<FlagsGetResponse>>(
  options?: AxiosRequestConfig,
): Promise<TData> => {
  return axios.get(`/flags`, options);
};

/**
 * @summary GroupByNicknameGet
 */
export const groupByNicknameGet = <
  TData extends AxiosResponse<GroupByNicknameGetResponse>,
>(
  params: GroupByNicknameGetParams,
  options?: AxiosRequestConfig,
): Promise<TData> => {
  return axios.get(`/group_by_nickname`, {
    ...options,
    params: { ...params, ...options?.params },
  });
};

/**
 * @summary GroupList
 */
export const groupList = <TData extends AxiosResponse<GroupListResponse>>(
  params?: GroupListParams,
  options?: AxiosRequestConfig,
): Promise<TData> => {
  return axios.get(`/groups`, {
    ...options,
    params: { ...params, ...options?.params },
  });
};

/**
 * @summary GroupGet
 */
export const groupGet = <TData extends AxiosResponse<GroupGetResponse>>(
  groupId: string,
  options?: AxiosRequestConfig,
): Promise<TData> => {
  return axios.get(`/groups/${groupId}`, options);
};

/**
 * @summary GroupPatch
 */
export const groupPatch = <TData extends AxiosResponse<GroupPatchResponse>>(
  groupId: string,
  groupPatchParameters: GroupPatchParameters,
  options?: AxiosRequestConfig,
): Promise<TData> => {
  return axios.patch(`/groups/${groupId}`, groupPatchParameters, options);
};

/**
 * @summary GroupGateList
 */
export const groupGateList = <TData extends AxiosResponse<GroupGateListResponse>>(
  groupId: string,
  options?: AxiosRequestConfig,
): Promise<TData> => {
  return axios.get(`/groups/${groupId}/gates`, options);
};

/**
 * @summary GroupGatePost
 */
export const groupGatePost = <TData extends AxiosResponse<GroupGatePostResponse>>(
  groupId: string,
  groupGatePostParameters: GroupGatePostParameters,
  options?: AxiosRequestConfig,
): Promise<TData> => {
  return axios.post(
    `/groups/${groupId}/gates`,
    groupGatePostParameters,
    options,
  );
};

/**
 * @summary GroupGateDelete
 */
export const groupGateDelete = <TData extends AxiosResponse<GroupGateDeleteResponse>>(
  groupId: string,
  feature:
    | "allow_any_visa_tokenization_token_requestor"
    | "account_list_balance"
    | "ach_debits"
    | "bookkeeping"
    | "credit_reserve_available_balance"
    | "disable_api_transfer_approvals"
    | "error_endpoint"
    | "government_authority_entity_create"
    | "high_priority_webhooks"
    | "inbound_checks"
    | "international_checks"
    | "legacy_entity_api"
    | "limits"
    | "max_limit_disabled"
    | "natural_person_entity_create"
    | "natural_person_entity_accept_non_tax_id"
    | "notification_of_change_creating"
    | "post_office_box_entity_address"
    | "rate_limiting_disabled"
    | "rate_limit_tier_2x"
    | "real_time_payments_request_for_payments"
    | "structured_x12_addenda"
    | "custom_physical_cards"
    | "faster_physical_cards_shipping"
    | "physical_cards_text"
    | "dashboard_rollups"
    | "wire_drawdown_requests"
    | "intrafi_deposit_sweep"
    | "prevent_new_account_creation"
    | "ramp_business_account_onboarding_survey"
    | "entity_onboarding_survey_backfill"
    | "new_pending_reviewing_status_behavior"
    | "effective_date_in_ach_transfer_instruction_post"
    | "wire_transfer_synchronous_intention"
    | "lockboxes",
  options?: AxiosRequestConfig,
): Promise<TData> => {
  return axios.delete(`/groups/${groupId}/gates/${feature}`, options);
};

/**
 * @summary IdentityDocumentList
 */
export const identityDocumentList = <
  TData extends AxiosResponse<IdentityDocumentListResponse>,
>(
  params?: IdentityDocumentListParams,
  options?: AxiosRequestConfig,
): Promise<TData> => {
  return axios.get(`/identity_documents`, {
    ...options,
    params: { ...params, ...options?.params },
  });
};

/**
 * @summary IdentityDocumentGet
 */
export const identityDocumentGet = <
  TData extends AxiosResponse<IdentityDocumentGetResponse>,
>(
  identityDocumentId: string,
  options?: AxiosRequestConfig,
): Promise<TData> => {
  return axios.get(`/identity_documents/${identityDocumentId}`, options);
};

/**
 * @summary IdentityDocumentPatch
 */
export const identityDocumentPatch = <
  TData extends AxiosResponse<IdentityDocumentPatchResponse>,
>(
  identityDocumentId: string,
  identityDocumentPatchParameters: IdentityDocumentPatchParameters,
  options?: AxiosRequestConfig,
): Promise<TData> => {
  return axios.patch(
    `/identity_documents/${identityDocumentId}`,
    identityDocumentPatchParameters,
    options,
  );
};

/**
 * @summary InboundCheckGet
 */
export const inboundCheckGet = <TData extends AxiosResponse<InboundCheckGetResponse>>(
  inboundCheckId: string,
  options?: AxiosRequestConfig,
): Promise<TData> => {
  return axios.get(`/inbound_check/${inboundCheckId}`, options);
};

/**
 * @summary InboundMailItemList
 */
export const inboundMailItemList = <
  TData extends AxiosResponse<InboundMailItemListResponse>,
>(
  options?: AxiosRequestConfig,
): Promise<TData> => {
  return axios.get(`/inbound_mail_items`, options);
};

/**
 * @summary InitialStateGet
 */
export const initialStateGet = <TData extends AxiosResponse<InitialStateGetResponse>>(
  options?: AxiosRequestConfig,
): Promise<TData> => {
  return axios.get(`/initial-state`, options);
};

/**
 * @summary InternalFileViewDirectiveGet
 */
export const internalFileViewDirectiveGet = <
  TData extends AxiosResponse<InternalFileViewDirectiveGetResponse>,
>(
  fileId: string,
  options?: AxiosRequestConfig,
): Promise<TData> => {
  return axios.get(`/internal-files/${fileId}/view`, options);
};

/**
 * @summary LateReturnRequestList
 */
export const lateReturnRequestList = <
  TData extends AxiosResponse<LateReturnRequestListResponse>,
>(
  params?: LateReturnRequestListParams,
  options?: AxiosRequestConfig,
): Promise<TData> => {
  return axios.get(`/late-return-requests`, {
    ...options,
    params: { ...params, ...options?.params },
  });
};

/**
 * @summary LateReturnRequestPost
 */
export const lateReturnRequestPost = <
  TData extends AxiosResponse<LateReturnRequestPostResponse>,
>(
  lateReturnRequestPostParameters: LateReturnRequestPostParameters,
  options?: AxiosRequestConfig,
): Promise<TData> => {
  const formData = generateFormData(lateReturnRequestPostParameters);
  return axios.post(`/late-return-requests`, formData, options);
};

/**
 * @summary LateReturnRequestGet
 */
export const lateReturnRequestGet = <
  TData extends AxiosResponse<LateReturnRequestGetResponse>,
>(
  lateReturnRequestId: string,
  options?: AxiosRequestConfig,
): Promise<TData> => {
  return axios.get(`/late-return-requests/${lateReturnRequestId}`, options);
};

/**
 * @summary ManagementInformationSystemAccountsList
 */
export const managementInformationSystemAccountsList = <
  TData extends AxiosResponse<ManagementInformationSystemAccountsListResponse>,
>(
  params: ManagementInformationSystemAccountsListParams,
  options?: AxiosRequestConfig,
): Promise<TData> => {
  return axios.get(`/management_information_system_accounts`, {
    ...options,
    params: { ...params, ...options?.params },
  });
};

/**
 * @summary ManagementInformationSystemBalancesList
 */
export const managementInformationSystemBalancesList = <
  TData extends AxiosResponse<ManagementInformationSystemBalancesListResponse>,
>(
  params: ManagementInformationSystemBalancesListParams,
  options?: AxiosRequestConfig,
): Promise<TData> => {
  return axios.get(`/management_information_system_balances`, {
    ...options,
    params: { ...params, ...options?.params },
  });
};

/**
 * @summary ManagementInformationSystemProgramCountsList
 */
export const managementInformationSystemProgramCountsList = <
  TData extends AxiosResponse<ManagementInformationSystemProgramCountsListResponse>,
>(
  params: ManagementInformationSystemProgramCountsListParams,
  options?: AxiosRequestConfig,
): Promise<TData> => {
  return axios.get(`/management_information_system_program_counts`, {
    ...options,
    params: { ...params, ...options?.params },
  });
};

/**
 * @summary ManagementInformationSystemRevenuesList
 */
export const managementInformationSystemRevenuesList = <
  TData extends AxiosResponse<ManagementInformationSystemRevenuesListResponse>,
>(
  params: ManagementInformationSystemRevenuesListParams,
  options?: AxiosRequestConfig,
): Promise<TData> => {
  return axios.get(`/management_information_system_revenues`, {
    ...options,
    params: { ...params, ...options?.params },
  });
};

/**
 * @summary ManagementInformationSystemRiskMetricsList
 */
export const managementInformationSystemRiskMetricsList = <
  TData extends AxiosResponse<ManagementInformationSystemRiskMetricsListResponse>,
>(
  params: ManagementInformationSystemRiskMetricsListParams,
  options?: AxiosRequestConfig,
): Promise<TData> => {
  return axios.get(`/management_information_system_risk_metrics`, {
    ...options,
    params: { ...params, ...options?.params },
  });
};

/**
 * @summary ManagementInformationSystemVolumesList
 */
export const managementInformationSystemVolumesList = <
  TData extends AxiosResponse<ManagementInformationSystemVolumesListResponse>,
>(
  params: ManagementInformationSystemVolumesListParams,
  options?: AxiosRequestConfig,
): Promise<TData> => {
  return axios.get(`/management_information_system_volumes`, {
    ...options,
    params: { ...params, ...options?.params },
  });
};

/**
 * @summary ManualTaskPreviewGet
 */
export const manualTaskPreviewGet = <
  TData extends AxiosResponse<ManualTaskPreviewGetResponse>,
>(
  params: ManualTaskPreviewGetParams,
  options?: AxiosRequestConfig,
): Promise<TData> => {
  return axios.get(`/manual-task-previews`, {
    ...options,
    params: { ...params, ...options?.params },
  });
};

/**
 * @summary ManualTaskRunList
 */
export const manualTaskRunList = <
  TData extends AxiosResponse<ManualTaskRunListResponse>,
>(
  params: ManualTaskRunListParams,
  options?: AxiosRequestConfig,
): Promise<TData> => {
  return axios.get(`/manual-task-runs`, {
    ...options,
    params: { ...params, ...options?.params },
  });
};

/**
 * @summary ManualTaskRunPost
 */
export const manualTaskRunPost = <
  TData extends AxiosResponse<ManualTaskRunPostResponse>,
>(
  manualTaskRunPostParameters: ManualTaskRunPostParameters,
  options?: AxiosRequestConfig,
): Promise<TData> => {
  return axios.post(`/manual-task-runs`, manualTaskRunPostParameters, options);
};

/**
 * @summary ManualTaskRunGet
 */
export const manualTaskRunGet = <
  TData extends AxiosResponse<ManualTaskRunGetResponse>,
>(
  manualTaskRunId: string,
  options?: AxiosRequestConfig,
): Promise<TData> => {
  return axios.get(`/manual-task-runs/${manualTaskRunId}`, options);
};

/**
 * @summary ManualTaskList
 */
export const manualTaskList = <TData extends AxiosResponse<ManualTaskListResponse>>(
  options?: AxiosRequestConfig,
): Promise<TData> => {
  return axios.get(`/manual-tasks`, options);
};

/**
 * @summary ManualAssuranceGet
 */
export const manualAssuranceGet = <
  TData extends AxiosResponse<ManualAssuranceGetResponse>,
>(
  manualAssuranceId: string,
  options?: AxiosRequestConfig,
): Promise<TData> => {
  return axios.get(`/manual_assurance/${manualAssuranceId}`, options);
};

/**
 * @summary OAuthApplicationList
 */
export const oAuthApplicationList = <
  TData extends AxiosResponse<OAuthApplicationListResponse>,
>(
  params?: OAuthApplicationListParams,
  options?: AxiosRequestConfig,
): Promise<TData> => {
  return axios.get(`/oauth_applications`, {
    ...options,
    params: { ...params, ...options?.params },
  });
};

/**
 * @summary OAuthApplicationGet
 */
export const oAuthApplicationGet = <
  TData extends AxiosResponse<OAuthApplicationGetResponse>,
>(
  oauthApplicationId: string,
  options?: AxiosRequestConfig,
): Promise<TData> => {
  return axios.get(`/oauth_applications/${oauthApplicationId}`, options);
};

/**
 * @summary OAuthConnectionList
 */
export const oAuthConnectionList = <
  TData extends AxiosResponse<OAuthConnectionListResponse>,
>(
  params?: OAuthConnectionListParams,
  options?: AxiosRequestConfig,
): Promise<TData> => {
  return axios.get(`/oauth_connections`, {
    ...options,
    params: { ...params, ...options?.params },
  });
};

/**
 * @summary ObjectGet
 */
export const objectGet = <TData extends AxiosResponse<ObjectGetResponse>>(
  id: string,
  options?: AxiosRequestConfig,
): Promise<TData> => {
  return axios.get(`/objects/${id}`, options);
};

/**
 * @summary ObligationDocumentList
 */
export const obligationDocumentList = <
  TData extends AxiosResponse<ObligationDocumentListResponse>,
>(
  options?: AxiosRequestConfig,
): Promise<TData> => {
  return axios.get(`/obligation_documents`, options);
};

/**
 * @summary ObligationDocumentGet
 */
export const obligationDocumentGet = <
  TData extends AxiosResponse<ObligationDocumentGetResponse>,
>(
  documentName: string,
  options?: AxiosRequestConfig,
): Promise<TData> => {
  return axios.get(`/obligation_documents/${documentName}`, options);
};

/**
 * @summary OperationList
 */
export const operationList = <TData extends AxiosResponse<OperationListResponse>>(
  options?: AxiosRequestConfig,
): Promise<TData> => {
  return axios.get(`/operations`, options);
};

/**
 * @summary OperationPost
 */
export const operationPost = <TData extends AxiosResponse<OperationPostResponse>>(
  operationPostParameters: OperationPostParameters,
  options?: AxiosRequestConfig,
): Promise<TData> => {
  return axios.post(`/operations`, operationPostParameters, options);
};

/**
 * @summary OperationPatch
 */
export const operationPatch = <TData extends AxiosResponse<OperationPatchResponse>>(
  operationName: string,
  operationPatchParameters: OperationPatchParameters,
  options?: AxiosRequestConfig,
): Promise<TData> => {
  return axios.patch(
    `/operations/${operationName}`,
    operationPatchParameters,
    options,
  );
};

/**
 * @summary OperatorGet
 */
export const operatorGet = <TData extends AxiosResponse<OperatorGetResponse>>(
  options?: AxiosRequestConfig,
): Promise<TData> => {
  return axios.get(`/operator`, options);
};

/**
 * @summary OperatorExportPost
 */
export const operatorExportPost = <
  TData extends AxiosResponse<OperatorExportPostResponse>,
>(
  operatorExportPostParameters: OperatorExportPostParameters,
  options?: AxiosRequestConfig,
): Promise<TData> => {
  return axios.post(`/operator-exports`, operatorExportPostParameters, options);
};

/**
 * @summary OperatorFileList
 */
export const operatorFileList = <
  TData extends AxiosResponse<OperatorFileListResponse>,
>(
  params: OperatorFileListParams,
  options?: AxiosRequestConfig,
): Promise<TData> => {
  return axios.get(`/operator-files`, {
    ...options,
    params: { ...params, ...options?.params },
  });
};

/**
 * @summary OperatorFilePost
 */
export const operatorFilePost = <
  TData extends AxiosResponse<OperatorFilePostResponse>,
>(
  operatorFilePostParameters: OperatorFilePostParameters,
  options?: AxiosRequestConfig,
): Promise<TData> => {
  const formData = generateFormData(operatorFilePostParameters);
  return axios.post(`/operator-files`, formData, options);
};

/**
 * @summary OperatorFileViewDirectiveGet
 */
export const operatorFileViewDirectiveGet = <
  TData extends AxiosResponse<OperatorFileViewDirectiveGetResponse>,
>(
  fileId: string,
  options?: AxiosRequestConfig,
): Promise<TData> => {
  return axios.get(`/operator-files/${fileId}/view`, options);
};

/**
 * @summary OperatorNoteList
 */
export const operatorNoteList = <
  TData extends AxiosResponse<OperatorNoteListResponse>,
>(
  params: OperatorNoteListParams,
  options?: AxiosRequestConfig,
): Promise<TData> => {
  return axios.get(`/operator-notes`, {
    ...options,
    params: { ...params, ...options?.params },
  });
};

/**
 * @summary OperatorNotePost
 */
export const operatorNotePost = <
  TData extends AxiosResponse<OperatorNotePostResponse>,
>(
  operatorNotePostParameters: OperatorNotePostParameters,
  options?: AxiosRequestConfig,
): Promise<TData> => {
  return axios.post(`/operator-notes`, operatorNotePostParameters, options);
};

/**
 * @summary OperatorTagList
 */
export const operatorTagList = <TData extends AxiosResponse<OperatorTagListResponse>>(
  params: OperatorTagListParams,
  options?: AxiosRequestConfig,
): Promise<TData> => {
  return axios.get(`/operator-tags`, {
    ...options,
    params: { ...params, ...options?.params },
  });
};

/**
 * @summary OperatorTagPost
 */
export const operatorTagPost = <TData extends AxiosResponse<OperatorTagPostResponse>>(
  operatorTagPostParameters: OperatorTagPostParameters,
  options?: AxiosRequestConfig,
): Promise<TData> => {
  return axios.post(`/operator-tags`, operatorTagPostParameters, options);
};

/**
 * @summary OperatorConfirmOneTimePasswordPost
 */
export const operatorConfirmOneTimePasswordPost = <
  TData extends AxiosResponse<OperatorConfirmOneTimePasswordPostResponse>,
>(
  operatorConfirmOneTimePasswordPostParameters: OperatorConfirmOneTimePasswordPostParameters,
  options?: AxiosRequestConfig,
): Promise<TData> => {
  return axios.post(
    `/operator/confirm_one_time_password`,
    operatorConfirmOneTimePasswordPostParameters,
    options,
  );
};

/**
 * @summary OperatorOneTimePasswordProvisioningURIGet
 */
export const operatorOneTimePasswordProvisioningURIGet = <
  TData extends AxiosResponse<OperatorOneTimePasswordProvisioningURIGetResponse>,
>(
  options?: AxiosRequestConfig,
): Promise<TData> => {
  return axios.get(`/operator/one_time_password_provisioning_uri`, options);
};

/**
 * @summary OperatorDelete
 */
export const operatorDelete = <TData extends AxiosResponse<OperatorDeleteResponse>>(
  operatorId: string,
  options?: AxiosRequestConfig,
): Promise<TData> => {
  return axios.delete(`/operator/${operatorId}`, options);
};

/**
 * @summary OperatorManualQueueItemList
 */
export const operatorManualQueueItemList = <
  TData extends AxiosResponse<OperatorManualQueueItemListResponse>,
>(
  params?: OperatorManualQueueItemListParams,
  options?: AxiosRequestConfig,
): Promise<TData> => {
  return axios.get(`/operator_manual_queue_items`, {
    ...options,
    params: { ...params, ...options?.params },
  });
};

/**
 * @summary OperatorManualQueueItemPatch
 */
export const operatorManualQueueItemPatch = <
  TData extends AxiosResponse<OperatorManualQueueItemPatchResponse>,
>(
  operatorManualQueueItemId: string,
  operatorManualQueueItemPatchParameters: OperatorManualQueueItemPatchParameters,
  options?: AxiosRequestConfig,
): Promise<TData> => {
  return axios.patch(
    `/operator_manual_queue_items/${operatorManualQueueItemId}`,
    operatorManualQueueItemPatchParameters,
    options,
  );
};

/**
 * @summary OperatorList
 */
export const operatorList = <TData extends AxiosResponse<OperatorListResponse>>(
  params?: OperatorListParams,
  options?: AxiosRequestConfig,
): Promise<TData> => {
  return axios.get(`/operators`, {
    ...options,
    params: { ...params, ...options?.params },
  });
};

/**
 * @summary OutboundACHFilesOverviewGet
 */
export const outboundACHFilesOverviewGet = <
  TData extends AxiosResponse<OutboundACHFilesOverviewGetResponse>,
>(
  options?: AxiosRequestConfig,
): Promise<TData> => {
  return axios.get(`/outbound-ach-files-overview`, options);
};

/**
 * @summary PartnerInvitationList
 */
export const partnerInvitationList = <
  TData extends AxiosResponse<PartnerInvitationListResponse>,
>(
  params?: PartnerInvitationListParams,
  options?: AxiosRequestConfig,
): Promise<TData> => {
  return axios.get(`/partner_invitations`, {
    ...options,
    params: { ...params, ...options?.params },
  });
};

/**
 * @summary PartnerInvitationPost
 */
export const partnerInvitationPost = <
  TData extends AxiosResponse<PartnerInvitationPostResponse>,
>(
  partnerInvitationPostParameters: PartnerInvitationPostParameters,
  options?: AxiosRequestConfig,
): Promise<TData> => {
  return axios.post(
    `/partner_invitations`,
    partnerInvitationPostParameters,
    options,
  );
};

/**
 * @summary PartnerInvitationDelete
 */
export const partnerInvitationDelete = <
  TData extends AxiosResponse<PartnerInvitationDeleteResponse>,
>(
  inviteId: string,
  options?: AxiosRequestConfig,
): Promise<TData> => {
  return axios.delete(`/partner_invitations/${inviteId}`, options);
};

/**
 * @summary PendingTransactionList
 */
export const pendingTransactionList = <
  TData extends AxiosResponse<PendingTransactionListResponse>,
>(
  params?: PendingTransactionListParams,
  options?: AxiosRequestConfig,
): Promise<TData> => {
  return axios.get(`/pending-transactions`, {
    ...options,
    params: { ...params, ...options?.params },
  });
};

/**
 * @summary PendingTransactionGet
 */
export const pendingTransactionGet = <
  TData extends AxiosResponse<PendingTransactionGetResponse>,
>(
  pendingTransactionId: string,
  options?: AxiosRequestConfig,
): Promise<TData> => {
  return axios.get(`/pending_transactions/${pendingTransactionId}`, options);
};

/**
 * @summary PhysicalCardImageFileList
 */
export const physicalCardImageFileList = <
  TData extends AxiosResponse<PhysicalCardImageFileListResponse>,
>(
  params?: PhysicalCardImageFileListParams,
  options?: AxiosRequestConfig,
): Promise<TData> => {
  return axios.get(`/physical_card_image_files`, {
    ...options,
    params: { ...params, ...options?.params },
  });
};

/**
 * @summary PhysicalCardImageFileApprovePost
 */
export const physicalCardImageFileApprovePost = <
  TData extends AxiosResponse<PhysicalCardImageFileApprovePostResponse>,
>(
  physicalCardImageFileId: string,
  options?: AxiosRequestConfig,
): Promise<TData> => {
  return axios.post(
    `/physical_card_image_files/${physicalCardImageFileId}/approve`,
    undefined,
    options,
  );
};

/**
 * @summary PhysicalCardImageFileRejectPost
 */
export const physicalCardImageFileRejectPost = <
  TData extends AxiosResponse<PhysicalCardImageFileRejectPostResponse>,
>(
  physicalCardImageFileId: string,
  options?: AxiosRequestConfig,
): Promise<TData> => {
  return axios.post(
    `/physical_card_image_files/${physicalCardImageFileId}/reject`,
    undefined,
    options,
  );
};

/**
 * @summary PlatformComplaintListSubmissionList
 */
export const platformComplaintListSubmissionList = <
  TData extends AxiosResponse<PlatformComplaintListSubmissionListResponse>,
>(
  params?: PlatformComplaintListSubmissionListParams,
  options?: AxiosRequestConfig,
): Promise<TData> => {
  return axios.get(`/platform-complaint-list-submissions`, {
    ...options,
    params: { ...params, ...options?.params },
  });
};

/**
 * @summary PlatformComplaintListSubmissionGet
 */
export const platformComplaintListSubmissionGet = <
  TData extends AxiosResponse<PlatformComplaintListSubmissionGetResponse>,
>(
  submissionId: string,
  options?: AxiosRequestConfig,
): Promise<TData> => {
  return axios.get(
    `/platform-complaint-list-submissions/${submissionId}`,
    options,
  );
};

/**
 * @summary PlatformComplaintList
 */
export const platformComplaintList = <
  TData extends AxiosResponse<PlatformComplaintListResponse>,
>(
  params?: PlatformComplaintListParams,
  options?: AxiosRequestConfig,
): Promise<TData> => {
  return axios.get(`/platform-complaints`, {
    ...options,
    params: { ...params, ...options?.params },
  });
};

/**
 * @summary PlatformComplianceMetricsSubmissionList
 */
export const platformComplianceMetricsSubmissionList = <
  TData extends AxiosResponse<PlatformComplianceMetricsSubmissionListResponse>,
>(
  params?: PlatformComplianceMetricsSubmissionListParams,
  options?: AxiosRequestConfig,
): Promise<TData> => {
  return axios.get(`/platform-compliance-metrics-submissions`, {
    ...options,
    params: { ...params, ...options?.params },
  });
};

/**
 * @summary PlatformComplianceMetricsSubmissionGet
 */
export const platformComplianceMetricsSubmissionGet = <
  TData extends AxiosResponse<PlatformComplianceMetricsSubmissionGetResponse>,
>(
  submissionId: string,
  options?: AxiosRequestConfig,
): Promise<TData> => {
  return axios.get(
    `/platform-compliance-metrics-submissions/${submissionId}`,
    options,
  );
};

/**
 * @summary PlatformComplianceSubmissionProgramList
 */
export const platformComplianceSubmissionProgramList = <
  TData extends AxiosResponse<PlatformComplianceSubmissionProgramListResponse>,
>(
  params?: PlatformComplianceSubmissionProgramListParams,
  options?: AxiosRequestConfig,
): Promise<TData> => {
  return axios.get(`/platform-compliance-submission-programs`, {
    ...options,
    params: { ...params, ...options?.params },
  });
};

/**
 * @summary PlatformFinancialReportSubmissionGet
 */
export const platformFinancialReportSubmissionGet = <
  TData extends AxiosResponse<PlatformFinancialReportSubmissionGetResponse>,
>(
  submissionId: string,
  options?: AxiosRequestConfig,
): Promise<TData> => {
  return axios.get(
    `/platform-financial-report-submissions/${submissionId}`,
    options,
  );
};

/**
 * @summary PlatformUnusualActivityReportList
 */
export const platformUnusualActivityReportList = <
  TData extends AxiosResponse<PlatformUnusualActivityReportListResponse>,
>(
  params?: PlatformUnusualActivityReportListParams,
  options?: AxiosRequestConfig,
): Promise<TData> => {
  return axios.get(`/platform-unusual-activity-reports`, {
    ...options,
    params: { ...params, ...options?.params },
  });
};

/**
 * @summary PlatformUnusualActivityReportGet
 */
export const platformUnusualActivityReportGet = <
  TData extends AxiosResponse<PlatformUnusualActivityReportGetResponse>,
>(
  unusualActivityReportId: string,
  options?: AxiosRequestConfig,
): Promise<TData> => {
  return axios.get(
    `/platform-unusual-activity-reports/${unusualActivityReportId}`,
    options,
  );
};

/**
 * @summary PlatformVendorListSubmissionList
 */
export const platformVendorListSubmissionList = <
  TData extends AxiosResponse<PlatformVendorListSubmissionListResponse>,
>(
  params?: PlatformVendorListSubmissionListParams,
  options?: AxiosRequestConfig,
): Promise<TData> => {
  return axios.get(`/platform-vendor-list-submissions`, {
    ...options,
    params: { ...params, ...options?.params },
  });
};

/**
 * @summary PlatformVendorListSubmissionGet
 */
export const platformVendorListSubmissionGet = <
  TData extends AxiosResponse<PlatformVendorListSubmissionGetResponse>,
>(
  submissionId: string,
  options?: AxiosRequestConfig,
): Promise<TData> => {
  return axios.get(
    `/platform-vendor-list-submissions/${submissionId}`,
    options,
  );
};

/**
 * @summary PlatformVendorList
 */
export const platformVendorList = <
  TData extends AxiosResponse<PlatformVendorListResponse>,
>(
  params?: PlatformVendorListParams,
  options?: AxiosRequestConfig,
): Promise<TData> => {
  return axios.get(`/platform-vendors`, {
    ...options,
    params: { ...params, ...options?.params },
  });
};

/**
 * @summary PlatformVendorGet
 */
export const platformVendorGet = <
  TData extends AxiosResponse<PlatformVendorGetResponse>,
>(
  platformVendorId: string,
  options?: AxiosRequestConfig,
): Promise<TData> => {
  return axios.get(`/platform-vendors/${platformVendorId}`, options);
};

/**
 * @summary PlatformComplaintGet
 */
export const platformComplaintGet = <
  TData extends AxiosResponse<PlatformComplaintGetResponse>,
>(
  platformComplaintId: string,
  options?: AxiosRequestConfig,
): Promise<TData> => {
  return axios.get(`/platform_complaints/${platformComplaintId}`, options);
};

/**
 * @summary ProgramACHReturnRateList
 */
export const programACHReturnRateList = <
  TData extends AxiosResponse<ProgramACHReturnRateListResponse>,
>(
  params?: ProgramACHReturnRateListParams,
  options?: AxiosRequestConfig,
): Promise<TData> => {
  return axios.get(`/program_ach_return_rates`, {
    ...options,
    params: { ...params, ...options?.params },
  });
};

/**
 * @summary ProgramACHReturnRateGet
 */
export const programACHReturnRateGet = <
  TData extends AxiosResponse<ProgramACHReturnRateGetResponse>,
>(
  programAchReturnRateId: string,
  options?: AxiosRequestConfig,
): Promise<TData> => {
  return axios.get(
    `/program_ach_return_rates/${programAchReturnRateId}`,
    options,
  );
};

/**
 * @summary ProgramBalanceGet
 */
export const programBalanceGet = <
  TData extends AxiosResponse<ProgramBalanceGetResponse>,
>(
  params: ProgramBalanceGetParams,
  options?: AxiosRequestConfig,
): Promise<TData> => {
  return axios.get(`/program_balance`, {
    ...options,
    params: { ...params, ...options?.params },
  });
};

/**
 * @summary ProgramCreditExposureGet
 */
export const programCreditExposureGet = <
  TData extends AxiosResponse<ProgramCreditExposureGetResponse>,
>(
  programId: string,
  options?: AxiosRequestConfig,
): Promise<TData> => {
  return axios.get(`/program_credit_exposure/${programId}`, options);
};

/**
 * @summary ProgramList
 */
export const programList = <TData extends AxiosResponse<ProgramListResponse>>(
  params?: ProgramListParams,
  options?: AxiosRequestConfig,
): Promise<TData> => {
  return axios.get(`/programs`, {
    ...options,
    params: { ...params, ...options?.params },
  });
};

/**
 * @summary ProgramGet
 */
export const programGet = <TData extends AxiosResponse<ProgramGetResponse>>(
  programId: string,
  options?: AxiosRequestConfig,
): Promise<TData> => {
  return axios.get(`/programs/${programId}`, options);
};

/**
 * @summary ProgramPatch
 */
export const programPatch = <TData extends AxiosResponse<ProgramPatchResponse>>(
  programId: string,
  programPatchParameters: ProgramPatchParameters,
  options?: AxiosRequestConfig,
): Promise<TData> => {
  return axios.patch(`/programs/${programId}`, programPatchParameters, options);
};

/**
 * @summary ProofOfAuthorizationRequestSubmissionPatch
 */
export const proofOfAuthorizationRequestSubmissionPatch = <
  TData extends AxiosResponse<ProofOfAuthorizationRequestSubmissionPatchResponse>,
>(
  proofOfAuthorizationRequestSubmissionId: string,
  proofOfAuthorizationRequestSubmissionPatchParameters: ProofOfAuthorizationRequestSubmissionPatchParameters,
  options?: AxiosRequestConfig,
): Promise<TData> => {
  const formData = generateFormData(
    proofOfAuthorizationRequestSubmissionPatchParameters,
  );
  return axios.patch(
    `/proof-of-authorization-request-submissions/${proofOfAuthorizationRequestSubmissionId}`,
    formData,
    options,
  );
};

/**
 * @summary RampBusinessAccountOnboardingSurveyList
 */
export const rampBusinessAccountOnboardingSurveyList = <
  TData extends AxiosResponse<RampBusinessAccountOnboardingSurveyListResponse>,
>(
  params?: RampBusinessAccountOnboardingSurveyListParams,
  options?: AxiosRequestConfig,
): Promise<TData> => {
  return axios.get(`/ramp_business_account_onboarding_surveys`, {
    ...options,
    params: { ...params, ...options?.params },
  });
};

/**
 * @summary RampBusinessAccountOnboardingSurveyGet
 */
export const rampBusinessAccountOnboardingSurveyGet = <
  TData extends AxiosResponse<RampBusinessAccountOnboardingSurveyGetResponse>,
>(
  rampBusinessAccountOnboardingSurveyId: string,
  options?: AxiosRequestConfig,
): Promise<TData> => {
  return axios.get(
    `/ramp_business_account_onboarding_surveys/${rampBusinessAccountOnboardingSurveyId}`,
    options,
  );
};

/**
 * @summary RealTimePaymentsPrefundedPositionOverviewGet
 */
export const realTimePaymentsPrefundedPositionOverviewGet = <
  TData extends AxiosResponse<RealTimePaymentsPrefundedPositionOverviewGetResponse>,
>(
  params: RealTimePaymentsPrefundedPositionOverviewGetParams,
  options?: AxiosRequestConfig,
): Promise<TData> => {
  return axios.get(`/real-time-payments-prefunded-position-overview`, {
    ...options,
    params: { ...params, ...options?.params },
  });
};

/**
 * @summary RegisterPost
 */
export const registerPost = <TData extends AxiosResponse<RegisterPostResponse>>(
  registerPostParameters: RegisterPostParameters,
  options?: AxiosRequestConfig,
): Promise<TData> => {
  return axios.post(`/register`, registerPostParameters, options);
};

/**
 * @summary ResultEvaluationList
 */
export const resultEvaluationList = <
  TData extends AxiosResponse<ResultEvaluationListResponse>,
>(
  params: ResultEvaluationListParams,
  options?: AxiosRequestConfig,
): Promise<TData> => {
  return axios.get(`/result_evaluations`, {
    ...options,
    params: { ...params, ...options?.params },
  });
};

/**
 * @summary ResultStatusGet
 */
export const resultStatusGet = <TData extends AxiosResponse<ResultStatusGetResponse>>(
  resultStatusId: string,
  options?: AxiosRequestConfig,
): Promise<TData> => {
  return axios.get(`/result_status/${resultStatusId}`, options);
};

/**
 * @summary ResultStatusList
 */
export const resultStatusList = <
  TData extends AxiosResponse<ResultStatusListResponse>,
>(
  params?: ResultStatusListParams,
  options?: AxiosRequestConfig,
): Promise<TData> => {
  return axios.get(`/result_statuses`, {
    ...options,
    params: { ...params, ...options?.params },
  });
};

/**
 * @summary ResultSummaryGet
 */
export const resultSummaryGet = <
  TData extends AxiosResponse<ResultSummaryGetResponse>,
>(
  options?: AxiosRequestConfig,
): Promise<TData> => {
  return axios.get(`/result_summary`, options);
};

/**
 * @summary ResultDelete
 */
export const resultDelete = <TData extends AxiosResponse<ResultDeleteResponse>>(
  params: ResultDeleteParams,
  options?: AxiosRequestConfig,
): Promise<TData> => {
  return axios.delete(`/results`, {
    ...options,
    params: { ...params, ...options?.params },
  });
};

/**
 * @summary ResultList
 */
export const resultList = <TData extends AxiosResponse<ResultListResponse>>(
  options?: AxiosRequestConfig,
): Promise<TData> => {
  return axios.get(`/results`, options);
};

/**
 * @summary ResultPost
 */
export const resultPost = <TData extends AxiosResponse<ResultPostResponse>>(
  resultPostParameters: ResultPostParameters,
  options?: AxiosRequestConfig,
): Promise<TData> => {
  return axios.post(`/results`, resultPostParameters, options);
};

/**
 * @summary ResultRetryDirectivePost
 */
export const resultRetryDirectivePost = <
  TData extends AxiosResponse<ResultRetryDirectivePostResponse>,
>(
  resultRetryDirectivePostParameters: ResultRetryDirectivePostParameters,
  options?: AxiosRequestConfig,
): Promise<TData> => {
  return axios.post(
    `/results/retry`,
    resultRetryDirectivePostParameters,
    options,
  );
};

/**
 * @summary ResultSuppressDirectivePost
 */
export const resultSuppressDirectivePost = <
  TData extends AxiosResponse<ResultSuppressDirectivePostResponse>,
>(
  resultSuppressDirectivePostParameters: ResultSuppressDirectivePostParameters,
  options?: AxiosRequestConfig,
): Promise<TData> => {
  return axios.post(
    `/results/suppress`,
    resultSuppressDirectivePostParameters,
    options,
  );
};

/**
 * @summary RoleList
 */
export const roleList = <TData extends AxiosResponse<RoleListResponse>>(
  params?: RoleListParams,
  options?: AxiosRequestConfig,
): Promise<TData> => {
  return axios.get(`/roles`, {
    ...options,
    params: { ...params, ...options?.params },
  });
};

/**
 * @summary RoutingNumberList
 */
export const routingNumberList = <
  TData extends AxiosResponse<RoutingNumberListResponse>,
>(
  params: RoutingNumberListParams,
  options?: AxiosRequestConfig,
): Promise<TData> => {
  return axios.get(`/routing-numbers`, {
    ...options,
    params: { ...params, ...options?.params },
  });
};

/**
 * @summary RoutingNumberPatch
 */
export const routingNumberPatch = <
  TData extends AxiosResponse<RoutingNumberPatchResponse>,
>(
  routingNumberId: string,
  routingNumberPatchParameters: RoutingNumberPatchParameters,
  options?: AxiosRequestConfig,
): Promise<TData> => {
  return axios.patch(
    `/routing-numbers/${routingNumberId}`,
    routingNumberPatchParameters,
    options,
  );
};

/**
 * @summary SanctionsScreeningHitList
 */
export const sanctionsScreeningHitList = <
  TData extends AxiosResponse<SanctionsScreeningHitListResponse>,
>(
  params?: SanctionsScreeningHitListParams,
  options?: AxiosRequestConfig,
): Promise<TData> => {
  return axios.get(`/sanctions_screening_hits`, {
    ...options,
    params: { ...params, ...options?.params },
  });
};

/**
 * @summary SanctionsScreeningHitPatch
 */
export const sanctionsScreeningHitPatch = <
  TData extends AxiosResponse<SanctionsScreeningHitPatchResponse>,
>(
  hitId: string,
  sanctionsScreeningHitPatchParameters: SanctionsScreeningHitPatchParameters,
  options?: AxiosRequestConfig,
): Promise<TData> => {
  return axios.patch(
    `/sanctions_screening_hits/${hitId}`,
    sanctionsScreeningHitPatchParameters,
    options,
  );
};

/**
 * @summary SanctionsScreeningReviewRecordGet
 */
export const sanctionsScreeningReviewRecordGet = <
  TData extends AxiosResponse<SanctionsScreeningReviewRecordGetResponse>,
>(
  params: SanctionsScreeningReviewRecordGetParams,
  options?: AxiosRequestConfig,
): Promise<TData> => {
  return axios.get(`/sanctions_screening_review_records`, {
    ...options,
    params: { ...params, ...options?.params },
  });
};

/**
 * @summary SanctionsScreeningReviewList
 */
export const sanctionsScreeningReviewList = <
  TData extends AxiosResponse<SanctionsScreeningReviewListResponse>,
>(
  params?: SanctionsScreeningReviewListParams,
  options?: AxiosRequestConfig,
): Promise<TData> => {
  return axios.get(`/sanctions_screening_reviews`, {
    ...options,
    params: { ...params, ...options?.params },
  });
};

/**
 * @summary SanctionsScreeningReviewGet
 */
export const sanctionsScreeningReviewGet = <
  TData extends AxiosResponse<SanctionsScreeningReviewGetResponse>,
>(
  reviewId: string,
  options?: AxiosRequestConfig,
): Promise<TData> => {
  return axios.get(`/sanctions_screening_reviews/${reviewId}`, options);
};

/**
 * @summary SanctionsScreeningReviewPatch
 */
export const sanctionsScreeningReviewPatch = <
  TData extends AxiosResponse<SanctionsScreeningReviewPatchResponse>,
>(
  reviewId: string,
  sanctionsScreeningReviewPatchParameters: SanctionsScreeningReviewPatchParameters,
  options?: AxiosRequestConfig,
): Promise<TData> => {
  return axios.patch(
    `/sanctions_screening_reviews/${reviewId}`,
    sanctionsScreeningReviewPatchParameters,
    options,
  );
};

/**
 * @summary SanctionsScreeningTriggerTokenList
 */
export const sanctionsScreeningTriggerTokenList = <
  TData extends AxiosResponse<SanctionsScreeningTriggerTokenListResponse>,
>(
  params?: SanctionsScreeningTriggerTokenListParams,
  options?: AxiosRequestConfig,
): Promise<TData> => {
  return axios.get(`/sanctions_screening_trigger_tokens`, {
    ...options,
    params: { ...params, ...options?.params },
  });
};

/**
 * @summary SanctionsScreeningTriggerTokenPost
 */
export const sanctionsScreeningTriggerTokenPost = <
  TData extends AxiosResponse<SanctionsScreeningTriggerTokenPostResponse>,
>(
  sanctionsScreeningTriggerTokenPostParameters: SanctionsScreeningTriggerTokenPostParameters,
  options?: AxiosRequestConfig,
): Promise<TData> => {
  return axios.post(
    `/sanctions_screening_trigger_tokens`,
    sanctionsScreeningTriggerTokenPostParameters,
    options,
  );
};

/**
 * @summary SanctionsScreeningTriggerTokenArchiveDirectivePost
 */
export const sanctionsScreeningTriggerTokenArchiveDirectivePost = <
  TData extends AxiosResponse<SanctionsScreeningTriggerTokenArchiveDirectivePostResponse>,
>(
  triggerTokenId: string,
  options?: AxiosRequestConfig,
): Promise<TData> => {
  return axios.post(
    `/sanctions_screening_trigger_tokens/${triggerTokenId}/archive`,
    undefined,
    options,
  );
};

/**
 * @summary SessionDelete
 */
export const sessionDelete = <TData extends AxiosResponse<SessionDeleteResponse>>(
  options?: AxiosRequestConfig,
): Promise<TData> => {
  return axios.delete(`/session`, options);
};

/**
 * @summary SessionGet
 */
export const sessionGet = <TData extends AxiosResponse<SessionGetResponse>>(
  options?: AxiosRequestConfig,
): Promise<TData> => {
  return axios.get(`/session`, options);
};

/**
 * @summary SessionPost
 */
export const sessionPost = <TData extends AxiosResponse<SessionPostResponse>>(
  sessionPostParameters: SessionPostParameters,
  options?: AxiosRequestConfig,
): Promise<TData> => {
  return axios.post(`/session`, sessionPostParameters, options);
};

/**
 * @summary TokenizationMessagePost
 */
export const tokenizationMessagePost = <
  TData extends AxiosResponse<TokenizationMessagePostResponse>,
>(
  tokenizationMessagePostParameters: TokenizationMessagePostParameters,
  options?: AxiosRequestConfig,
): Promise<TData> => {
  return axios.post(
    `/tokenization-message`,
    tokenizationMessagePostParameters,
    options,
  );
};

/**
 * @summary TransactionGet
 */
export const transactionGet = <TData extends AxiosResponse<TransactionGetResponse>>(
  transactionId: string,
  options?: AxiosRequestConfig,
): Promise<TData> => {
  return axios.get(`/transactions/${transactionId}`, options);
};

/**
 * @summary TransactionsByACHTraceNumberList
 */
export const transactionsByACHTraceNumberList = <
  TData extends AxiosResponse<TransactionsByACHTraceNumberListResponse>,
>(
  params: TransactionsByACHTraceNumberListParams,
  options?: AxiosRequestConfig,
): Promise<TData> => {
  return axios.get(`/transactions_by_ach_trace_number`, {
    ...options,
    params: { ...params, ...options?.params },
  });
};

/**
 * @summary TransferReviewingRejectDirectivePost
 */
export const transferReviewingRejectDirectivePost = <
  TData extends AxiosResponse<TransferReviewingRejectDirectivePostResponse>,
>(
  transferId: string,
  transferReviewingRejectDirectivePostParameters: TransferReviewingRejectDirectivePostParameters,
  options?: AxiosRequestConfig,
): Promise<TData> => {
  return axios.post(
    `/transfer-reviewing/${transferId}/reject`,
    transferReviewingRejectDirectivePostParameters,
    options,
  );
};

/**
 * @summary TransferReviewingReleaseDirectivePost
 */
export const transferReviewingReleaseDirectivePost = <
  TData extends AxiosResponse<TransferReviewingReleaseDirectivePostResponse>,
>(
  transferId: string,
  transferReviewingReleaseDirectivePostParameters: TransferReviewingReleaseDirectivePostParameters,
  options?: AxiosRequestConfig,
): Promise<TData> => {
  return axios.post(
    `/transfer-reviewing/${transferId}/release`,
    transferReviewingReleaseDirectivePostParameters,
    options,
  );
};

/**
 * @summary TransferReviewList
 */
export const transferReviewList = <
  TData extends AxiosResponse<TransferReviewListResponse>,
>(
  params?: TransferReviewListParams,
  options?: AxiosRequestConfig,
): Promise<TData> => {
  return axios.get(`/transfer-reviews`, {
    ...options,
    params: { ...params, ...options?.params },
  });
};

/**
 * @summary TransferGet
 */
export const transferGet = <TData extends AxiosResponse<TransferGetResponse>>(
  transferId: string,
  options?: AxiosRequestConfig,
): Promise<TData> => {
  return axios.get(`/transfers/${transferId}`, options);
};

/**
 * @summary TransfersByCheckNumberList
 */
export const transfersByCheckNumberList = <
  TData extends AxiosResponse<TransfersByCheckNumberListResponse>,
>(
  params: TransfersByCheckNumberListParams,
  options?: AxiosRequestConfig,
): Promise<TData> => {
  return axios.get(`/transfers_by_check_number`, {
    ...options,
    params: { ...params, ...options?.params },
  });
};

/**
 * @summary UnusualActivityReportList
 */
export const unusualActivityReportList = <
  TData extends AxiosResponse<UnusualActivityReportListResponse>,
>(
  params?: UnusualActivityReportListParams,
  options?: AxiosRequestConfig,
): Promise<TData> => {
  return axios.get(`/unusual-activity-reports`, {
    ...options,
    params: { ...params, ...options?.params },
  });
};

/**
 * @summary UnusualActivityReportGet
 */
export const unusualActivityReportGet = <
  TData extends AxiosResponse<UnusualActivityReportGetResponse>,
>(
  unusualActivityReportId: string,
  options?: AxiosRequestConfig,
): Promise<TData> => {
  return axios.get(
    `/unusual_activity_reports/${unusualActivityReportId}`,
    options,
  );
};

/**
 * @summary UserSessionList
 */
export const userSessionList = <TData extends AxiosResponse<UserSessionListResponse>>(
  params?: UserSessionListParams,
  options?: AxiosRequestConfig,
): Promise<TData> => {
  return axios.get(`/user-sessions`, {
    ...options,
    params: { ...params, ...options?.params },
  });
};

/**
 * @summary UserList
 */
export const userList = <TData extends AxiosResponse<UserListResponse>>(
  params?: UserListParams,
  options?: AxiosRequestConfig,
): Promise<TData> => {
  return axios.get(`/users`, {
    ...options,
    params: { ...params, ...options?.params },
  });
};

/**
 * @summary UserGet
 */
export const userGet = <TData extends AxiosResponse<UserGetResponse>>(
  userId: string,
  options?: AxiosRequestConfig,
): Promise<TData> => {
  return axios.get(`/users/${userId}`, options);
};

/**
 * @summary UserImpersonatePost
 */
export const userImpersonatePost = <
  TData extends AxiosResponse<UserImpersonatePostResponse>,
>(
  userId: string,
  options?: AxiosRequestConfig,
): Promise<TData> => {
  return axios.post(`/users/${userId}/impersonate`, undefined, options);
};

/**
 * @summary ValidPhysicalCardImageFileList
 */
export const validPhysicalCardImageFileList = <
  TData extends AxiosResponse<ValidPhysicalCardImageFileListResponse>,
>(
  params?: ValidPhysicalCardImageFileListParams,
  options?: AxiosRequestConfig,
): Promise<TData> => {
  return axios.get(`/valid_physical_card_image_files`, {
    ...options,
    params: { ...params, ...options?.params },
  });
};

export type AuthenticationPostResult =
  AxiosResponse<AuthenticationPostResponse>;
export type ChallengePostResult = AxiosResponse<ChallengePostResponse>;
export type AccountNumberListResult = AxiosResponse<AccountNumberListResponse>;
export type AccountNumberGetResult = AxiosResponse<AccountNumberGetResponse>;
export type AccountBalanceGetResult = AxiosResponse<AccountBalanceGetResponse>;
export type AccountByAccountNumberGetResult =
  AxiosResponse<AccountByAccountNumberGetResponse>;
export type AccountListResult = AxiosResponse<AccountListResponse>;
export type AccountGetResult = AxiosResponse<AccountGetResponse>;
export type ACHHistoricalOriginationVolumeForRoutingNumberGetResult =
  AxiosResponse<ACHHistoricalOriginationVolumeForRoutingNumberGetResponse>;
export type ACHOriginationVolumeMonitoringGetResult =
  AxiosResponse<ACHOriginationVolumeMonitoringGetResponse>;
export type ACHCompanyIDLookupGetResult =
  AxiosResponse<ACHCompanyIDLookupGetResponse>;
export type ACHTransferLookupGetResult =
  AxiosResponse<ACHTransferLookupGetResponse>;
export type APIInboundFileViewDirectiveGetResult =
  AxiosResponse<APIInboundFileViewDirectiveGetResponse>;
export type AttestationListResult = AxiosResponse<AttestationListResponse>;
export type AttestationGetResult = AxiosResponse<AttestationGetResponse>;
export type AttestationPatchResult = AxiosResponse<AttestationPatchResponse>;
export type AuthorizationMessagePostResult =
  AxiosResponse<AuthorizationMessagePostResponse>;
export type BankFeeStatementListResult =
  AxiosResponse<BankFeeStatementListResponse>;
export type BankFeeStatementGetResult =
  AxiosResponse<BankFeeStatementGetResponse>;
export type BankFeeListResult = AxiosResponse<BankFeeListResponse>;
export type BankProgramListResult = AxiosResponse<BankProgramListResponse>;
export type BankProgramGetResult = AxiosResponse<BankProgramGetResponse>;
export type BankProgramCurrentFeePlanGetResult =
  AxiosResponse<BankProgramCurrentFeePlanGetResponse>;
export type BeneficialOwnerGetResult =
  AxiosResponse<BeneficialOwnerGetResponse>;
export type BeneficialOwnerRelationshipListResult =
  AxiosResponse<BeneficialOwnerRelationshipListResponse>;
export type BookkeepingAccountListResult =
  AxiosResponse<BookkeepingAccountListResponse>;
export type BookkeepingAccountGetResult =
  AxiosResponse<BookkeepingAccountGetResponse>;
export type BookkeepingEntryListResult =
  AxiosResponse<BookkeepingEntryListResponse>;
export type CardDeclineGetResult = AxiosResponse<CardDeclineGetResponse>;
export type CardPaymentGetResult = AxiosResponse<CardPaymentGetResponse>;
export type CardListResult = AxiosResponse<CardListResponse>;
export type CardGetResult = AxiosResponse<CardGetResponse>;
export type CashReconciliationOverviewGetResult =
  AxiosResponse<CashReconciliationOverviewGetResponse>;
export type CashReconciliationUnreconciledExpectedCashTransactionTupleDetailsGetResult =
  AxiosResponse<CashReconciliationUnreconciledExpectedCashTransactionTupleDetailsGetResponse>;
export type CashReconciliationUnreconciledExpectedCashTransactionTupleListResult =
  AxiosResponse<CashReconciliationUnreconciledExpectedCashTransactionTupleListResponse>;
export type CheckItemAllocationInformationGetResult =
  AxiosResponse<CheckItemAllocationInformationGetResponse>;
export type CheckItemAllocationSearchDirectiveGetResult =
  AxiosResponse<CheckItemAllocationSearchDirectiveGetResponse>;
export type CheckItemAllocationPostResult =
  AxiosResponse<CheckItemAllocationPostResponse>;
export type CheckItemAllocationReturnDirectivePostResult =
  AxiosResponse<CheckItemAllocationReturnDirectivePostResponse>;
export type CheckDepositContextGetResult =
  AxiosResponse<CheckDepositContextGetResponse>;
export type CheckDepositListResult = AxiosResponse<CheckDepositListResponse>;
export type CheckDepositGetResult = AxiosResponse<CheckDepositGetResponse>;
export type CheckDepositRejectDirectivePostResult =
  AxiosResponse<CheckDepositRejectDirectivePostResponse>;
export type CheckDepositApproveDirectivePostResult =
  AxiosResponse<CheckDepositApproveDirectivePostResponse>;
export type CheckItemAllocationQueueItemListResult =
  AxiosResponse<CheckItemAllocationQueueItemListResponse>;
export type CheckItemImageViewDataViewDirectiveGetResult =
  AxiosResponse<CheckItemImageViewDataViewDirectiveGetResponse>;
export type CheckTransferLookupGetResult =
  AxiosResponse<CheckTransferLookupGetResponse>;
export type ClearingMessagePostResult =
  AxiosResponse<ClearingMessagePostResponse>;
export type CommercialOnboardingSurveyListResult =
  AxiosResponse<CommercialOnboardingSurveyListResponse>;
export type CommercialOnboardingSurveyGetResult =
  AxiosResponse<CommercialOnboardingSurveyGetResponse>;
export type ComplaintEntryListResult =
  AxiosResponse<ComplaintEntryListResponse>;
export type ComplaintEntryGetResult = AxiosResponse<ComplaintEntryGetResponse>;
export type CompletedTransactionListResult =
  AxiosResponse<CompletedTransactionListResponse>;
export type ComplianceDocumentListResult =
  AxiosResponse<ComplianceDocumentListResponse>;
export type ComplianceDocumentDownloadResult =
  AxiosResponse<ComplianceDocumentDownloadResponse>;
export type ComplianceDocumentDeleteDirectivePostResult =
  AxiosResponse<ComplianceDocumentDeleteDirectivePostResponse>;
export type ConsolidatedScreeningListEntryGetResult =
  AxiosResponse<ConsolidatedScreeningListEntryGetResponse>;
export type ConsumerOnboardingSurveyListResult =
  AxiosResponse<ConsumerOnboardingSurveyListResponse>;
export type ConsumerOnboardingSurveyGetResult =
  AxiosResponse<ConsumerOnboardingSurveyGetResponse>;
export type ControlConfigurationListResult =
  AxiosResponse<ControlConfigurationListResponse>;
export type ControlConfigurationGetResult =
  AxiosResponse<ControlConfigurationGetResponse>;
export type ControlEvaluationListResult =
  AxiosResponse<ControlEvaluationListResponse>;
export type ControlRecordListResult = AxiosResponse<ControlRecordListResponse>;
export type ControlRecordGetResult = AxiosResponse<ControlRecordGetResponse>;
export type CustomerIdentificationProgramTestingBatchSummaryGetResult =
  AxiosResponse<CustomerIdentificationProgramTestingBatchSummaryGetResponse>;
export type CustomerIdentificationProgramTestingBatchListResult =
  AxiosResponse<CustomerIdentificationProgramTestingBatchListResponse>;
export type CustomerIdentificationProgramTestingBatchGetResult =
  AxiosResponse<CustomerIdentificationProgramTestingBatchGetResponse>;
export type CustomerIdentificationProgramTestingEntryListResult =
  AxiosResponse<CustomerIdentificationProgramTestingEntryListResponse>;
export type CustomerIdentificationProgramTestingEntryGetResult =
  AxiosResponse<CustomerIdentificationProgramTestingEntryGetResponse>;
export type DeclinedTransactionGetResult =
  AxiosResponse<DeclinedTransactionGetResponse>;
export type DigitalWalletImageFileListResult =
  AxiosResponse<DigitalWalletImageFileListResponse>;
export type DigitalWalletImageFileApprovePostResult =
  AxiosResponse<DigitalWalletImageFileApprovePostResponse>;
export type DigitalWalletImageFileRejectPostResult =
  AxiosResponse<DigitalWalletImageFileRejectPostResponse>;
export type DocumentRequestCancelResult =
  AxiosResponse<DocumentRequestCancelResponse>;
export type DocumentRequestScheduleListResult =
  AxiosResponse<DocumentRequestScheduleListResponse>;
export type DocumentRequestSchedulePostResult =
  AxiosResponse<DocumentRequestSchedulePostResponse>;
export type DocumentRequestListResult =
  AxiosResponse<DocumentRequestListResponse>;
export type DocumentRequestGetResult =
  AxiosResponse<DocumentRequestGetResponse>;
export type EmailPreviewGetResult = AxiosResponse<EmailPreviewGetResponse>;
export type EntityGetResult = AxiosResponse<EntityGetResponse>;
export type EntityPatchResult = AxiosResponse<EntityPatchResponse>;
export type EntityByNameListResult = AxiosResponse<EntityByNameListResponse>;
export type EntityReferenceListResult =
  AxiosResponse<EntityReferenceListResponse>;
export type EntityClusterListResult = AxiosResponse<EntityClusterListResponse>;
export type EntityClusterGetResult = AxiosResponse<EntityClusterGetResponse>;
export type EntityClusterReviewPostResult =
  AxiosResponse<EntityClusterReviewPostResponse>;
export type EntitySupplementalDocumentSubmissionEvaluationGetResult =
  AxiosResponse<EntitySupplementalDocumentSubmissionEvaluationGetResponse>;
export type FeePlanListResult = AxiosResponse<FeePlanListResponse>;
export type FeeStatementListResult = AxiosResponse<FeeStatementListResponse>;
export type FlagsGetResult = AxiosResponse<FlagsGetResponse>;
export type GroupByNicknameGetResult =
  AxiosResponse<GroupByNicknameGetResponse>;
export type GroupListResult = AxiosResponse<GroupListResponse>;
export type GroupGetResult = AxiosResponse<GroupGetResponse>;
export type GroupPatchResult = AxiosResponse<GroupPatchResponse>;
export type GroupGateListResult = AxiosResponse<GroupGateListResponse>;
export type GroupGatePostResult = AxiosResponse<GroupGatePostResponse>;
export type GroupGateDeleteResult = AxiosResponse<GroupGateDeleteResponse>;
export type IdentityDocumentListResult =
  AxiosResponse<IdentityDocumentListResponse>;
export type IdentityDocumentGetResult =
  AxiosResponse<IdentityDocumentGetResponse>;
export type IdentityDocumentPatchResult =
  AxiosResponse<IdentityDocumentPatchResponse>;
export type InboundCheckGetResult = AxiosResponse<InboundCheckGetResponse>;
export type InboundMailItemListResult =
  AxiosResponse<InboundMailItemListResponse>;
export type InitialStateGetResult = AxiosResponse<InitialStateGetResponse>;
export type InternalFileViewDirectiveGetResult =
  AxiosResponse<InternalFileViewDirectiveGetResponse>;
export type LateReturnRequestListResult =
  AxiosResponse<LateReturnRequestListResponse>;
export type LateReturnRequestPostResult =
  AxiosResponse<LateReturnRequestPostResponse>;
export type LateReturnRequestGetResult =
  AxiosResponse<LateReturnRequestGetResponse>;
export type ManagementInformationSystemAccountsListResult =
  AxiosResponse<ManagementInformationSystemAccountsListResponse>;
export type ManagementInformationSystemBalancesListResult =
  AxiosResponse<ManagementInformationSystemBalancesListResponse>;
export type ManagementInformationSystemProgramCountsListResult =
  AxiosResponse<ManagementInformationSystemProgramCountsListResponse>;
export type ManagementInformationSystemRevenuesListResult =
  AxiosResponse<ManagementInformationSystemRevenuesListResponse>;
export type ManagementInformationSystemRiskMetricsListResult =
  AxiosResponse<ManagementInformationSystemRiskMetricsListResponse>;
export type ManagementInformationSystemVolumesListResult =
  AxiosResponse<ManagementInformationSystemVolumesListResponse>;
export type ManualTaskPreviewGetResult =
  AxiosResponse<ManualTaskPreviewGetResponse>;
export type ManualTaskRunListResult = AxiosResponse<ManualTaskRunListResponse>;
export type ManualTaskRunPostResult = AxiosResponse<ManualTaskRunPostResponse>;
export type ManualTaskRunGetResult = AxiosResponse<ManualTaskRunGetResponse>;
export type ManualTaskListResult = AxiosResponse<ManualTaskListResponse>;
export type ManualAssuranceGetResult =
  AxiosResponse<ManualAssuranceGetResponse>;
export type OAuthApplicationListResult =
  AxiosResponse<OAuthApplicationListResponse>;
export type OAuthApplicationGetResult =
  AxiosResponse<OAuthApplicationGetResponse>;
export type OAuthConnectionListResult =
  AxiosResponse<OAuthConnectionListResponse>;
export type ObjectGetResult = AxiosResponse<ObjectGetResponse>;
export type ObligationDocumentListResult =
  AxiosResponse<ObligationDocumentListResponse>;
export type ObligationDocumentGetResult =
  AxiosResponse<ObligationDocumentGetResponse>;
export type OperationListResult = AxiosResponse<OperationListResponse>;
export type OperationPostResult = AxiosResponse<OperationPostResponse>;
export type OperationPatchResult = AxiosResponse<OperationPatchResponse>;
export type OperatorGetResult = AxiosResponse<OperatorGetResponse>;
export type OperatorExportPostResult =
  AxiosResponse<OperatorExportPostResponse>;
export type OperatorFileListResult = AxiosResponse<OperatorFileListResponse>;
export type OperatorFilePostResult = AxiosResponse<OperatorFilePostResponse>;
export type OperatorFileViewDirectiveGetResult =
  AxiosResponse<OperatorFileViewDirectiveGetResponse>;
export type OperatorNoteListResult = AxiosResponse<OperatorNoteListResponse>;
export type OperatorNotePostResult = AxiosResponse<OperatorNotePostResponse>;
export type OperatorTagListResult = AxiosResponse<OperatorTagListResponse>;
export type OperatorTagPostResult = AxiosResponse<OperatorTagPostResponse>;
export type OperatorConfirmOneTimePasswordPostResult =
  AxiosResponse<OperatorConfirmOneTimePasswordPostResponse>;
export type OperatorOneTimePasswordProvisioningURIGetResult =
  AxiosResponse<OperatorOneTimePasswordProvisioningURIGetResponse>;
export type OperatorDeleteResult = AxiosResponse<OperatorDeleteResponse>;
export type OperatorManualQueueItemListResult =
  AxiosResponse<OperatorManualQueueItemListResponse>;
export type OperatorManualQueueItemPatchResult =
  AxiosResponse<OperatorManualQueueItemPatchResponse>;
export type OperatorListResult = AxiosResponse<OperatorListResponse>;
export type OutboundACHFilesOverviewGetResult =
  AxiosResponse<OutboundACHFilesOverviewGetResponse>;
export type PartnerInvitationListResult =
  AxiosResponse<PartnerInvitationListResponse>;
export type PartnerInvitationPostResult =
  AxiosResponse<PartnerInvitationPostResponse>;
export type PartnerInvitationDeleteResult =
  AxiosResponse<PartnerInvitationDeleteResponse>;
export type PendingTransactionListResult =
  AxiosResponse<PendingTransactionListResponse>;
export type PendingTransactionGetResult =
  AxiosResponse<PendingTransactionGetResponse>;
export type PhysicalCardImageFileListResult =
  AxiosResponse<PhysicalCardImageFileListResponse>;
export type PhysicalCardImageFileApprovePostResult =
  AxiosResponse<PhysicalCardImageFileApprovePostResponse>;
export type PhysicalCardImageFileRejectPostResult =
  AxiosResponse<PhysicalCardImageFileRejectPostResponse>;
export type PlatformComplaintListSubmissionListResult =
  AxiosResponse<PlatformComplaintListSubmissionListResponse>;
export type PlatformComplaintListSubmissionGetResult =
  AxiosResponse<PlatformComplaintListSubmissionGetResponse>;
export type PlatformComplaintListResult =
  AxiosResponse<PlatformComplaintListResponse>;
export type PlatformComplianceMetricsSubmissionListResult =
  AxiosResponse<PlatformComplianceMetricsSubmissionListResponse>;
export type PlatformComplianceMetricsSubmissionGetResult =
  AxiosResponse<PlatformComplianceMetricsSubmissionGetResponse>;
export type PlatformComplianceSubmissionProgramListResult =
  AxiosResponse<PlatformComplianceSubmissionProgramListResponse>;
export type PlatformFinancialReportSubmissionGetResult =
  AxiosResponse<PlatformFinancialReportSubmissionGetResponse>;
export type PlatformUnusualActivityReportListResult =
  AxiosResponse<PlatformUnusualActivityReportListResponse>;
export type PlatformUnusualActivityReportGetResult =
  AxiosResponse<PlatformUnusualActivityReportGetResponse>;
export type PlatformVendorListSubmissionListResult =
  AxiosResponse<PlatformVendorListSubmissionListResponse>;
export type PlatformVendorListSubmissionGetResult =
  AxiosResponse<PlatformVendorListSubmissionGetResponse>;
export type PlatformVendorListResult =
  AxiosResponse<PlatformVendorListResponse>;
export type PlatformVendorGetResult = AxiosResponse<PlatformVendorGetResponse>;
export type PlatformComplaintGetResult =
  AxiosResponse<PlatformComplaintGetResponse>;
export type ProgramACHReturnRateListResult =
  AxiosResponse<ProgramACHReturnRateListResponse>;
export type ProgramACHReturnRateGetResult =
  AxiosResponse<ProgramACHReturnRateGetResponse>;
export type ProgramBalanceGetResult = AxiosResponse<ProgramBalanceGetResponse>;
export type ProgramCreditExposureGetResult =
  AxiosResponse<ProgramCreditExposureGetResponse>;
export type ProgramListResult = AxiosResponse<ProgramListResponse>;
export type ProgramGetResult = AxiosResponse<ProgramGetResponse>;
export type ProgramPatchResult = AxiosResponse<ProgramPatchResponse>;
export type ProofOfAuthorizationRequestSubmissionPatchResult =
  AxiosResponse<ProofOfAuthorizationRequestSubmissionPatchResponse>;
export type RampBusinessAccountOnboardingSurveyListResult =
  AxiosResponse<RampBusinessAccountOnboardingSurveyListResponse>;
export type RampBusinessAccountOnboardingSurveyGetResult =
  AxiosResponse<RampBusinessAccountOnboardingSurveyGetResponse>;
export type RealTimePaymentsPrefundedPositionOverviewGetResult =
  AxiosResponse<RealTimePaymentsPrefundedPositionOverviewGetResponse>;
export type RegisterPostResult = AxiosResponse<RegisterPostResponse>;
export type ResultEvaluationListResult =
  AxiosResponse<ResultEvaluationListResponse>;
export type ResultStatusGetResult = AxiosResponse<ResultStatusGetResponse>;
export type ResultStatusListResult = AxiosResponse<ResultStatusListResponse>;
export type ResultSummaryGetResult = AxiosResponse<ResultSummaryGetResponse>;
export type ResultDeleteResult = AxiosResponse<ResultDeleteResponse>;
export type ResultListResult = AxiosResponse<ResultListResponse>;
export type ResultPostResult = AxiosResponse<ResultPostResponse>;
export type ResultRetryDirectivePostResult =
  AxiosResponse<ResultRetryDirectivePostResponse>;
export type ResultSuppressDirectivePostResult =
  AxiosResponse<ResultSuppressDirectivePostResponse>;
export type RoleListResult = AxiosResponse<RoleListResponse>;
export type RoutingNumberListResult = AxiosResponse<RoutingNumberListResponse>;
export type RoutingNumberPatchResult =
  AxiosResponse<RoutingNumberPatchResponse>;
export type SanctionsScreeningHitListResult =
  AxiosResponse<SanctionsScreeningHitListResponse>;
export type SanctionsScreeningHitPatchResult =
  AxiosResponse<SanctionsScreeningHitPatchResponse>;
export type SanctionsScreeningReviewRecordGetResult =
  AxiosResponse<SanctionsScreeningReviewRecordGetResponse>;
export type SanctionsScreeningReviewListResult =
  AxiosResponse<SanctionsScreeningReviewListResponse>;
export type SanctionsScreeningReviewGetResult =
  AxiosResponse<SanctionsScreeningReviewGetResponse>;
export type SanctionsScreeningReviewPatchResult =
  AxiosResponse<SanctionsScreeningReviewPatchResponse>;
export type SanctionsScreeningTriggerTokenListResult =
  AxiosResponse<SanctionsScreeningTriggerTokenListResponse>;
export type SanctionsScreeningTriggerTokenPostResult =
  AxiosResponse<SanctionsScreeningTriggerTokenPostResponse>;
export type SanctionsScreeningTriggerTokenArchiveDirectivePostResult =
  AxiosResponse<SanctionsScreeningTriggerTokenArchiveDirectivePostResponse>;
export type SessionDeleteResult = AxiosResponse<SessionDeleteResponse>;
export type SessionGetResult = AxiosResponse<SessionGetResponse>;
export type SessionPostResult = AxiosResponse<SessionPostResponse>;
export type TokenizationMessagePostResult =
  AxiosResponse<TokenizationMessagePostResponse>;
export type TransactionGetResult = AxiosResponse<TransactionGetResponse>;
export type TransactionsByACHTraceNumberListResult =
  AxiosResponse<TransactionsByACHTraceNumberListResponse>;
export type TransferReviewingRejectDirectivePostResult =
  AxiosResponse<TransferReviewingRejectDirectivePostResponse>;
export type TransferReviewingReleaseDirectivePostResult =
  AxiosResponse<TransferReviewingReleaseDirectivePostResponse>;
export type TransferReviewListResult =
  AxiosResponse<TransferReviewListResponse>;
export type TransferGetResult = AxiosResponse<TransferGetResponse>;
export type TransfersByCheckNumberListResult =
  AxiosResponse<TransfersByCheckNumberListResponse>;
export type UnusualActivityReportListResult =
  AxiosResponse<UnusualActivityReportListResponse>;
export type UnusualActivityReportGetResult =
  AxiosResponse<UnusualActivityReportGetResponse>;
export type UserSessionListResult = AxiosResponse<UserSessionListResponse>;
export type UserListResult = AxiosResponse<UserListResponse>;
export type UserGetResult = AxiosResponse<UserGetResponse>;
export type UserImpersonatePostResult =
  AxiosResponse<UserImpersonatePostResponse>;
export type ValidPhysicalCardImageFileListResult =
  AxiosResponse<ValidPhysicalCardImageFileListResponse>;

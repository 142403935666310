import React, { useMemo } from 'react';
import { PageLayout } from '../deprecated/PageLayout';
import { useLateReturnRequestListInfinite } from 'src/hooks/reactQuery';
import { StyledLink } from 'shared/components/StyledLink';
import { LateReturnRequestsTable } from '../tables/LateReturnRequestsTable';
import { TableStateWrapper } from 'shared/components/TableStateWrapper';
import { compact } from 'lodash';
import { TableFilter } from 'shared/components/Table/filters';
import { makeGroupFilter, makeKeywordFilter } from 'src/lib/tableFilterHelpers';
import { useSearchParamsState } from 'shared/hooks/useTypedSearchParamsState';
import { ROUTES, buildPath } from 'src/lib/routes';

export const LateReturnRequestListPage = () => {
  const [groupFilter, setGroupFilter] = useSearchParamsState(
    ROUTES.LATE_RETURN_REQUESTS_LIST,
    'group'
  );

  const [keywordFilter, setKeywordFilter] = useSearchParamsState(
    ROUTES.LATE_RETURN_REQUESTS_LIST,
    'keyword'
  );

  const filters: TableFilter[] = useMemo(
    () =>
      compact([
        makeGroupFilter(groupFilter, setGroupFilter),
        makeKeywordFilter(keywordFilter, setKeywordFilter),
      ]),
    [groupFilter, keywordFilter, setGroupFilter, setKeywordFilter]
  );
  const listResult = useLateReturnRequestListInfinite({
    group_ids: groupFilter.length > 0 ? groupFilter : undefined,
    keyword:
      keywordFilter && keywordFilter.length > 0 ? keywordFilter : undefined,
  });

  return (
    <PageLayout
      headline={'Late Return Requests'}
      action={
        <StyledLink
          href={buildPath(ROUTES.LATE_RETURN_REQUESTS_CREATE, {})}
          style="underline"
        >
          Create late return request
        </StyledLink>
      }
      breadcrumbs={[{ text: 'Late Return Requests' }]}
    >
      <TableStateWrapper
        {...listResult}
        table={LateReturnRequestsTable}
        style="primary"
        emptyTitle="No late return requests found"
        emptySubtitle="Late return requests will appear here when they are created"
        filters={filters}
      />
    </PageLayout>
  );
};

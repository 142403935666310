import {
  AccountNumberGetResponseStatus,
  AccountGetResponseStatus,
  GroupGetResponseStatus,
  EntityGetResponseStatus,
  CardGetResponseStatus,
} from 'src/build/operations';

export const statusIndicatorColors: Record<string, string> = {
  red: 'bg-danger-strong',
  blue: 'bg-info-strong',
  green: 'bg-success-strong',
  yellow: 'bg-warning-strong',
  purple: 'bg-additive-strong',
  gray: 'bg-disabled-strong',
  none: 'bg-transparent',
};

export type StatusIndicatorColor = keyof typeof statusIndicatorColors;

export const accountStatusIndicatorColor: Record<
  AccountGetResponseStatus,
  StatusIndicatorColor
> = {
  open: 'none',
  closed: 'red',
};

export const accountNumberStatusIndicatorColor: Record<
  AccountNumberGetResponseStatus,
  StatusIndicatorColor
> = {
  active: 'none',
  disabled: 'yellow',
  canceled: 'red',
};

export const cardStatusIndicatorColor: Record<
  CardGetResponseStatus,
  StatusIndicatorColor
> = {
  active: 'none',
  disabled: 'yellow',
  canceled: 'red',
};

export const entityStatusIndicatorColor: Record<
  EntityGetResponseStatus,
  StatusIndicatorColor
> = {
  active: 'none',
  archived: 'gray',
  disabled: 'gray',
};

export const groupStatusIndicatorColor: Record<
  GroupGetResponseStatus,
  StatusIndicatorColor
> = {
  active: 'none',
  pending_activating: 'gray',
  offboarded: 'gray',
  rejected: 'gray',
};

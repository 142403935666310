import { PageLayout } from '../deprecated/PageLayout';
import { SegmentedControl } from 'shared/components/SegmentedControl';
import { Outlet, useNavigate } from 'react-router';

export const UnusualActivityReportsWrapperPage = () => {
  const navigate = useNavigate();

  const segments = [
    {
      slug: '/unusual_activity_reports/platform',
      title: 'Platform',
    },
    {
      slug: '/unusual_activity_reports/direct',
      title: 'Direct',
    },
  ] as const;

  const [, segmentIndex] = location.pathname.includes('platform')
    ? [segments[0], 0]
    : [segments[1], 1];

  return (
    <PageLayout
      breadcrumbs={[]}
      headline={'Unusual activity reports'}
      segmentedControl={
        <SegmentedControl
          segments={segments}
          selectedIndex={segmentIndex}
          onSelectRequest={(idx) => navigate(segments[idx].slug)}
        />
      }
    >
      <Outlet />
    </PageLayout>
  );
};

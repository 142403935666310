import moment from 'moment';
import {
  InParams,
  InSearchParams,
  Route,
  number,
  route,
  string,
} from 'react-router-typesafe-routes/dom';
import {
  AccountGetResponseBank,
  AccountListResponseDataItemStatus,
  AccountNumberGetResponseStatus,
  BookkeepingAccountListComplianceCategoriesItem,
  CardGetResponseStatus,
  ControlConfigurationGetResponseResult,
  ControlConfigurationListCategoriesItem,
  ControlRecordGetResponseCategory,
  ControlRecordGetResponseResult,
  CustomerIdentificationProgramTestingEntryGetResponseTestingStatus,
  DocumentRequestListPlatformPeriodicTemplatedSpreadsheetReportRequestCategoriesItem,
  DocumentRequestListResponseDataItemStatus,
  DocumentRequestListSourceCategoriesItem,
  EntityClusterListCategoriesItem,
  EntityClusterListStatusesItem,
  EntityGetResponseReviewStatus,
  EntityGetResponseStatus,
  EntityGetResponseStructure,
  GroupListStatusItem,
  ManagementInformationSystemRevenuesListPeriod,
  OperatorManualQueueItemListStatusItem,
  PartnerInvitationPostParametersBank,
  PartnerInvitationPostParametersRole,
  ProgramGetResponseIncreaseComplianceRole,
  ProgramListResponseDataItemBank,
  ResultStatusListResponseDataItemStatus,
  RoleListStatusesItem,
  RoleListTitlesItem,
  SanctionsScreeningReviewListResponseDataItemReviewer,
  SanctionsScreeningReviewListResponseDataItemStatus,
  TransferReviewListBanksItem,
  TransferReviewListReasonsItem,
  TransferReviewListResponseDataItemTransferType,
  TransferReviewListReviewersItem,
} from 'src/build/operations';
import { CompletedTransactionPaymentRail } from 'src/components/list_tabs/CompletedTransactionListTab';

const validator = <T extends string>(x: string): T => x as T;
const stringEnum = <T extends string>() => string<T>(validator);

export const ROUTES = {
  ROOT: route('', { searchParams: { i: string() } }),
  OBJECT_VIEWER: route('object/:objectID', { searchParams: { raw: string() } }),
  UNIT21_OBJECT_VIEWER: route('unit21-object/:objectType/:objectID'),
  OPERATIONS: route('operations'),
  MANAGEMENT_INFORMATION_SYSTEM: route(
    'management_information_system',
    {
      searchParams: {
        at_time: string().default(
          moment().startOf('month').format('YYYY-MM-DD')
        ),
        lookback_period:
          stringEnum<ManagementInformationSystemRevenuesListPeriod>().default(
            'month'
          ),
      },
    },
    {
      OVERVIEW: route('overview'),
      VOLUME: route('volume'),
      ACCOUNTS: route('accounts'),
      COMPLIANCE: route('compliance'),
    }
  ),

  PARTNERS: route('partners', {
    searchParams: {
      email: string(),
      bank: stringEnum<PartnerInvitationPostParametersBank>().defined().array(),
      role: stringEnum<PartnerInvitationPostParametersRole>().defined().array(),
    },
  }),
  SEARCH: route('search'),
  RESULTS: route('results'),
  RESULT_STATUS: route('results/:resultStatusID'),
  RESULTS_V2: route('results2', {
    searchParams: {
      statuses: stringEnum<ResultStatusListResponseDataItemStatus>()
        .defined()
        .array(),
      suppression_status: stringEnum<'true' | 'false'>().defined().array(),
    },
  }),

  MANUAL_TASKS_LIST: route('manual_tasks'),
  MANUAL_TASK: route('manual_tasks/:taskName'),
  MANUAL_TASK_RUN: route('manual_task_runs/:manualTaskRunId'),

  CARD: route('cards/:cardID'),
  CARD_DECLINE: route('card_declines/:cardDeclineID'),
  CARD_PAYMENT: route('card_payments/:cardPaymentID'),
  GROUPS_LIST: route('groups', {
    searchParams: {
      statuses: stringEnum<GroupListStatusItem>().defined().array(),
      keyword: string(),
      created_on_or_after: string(),
      created_on_or_before: string(),
    },
  }),
  GROUP_GATES: route('groups/:groupID/gates'),
  GROUP_FEE_STATEMENTS: route('groups/:groupID/fee_statements'),
  GROUP_FEE_PLANS: route('groups/:groupID/fee_plans'),
  GROUP_PROGRAM_FEE_PLANS: route(
    'groups/:groupID/programs/:programID/fee_plans'
  ),
  DOCUMENT_REQUESTS_LIST: route('document_requests', {
    searchParams: {
      group: string().defined().array(),
      program: string().defined().array(),
      statuses: stringEnum<DocumentRequestListResponseDataItemStatus>()
        .defined()
        .array(),
      categories: stringEnum<DocumentRequestListSourceCategoriesItem>()
        .defined()
        .array(),
      subcategories:
        stringEnum<DocumentRequestListPlatformPeriodicTemplatedSpreadsheetReportRequestCategoriesItem>()
          .defined()
          .array(),
      created_on_or_after: string(),
      created_on_or_before: string(),
    },
  }),
  DOCUMENT_REQUEST_DETAIL: route('document_requests/:documentRequestId'),
  DOCUMENT_REQUEST_SCHEDULE: route('document_request_schedule/:programId'),
  USER_SESSIONS_LIST: route('user_sessions'),
  ENTITY: route('entities/:entityId'),

  ENTITY_CLUSTER_DETAIL: route('entity_clusters/:entityClusterID'),
  ENTITY_CLUSTERS_LIST: route('entity_clusters', {
    searchParams: {
      statuses: stringEnum<EntityClusterListStatusesItem>().defined().array(),
      count_or_greater: number(),
      categories: stringEnum<EntityClusterListCategoriesItem>()
        .defined()
        .array(),
    },
  }),
  ADDRESS_REVIEWING: route('address_reviewing'),
  BENEFICIAL_OWNER: route('beneficial_owners/:beneficialOwnerId'),

  CARD_PROFILE_IMAGES: route('card_profile_images'),
  CARD_PRINTER_CARD_PROFILE_IMAGES: route('card_printer/physical_card_images'),

  CHECK_DEPOSIT_REVIEW_LIST: route('check_deposits'),
  CHECK_DEPOSIT_REVIEW: route('check_deposits/:checkDepositID/review'),
  CHECK_ITEM_ALLOCATING_LIST: route('check_item_allocating'),
  CHECK_ITEM_ALLOCATING: route('check_item_allocating/:checkItemID'),

  IDENTITY_DOCUMENTS_LIST: route('identity_documents'),
  IDENTITY_DOCUMENT_REVIEW: route(
    'identity_documents/:identityDocumentID/review'
  ),
  INBOUND_MAIL_ITEM_REVIEW_LIST: route('inbound_mail_items'),

  QUEUES: route('queues', {
    searchParams: {
      status: stringEnum<OperatorManualQueueItemListStatusItem>()
        .defined()
        .array(),
    },
  }),
  GROUP_QUEUE: route('group_queue', {
    searchParams: {
      status: stringEnum<OperatorManualQueueItemListStatusItem>()
        .defined()
        .array(),
    },
  }),
  TRANSACTION_DETAIL: route('transactions/:transactionID'),
  TRANSFER_DETAIL: route('transfers/:transferID'),
  FLAGS_LIST: route('flags'),
  DECLINED_TRANSACTION_DETAIL: route(
    'declined_transactions/:declinedTransactionID'
  ),
  OAUTH_APPLICATION_LIST: route('oauth_applications', {
    searchParams: {
      group: string().defined().array(),
    },
  }),
  OAUTH_APPLICATION_DETAIL: route('oauth_applications/:oauthApplicationID'),
  OAUTH_CONNECTION_LIST: route('oauth_connections', {
    searchParams: {
      group: string().defined().array(),
      connected_group: string().defined().array(),
      oauth_application: string().defined().array(),
    },
  }),
  PENDING_TRANSACTION_DETAIL: route(
    'pending_transactions/:pendingTransactionID'
  ),
  PLATFORM_COMPLAINT_LIST_SUBMISSIONS_LIST: route(
    'platform_complaint_list_submissions',
    {
      searchParams: { program: string().defined().array() },
    }
  ),
  PLATFORM_COMPLAINT_LIST_SUBMISSION_DETAIL: route(
    'platform_complaint_list_submissions/:submissionID'
  ),
  PLATFORM_VENDOR_LIST_SUBMISSIONS_LIST: route(
    'platform_vendor_list_submissions',
    {
      searchParams: { program: string().defined().array() },
    }
  ),
  PLATFORM_VENDOR_LIST_SUBMISSIONS_DETAIL: route(
    'platform_vendor_list_submissions/:submissionID'
  ),
  PLATFORM_COMPLIANCE_METRICS_SUBMISSIONS_LIST: route(
    'platform_compliance_metrics_submissions',
    {
      searchParams: { program: string().defined().array() },
    }
  ),
  PLATFORM_COMPLIANCE_METRICS_SUBMISSIONS_DETAIL: route(
    'platform_compliance_metrics_submissions/:submissionID'
  ),
  PLATFORM_FINANCIAL_REPORT_SUBMISSIONS_DETAIL: route(
    'platform_financial_report_submissions/:submissionID'
  ),
  UNUSUAL_ACTIVITY_REPORTS: route(
    'unusual_activity_reports',
    {},
    {
      PLATFORM: route(
        'platform',
        {
          searchParams: {
            group: string().defined().array(),
            created_on_or_after: string(),
            created_on_or_before: string(),
          },
        },
        { DETAILS: route(':unusualActivityReportId') }
      ),
      DIRECT: route(
        'direct',
        {},
        { DETAILS: route(':unusualActivityReportId') }
      ),
    }
  ),

  //  How different from `/platform_unusual_activity_reports`?
  UNUSUAL_ACTIVITY_REPORTS_LIST_FOR_PLATFORM: route(
    'unusual_activity_reports/platform'
  ),
  UNUSUAL_ACTIVITY_REPORTS_FOR_PLATFORM_DETAIL: route(
    'platform_unusual_activity_reports/:unusualActivityReportId'
  ),
  DIRECT_UNUSUAL_ACTIVITY_REPORTS_LIST: route(
    'unusual_activity_reports/direct'
  ),
  DIRECT_UNUSUAL_ACTIVITY_REPORTS_DETAIL: route(
    'unusual_activity_reports/direct/:unusualActivityReportID'
  ),
  ACH_RETURN_RATES_DETAIL: route('ach_return_rates/:achReturnRateID'),
  ACH_RETURN_RATES_LIST: route('ach_return_rates', {
    searchParams: {
      group: string().defined().array(),
      program: string().defined().array(),
      threshold: stringEnum<'over' | 'under'>().defined().array(),
      on_or_after: string(),
      on_or_before: string(),
    },
  }),
  BOOKKEEPING_ACCOUNTS_LIST: route('bookkeeping_accounts', {
    searchParams: {
      compliance_categories:
        stringEnum<BookkeepingAccountListComplianceCategoriesItem>()
          .defined()
          .array(),
      group: string().defined().array(),
    },
  }),
  BOOKKEEPING_ACCOUNTS_DETAIL: route(
    'bookkeeping_accounts/:bookkeepingAccountID'
  ),
  LATE_RETURN_REQUESTS_LIST: route('late_return_requests', {
    searchParams: { group: string().defined().array(), keyword: string() },
  }),
  LATE_RETURN_REQUESTS_DETAIL: route(
    'late_return_requests/:lateReturnRequestId'
  ),
  LATE_RETURN_REQUESTS_CREATE: route('late_return_request_create'),
  TRANSFER_LOOKUP: route('transfer_lookup'),
  TRANSFER_REVIEWING: route('transfer_reviewing', {
    searchParams: {
      group: string().defined().array(),
      reason: stringEnum<TransferReviewListReasonsItem>().defined().array(),
      reviewer: stringEnum<TransferReviewListReviewersItem>().defined().array(),
      bank: stringEnum<TransferReviewListBanksItem>().defined().array(),
      transfer_type:
        stringEnum<TransferReviewListResponseDataItemTransferType>()
          .defined()
          .array(),
    },
  }),
  OUTBOUND_ACH_FILES_LIST: route('outbound_ach_files'),
  ACH_ORIGINATION_VOLUME_MONITORING: route(
    'ach_origination_volume_monitoring',
    {
      searchParams: {
        bank: stringEnum<AccountGetResponseBank>(),
      },
    }
  ),
  REAL_TIME_PAYMENTS_PREFUNDED_POSITION: route(
    'real_time_payments_prefunded_position',
    {
      searchParams: {
        bank: stringEnum<AccountGetResponseBank>(),
      },
    }
  ),
  CASH_RECONCILIATION: route('cash_reconciliation'),
  SANCTIONS_SCREENING_REVIEWS_LIST: route('sanctions_screening_reviews', {
    searchParams: {
      statuses: stringEnum<SanctionsScreeningReviewListResponseDataItemStatus>()
        .defined()
        .array(),
      reviewer:
        stringEnum<SanctionsScreeningReviewListResponseDataItemReviewer>()
          .defined()
          .array(),
      records: string().defined().array(),
    },
  }),
  SANCTIONS_SCREENING_REVIEW_DETAIL: route(
    'sanctions_screening_reviews/:reviewID'
  ),
  SANCTIONS_SCREENING_TRIGGER_WORDS_LIST: route(
    'sanctions_screening_trigger_words'
  ),
  SANCTIONS_SCREENING_ENTRY_DETAILS: route(
    'sanctions_screening_entry/:entryId'
  ),
  PLATFORM_COMPLIANCE_SUBMISSIONS: route('platform_compliance_submissions'),
  ROUTING_NUMBERS: route('routing_numbers'),
  OBLIGATION_DOCUMENTS: route('obligation_documents'),
  OBLIGATION_DOCUMENTS_DETAILS: route('obligation_documents/:documentName'),
  ATTESTATIONS_LIST: route('attestations'),
  ATTESTATIONS_DETAIL: route('attestations/:attestationId'),
  COMPLIANCE_DOCUMENTS: route('compliance_documents'),
  COMPLAINTS: route(
    'complaints',
    {},
    {
      PLATFORM_COMPLAINTS: route('platform', {
        searchParams: {
          group: string().defined().array(),
          keyword: string(),
          on_or_after: string(),
          on_or_before: string(),
        },
      }),
      DIRECT_COMPLAINTS: route('direct'),
    }
  ),

  PLATFORM_COMPLAINTS_DETAIL: route('platform_complaints/:platformComplaintId'),
  PLATFORM_VENDORS: route('platform_vendors', {
    searchParams: {
      group: string().defined().array(),
      keyword: string(),
    },
  }),
  CARDS_LIST: route('cards', {
    searchParams: {
      group: string().defined().array(),
      entity: string().defined().array(),
      account: string().defined().array(),
      statuses: stringEnum<CardGetResponseStatus>().defined().array(),
      keyword: string(),
      created_on_or_after: string(),
      created_on_or_before: string(),
    },
  }),
  TRANSACTIONS_LIST: route('transactions', {
    searchParams: {
      group: string().defined().array(),
      account: string().defined().array(),
      program: string().defined().array(),
      card: string().defined().array(),
      account_number: string().defined().array(),
      source_type: stringEnum<CompletedTransactionPaymentRail>()
        .defined()
        .array(),
      on_or_after: string(),
      on_or_before: string(),
      amount_or_greater: number(),
      amount_or_less: number(),
      exact_amount: number(),
    },
  }),
  PENDING_TRANSACTIONS_LIST: route('pending_transactions', {
    searchParams: {
      group: string().defined().array(),
      account: string().defined().array(),
      card: string().defined().array(),
      account_number: string().defined().array(),
      source_type: stringEnum<CompletedTransactionPaymentRail>()
        .defined()
        .array(),
      on_or_after: string(),
      on_or_before: string(),
      amount_or_greater: number(),
      amount_or_less: number(),
      exact_amount: number(),
    },
  }),
  ACCOUNT_NUMBERS_LIST: route('account_numbers', {
    searchParams: {
      group: string().defined().array(),
      account: string().defined().array(),
      statuses: stringEnum<AccountNumberGetResponseStatus>().defined().array(),
      keyword: string(),
      created_on_or_after: string(),
      created_on_or_before: string(),
    },
  }),
  ACCOUNT_NUMBERS_DETAILS: route('account_numbers/:accountNumberId'),
  ACCOUNTS_LIST: route('accounts', {
    searchParams: {
      group: string().defined().array(),
      entity: string().defined().array(),
      program: string().defined().array(),
      statuses: stringEnum<AccountListResponseDataItemStatus>()
        .defined()
        .array(),
      bank: stringEnum<AccountGetResponseBank>().defined().array(),
      keyword: string(),
      created_on_or_after: string(),
      created_on_or_before: string(),
    },
  }),
  ACCOUNTS_DETAIL: route('accounts/:accountId'),
  PROGRAMS_LIST: route('programs', {
    searchParams: {
      group: string().defined().array(),
      bank_program: string().defined().array(),
      bank: stringEnum<ProgramListResponseDataItemBank>().defined().array(),
      compliance_role: stringEnum<ProgramGetResponseIncreaseComplianceRole>()
        .defined()
        .array(),
      created_on_or_after: string(),
      created_on_or_before: string(),
    },
  }),
  PROGRAMS_DETAIL: route('programs/:programId'),
  BANK_PROGRAMS_LIST: route('bank_programs'),
  BANK_PROGRAMS_DETAIL: route('bank_programs/:bankProgramId'),
  BANK_FEE_STATEMENTS_DETAIL: route('bank_fee_statements/:bankFeeStatementId'),
  ONBOARDING_SURVEYS_INDEX: route('onboarding_surveys'),
  RAMP_BUSINESS_ACCOUNT_ONBOARDING_SURVEYS_LIST: route(
    'ramp_business_account_onboarding_surveys',
    {
      searchParams: {
        created_on_or_after: string(),
        created_on_or_before: string(),
        accounts: string().defined().array(),
      },
    }
  ),
  RAMP_BUSINESS_ACCOUNT_ONBOARDING_SURVEYS_DETAILS: route(
    'ramp_business_account_onboarding_surveys/:id'
  ),
  COMMERCIAL_ONBOARDING_SURVEYS_LIST: route('commercial_onboarding_surveys', {
    searchParams: {
      created_on_or_after: string(),
      created_on_or_before: string(),
      program: string().defined().array(),
    },
  }),
  COMMERCIAL_ONBOARDING_SURVEYS_DETAILS: route(
    'commercial_onboarding_surveys/:id'
  ),
  CONSUMER_ONBOARDING_SURVEYS_LIST: route('consumer_onboarding_surveys', {
    searchParams: {
      created_on_or_after: string(),
      created_on_or_before: string(),
      program: string().defined().array(),
    },
  }),
  CONSUMER_ONBOARDING_SURVEYS_DETAILS: route('consumer_onboarding_surveys/:id'),
  CONTROLS_LIST: route('controls', {
    searchParams: {
      results: stringEnum<ControlConfigurationGetResponseResult>()
        .defined()
        .array(),
      categories: stringEnum<ControlConfigurationListCategoriesItem>()
        .defined()
        .array(),
      group: string().defined().array(),
    },
  }),
  CONTROLS_DETAIL: route('controls/:id'),
  CONTROL_RECORDS_LIST: route('control_records', {
    searchParams: {
      categories: stringEnum<ControlRecordGetResponseCategory>()
        .defined()
        .array(),
      results: stringEnum<ControlRecordGetResponseResult>().defined().array(),
      control_configuration_ids: string().defined().array(),
    },
  }),
  CONTROL_RECORDS_DETAILS: route('control_records/:id'),
  CUSTOMER_IDENTIFICATION_PROGRAM_TESTING_BATCHES_LIST: route(
    'customer_identification_program_testing_batches'
  ),
  CUSTOMER_IDENTIFICATION_PROGRAM_TESTING_BATCHES_DETAILS: route(
    'customer_identification_program_testing_batches/:batchId'
  ),
  CUSTOMER_IDENTIFICATION_PROGRAM_TESTING_ENTRIES_LIST: route(
    'customer_identification_program_testing_entries',
    {
      searchParams: {
        batch_id: string().defined().array(),
        bank_program: string().defined().array(),
        status:
          stringEnum<CustomerIdentificationProgramTestingEntryGetResponseTestingStatus>()
            .defined()
            .array(),
      },
    }
  ),
  CUSTOMER_IDENTIFICATION_PROGRAM_TESTING_ENTRIES_DETAILS: route(
    'customer_identification_program_testing_entries/:entryId'
  ),
  ENTITIES_LIST: route('entities', {
    searchParams: {
      group: string().defined().array(),
      keyword: string(),
      created_on_or_after: string(),
      created_on_or_before: string(),
      status: stringEnum<EntityGetResponseStatus>().defined().array(),
      structure: stringEnum<EntityGetResponseStructure>().defined().array(),
      review_status: stringEnum<EntityGetResponseReviewStatus>()
        .defined()
        .array(),
    },
  }),
  ROLES_LIST: route('roles', {
    searchParams: {
      group: string().defined().array(),
      user: string().defined().array(),
      title: stringEnum<RoleListTitlesItem>().defined().array(),
      status: stringEnum<RoleListStatusesItem>().defined().array(),
    },
  }),
  USERS_LIST: route('users', { searchParams: { keyword: string() } }),
  USERS_DETAIL: route('users/:userId'),
  GROUPS_DETAIL: route('groups/:groupId'),

  FEDERAL_RESERVE_LIMITS_LIST: route('all_federal_reserve_limits'),
  FEDERAL_RESERVE_LIMIT_DETAILS: route('federal_reserve_limits/:limitsId'),
  NEW_FEDERAL_RESERVE_LIMIT_DETAILS: route(
    'federal_reserve_limits_new/:limitsId'
  ),
  PARTNER_FEDERAL_RESERVE_LIMITS: route('partner/federal_reserve_limits'),
};

type Exact<A, B> = (<T>() => T extends A ? 1 : 0) extends <T>() => T extends B
  ? 1
  : 0
  ? A extends B
    ? B extends A
      ? unknown
      : never
    : never
  : never;

export const buildPath = <
  TPath extends string,
  TPathTypes,
  TSearchTypes,
  THash extends string[],
  TStateTypes,
>(
  r: Route<TPath, TPathTypes, TSearchTypes, THash, TStateTypes>,
  pathParams: Exact<
    InParams<TPath, TPathTypes>,
    Record<never, never>
  > extends never
    ? InParams<TPath, TPathTypes>
    : Record<string, never>,
  searchParams?: InSearchParams<TSearchTypes>
): string =>
  r.buildPath(pathParams as InParams<TPath, TPathTypes>, searchParams);

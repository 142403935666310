import {
  TableColumns,
  Table,
  PrestyledTableProps,
} from 'shared/components/Table';
import { formatInteger, humanize } from 'shared/lib/formatting';
import { useMemo } from 'react';

import { ControlConfigurationGetResponse } from 'src/build/operations';
import { ROUTES, buildPath } from 'src/lib/routes';

type Props = PrestyledTableProps<ControlConfigurationGetResponse>;

export const ControlConfigurationsTable = (props: Props) => {
  const { getRowProps, ...rest } = props;
  const columns = useMemo<TableColumns<ControlConfigurationGetResponse>>(() => {
    return [
      {
        header: 'Category',
        expand: 1,
        contents: (control) => ({
          text: humanize(control.category),
          textWeight: 'medium',
          textColor: 'emphasis',
        }),
      },
      {
        header: 'Group',
        contents: (program) => ({
          text: program.group_id,
          href: '/groups/' + program.group_id,
        }),
      } as const,
      {
        header: 'Program',
        contents: (program) => ({
          text: program.program_id,
          href: '/programs/' + program.group_id,
        }),
      },
      {
        header: 'Number of successes',
        contents: (program) => ({
          text: formatInteger(program.number_of_successes),
        }),
      },
      {
        header: 'Number of failures',
        contents: (program) => ({
          text: formatInteger(program.number_of_failures),
        }),
      },
    ];
  }, []);

  const defaultRowProps = (datum: ControlConfigurationGetResponse) => ({
    href: buildPath(ROUTES.CONTROLS_DETAIL, { id: datum.id }),
    className: 'hover:bg-main-hover cursor-pointer transition-all group',
  });

  return (
    <Table<ControlConfigurationGetResponse>
      columns={columns}
      getRowProps={getRowProps ?? defaultRowProps}
      {...rest}
    />
  );
};

import { useFeePlanList, useGroupGet } from 'src/hooks/reactQuery';
import { PageLayout } from './deprecated/PageLayout';
import { Table } from './deprecated/Table';

import { partition } from 'lodash';
import { Section } from './deprecated/Section';
import { FeePlanListResponseDataItem } from 'src/build/operations';
import { DateTime } from 'luxon';
import { StyledLink } from './deprecated/StyledLink';
import {
  formatDecimalMinorAmount,
  formatISO8601TimestampAsISODate,
} from 'shared/lib/formatting';
import { useTypedParams } from 'react-router-typesafe-routes/dom';
import { ROUTES } from 'src/lib/routes';

const generateUpdateLink = (feePlan: FeePlanListResponseDataItem) => {
  let updateLink = `/manual_tasks/Operations::ManualTask::CreateFeePlan?effective_date=${DateTime.now()
    .plus({ months: 1 })
    .startOf('month')
    .toISODate()}`;

  Object.keys(feePlan).forEach((key) => {
    updateLink += `&${key}=${feePlan[key as keyof typeof feePlan]}`;
  });

  return updateLink;
};

const FeePlanTable = (props: {
  title: string;
  feePlans: FeePlanListResponseDataItem[];
  isActive: boolean;
}) => {
  const columns = [
    {
      key: 'program_id',
      label: 'Program',
      render: (fp: FeePlanListResponseDataItem) => (
        <>
          {fp.program_id} ({fp.program_name})
        </>
      ),
    },
    {
      key: 'effective_date',
      label: 'Effective Date',
      render: (fp: FeePlanListResponseDataItem) =>
        formatISO8601TimestampAsISODate(fp.effective_date),
    },
    {
      key: 'created_at',
      label: 'Created At',
      render: (fp: FeePlanListResponseDataItem) => fp.created_at,
    },
    {
      key: 'monthly_base_fee',
      label: 'Base',
      render: (fp: FeePlanListResponseDataItem) =>
        formatDecimalMinorAmount(fp.monthly_base_fee, 'USD'),
    },
    {
      key: 'monthly_minimum_fee',
      label: 'Minimum',
      render: (fp: FeePlanListResponseDataItem) =>
        formatDecimalMinorAmount(fp.monthly_minimum_fee, 'USD'),
    },
    {
      key: 'ach_origination_fee',
      label: 'ACH Fee',
      render: (fp: FeePlanListResponseDataItem) =>
        formatDecimalMinorAmount(fp.ach_origination_fee, 'USD'),
    },
    {
      key: 'wire_origination_fee',
      label: 'Wire Fee',
      render: (fp: FeePlanListResponseDataItem) =>
        formatDecimalMinorAmount(fp.wire_origination_fee, 'USD'),
    },
    {
      key: 'check_origination_fee',
      label: 'Check Fee',
      render: (fp: FeePlanListResponseDataItem) =>
        formatDecimalMinorAmount(fp.check_transfer_origination_fee, 'USD'),
    },
    {
      key: 'real_time_payments_transfer_origination_fee',
      label: 'RTP Fee',
      render: (fp: FeePlanListResponseDataItem) =>
        formatDecimalMinorAmount(
          fp.real_time_payments_transfer_origination_fee,
          'USD'
        ),
    },
  ];

  if (props.isActive) {
    columns.push({
      key: 'update',
      label: '',
      render: (fp: FeePlanListResponseDataItem) => (
        <StyledLink to={generateUpdateLink(fp)}>Create Fee Plan</StyledLink>
      ),
    });
  }

  return (
    <Section header={props.title}>
      <Table
        data={props.feePlans}
        columns={columns}
        href={(fp) => `/object/${fp.id}`}
      />
    </Section>
  );
};

const FeePlansList = ({
  groupID,
  programID,
}: {
  groupID: string;
  programID?: string;
}) => {
  const { data: group } = useGroupGet(groupID);
  const { data: feePlanList } = useFeePlanList({
    group_id: groupID,
    program_id: programID,
  });

  if (!group || !feePlanList) {
    return <></>;
  }

  const groupName = group.name || group.friendly_nickname || group.id;

  const [active, inactive] = partition(feePlanList.data, (fp) => fp.active);

  const [future, historical] = partition(
    inactive,
    (fp) => fp.effective_date > new Date().toISOString()
  );

  const headline = programID
    ? `Fee Plans for ${programID}`
    : `Fee Plans for ${groupName}`;

  return (
    <PageLayout
      headline={headline}
      breadcrumbs={[
        { text: 'Groups' },
        { text: groupID },
        { text: 'Fee Plans' },
      ]}
    >
      <>
        <FeePlanTable title="Active fee plans" feePlans={active} isActive />
        <FeePlanTable
          title="Upcoming fee plans"
          feePlans={future}
          isActive={false}
        />
        <FeePlanTable
          title="Historical fee plans"
          feePlans={historical}
          isActive={false}
        />
      </>
    </PageLayout>
  );
};

export const ProgramFeePlansPage = () => {
  const { groupID, programID } = useTypedParams(ROUTES.GROUP_PROGRAM_FEE_PLANS);

  return <FeePlansList groupID={groupID} programID={programID} />;
};

export const GroupFeePlansPage = () => {
  const { groupID } = useTypedParams(ROUTES.GROUP_FEE_PLANS);

  return <FeePlansList groupID={groupID} />;
};

export function nameFromMapping(
  mapping: {
    name: string;
    value: string;
  }[],
  value: string
): string {
  const element = mapping.find((candidate) => candidate.value === value);
  return element ? element.name : value;
}

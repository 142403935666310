import {
  TableColumns,
  Table,
  PrestyledTableProps,
} from 'shared/components/Table';
import { useMemo } from 'react';

import { BookkeepingEntryListResponseDataItem } from 'src/build/operations';

import { formatAmount, formatISO8601Timestamp } from 'shared/lib/formatting';
import { buildPath, ROUTES } from 'src/lib/routes';

type Props = PrestyledTableProps<BookkeepingEntryListResponseDataItem>;

export const BookkeepingEntriesTable = (props: Props) => {
  const { getRowProps, ...rest } = props;
  const columns = useMemo<
    TableColumns<BookkeepingEntryListResponseDataItem>
  >(() => {
    return [
      {
        header: 'Date',
        contents: (record) => ({
          text: formatISO8601Timestamp(record.entry_set.date, 'medium'),
        }),
      },
      {
        header: 'Transaction ID',
        contents: (record) => ({
          text: record.entry_set.transaction_id ?? '',
          href: record.entry_set.transaction_id
            ? buildPath(ROUTES.OBJECT_VIEWER, {
                objectID: record.entry_set.transaction_id,
              })
            : undefined,
        }),
      },
      {
        header: 'Entry Set',
        contents: (record) => ({
          text: record.entry_set.id,
          href: buildPath(ROUTES.OBJECT_VIEWER, {
            objectID: record.entry_set.id,
          }),
        }),
      },
      {
        header: 'Amount',
        contents: (record) => ({
          text: formatAmount(record.amount, 'USD'),
          textColor: 'emphasis',
          textWeight: 'bold',
        }),
      },
    ];
  }, []);

  const defaultRowProps = (datum: BookkeepingEntryListResponseDataItem) => ({
    href: buildPath(ROUTES.BOOKKEEPING_ACCOUNTS_DETAIL, {
      bookkeepingAccountID: datum.id,
    }),
    className: 'hover:bg-main-hover cursor-pointer transition-all group',
  });

  return (
    <Table<BookkeepingEntryListResponseDataItem>
      columns={columns}
      getRowProps={getRowProps ?? defaultRowProps}
      {...rest}
    />
  );
};

import {
  TableColumns,
  Table,
  PrestyledTableProps,
} from 'shared/components/Table';
import { formatISO8601Timestamp, humanize } from 'shared/lib/formatting';
import { useMemo } from 'react';

import {
  BankProgramGetResponse,
  BankProgramGetResponseClassification,
} from 'src/build/operations';
import { IconType } from 'shared/components/Icon';
import { ROUTES, buildPath } from 'src/lib/routes';

type Props = PrestyledTableProps<BankProgramGetResponse>;

export const bankProgramClassificationIconTypes: Record<
  NonNullable<BankProgramGetResponseClassification>,
  IconType
> = {
  direct_user: 'user',
  money_transmitter: 'arrows_right',
  regulated_user: 'bank',
  unlicensed_lender: 'coins_swap',
};

export const BankProgramsTable = (props: Props) => {
  const { getRowProps, ...rest } = props;
  const columns = useMemo<TableColumns<BankProgramGetResponse>>(() => {
    return [
      {
        header: 'Name',
        expand: 1,
        contents: (program) => ({
          text: program.partner_name,
          textWeight: 'medium',
          textColor: 'emphasis',
        }),
      } as const,
      {
        header: 'Classification',
        contents: (program) => ({
          text: program.classification
            ? humanize(program.classification)
            : 'Not applicable',
          badgeColor: program.classification ? 'gray' : undefined,
          icon: program.classification
            ? bankProgramClassificationIconTypes[program.classification]
            : undefined,
        }),
      },
      {
        header: 'Bank',
        contents: (program) => ({
          text: humanize(program.bank),
        }),
      },
      {
        header: 'Opened',
        align: 'right',
        contents: (program) => ({
          text: program.opened_at
            ? formatISO8601Timestamp(program.opened_at, 'medium')
            : '-',
        }),
      },
    ];
  }, []);

  const defaultRowProps = (datum: BankProgramGetResponse) => ({
    href: buildPath(ROUTES.BANK_PROGRAMS_DETAIL, {
      bankProgramId: datum.id,
    }),
    className: 'hover:bg-main-hover cursor-pointer transition-all group',
  });

  return (
    <Table<BankProgramGetResponse>
      columns={columns}
      getRowProps={getRowProps ?? defaultRowProps}
      {...rest}
    />
  );
};

import {
  createContext,
  Fragment,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react';
import { Dialog, Transition } from '@headlessui/react';
import classNames from 'classnames';

type DialogState = 'none' | 'modal' | 'drawer' | 'full';

type DialogProps = {
  state: DialogState;
  onClose: () => void;
  children: ReactNode;
};

// You probably don't need to use this directly
const DialogContext = createContext({
  modalState: 'none' as DialogState,
  setModalState: (_open: DialogState) => {},
  modalChild: null as ReactNode,
  setModalChild: (_child: ReactNode) => {},
  searchOpen: false as boolean,
  setSearchOpen: (_open: boolean) => {},
});

export const Modal = ({ state, onClose, children }: DialogProps) => {
  const { setModalState } = useContext(DialogContext);
  useEffect(() => {
    setModalState(state);
  }, [setModalState, state]);
  return (
    <Transition.Root show={state === 'modal'} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={onClose}>
        <div className="fixed inset-0 overflow-y-visible">
          <div className="flex h-full items-start justify-center sm:p-4 md:items-center md:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out-quint duration-700"
              enterFrom="opacity-0 translate-y-full sm:scale-75 sm:translate-y-0"
              enterTo="opacity-100 scale-100 translate-y-0"
              leave="ease-out-quint duration-700"
              leaveFrom="opacity-100 scale-100 translate-y-0"
              leaveTo="opacity-0 translate-y-full sm:scale-75 sm:translate-y-0"
            >
              <Dialog.Panel className="absolute inset-x-0 bottom-0 top-12 w-full overflow-y-scroll rounded-t-lg border border-main bg-main shadow-xl transition-all no-scrollbar sm:relative sm:inset-x-auto sm:h-auto sm:max-h-[90vh] sm:w-full sm:max-w-lg sm:rounded-lg">
                {children}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export const Drawer = ({ state, onClose, children }: DialogProps) => {
  const { setModalState } = useContext(DialogContext);
  useEffect(() => {
    setModalState(state);
  }, [setModalState, state]);
  return (
    <Transition.Root show={state === 'drawer'} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={onClose}>
        <div className="fixed inset-0 overflow-y-visible">
          <Transition.Child
            as={Fragment}
            enter="ease-out-quint duration-700"
            enterFrom="translate-y-full sm:translate-y-0 sm:translate-x-[calc(100%+24px)]"
            enterTo="translate-y-0 translate-x-0"
            leave="ease-out-quint duration-700"
            leaveFrom="translate-y-0 translate-x-0"
            leaveTo="translate-y-full sm:translate-y-0 sm:translate-x-[calc(100%+24px)]"
          >
            <Dialog.Panel
              className={classNames(
                'absolute inset-x-0 bottom-0 top-12 w-full overflow-hidden rounded-t-lg bg-main shadow-2xl transition-all sm:inset-y-[12px] sm:left-auto sm:right-[12px] sm:w-[calc(100vw-24px)] sm:max-w-[500px] sm:rounded-lg'
              )}
            >
              {children}
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export const FullscreenForm = ({ state, onClose, children }: DialogProps) => {
  const { setModalState } = useContext(DialogContext);
  useEffect(() => {
    setModalState(state);
  }, [setModalState, state]);
  return (
    <Transition.Root show={state === 'full'} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={onClose}>
        <div className="fixed inset-0 overflow-y-visible">
          <Transition.Child
            as={Fragment}
            enter="ease-out-quint duration-700"
            enterFrom="translate-y-full"
            enterTo="translate-y-0"
            leave="ease-out-quint duration-700"
            leaveFrom="translate-y-0"
            leaveTo="translate-y-full"
          >
            <Dialog.Panel
              className={classNames(
                'absolute inset-0 h-full w-full bg-main shadow-2xl transition-all md:inset-x-3 md:bottom-3 md:top-8 md:h-[calc(100vh-44px)] md:w-[calc(100vw-24px)] md:overflow-hidden md:rounded-lg'
              )}
            >
              {children}
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

const useModal = () => {
  const { setModalState, setModalChild, modalState } =
    useContext(DialogContext);
  const modalOpen = modalState === 'modal';
  const showModal = (child: ReactNode) => {
    setModalChild(child);
    setModalState('modal');
  };
  const closeModal = () => {
    setModalState('none');
  };
  return { modalOpen, showModal, closeModal };
};

const useDrawer = () => {
  const { setModalState, setModalChild, modalState } =
    useContext(DialogContext);
  const modalOpen = modalState === 'drawer';
  const showDrawer = (child: ReactNode) => {
    setModalChild(child);
    setModalState('drawer');
  };
  const closeDrawer = () => {
    setModalState('none');
  };
  return { modalOpen, showDrawer, closeDrawer };
};

const useFullscreenForm = () => {
  const { setModalState, setModalChild, modalState } =
    useContext(DialogContext);
  const modalOpen = modalState === 'full';
  const showFullscreenForm = (child: ReactNode) => {
    setModalChild(child);
    setModalState('full');
  };
  const closeFullscreenForm = () => {
    setModalState('none');
  };
  return { modalOpen, showFullscreenForm, closeFullscreenForm };
};

const SearchBar = ({
  searchOpen,
  setSearchOpen,
  children,
}: {
  searchOpen: boolean;
  setSearchOpen: (_open: boolean) => void;
  children: ReactNode;
}) => {
  return (
    <Transition.Root show={!!searchOpen} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-50"
        onClose={() => {
          setSearchOpen(false);
        }}
      >
        <div className="fixed inset-0 overflow-y-visible">
          <div className="flex h-full items-start justify-center sm:p-4 md:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out-quint duration-50"
              enterFrom="opacity-0 translate-y-2"
              enterTo="opacity-100 translate-y-0"
              leave="ease-out-quint duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Panel className="absolute inset-x-0 bottom-0 top-12 h-[calc(100%-48px)] w-full overflow-hidden rounded-t-lg border border-strong bg-subtle shadow-3xl transition-all no-scrollbar sm:relative sm:inset-x-auto sm:top-[15vh] sm:h-auto sm:w-full sm:max-w-[680px] sm:rounded-lg">
                {children}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

const ModalContainer = ({
  children,
  search,
  searchOpen,
  setSearchOpen,
}: {
  children: ReactNode;
  search?: ReactNode;
  searchOpen: boolean;
  setSearchOpen: (_open: boolean) => void;
}) => {
  const [modalState, setModalState] = useState<DialogState>('none');
  const [modalChild, setModalChild] = useState<ReactNode>(null);

  return (
    <DialogContext.Provider
      value={{
        modalState,
        setModalState,
        modalChild,
        setModalChild,
        searchOpen,
        setSearchOpen,
      }}
    >
      <div className="bg-canvas">
        <div
          className={classNames(
            'origin-[50%_50vh]',
            'overflow-hidden transition-all duration-700 ease-out-quint',
            modalState !== 'none' &&
              'scale-95 rounded-lg opacity-30 sm:scale-95'
          )}
        >
          {children}
        </div>
        <SearchBar searchOpen={searchOpen} setSearchOpen={setSearchOpen}>
          {search}
        </SearchBar>
        <Modal state={modalState} onClose={() => setModalState('none')}>
          {modalChild}
        </Modal>
        <Drawer state={modalState} onClose={() => setModalState('none')}>
          {modalChild}
        </Drawer>
        <FullscreenForm
          state={modalState}
          onClose={() => setModalState('none')}
        >
          {modalChild}
        </FullscreenForm>
      </div>
    </DialogContext.Provider>
  );
};

const useSearchBar = () => {
  const { searchOpen, setSearchOpen } = useContext(DialogContext);
  return [searchOpen, setSearchOpen] as const;
};

export {
  ModalContainer,
  useModal,
  useDrawer,
  useFullscreenForm,
  useSearchBar,
  DialogContext,
};

import { useEntitySupplementalDocumentSubmissionEvaluationGet } from 'src/hooks/reactQuery';
import { CheckIcon, XMarkIcon } from '@heroicons/react/20/solid';
import { EntitySupplementalDocumentSubmissionEvaluationGetResponseRequiredChecksItem } from 'src/build/operations';

type Props = {
  entitySetupSupplementalDocumentSubmissionId: string;
};

const description = (
  name: EntitySupplementalDocumentSubmissionEvaluationGetResponseRequiredChecksItem
): string =>
  ({
    correct_format: 'The uploaded file matches the expected JSON format.',
    expected_entity_structure:
      'The entity type (eg Corporation) matches the expected type.',
    entity_lookup_performed:
      'The platform looked up the entity in an identification database.',
    beneficial_owner_lookup_performed:
      'The platform looked up beneficial owner(s) in an identification database.',
    entity_data_matches:
      'The platform looked up the same entity data they submitted to Increase.',
    beneficial_owner_data_matches:
      'The platform looked up the same beneficial owner data they submitted to Increase.',
    evaluation_successful:
      'The rules used by the platform accepted this as a valid entity.',
  })[name];

export const EntitySetupSupplementalDocumentSubmissionEvaluation = ({
  entitySetupSupplementalDocumentSubmissionId,
}: Props) => {
  const { data } = useEntitySupplementalDocumentSubmissionEvaluationGet(
    entitySetupSupplementalDocumentSubmissionId
  );

  if (!data) {
    return <div>Loading</div>;
  }

  if (data.required_checks.length === 0) {
    return <div className="p-2 text-sm">Checks not performed.</div>;
  }

  return (
    <div className="grid grid-cols-2 p-2 text-sm">
      {data.required_checks.map((check_name) => (
        <>
          <div className="flex flex-row gap-1">
            {data.successful_checks.includes(check_name) ? (
              <CheckIcon className="mr-2 flex h-5 w-5 shrink-0 grow-0 text-success" />
            ) : (
              <XMarkIcon className="mr-2 flex h-5 w-5 shrink-0 grow-0 text-danger" />
            )}
            <b>{check_name}</b>
          </div>

          {description(check_name)}
        </>
      ))}
    </div>
  );
};

import {
  ManagementInformationSystemProgramCountsListResponseData,
  ManagementInformationSystemVolumesListPeriod,
} from 'src/build/operations';
import { PercentChange } from '../deprecated/PercentChange';
import { PropertyList } from 'shared/components/PropertyList';
import { formatInteger } from 'shared/lib/formatting';

type Props = {
  data: ManagementInformationSystemProgramCountsListResponseData;
  period: ManagementInformationSystemVolumesListPeriod;
};

export const ProgramCounts = ({ data, period }: Props) => {
  const hasPrograms = data.number_programs;

  if (hasPrograms > 0) {
    return (
      <div>
        <PropertyList
          items={[{ label: 'Program Count', value: 'No programs found' }]}
        />
      </div>
    );
  }

  return (
    <PropertyList
      items={[
        {
          label: 'Program count',
          value: formatInteger(data.number_programs),
        },
        {
          label: `New programs (${period})`,
          value: data.new_program_names.join(', '),
        },
        {
          label: `Period Change ${period}`,
          valueNode: (
            <PercentChange
              previous={data.lookback_number_of_programs}
              current={data.number_programs}
            />
          ),
        },
      ]}
    />
  );
};

import {
  ManagementInformationSystemAccountsListResponseData,
  ManagementInformationSystemAccountsListResponseDataAccountSummariesItem,
  ManagementInformationSystemAccountsListResponseDataAccountSummariesItemNegativeAccountsItem,
} from 'src/build/operations';
import { Table } from '../deprecated/Table';
import { formatInteger } from 'src/lib/format-string';
import { formatAmount } from 'shared/lib/formatting';

type AccountSummaryTableDataRow =
  ManagementInformationSystemAccountsListResponseDataAccountSummariesItem & {
    id: string;
  };

type Props = {
  accountSummaries: ManagementInformationSystemAccountsListResponseData;
};

export const ProgramAccountSummary = ({ accountSummaries }: Props) => {
  const tableData: AccountSummaryTableDataRow[] =
    accountSummaries.account_summaries.map((summary, index) => ({
      ...summary,
      id: `${summary.program_id}-${index}-account-summary`,
    }));

  tableData.push({
    id: 'total-account-summaries',
    program_id: 'total',
    partner_name: 'Total',
    count_accounts: accountSummaries.totals.count_accounts,
    count_open_accounts: accountSummaries.totals.count_open_accounts,
    negative_accounts: [],
    total_usd_negative_balance:
      accountSummaries.totals.total_usd_negative_balance,
  });

  return (
    <div>
      <Table
        data={tableData}
        columns={[
          {
            key: 'partner_name',
            label: 'Name',
          },
          {
            key: 'count_accounts',
            label: 'Accounts',
            render: (record: AccountSummaryTableDataRow) =>
              formatInteger(record.count_accounts),
          },
          {
            key: 'count_open_accounts',
            label: 'Open Accounts',
            render: (record: AccountSummaryTableDataRow) =>
              formatInteger(record.count_open_accounts),
          },
          {
            key: 'negative_accounts',
            label: 'Negative Accounts',
            render: (record: AccountSummaryTableDataRow) => {
              if (record.negative_accounts.length > 0) {
                return (
                  <ul>
                    {record.negative_accounts.map(
                      (
                        negative_account: ManagementInformationSystemAccountsListResponseDataAccountSummariesItemNegativeAccountsItem,
                        index: number
                      ) => (
                        <li key={index}>{`${
                          negative_account.account_id
                        } ${formatAmount(
                          negative_account.usd_balance,
                          'USD'
                        )}`}</li>
                      )
                    )}
                  </ul>
                );
              }

              if (record.id === 'total-account-summaries') {
                return 'N/A';
              }
              return 'None';
            },
          },
          {
            key: 'total_usd_negative_balance',
            label: 'Total USD Negative Balance',
            align: 'right',
            render: (record: AccountSummaryTableDataRow) =>
              formatAmount(record.total_usd_negative_balance, 'USD'),
          },
        ]}
        boldLastRow
      />
    </div>
  );
};

import { useManualTaskList, useObjectGet } from 'src/hooks/reactQuery';
import { InternalOperatorOnly } from './internal-operator-only';
import { StackOfLinks } from 'shared/components/StackOfLinks';

type Props = {
  objectId: string;
};

export const AssociatedManualTasks = ({ objectId }: Props) => {
  const { data: object } = useObjectGet(objectId);
  const { data: manualTasks } = useManualTaskList({});
  if (!object || !manualTasks) {
    return <></>;
  }
  const associatedTasks = manualTasks.data
    .map((task) => ({
      taskName: task.name,
      argumentName: task.arguments.find(
        (arg) => arg.model_classes?.includes(object.meta.class_name)
      )?.name,
    }))
    .filter(({ argumentName }) => argumentName);

  if (associatedTasks.length === 0) {
    return <></>;
  }

  const items = associatedTasks.map(({ taskName, argumentName }) => ({
    text: taskName.split('::').pop() ?? taskName,
    href: `/manual_tasks/${taskName}?${argumentName}=${objectId}`,
  }));

  return (
    <InternalOperatorOnly>
      <StackOfLinks title="Manual Tasks" links={items} />
    </InternalOperatorOnly>
  );
};

import {
  TableColumns,
  Table,
  PrestyledTableProps,
} from 'shared/components/Table';
import {
  formatISO8601Timestamp,
  formatAmount,
  humanize,
  formatInteger,
} from 'shared/lib/formatting';
import { useMemo } from 'react';
import { ProgramCreditExposureGetResponseUnreleasedSummariesItem } from 'src/build/operations';

type RowType = ProgramCreditExposureGetResponseUnreleasedSummariesItem & {
  id: string;
};

type UnreleasedCreditExposureTransactionSummariesTableProps =
  PrestyledTableProps<RowType>;

export const UnreleasedCreditExposureTransactionSummariesTable = ({
  ...rest
}: UnreleasedCreditExposureTransactionSummariesTableProps) => {
  const columns = useMemo<TableColumns<RowType>>(
    () => [
      {
        header: 'Source Type',
        contents: (summary) => ({
          text: humanize(summary.source_type),
        }),
      },
      {
        header: 'Amount',
        contents: (summary) => ({
          text: formatAmount(summary.amount, summary.currency),
        }),
      },
      {
        header: 'Releases At',
        contents: (summary) => ({
          text: summary.releases_at
            ? formatISO8601Timestamp(
                summary.releases_at,
                'month-day-hour-minute'
              )
            : '-',
        }),
      },
      {
        header: 'Count',
        contents: (summary) => ({
          text: formatInteger(summary.count),
        }),
      },
    ],
    []
  );

  return <Table columns={columns} {...rest} />;
};

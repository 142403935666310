import { formatAmount } from 'shared/lib/formatting';
import { getDataURL } from '../../lib/image-utility';

import {
  CHECK_IMAGE_MINIMUM_HEIGHT,
  CHECK_IMAGE_MINIMUM_WIDTH,
} from '../check-deposit-review';
import { Button } from '../deprecated/Button';
import { ImageVerify } from './image-verify';
import {
  CheckDepositContextGetResponse,
  CheckDepositGetResponse,
} from 'src/build/operations';
import {
  useCheckDepositApprove,
  useCheckDepositContextGet,
} from 'src/hooks/reactQuery';
import { StyledLink } from 'shared/components/StyledLink';
import { ROUTES, buildPath } from 'src/lib/routes';
import { useState } from 'react';
import { Subheading } from 'shared/components/Text';
import { Box } from 'shared/components/Box';
import { PropertyList } from 'shared/components/PropertyList';

type Props = {
  checkDeposit: CheckDepositGetResponse;
  front: ImageData;
  rear: ImageData;
  micr: {
    accountNumber: string;
    routingNumber: string;
    auxiliaryOnUs?: string;
    serialNumber?: string;
  };
};

const removeEmpty = (s: string | undefined) => (s && s !== '' ? s : undefined);

const SubmitReviewInner: React.FC<
  Props & { checkDepositContext: CheckDepositContextGetResponse }
> = (props) => {
  const { checkDepositContext, checkDeposit, front, rear, micr } = props;
  const checkDepositApprove = useCheckDepositApprove();
  const frontSrc = front && getDataURL(front);
  const rearSrc = rear && getDataURL(rear);
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });
  const formatted = formatter.format(
    checkDeposit.check_deposit_instruction.amount / 100
  );
  const issues = [
    {
      id: 'checkAmount',
      description: `Check is made out in the amount of ${formatted}`,
    },
    {
      id: 'checkMadeOutTo',
      description: (
        <span>
          Check is made out to{' '}
          {checkDepositContext.account_entity_names.join(' or ')}
        </span>
      ),
    },
    ...checkDepositContext.potential_duplicates.map((duplicate) => ({
      id: duplicate.id,
      description: (
        <span>
          Not a duplicate of{' '}
          <StyledLink
            href={buildPath(ROUTES.CHECK_DEPOSIT_REVIEW, {
              checkDepositID: duplicate.id,
            })}
          >
            {duplicate.id}
          </StyledLink>
        </span>
      ),
    })),
  ];
  const [acknowledgedIssues, setAcknowledgedIssues] = useState(
    issues.reduce((acc, issue) => ({ ...acc, [issue.id]: false }), {})
  );

  const submit = () => {
    checkDepositApprove
      .mutateAsync([
        checkDeposit.id,
        {
          account_number: micr.accountNumber,
          routing_number: micr.routingNumber,
          auxiliary_on_us: removeEmpty(micr.auxiliaryOnUs),
          serial_number: removeEmpty(micr.serialNumber),
          processed_front_image_data_url: frontSrc,
          processed_rear_image_data_url: rearSrc,
        },
      ])
      .catch(() => {});
  };

  return (
    <Box>
      <Subheading contents="Front" />
      {frontSrc && (
        <ImageVerify
          minimumWidth={CHECK_IMAGE_MINIMUM_WIDTH}
          minimumHeight={CHECK_IMAGE_MINIMUM_HEIGHT}
          src={frontSrc}
          alt="Front of check"
        />
      )}

      <br />
      <PropertyList
        items={[
          {
            label: 'Amount',
            value: formatAmount(
              checkDeposit.check_deposit_instruction.amount,
              'USD'
            ),
          },
          { label: 'Auxiliary on-us', value: micr.auxiliaryOnUs || 'N/A' },
          { label: 'Routing number', value: micr.routingNumber },
          { label: 'Account number', value: micr.accountNumber },
          { label: 'Serial number', value: micr.serialNumber || 'N/A' },
        ]}
      />

      <Subheading contents="Back" />
      {rearSrc && (
        <ImageVerify
          minimumWidth={CHECK_IMAGE_MINIMUM_WIDTH}
          minimumHeight={CHECK_IMAGE_MINIMUM_HEIGHT}
          src={rearSrc}
          alt="Rear of check"
        />
      )}
      <ul>
        {issues.map((issue) => (
          <li key={issue.id}>
            <input
              id={issue.id}
              data-testid={issue.id}
              className="rounded"
              type="checkbox"
              onChange={(e) =>
                setAcknowledgedIssues({
                  ...acknowledgedIssues,
                  [issue.id]: e.target.checked,
                })
              }
            />
            <span className="mx-1">{issue.description}</span>
          </li>
        ))}
      </ul>

      {checkDepositApprove.error && (
        <div data-testid="SubmitReviewError">
          {checkDepositApprove.error.response?.data.message}
        </div>
      )}
      <div className="mt-3">
        <Button
          disabled={
            checkDepositApprove.isLoading ||
            !Object.values(acknowledgedIssues).every((value) => value)
          }
          onClick={() => {
            submit();
          }}
        >
          Approve Check Deposit
        </Button>
      </div>
    </Box>
  );
};

export const SubmitReview: React.FC<Props> = (props) => {
  const { checkDeposit, micr } = props;
  const { data: checkDepositContext } = useCheckDepositContextGet({
    check_deposit_id: checkDeposit.id,
    auxiliary_on_us: removeEmpty(micr.auxiliaryOnUs),
    serial_number: removeEmpty(micr.serialNumber),
    account_number: micr.accountNumber,
    routing_number: micr.routingNumber,
  });
  if (!checkDepositContext) {
    return <div>Loading...</div>;
  }
  return (
    <SubmitReviewInner {...props} checkDepositContext={checkDepositContext} />
  );
};

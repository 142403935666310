import { compact } from 'lodash';
import {
  ListItem,
  PropertyList,
  PropertyListProps,
} from 'shared/components/PropertyList';
import { formatAmount } from 'shared/lib/formatting';
import { ProgramGetResponse } from 'src/build/operations';

export type FirstInternetBankProgramTransactionLimitsPropertyListProps = {
  program: ProgramGetResponse;
} & Omit<PropertyListProps, 'items'>;

export const FirstInternetBankProgramTransactionLimitsPropertyList = (
  props: FirstInternetBankProgramTransactionLimitsPropertyListProps
) => {
  const { program, ...rest } = props;

  return (
    <PropertyList
      title="Transfer limits"
      {...rest}
      items={compact<ListItem>([
        {
          label: 'Combined Rolling 24-Hour Transfer Volume Limit',
          value: formatAmount(
            program.usd_combined_rolling_24_hour_transfer_volume_limit,
            'USD'
          ),
        },
        {
          label: 'Per ACH Credit Transfer Limit',
          value: formatAmount(program.usd_per_ach_credit_transfer_limit, 'USD'),
        },

        {
          label: 'Per ACH Debit Transfer Limit',
          value: formatAmount(program.usd_per_ach_debit_transfer_limit, 'USD'),
        },

        {
          label: 'Per Check Transfer Limit',
          value: formatAmount(program.usd_per_check_transfer_limit, 'USD'),
        },
        {
          label: 'Per Real-Time Payments Transfer Limit',
          value: formatAmount(
            program.usd_per_real_time_payments_transfer_limit,
            'USD'
          ),
        },
        {
          label: 'Per Wire Transfer Limit',
          value: formatAmount(program.usd_per_wire_transfer_limit, 'USD'),
        },
      ])}
    />
  );
};

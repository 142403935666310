import { useCallback, useEffect, useState } from 'react';
import { must } from 'src/lib/must';
import { getPixels, loadImages } from '../../lib/image-utility';
import { useKeyPressEvent } from 'react-use';
import { Button } from 'shared/components/Button';
import { Body } from 'shared/components/Text';
import { Box } from 'shared/components/Box';

type Point = { x: number; y: number };

type SharedProps = {
  minimumWidth?: number;
  minimumHeight?: number;
  size?: number;
} & (
  | { selectionMode: 'none' }
  | { selectionMode: 'area'; onPixelsExtracted: (pixels: ImageData) => void }
  | { selectionMode: 'point'; onPointSelected: (point: Point) => void }
);

type Props = {
  url: string;
} & SharedProps;

type Rect = {
  top: number;
  left: number;
  width: number;
  height: number;
};

function drawGrid(
  ctx: CanvasRenderingContext2D,
  width: number,
  height: number,
  spacing: number
) {
  ctx.strokeStyle = 'rgba(200,100,100,0.4)';

  ctx.beginPath();

  for (let x = 0; x < width; x += spacing) {
    ctx.moveTo(x, 0);
    ctx.lineTo(x, height);
  }
  for (let y = 0; y < height; y += spacing) {
    ctx.moveTo(0, y);
    ctx.lineTo(width, y);
  }
  ctx.stroke();
}

function boundsFrom(a: Point | null, b: Point | null) {
  if (!a || !b) {
    return null;
  }
  return {
    left: Math.min(a.x, b.x),
    width: Math.abs(a.x - b.x),
    top: Math.min(a.y, b.y),
    height: Math.abs(a.y - b.y),
  };
}

function drawBounds(
  ctx: CanvasRenderingContext2D,
  canvas: HTMLCanvasElement,
  a: Point | null,
  b: Point | null
) {
  if (!a || !b) {
    return;
  }

  const bounds = must(boundsFrom(a, b));
  ctx.beginPath();
  ctx.rect(0, 0, canvas.width, canvas.height);
  ctx.rect(bounds.left, bounds.top, bounds.width, bounds.height);
  ctx.fillStyle = 'rgba(128,128,128,0.6)';
  ctx.fill('evenodd');
}

function drawCursor(
  ctx: CanvasRenderingContext2D,
  canvas: HTMLCanvasElement,
  position: Point | null
) {
  if (!position) {
    return;
  }

  ctx.fillStyle = 'red';

  ctx.fillRect(position.x - 2, position.y - 2, 4, 4);
  ctx.fillRect(0, position.y, canvas.width, 1);
  ctx.fillRect(position.x, 0, 1, canvas.height);
}

function unitBound(value: number) {
  if (value < 0) {
    return 0;
  }
  if (value > 1) {
    return 1;
  }
  return value;
}

function rotateUnitPoint(point: Point, rotation: number) {
  const r = rotation * (Math.PI / 180);
  const x = point.x - 0.5;
  const y = point.y - 0.5;

  const rx = x * Math.cos(r) - y * Math.sin(r);
  const ry = y * Math.cos(r) + x * Math.sin(r);

  return {
    x: unitBound(rx + 0.5),
    y: unitBound(ry + 0.5),
  };
}

function rotateUnitBounds(bounds: Rect, rotation: number) {
  const { top, left, width, height } = bounds;
  const bottom = top + height;
  const right = left + width;

  const corners = [
    { x: left, y: top },
    { x: left, y: bottom },
    { x: right, y: bottom },
    { x: right, y: top },
  ].map((corner) => rotateUnitPoint(corner, rotation));

  const xPositions = corners.map((corner) => corner.x);
  const yPositions = corners.map((corner) => corner.y);

  const rotatedLeft = Math.min(...xPositions);
  const rotatedTop = Math.min(...yPositions);

  const r = {
    left: rotatedLeft,
    top: rotatedTop,
    width: Math.max(...xPositions) - rotatedLeft,
    height: Math.max(...yPositions) - rotatedTop,
  };

  return r;
}

type InnerProps = {
  imageSource: CanvasImageSource & { width: number; height: number };
} & SharedProps;

export const ImageToolInner = (props: InnerProps) => {
  const {
    selectionMode,
    minimumWidth,
    minimumHeight,
    imageSource,
    size = 1000,
  } = props;
  const [rotation, setRotation] = useState(0);
  const [mouseLocation, setMouseLocation] = useState<Point | null>(null);
  const [boundsStart, setBoundsStart] = useState<Point | null>(null);
  const [boundsEnd, setBoundsEnd] = useState<Point | null>(null);
  const [mouseDown, setMouseDown] = useState(false);
  const [canvas, setCanvas] = useState<HTMLCanvasElement | null>(null);
  let scale = 1;
  if (imageSource.width > imageSource.height) {
    scale = size / imageSource.width;
  } else {
    scale = size / imageSource.height;
  }

  const w = imageSource.width * scale;
  const h = imageSource.height * scale;

  const canvasRef = useCallback(
    (canvasNode: HTMLCanvasElement | null) => {
      if (canvasNode) {
        setCanvas(canvasNode);
        const ctx = canvasNode.getContext('2d');
        if (ctx) {
          ctx.clearRect(0, 0, canvasNode.width, canvasNode.height);
          ctx.fillStyle = 'rgba(0,0,0,0.1)';
          ctx.fillRect(0, 0, canvasNode.width, canvasNode.height);

          ctx.save();
          ctx.translate(canvasNode.width / 2, canvasNode.height / 2);
          ctx.rotate((Math.PI * rotation) / 180);

          ctx.drawImage(imageSource, -w / 2, -h / 2, w, h);
          ctx.restore();

          if (selectionMode === 'area') {
            drawGrid(ctx, canvasNode.width, canvasNode.height, 20);
            drawCursor(ctx, canvasNode, mouseLocation);

            if (boundsStart) {
              drawBounds(ctx, canvasNode, boundsStart, boundsEnd);
            }
          }
        }
      }
    },
    [
      boundsStart,
      boundsEnd,
      imageSource,
      rotation,
      mouseLocation,
      selectionMode,
      h,
      w,
    ]
  );

  const updateRotation = (amount: number) => {
    setRotation((rotation + amount + 360) % 360);
  };

  useKeyPressEvent('ArrowLeft', (e) => {
    updateRotation(e.shiftKey ? -0.25 : -90);
  });
  useKeyPressEvent('ArrowRight', (e) => updateRotation(e.shiftKey ? 0.25 : 90));

  const extractImage = () => {
    if (!canvas) {
      return;
    }

    const bounds = boundsFrom(boundsStart, boundsEnd);
    const unitBounds = bounds
      ? {
          left: bounds.left / canvas.width,
          top: bounds.top / canvas.height,
          width: bounds.width / canvas.width,
          height: bounds.height / canvas.height,
        }
      : rotateUnitBounds(
          {
            left: (canvas.width / 2 - w / 2) / canvas.width,
            top: (canvas.height / 2 - h / 2) / canvas.height,
            width: w / canvas.width,
            height: h / canvas.height,
          },
          rotation
        );
    const onPixelsExtracted =
      props.selectionMode === 'area' ? props.onPixelsExtracted : null;

    getPixels(imageSource, {
      bounds: unitBounds,
      rotation: rotation,
      minimumWidth: minimumWidth,
      minimumHeight: minimumHeight,
    }).then((pixels) => onPixelsExtracted?.(pixels));
  };

  useKeyPressEvent('Enter', () => extractImage());

  const getMouseLocation = (
    e: React.MouseEvent<HTMLCanvasElement, MouseEvent>
  ) => {
    const canvasElement = e.currentTarget;
    const rect = canvasElement.getBoundingClientRect(); // abs. size of element
    const scaleX = canvasElement.width / rect.width; // relationship bitmap vs. element for X
    const scaleY = canvasElement.height / rect.height; // relationship bitmap vs. element for Y

    return {
      x: (e.clientX - rect.left) * scaleX,
      y: (e.clientY - rect.top) * scaleY,
    };
  };

  const onMouseDown = (e: React.MouseEvent<HTMLCanvasElement, MouseEvent>) => {
    const l = getMouseLocation(e);
    setBoundsStart(l);
    setMouseDown(true);
  };

  const onMouseMove = (e: React.MouseEvent<HTMLCanvasElement, MouseEvent>) => {
    const l = getMouseLocation(e);
    setMouseLocation(l);
    if (mouseDown) {
      setBoundsEnd(l);
    }
  };

  const onMouseUp = (e: React.MouseEvent<HTMLCanvasElement, MouseEvent>) => {
    if (props.selectionMode === 'point') {
      const l = getMouseLocation(e);
      const xOffset = (size - w) / 2;
      const yOffset = (size - h) / 2;
      if (
        l.x > xOffset &&
        l.x < w + xOffset &&
        l.y > yOffset &&
        l.y < h + yOffset
      ) {
        props.onPointSelected({
          x: (l.x - xOffset) / scale,
          y: (l.y - yOffset) / scale,
        });
      }
    }
    setMouseDown(false);
  };

  const useMouse =
    selectionMode === 'area' || selectionMode === 'point' ? true : undefined;
  return (
    <div data-testid="image-tool">
      {selectionMode === 'area' && (
        <div className="space-y-0.5 text-xs">
          <div>
            Rotate with arrow keys (fine control with shift key). Draw to
            select, press 'enter' when done.
          </div>
          <div>
            Minimums: {minimumWidth}x{minimumHeight}
          </div>
          <div>
            Image Dimensions: {imageSource.width}x{imageSource.height}
          </div>
        </div>
      )}
      {selectionMode === 'none' && (
        <>
          <div>
            Image Dimensions: {imageSource.width}x{imageSource.height}
          </div>
          <button onClick={() => updateRotation(-90)}>↶</button>
          <button onClick={() => updateRotation(90)}>↷</button>
        </>
      )}
      <canvas
        ref={canvasRef}
        width={size}
        height={size}
        style={{ border: '1px solid gray', position: 'relative' }}
        onMouseDown={useMouse && onMouseDown}
        onMouseMove={useMouse && onMouseMove}
        onMouseUp={useMouse && onMouseUp}
      />
    </div>
  );
};

export const MultiImageTool = (props: Props) => {
  const { url } = props;
  const [imageSources, setImageSources] = useState<
    (CanvasImageSource & { width: number; height: number })[] | null
  >(null);
  const [page, setPage] = useState(0);
  const incrementPage = () =>
    setPage(Math.min(page + 1, (imageSources?.length ?? 1) - 1));
  const decrementPage = () => setPage(Math.max(page - 1, 0));

  useEffect(() => {
    loadImages(url).then((i) => {
      setImageSources(i);
    });
  }, [url]);

  useKeyPressEvent('j', decrementPage);
  useKeyPressEvent('k', incrementPage);

  if (!imageSources) {
    return <h3>Loading image</h3>;
  }

  return (
    <div>
      {imageSources.length > 1 && (
        <div>
          <Body contents={`This PDF contains ${imageSources.length} pages!`} />
          <Box flex gap="2">
            <Button
              onClick={decrementPage}
              disabled={page <= 0}
              text="Previous page (j)"
            />
            <Button
              onClick={incrementPage}
              disabled={page === imageSources.length - 1}
              text="Next page (k)"
            />
          </Box>
        </div>
      )}
      <ImageToolInner key={page} imageSource={imageSources[page]} {...props} />
    </div>
  );
};

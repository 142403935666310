import { compact } from 'lodash';
import {
  ListItem,
  PropertyList,
  PropertyListProps,
} from 'shared/components/PropertyList';
import {
  formatAmount,
  formatISO8601Timestamp,
  humanize,
} from 'shared/lib/formatting';
import { BookkeepingAccountGetResponse } from 'src/build/operations';
import { complianceCategoriesBadgeColors } from '../tables/BookkeepingAccountsTable';
import { useGroupPropertyRow } from 'src/lib/propertyListHelpers';

export type BookkeepingAccountPropertyListProps = {
  bookkeepingAccount: BookkeepingAccountGetResponse;
} & Omit<PropertyListProps, 'items'>;

export const BookkeepingAccountPropertyList = (
  props: BookkeepingAccountPropertyListProps
) => {
  const { bookkeepingAccount: bookkeepingAccount, ...rest } = props;
  const groupRow = useGroupPropertyRow(bookkeepingAccount.group_id);

  return (
    <PropertyList
      {...rest}
      items={compact<ListItem>([
        {
          label: 'Name',
          value: bookkeepingAccount.name,
        },
        groupRow,
        {
          label: 'Compliance Category',
          value: bookkeepingAccount.compliance_category
            ? humanize(bookkeepingAccount.compliance_category)
            : 'None',
          badgeColor: bookkeepingAccount.compliance_category
            ? complianceCategoriesBadgeColors[
                bookkeepingAccount.compliance_category
              ]
            : undefined,
        },
        {
          label: 'Created At',
          value: formatISO8601Timestamp(
            bookkeepingAccount.created_at,
            'medium'
          ),
        },
        bookkeepingAccount.cached_balance && {
          label: 'Cached Balance',
          value: formatAmount(bookkeepingAccount.cached_balance, 'USD'),
        },
        bookkeepingAccount.cached_balance_updated_at && {
          label: 'Cached Balance Updated At',
          value: formatISO8601Timestamp(
            bookkeepingAccount.cached_balance_updated_at,
            'medium'
          ),
        },
      ])}
    />
  );
};

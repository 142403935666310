import { useDropzone } from 'react-dropzone';
import { useState } from 'react';
import { useOperatorFileList, useOperatorFilePost } from 'src/hooks/reactQuery';
import { useServices } from 'src/hooks/use-services';
import { Item, ItemList } from './deprecated/ItemList';

type Props = {
  modelId: string;
};

export const OperatorFiles = ({ modelId }: Props) => {
  const { operations } = useServices();
  const [submitting, setSubmitting] = useState(false);
  const { data: operatorFiles, refetch } = useOperatorFileList({
    model_id: modelId,
  });
  const operatorFilePost = useOperatorFilePost();
  const createOperatorFile = (file: File) => {
    setSubmitting(true);
    operatorFilePost
      .mutateAsync([
        {
          file,
          model_id: modelId,
          purpose: 'know_your_customer',
        },
      ])
      .then(() => {
        refetch();
      })
      .finally(() => setSubmitting(false));
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: (acceptedFiles) => createOperatorFile(acceptedFiles[0]),
  });

  const items: Item[] =
    operatorFiles?.data.map((operatorFile) => ({
      id: operatorFile.id,
      text: operatorFile.transmission_name,
      detail: operatorFile.purpose ?? '',
      rightText: 'click to view',
      href: `${operations}/operator-files/${operatorFile.id}/view`,
    })) ?? [];

  return (
    <>
      <div className="rounded border border-main bg-strong p-4 text-center">
        <div>
          <div data-testid="dropzone" {...getRootProps()}>
            <input {...getInputProps()} />
            {submitting ? (
              <p>Uploading...</p>
            ) : isDragActive ? (
              <p>Drop files here...</p>
            ) : (
              <p>Drag a file here, or click to select</p>
            )}
          </div>
        </div>
      </div>
      <ItemList items={items} />
    </>
  );
};

import { NumberFormatValues, NumericFormat } from 'react-number-format';
import { TextInput } from './TextInput';

type Props = {
  value?: string | number;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onValueChange?: (v: NumberFormatValues) => void;
  hasError?: boolean;
  placeholder?: string;
  autoFocus?: boolean;
  disabled?: boolean;
  name?: string;
  className?: string;
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
};

export const AmountInput = ({
  hasError,
  autoFocus,
  disabled,
  value,
  ...rest
}: Props) => (
  <NumericFormat
    {...rest}
    thousandSeparator=","
    customInput={TextInput}
    prefix="$"
    autoFocus={autoFocus}
    decimalScale={2}
    fixedDecimalScale
    hasError={hasError}
    type="text"
    disabled={disabled}
    value={value ?? ''}
  />
);

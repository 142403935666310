export const titleCaseFromSnakeCase = (str: string): string => {
  return str
    .split('_')
    .map((word) => word[0].toUpperCase() + word.slice(1))
    .join(' ');
};

export const formatInteger = (value: number): string => {
  return value.toLocaleString('en-US', {
    maximumFractionDigits: 0,
  });
};

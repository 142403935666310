import { compact } from 'lodash';
import {
  ListItem,
  PropertyList,
  PropertyListProps,
} from 'shared/components/PropertyList';
import {
  formatAmount,
  formatISO8601Timestamp,
  humanize,
} from 'shared/lib/formatting';
import { TransferGetResponse } from 'src/build/operations';
import { useAccountGet } from 'src/hooks/reactQuery';

import {
  useAccountPropertyRow,
  useGroupPropertyRow,
  useProgramPropertyRow,
} from 'src/lib/propertyListHelpers';

type TransferPropertyListProps = {
  id: string;
  transfer: TransferGetResponse;
} & Omit<PropertyListProps, 'items'>;

export const TransferPropertyList = (props: TransferPropertyListProps) => {
  const { id, transfer, ...rest } = props;

  const groupRow = useGroupPropertyRow(transfer.group_id);
  const accountRow = useAccountPropertyRow(transfer.account_id);
  const { data: account } = useAccountGet(transfer.account_id);
  const programRow = useProgramPropertyRow(account?.program_id);

  const transferInstruction = transfer.transfer_instruction as Record<
    string,
    string
  >;

  const dashboardUrl = `https://dashboard.increase.com/transfers/${id}`;

  return (
    <PropertyList
      {...rest}
      items={compact<ListItem>([
        {
          label: 'ID',
          value: id,
          copyable: id,
        },
        {
          label: 'Type',
          value: humanize(transfer.transfer_type),
        },
        {
          label: 'Status',
          value: humanize(transfer.status),
          badgeColor:
            transfer.status === 'pending'
              ? 'yellow'
              : transfer.status === 'rejected'
                ? 'red'
                : 'gray',
        },
        {
          label: 'Amount',
          value: formatAmount(transfer.amount, 'USD'),
        },
        {
          label: 'Created at',
          value: formatISO8601Timestamp(transferInstruction.created_at, 'full'),
        },
        groupRow,
        programRow,
        accountRow,
        {
          label: 'Dashboard URL',
          value: 'Copy',
          copyable: dashboardUrl,
        },
      ])}
    />
  );
};

import classNames from 'classnames';
import { useEffect, useRef, useState } from 'react';
import { Icon, IconType } from '../Icon';
import { Body } from '../Text';
import { WarningNumber } from '../WarningNumber';

import styles from './index.module.css';
import { Link } from 'react-router-dom';

export type BaseSegment = {
  icon?: IconType;
  label: string;
  disabled?: boolean;
  sectionBreak?: boolean;
  key?: string;
  count?: number;
};

export type HrefSegment = BaseSegment & {
  href: string;
};

export type PropsWithHrefSegments = {
  selectedIndex: number;
  segments: readonly HrefSegment[];
};

export type PropsWithOnClickHandler = {
  segments: readonly BaseSegment[];
  selectedIndex: number;
  onSelectRequest: (index: number) => void;
};

export type SidebarSegmentedControlProps =
  | PropsWithHrefSegments
  | PropsWithOnClickHandler;

const SidebarSegmentedControl = ({
  selectedIndex,
  segments,
  ...props
}: SidebarSegmentedControlProps) => {
  const controlRef = useRef<HTMLDivElement>(null);

  const segmentRefs = useRef<(HTMLDivElement | HTMLAnchorElement | null)[]>([]);
  useEffect(() => {
    segmentRefs.current = segmentRefs.current.slice(0, segments.length);
  }, [segments]);

  const [loaded, setLoaded] = useState(false);
  useEffect(() => {
    if (selectedIndex >= 0) {
      setLoaded(true);
    }
  }, [selectedIndex]);

  useEffect(() => {
    const currentSegmentRef = segmentRefs.current[selectedIndex];
    if (currentSegmentRef) {
      const { offsetTop, offsetHeight } = currentSegmentRef;
      const style = controlRef.current?.style;

      style?.setProperty('--highlight-y-pos', `${offsetTop}px`);
      style?.setProperty('--highlight-height', `${offsetHeight}px`);
    }
  }, [segments, selectedIndex]);

  return (
    <div
      className={classNames(styles['controls-container'], 'w-full')}
      ref={controlRef}
    >
      <div
        className={classNames(
          styles.controls,
          loaded && styles.ready,
          'relative m-auto w-full justify-start transition',
          `before:absolute before:inset-0 before:rounded before:border before:border-main before:bg-main-selected before:content-['']`
        )}
      >
        {segments.map((item, i) => {
          const active = i === selectedIndex;

          const textColor = active
            ? 'stroke-strong text-strong'
            : 'stroke-subtle text-subtle';

          const inner = (
            <label
              className={classNames(
                'pointer-events-none relative flex min-w-0 select-none flex-row items-center space-x-1 py-1 transition-colors',
                'whitespace-nowrap',
                textColor
              )}
            >
              {item.icon && (
                <Icon
                  size="large"
                  name={item.icon}
                  className={classNames('mr-1 shrink-0')}
                />
              )}
              <div className="flex w-full min-w-0 flex-row justify-between">
                <Body
                  color="inherit"
                  contents={item.label}
                  weight={active ? 'medium' : 'medium'}
                  className={classNames(
                    textColor,
                    !active &&
                      'min-w-0 overflow-x-hidden text-ellipsis whitespace-nowrap',
                    active && item.label.includes(' ') && 'break-words',
                    active && !item.label.includes(' ') && 'break-all'
                  )}
                />
                {item.count != null && <WarningNumber count={item.count} />}
              </div>
            </label>
          );

          const classes = classNames(
            'relative block z-[1] mb-1 overflow-x-hidden rounded px-2 md:mb-0.5',
            'focus-visible:outline-none focus-visible:ring-[3px] focus-visible:ring-main',
            !item.disabled && 'cursor-pointer',
            item.disabled && 'opacity-50',
            active
              ? 'transition-colors hover:bg-main-selected'
              : 'hover:bg-main-hover'
          );

          return (
            <div key={item.key || item.label}>
              {'href' in item ? (
                <Link
                  className={classes}
                  to={item.href}
                  tabIndex={0}
                  ref={(el) => (segmentRefs.current[i] = el)}
                >
                  {inner}
                </Link>
              ) : (
                <div
                  className={classes}
                  ref={(el) => (segmentRefs.current[i] = el)}
                  tabIndex={0}
                  onClick={() => {
                    if (!item.disabled) {
                      (
                        props as {
                          onSelectRequest: (index: number) => void;
                        }
                      ).onSelectRequest(i);
                    }
                  }}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter' && !item.disabled) {
                      (
                        props as {
                          onSelectRequest: (index: number) => void;
                        }
                      ).onSelectRequest(i);
                    }
                  }}
                >
                  {inner}
                </div>
              )}
              {item.sectionBreak && (
                <div className="m-3 h-[1px] border-t border-main" />
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export { SidebarSegmentedControl };

import { Combobox, Popover } from '@headlessui/react';
import classNames from 'classnames';
import { absurd } from '../../lib/absurd';
import { DateTime } from 'luxon';
import { ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import { Button } from '../Button';
import { Body, Caption } from '../Text';
import { DateRangePicker } from '../DateRangerPicker';
import { Icon, IconType } from '../Icon';
import { formatAmount } from '../../lib/formatting';
import { FormRowStyling } from '../FormRowStyling';
import { AmountInput } from '../AmountInput';
import { isNumber } from 'lodash';
import _ from 'lodash';
import { Badge, BadgeColor } from '../Badge';
import { Link } from 'react-router-dom';

export type TableRadioFilter<T extends string> = {
  type: 'radio';
  label: string;
  id: string;
  icon: IconType;
  selection: T | null;
  labeledOptions: Record<T, string>;
  onChange: (selection: T | null) => void;
};

export type TableStringFilter = {
  type: 'string';
  label: string;
  id: string;
  icon: IconType;
  value: string;
  onChange: (value: string) => void;
};

export type TableEnumFilter<T extends string> = {
  type: 'enum';
  label: string;
  id: string;
  icon: IconType;
  selections: T[];
  labeledOptions: Record<T, string>;
  onChange: (selections: T[]) => void;
};

export type TableGroupedEnumFilter<T extends string> = {
  type: 'grouped-enum';
  label: string;
  id: string;
  icon: IconType;
  selections: T[];
  labeledOptions: Record<T, string>;
  optionGrouping: Record<T, string>;
  optionIcons?: Record<T, { icon: IconType; color: BadgeColor }>;
  onChange: (selections: T[]) => void;
};

export interface SearchableObject {
  id: string;
}

export type TableSearchableObjectFilter<T extends SearchableObject> = {
  type: 'searchable-object';
  label: string;
  id: string;
  icon: IconType;
  selections: string[];
  onChange: (selections: string[]) => void;
  getInitialElements: () => Promise<T[]>;
  search?: (query: string) => Promise<T[]>;
  getElement: (id: string) => Promise<T | undefined>;
  getLabel: (element: T) => string;
  getHref?: (element: T) => string;
  getDetail?: (element: T) => React.ReactNode;
};

export type TableDateFilter = {
  type: 'date';
  label: string;
  id: string;
  icon: IconType;
  selection: {
    onOrAfter?: DateTime | undefined;
    onOrBefore?: DateTime | undefined;
  };
  onChange: (selection: {
    onOrAfter?: DateTime | undefined;
    onOrBefore?: DateTime | undefined;
  }) => void;
};

type AmountFilterSelection =
  | {
      exactly?: number;
    }
  | {
      lessThanOrEqualTo?: number;
      greaterThanOrEqualTo?: number;
    };

export type TableAmountFilter = {
  type: 'amount';
  label: string;
  id: string;
  icon: IconType;
  selection: AmountFilterSelection;
  onChange: (selection: AmountFilterSelection) => void;
};

export type TableFilter =
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  | TableEnumFilter<any>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  | TableRadioFilter<any>
  | TableDateFilter
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  | TableSearchableObjectFilter<any>
  | TableStringFilter
  | TableAmountFilter
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  | TableGroupedEnumFilter<any>;

const isApplied = (filter: TableFilter): boolean => {
  switch (filter.type) {
    case 'date':
      return (
        filter.selection.onOrAfter !== undefined ||
        filter.selection.onOrBefore !== undefined
      );
    case 'enum':
    case 'grouped-enum':
      return (
        filter.selections.length > 0 &&
        filter.selections.length < Object.keys(filter.labeledOptions).length
      );
    case 'radio':
      return filter.selection !== null;
    case 'searchable-object':
      return filter.selections.length > 0;
    case 'string':
      return filter.value.length > 0;
    case 'amount':
      return (
        ('exactly' in filter.selection &&
          filter.selection.exactly !== undefined) ||
        ('lessThanOrEqualTo' in filter.selection &&
          filter.selection.lessThanOrEqualTo !== undefined) ||
        ('greaterThanOrEqualTo' in filter.selection &&
          filter.selection.greaterThanOrEqualTo !== undefined)
      );
  }
};

export const getLabelForAmountFilter = (filter: TableAmountFilter): string => {
  if ('exactly' in filter.selection && filter.selection.exactly) {
    return `Exactly ${formatAmount(filter.selection.exactly, 'USD')}`;
  }
  if (
    'lessThanOrEqualTo' in filter.selection &&
    filter.selection.lessThanOrEqualTo !== undefined &&
    filter.selection.greaterThanOrEqualTo === undefined
  ) {
    return `At most ${formatAmount(filter.selection.lessThanOrEqualTo, 'USD')}`;
  }
  if (
    'greaterThanOrEqualTo' in filter.selection &&
    filter.selection.greaterThanOrEqualTo !== undefined &&
    filter.selection.lessThanOrEqualTo === undefined
  ) {
    return `At least ${formatAmount(
      filter.selection.greaterThanOrEqualTo,
      'USD'
    )}`;
  }
  if (
    'greaterThanOrEqualTo' in filter.selection &&
    filter.selection.greaterThanOrEqualTo !== undefined &&
    filter.selection.lessThanOrEqualTo !== undefined
  ) {
    return `Between ${formatAmount(
      filter.selection.greaterThanOrEqualTo,
      'USD'
    )} and ${formatAmount(filter.selection.lessThanOrEqualTo, 'USD')}`;
  }
  return '';
};

export const getLabelForDateFilter = (filter: TableDateFilter): string => {
  const { onOrAfter, onOrBefore } = filter.selection;
  const f = (date: DateTime) => {
    const isThisYear = date.year === DateTime.now().year;
    return isThisYear ? date.toFormat('MMM d') : date.toFormat('MMM d, yyyy');
  };

  if (onOrAfter && onOrBefore) {
    return `Between ${f(onOrAfter)} and ${f(onOrBefore)}`;
  }
  if (onOrAfter) {
    return `On or after ${f(onOrAfter)}`;
  }
  if (onOrBefore) {
    return `On or before ${f(onOrBefore)}`;
  }
  return '';
};

export const getLabelForEnumFilter = (
  filter: TableEnumFilter<string> | TableGroupedEnumFilter<string>
) => {
  if (filter.selections.length === 0) {
    return '';
  }
  if (filter.selections.length === 1) {
    return `${filter.label} ${filter.labeledOptions[filter.selections[0]]}`;
  }
  if (filter.selections.length === 2) {
    return `${filter.label} ${filter.labeledOptions[filter.selections[0]]} or ${
      filter.labeledOptions[filter.selections[1]]
    }`;
  }
  if (filter.selections.length === 3) {
    return `${filter.label} ${filter.labeledOptions[filter.selections[0]]}, ${
      filter.labeledOptions[filter.selections[1]]
    }, or ${filter.labeledOptions[filter.selections[2]]}`;
  }
  return `${filter.label} ${filter.selections
    .slice(0, 2)
    .map((s) => filter.labeledOptions[s])
    .join(', ')} or ${filter.selections.length - 2} others`;
};

const getLabelForSearchableObjectFilter = (
  filter: TableSearchableObjectFilter<SearchableObject>,
  elements: SearchableObject[]
) => {
  const getLabel = (id: string) => {
    const element = elements.find((e) => e.id === id);
    if (!element) {
      return <span />;
    }
    const label = filter.getLabel(element);
    const href = filter.getHref && filter.getHref(element);
    if (!href) {
      return <span>{label}</span>;
    }
    return (
      <Link
        className="underline decoration-dashed"
        onClick={(e) => e.stopPropagation()}
        to={href}
      >
        {label}
      </Link>
    );
  };

  if (filter.selections.length === 0) {
    return '';
  }
  if (filter.selections.length === 1) {
    return (
      <span>
        {filter.label} is {getLabel(filter.selections[0])}
      </span>
    );
  }
  if (filter.selections.length === 2) {
    return (
      <span>
        {filter.label} is {getLabel(filter.selections[0])} or{' '}
        {getLabel(filter.selections[1])}
      </span>
    );
  }
  if (filter.selections.length === 3) {
    return (
      <span>
        {filter.label} is {getLabel(filter.selections[0])},
        {getLabel(filter.selections[1])} or {getLabel(filter.selections[2])}
      </span>
    );
  }
  return (
    <span>
      {filter.label} is{' '}
      {filter.selections.slice(0, 2).map((s) => (
        <>
          {getLabel(s)}
          {', '}
        </>
      ))}{' '}
      or {filter.selections.length - 2} others
    </span>
  );
};

export const getLabelForRadioFilter = (filter: TableRadioFilter<string>) => {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  return `${filter.label} is ${filter.labeledOptions[filter.selection!]}`;
};

export const getLabelForStringFilter = (filter: TableStringFilter) => {
  return `${filter.label} contains "${filter.value}"`;
};

type ComboboxCheckboxOptionProps = {
  focus: boolean;
  selected: boolean;
  label: string;
  detail?: React.ReactNode;
};

const ComboboxCheckboxOption = ({
  focus,
  selected,
  label,
  detail,
}: ComboboxCheckboxOptionProps) => (
  <div
    className={classNames(
      'cursor-pointer rounded-sm px-3 py-2.5 sm:py-1.5',
      focus && 'bg-main-selected'
    )}
  >
    <div className="flex flex-row items-center gap-2">
      <input
        className={classNames(
          'rounded-sm bg-main text-brand',
          'cursor-pointer border-strong shadow-xs ring-offset-main transition-colors focus:ring-[2px] focus:ring-main focus:ring-offset-0',
          selected && 'bg-main'
        )}
        type="checkbox"
        checked={selected}
        readOnly
      />

      <Body
        color="primary"
        weight="medium"
        className="shrink grow cursor-pointer select-none truncate"
      >
        {label}
      </Body>
      <div className="shrink-0">{detail}</div>
    </div>
  </div>
);

const AmountFilter = ({ filter }: { filter: TableAmountFilter }) => {
  return (
    <div className="p-1 pb-3">
      <div className="space-y-2 p-3">
        <Caption contents={'Filter by an exact amount'} color="secondary" />
        <FormRowStyling
          label={'Exactly'}
          labelPlacement={'above'}
          field={
            <AmountInput
              placeholder="$1.00"
              autoFocus
              value={
                'exactly' in filter.selection &&
                isNumber(filter.selection.exactly)
                  ? filter.selection.exactly / 100
                  : undefined
              }
              onValueChange={(e) => {
                const value =
                  e.floatValue !== undefined
                    ? Math.round(e.floatValue * 100)
                    : undefined;
                filter.onChange({ exactly: value });
              }}
            />
          }
        />
      </div>
      <div className="pb-1">
        <div className="h-[1px] border-t border-main" />
      </div>
      <div className="space-y-2 p-3">
        <Caption contents={'Filter by an amount range'} color="secondary" />
        <FormRowStyling
          label={'Greater than'}
          labelPlacement="above"
          detailRight={
            <Body color="primary" weight="medium">
              Less than
            </Body>
          }
          field={
            <div className="flex flex-row">
              <AmountInput
                value={
                  'greaterThanOrEqualTo' in filter.selection &&
                  filter.selection.greaterThanOrEqualTo !== undefined
                    ? filter.selection.greaterThanOrEqualTo / 100
                    : undefined
                }
                className="grow rounded-r-[0px] focus:z-10"
                placeholder="$1.00"
                onValueChange={(e) => {
                  const value =
                    e.floatValue !== undefined
                      ? Math.round(e.floatValue * 100)
                      : undefined;
                  filter.onChange({
                    ...filter.selection,
                    exactly: undefined,
                    greaterThanOrEqualTo: value,
                  });
                }}
              />
              <AmountInput
                value={
                  'lessThanOrEqualTo' in filter.selection &&
                  filter.selection.lessThanOrEqualTo !== undefined
                    ? filter.selection.lessThanOrEqualTo / 100
                    : undefined
                }
                className="grow rounded-l-[0px] focus:z-10"
                placeholder="$1000.00"
                onValueChange={(e) => {
                  const value =
                    e.floatValue !== undefined
                      ? Math.round(e.floatValue * 100)
                      : undefined;
                  filter.onChange({
                    ...filter.selection,
                    exactly: undefined,
                    lessThanOrEqualTo: value,
                  });
                }}
              />
            </div>
          }
        />
      </div>
    </div>
  );
};

const EnumFilter = ({ filter }: { filter: TableEnumFilter<string> }) => {
  const [query, setQuery] = useState<string>('');
  const trimmedQuery = query.trim();
  const filteredOptions = Object.keys(filter.labeledOptions).filter((key) => {
    if (trimmedQuery === '') {
      return true;
    }
    return (
      filter.labeledOptions[key]
        .toLowerCase()
        .indexOf(trimmedQuery.toLowerCase()) > -1
    );
  });

  return (
    <Combobox
      as={'div'}
      value={filter.selections}
      multiple
      onChange={filter.onChange}
      className="flex max-h-[360px] flex-col"
    >
      <Combobox.Input
        onChange={(event) => setQuery(event.target.value)}
        autoFocus
        placeholder={`Filter by ${filter.label}...`}
        className={classNames(
          'block h-[44px] w-full px-[16px] py-3.5 text-[14px] font-[440] leading-[20px] tracking-[.014em] text-strong outline-none placeholder:text-disabled sm:h-[36px]',
          'focus:z-10 focus:outline-none focus:ring-0',
          'border-x-0 border-b border-t-0 border-main bg-subtle hover:bg-subtle focus:border-main'
        )}
      />
      <Combobox.Options static className="shrink grow overflow-scroll p-1">
        {filteredOptions.map((option) => {
          return (
            <Combobox.Option key={option} value={option}>
              {({ focus, selected }) => (
                <ComboboxCheckboxOption
                  focus={focus}
                  selected={selected}
                  label={filter.labeledOptions[option]}
                />
              )}
            </Combobox.Option>
          );
        })}
      </Combobox.Options>
    </Combobox>
  );
};

const GroupedEnumFilter = ({
  filter,
}: {
  filter: TableGroupedEnumFilter<string>;
}) => {
  const [query, setQuery] = useState<string>('');
  const trimmedQuery = query.trim();
  const filteredOptions = Object.keys(filter.labeledOptions).filter((key) => {
    if (trimmedQuery === '') {
      return true;
    }
    return (
      filter.labeledOptions[key]
        .toLowerCase()
        .indexOf(trimmedQuery.toLowerCase()) > -1
    );
  });

  const groups = _.uniq(Object.values(filter.optionGrouping));

  const selectAllEffect = filteredOptions.some((o) =>
    filter.selections.includes(o)
  )
    ? 'Deselect'
    : 'Select';

  const toggleSelectAllVisible = useCallback(() => {
    // If all of the filtered options are selected, deselect all of them
    if (selectAllEffect === 'Deselect') {
      filter.onChange([]);
    }
    // Otherwise, select all of the filtered options
    else {
      filter.onChange(filteredOptions);
    }
  }, [filter, filteredOptions, selectAllEffect]);

  return (
    <Combobox
      as={'div'}
      value={filter.selections}
      multiple
      onChange={filter.onChange}
      onKeyDownCapture={(e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.metaKey && e.code === 'Enter') {
          e.preventDefault();
          e.stopPropagation();
          toggleSelectAllVisible();
        }
      }}
      className="flex max-h-[360px] flex-col"
    >
      <Combobox.Input
        onChange={(event) => setQuery(event.target.value)}
        autoFocus
        placeholder={`Filter by ${filter.label}...`}
        className={classNames(
          'block h-[44px] w-full px-[16px] py-3.5 text-[14px] font-[440] leading-[20px] tracking-[.014em] text-strong outline-none placeholder:text-disabled sm:h-[36px]',
          'focus:z-10 focus:outline-none focus:ring-0',
          'border-x-0 border-b border-t-0 border-main bg-subtle hover:bg-subtle focus:border-main'
        )}
      />
      <Combobox.Options static className="shrink grow overflow-scroll p-1">
        {groups.map((group, groupIdx) => {
          const filteredOptionsInThisGroup = filteredOptions.filter(
            (key) => filter.optionGrouping[key] === group
          );
          if (filteredOptionsInThisGroup.length === 0) {
            return null;
          }
          return (
            <div key={group}>
              {groupIdx > 0 && !query && (
                <div className="mx-2 mb-1.5 mt-2 h-[1px] border-t border-main" />
              )}
              {!query && (
                <div className="px-3 py-1.5">
                  <Caption color="secondary">{group}</Caption>
                </div>
              )}
              <div>
                {filteredOptionsInThisGroup.map((option) => {
                  return (
                    <Combobox.Option key={option} value={option}>
                      {({ focus, selected }) => (
                        <ComboboxCheckboxOption
                          focus={focus}
                          selected={selected}
                          label={filter.labeledOptions[option]}
                          detail={
                            filter.optionIcons && (
                              <Badge
                                label=""
                                color={filter.optionIcons[option].color}
                                size="small"
                                icon={filter.optionIcons[option].icon}
                              />
                            )
                          }
                        />
                      )}
                    </Combobox.Option>
                  );
                })}
              </div>
            </div>
          );
        })}
      </Combobox.Options>
      <div
        className={classNames(
          'flex cursor-pointer items-center justify-between border-t border-main bg-subtle px-4 py-2 hover:bg-subtle-hover'
        )}
        onClick={toggleSelectAllVisible}
      >
        <div className="flex items-center space-x-2">
          <Icon name="list" size="normal" className="text-subtle" />
          <Caption color="secondary" weight="medium">
            {selectAllEffect}{' '}
            {selectAllEffect === 'Select'
              ? filteredOptions.length
              : filter.selections.length}{' '}
            categories
          </Caption>
        </div>
        <Badge size="small" color="gray" label="⌘ ⏎" />
      </div>
    </Combobox>
  );
};

const SearchableObjectFilter = <T extends SearchableObject>({
  filter,
  elements,
  onQueryChange,
}: {
  filter: TableSearchableObjectFilter<T>;
  elements: T[];
  onQueryChange: (query: string) => void;
}) => {
  return (
    <Combobox
      as={'div'}
      value={filter.selections}
      multiple
      onChange={filter.onChange}
      className="flex max-h-[360px] flex-col"
    >
      <Combobox.Input
        onChange={(event) => onQueryChange(event.target.value)}
        autoFocus
        placeholder={`Filter by ${filter.label}...`}
        className={classNames(
          'block h-[44px] w-full px-[16px] py-2.5 text-[14px] font-[440] leading-[20px] tracking-[.014em] text-strong outline-none placeholder:text-disabled sm:h-[36px]',
          'focus:z-10 focus:outline-none focus:ring-0',
          'border-x-0 border-b border-t-0 border-main bg-subtle hover:bg-subtle focus:border-main'
        )}
      />
      <Combobox.Options static className="shrink grow overflow-scroll p-1">
        {elements.map((element) => {
          return (
            <Combobox.Option key={element.id} value={element.id}>
              {({ focus, selected }) => (
                <ComboboxCheckboxOption
                  focus={focus}
                  selected={selected}
                  label={filter.getLabel(element)}
                  detail={filter.getDetail && filter.getDetail(element)}
                />
              )}
            </Combobox.Option>
          );
        })}
      </Combobox.Options>
    </Combobox>
  );
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const RadioFilter = ({ filter }: { filter: TableRadioFilter<any> }) => {
  return (
    <div className="space-y-2">
      <div className="space-y-1">
        <div key="any" className="flex cursor-pointer items-center">
          <input
            id="any"
            name="any"
            type="radio"
            checked={filter.selection === null}
            onChange={() => filter.onChange(null)}
            className={classNames(
              'h-4 w-4 border-strong text-strong focus:ring-main'
            )}
          />
          <label htmlFor={'any'} className="ml-3 block">
            <Body color="primary">Any {filter.label}</Body>
          </label>
        </div>
        {Object.keys(filter.labeledOptions).map((key) => (
          <div key={key} className="flex items-center">
            <input
              id={key}
              name={filter.labeledOptions[key]}
              type="radio"
              checked={filter.selection === key}
              className={classNames(
                'h-4 w-4 cursor-pointer border-strong text-strong focus:ring-main'
              )}
              onChange={() => filter.onChange(key)}
            />
            <label htmlFor={key} className="ml-3 block">
              <Body color="emphasis">{filter.labeledOptions[key]}</Body>
            </label>
          </div>
        ))}
      </div>
    </div>
  );
};

export const DateFilter = ({ filter }: { filter: TableDateFilter }) => {
  return (
    <div className="p-4">
      <DateRangePicker
        startDate={filter.selection.onOrAfter}
        endDate={filter.selection.onOrBefore}
        startDateLabel="On or after"
        endDateLabel="On or before"
        selectableRange="past"
        onChange={({ startDate, endDate }) => {
          filter.onChange({ onOrAfter: startDate, onOrBefore: endDate });
        }}
      />
    </div>
  );
};

export const StringFilter = ({ filter }: { filter: TableStringFilter }) => {
  return (
    <div>
      <input
        value={filter.value}
        onChange={(event) => filter.onChange(event.target.value)}
        autoFocus
        placeholder={`Filter by ${filter.label}...`}
        className={classNames(
          'block h-[44px] w-full px-[16px] py-2.5 text-[14px] font-[440] leading-[20px] tracking-[.014em] text-strong outline-none placeholder:text-disabled sm:h-[36px]',
          'focus:z-10 focus:outline-none focus:ring-0',
          'border-x-0 border-b border-t-0 border-main bg-subtle hover:bg-subtle focus:border-main'
        )}
      />
    </div>
  );
};

export const TableFilters = ({ filters }: { filters: TableFilter[] }) => {
  const [selectedFilter, setSelectedFilter] = useState<string | null>(null);
  const ref = useRef<HTMLButtonElement>(null);

  const [initialElementsPerObjectFilter, setInitialElementsPerObjectFilter] =
    useState<Record<string, SearchableObject[]>>({});

  // Load the initial elements into each filter
  useEffect(() => {
    filters
      .filter(
        (f): f is TableSearchableObjectFilter<SearchableObject> =>
          f.type === 'searchable-object'
      )
      .forEach((filter) => {
        filter.getInitialElements().then((initialElements) => {
          setInitialElementsPerObjectFilter((prev) => ({
            ...prev,
            [filter.id]: initialElements,
          }));
        });
      });
  }, [filters]);

  // This stores the state for each filter
  const [queriesPerObjectFilter, setQueriesPerObjectFilter] = useState<
    Record<string, string>
  >({});
  const [queryResultsPerObjectFilter, setQueryResultsPerObjectFilter] =
    useState<Record<string, SearchableObject[]>>({});
  const handleQueryChange = (
    filter: TableSearchableObjectFilter<SearchableObject>,
    queryString: string
  ) => {
    const trimmed = queryString.trim();
    if (filter.search) {
      if (trimmed === '') {
        setQueryResultsPerObjectFilter((prev) => ({
          ...prev,
          [filter.id]: [],
        }));
        return;
      }
      filter.search(trimmed).then((results) => {
        setQueryResultsPerObjectFilter((prev) => ({
          ...prev,
          [filter.id]: results,
        }));
      });
    } else {
      setQueryResultsPerObjectFilter((prev) => ({
        ...prev,
        [filter.id]: initialElementsPerObjectFilter[filter.id].filter((e) =>
          filter.getLabel(e).toLowerCase().includes(trimmed.toLowerCase())
        ),
      }));
    }
  };

  const [selectionCachePerObjectFilter, setSelectionCachePerObjectFilter] =
    useState<Record<string, SearchableObject[]>>({});
  useEffect(() => {
    filters
      .filter(
        (f): f is TableSearchableObjectFilter<SearchableObject> =>
          f.type === 'searchable-object'
      )
      .forEach((filter) => {
        return Promise.all(
          filter.selections.map((s) => filter.getElement(s))
        ).then((elements) => {
          setSelectionCachePerObjectFilter((prev) => ({
            ...prev,
            [filter.id]: elements.filter(
              (e): e is SearchableObject => e !== undefined
            ),
          }));
        });
      });
  }, [filters]);

  // The actual visible element lists for each searchable object filter
  const elementsByObjectFilter = filters
    .filter(
      (f): f is TableSearchableObjectFilter<SearchableObject> =>
        f.type === 'searchable-object'
    )
    .reduce(
      (acc, filter) => {
        return {
          ...acc,
          [filter.id]: queriesPerObjectFilter[filter.id]?.trim()
            ? queryResultsPerObjectFilter[filter.id] ?? []
            : initialElementsPerObjectFilter[filter.id] ?? [],
        };
      },
      {} as Record<string, SearchableObject[]>
    );

  const [filterQuery, setFilterQuery] = useState<string>('');

  const filteredFilters = filters.filter((filter) => {
    if (filterQuery === '') {
      return true;
    }
    return filter.label.toLowerCase().indexOf(filterQuery.toLowerCase()) > -1;
  });

  return (
    <div className="flex grow flex-wrap items-center gap-1">
      <Popover as="div" className="relative flex">
        {({}) => (
          <>
            <Popover.Button
              as={Button}
              size="small"
              style="secondary"
              text="Filter"
              ref={ref}
              keyboardShortcut="F"
              icon="create"
              onClick={() => {
                setSelectedFilter(null);
                setFilterQuery('');
              }}
            />

            <Popover.Panel className="absolute left-0 top-8 z-10 w-[calc(100vw-32px)] divide-x divide-main overflow-hidden rounded border border-strong bg-subtle shadow-lg sm:w-[335px]">
              {selectedFilter === null && (
                <Combobox
                  as="div"
                  value={selectedFilter}
                  onChange={setSelectedFilter}
                >
                  <Combobox.Input
                    onChange={(event) => setFilterQuery(event.target.value)}
                    displayValue={(f) =>
                      filters.find((filter) => filter.id === f)?.label ?? ''
                    }
                    autoFocus
                    placeholder="Search filters..."
                    className={classNames(
                      'block h-[44px] w-full px-[16px] py-2.5 text-[14px] font-[440] leading-[20px] tracking-[.014em] text-strong outline-none placeholder:text-disabled sm:h-[36px]',
                      'focus:z-10 focus:outline-none focus:ring-0',
                      'border-x-0 border-b border-t-0 border-main bg-subtle hover:bg-subtle focus:border-main'
                    )}
                  />
                  <Combobox.Options static className="h-full p-1">
                    {filteredFilters.map((filter) => {
                      return (
                        <Combobox.Option key={filter.id} value={filter.id}>
                          {({ active }) => (
                            <div
                              className={classNames(
                                'flex cursor-pointer items-center space-x-2 rounded-sm px-3 py-2.5 text-strong sm:py-1.5',
                                active && 'bg-main-selected'
                              )}
                            >
                              <Icon
                                name={filter.icon}
                                className={classNames(
                                  active ? 'text-strong' : 'text-subtle'
                                )}
                              />
                              <Body
                                color={active ? 'emphasis' : 'secondary'}
                                weight="medium"
                              >
                                {filter.label}
                              </Body>
                            </div>
                          )}
                        </Combobox.Option>
                      );
                    })}
                  </Combobox.Options>
                </Combobox>
              )}
              {selectedFilter !== null && (
                <div className="h-full">
                  {filters.map((filter) => {
                    if (filter.id !== selectedFilter) {
                      return null;
                    }
                    switch (filter.type) {
                      case 'date':
                        return <DateFilter filter={filter} key={filter.id} />;
                      case 'enum':
                        return <EnumFilter filter={filter} key={filter.id} />;
                      case 'grouped-enum':
                        return (
                          <GroupedEnumFilter filter={filter} key={filter.id} />
                        );
                      case 'radio':
                        return <RadioFilter filter={filter} key={filter.id} />;
                      case 'string':
                        return <StringFilter filter={filter} key={filter.id} />;
                      case 'searchable-object':
                        return (
                          <SearchableObjectFilter
                            filter={filter}
                            key={filter.id}
                            elements={elementsByObjectFilter[filter.id]}
                            onQueryChange={(query) => {
                              handleQueryChange(filter, query);
                              setQueriesPerObjectFilter((prev) => ({
                                ...prev,
                                [filter.id]: query,
                              }));
                            }}
                          />
                        );
                      case 'amount':
                        return <AmountFilter filter={filter} key={filter.id} />;
                      default:
                        absurd(filter);
                    }
                  })}
                </div>
              )}
            </Popover.Panel>
          </>
        )}
      </Popover>

      {filters.map((filter) => {
        if (!isApplied(filter)) {
          return null;
        }
        let text: ReactNode = '';
        switch (filter.type) {
          case 'date':
            text = getLabelForDateFilter(filter);
            break;
          case 'enum':
            text = getLabelForEnumFilter(filter);
            break;
          case 'grouped-enum':
            text = getLabelForEnumFilter(filter);
            break;
          case 'radio':
            text = getLabelForRadioFilter(filter);
            break;
          case 'string':
            text = getLabelForStringFilter(filter);
            break;
          case 'searchable-object':
            text = getLabelForSearchableObjectFilter(
              filter,
              selectionCachePerObjectFilter[filter.id] ?? []
            );
            break;
          case 'amount':
            text = getLabelForAmountFilter(filter);
            break;
          default:
            absurd(filter);
        }

        function handleFilterClick() {
          ref.current?.click();
          setSelectedFilter(filter.id);
        }

        function handleCloseClick() {
          switch (filter.type) {
            case 'date':
              filter.onChange({});
              break;
            case 'enum':
              filter.onChange([]);
              break;
            case 'grouped-enum':
              filter.onChange([]);
              break;
            case 'radio':
              filter.onChange(null);
              break;
            case 'string':
              filter.onChange('');
              break;
            case 'searchable-object':
              filter.onChange([]);
              break;
            case 'amount':
              filter.onChange({});
              break;
            default:
              absurd(filter);
          }
        }

        return (
          <div
            key={filter.id}
            className="flex h-[28px] cursor-pointer items-center overflow-hidden rounded border border-strong bg-main text-subtle"
          >
            <div
              className={classNames(
                'truncate rounded-l-sm py-1.5 pl-2 pr-1.5 transition-colors hover:bg-main-hover',
                'ring-inset focus-visible:bg-main-selected focus-visible:outline-none focus-visible:ring-[3px] focus-visible:ring-main'
              )}
              onClick={handleFilterClick}
              tabIndex={0}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  handleFilterClick();
                }
              }}
            >
              <Caption
                color="secondary"
                weight="medium"
                className="max-w-[50ch] truncate"
              >
                {text}
              </Caption>
            </div>
            <div
              className={classNames(
                'rounded-r-sm p-1.5 transition-colors hover:bg-main-hover',
                'ring-inset focus-visible:bg-main-selected focus-visible:outline-none focus-visible:ring-[3px] focus-visible:ring-main'
              )}
              onClick={handleCloseClick}
              tabIndex={0}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  handleCloseClick();
                }
              }}
            >
              <Icon name="close" className="text-subtle" />
            </div>
          </div>
        );
      })}
    </div>
  );
};

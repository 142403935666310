import { useState } from 'react';
import { Button } from './Button';
import {
  ClipboardDocumentCheckIcon,
  ClipboardDocumentIcon,
} from '@heroicons/react/20/solid';

export const CopyButton = (props: { text: string }) => {
  const [done, setDone] = useState(false);
  return (
    <Button
      className="bg-main"
      onClick={() => {
        navigator.clipboard.writeText(props.text);
        setDone(true);
        setTimeout(() => setDone(false), 1000);
      }}
    >
      {!done ? (
        <ClipboardDocumentIcon className="h-4 w-4" />
      ) : (
        <ClipboardDocumentCheckIcon className="h-4 w-4" />
      )}
    </Button>
  );
};

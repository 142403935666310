import React from 'react';
import {
  useOAuthApplicationGet,
  useOAuthConnectionListInfinite,
} from 'src/hooks/reactQuery';
import { PageLayout } from '../deprecated/PageLayout';
import { SideBySide } from 'shared/components/SideBySide';
import { TableStateWrapper } from 'shared/components/TableStateWrapper';
import { useTypedParams } from 'react-router-typesafe-routes/dom';
import { ROUTES, buildPath } from 'src/lib/routes';
import { PropertyList } from 'shared/components/PropertyList';
import { formatISO8601Timestamp } from 'shared/lib/formatting';
import { OAuthConnectionsTable } from '../tables/OAuthConnectionsTable';
import { useGroupPropertyRow } from 'src/lib/propertyListHelpers';

export const OAuthApplicationDetailPage = () => {
  const { oauthApplicationID } = useTypedParams(
    ROUTES.OAUTH_APPLICATION_DETAIL
  );
  const { data: application } = useOAuthApplicationGet(oauthApplicationID);

  const connectionList = useOAuthConnectionListInfinite({
    oauth_applications: [oauthApplicationID],
  });

  const groupRow = useGroupPropertyRow(application?.group_id);

  if (!application) {
    return <div>Loading...</div>;
  }

  return (
    <PageLayout headline={application.id} breadcrumbs={[]}>
      <SideBySide
        mode="rightDrawer"
        left={
          <>
            <TableStateWrapper
              table={OAuthConnectionsTable}
              style="detail"
              {...connectionList}
              title="Connections"
              emptyTitle="No connections"
              emptySubtitle="There are groups connected to this application"
              viewMoreHref={buildPath(
                ROUTES.OAUTH_CONNECTION_LIST,
                {},
                { oauth_application: [oauthApplicationID] }
              )}
            />
          </>
        }
        right={
          <PropertyList
            title="Details"
            layoutHorizontallyWhenPossible={false}
            items={[
              {
                label: 'ID',
                value: application.id,
              },
              {
                label: 'Name',
                value: application.name,
              },
              {
                label: 'Created At',
                value: formatISO8601Timestamp(application.created_at, 'medium'),
              },
              groupRow,
            ]}
          />
        }
      />
    </PageLayout>
  );
};

import { Icon } from './Icon';
import { Body, Heading } from './Text';
import { Illustration, IllustrationType } from './Illustration';

export type InstructionProps = {
  heading?: string;
  text: string;
  details: string[];
  illustration: IllustrationType;
};

export const Instructions = ({
  heading = 'Instructions',
  text,
  details,
  illustration,
}: InstructionProps) => (
  <div className="space-y-4">
    <Heading contents={heading} />
    <div className="max-w-[800px] space-y-5 sm:flex sm:space-x-5 sm:space-y-0">
      <div className="flex items-center justify-center rounded border border-main bg-strong p-4">
        <Illustration name={illustration} />
      </div>
      <div className="grow">
        <Body color="secondary">{text}</Body>
        <Body weight="medium" className="mt-4">
          Requirements
        </Body>
        <div className="mt-1">
          {details.map((detail, index) => (
            <div key={index} className="relative mt-1 flex">
              <Icon
                name="tick"
                className="relative top-0.5 mr-2 stroke-strong"
              />
              <Body color="secondary">{detail}</Body>
            </div>
          ))}
        </div>
      </div>
    </div>
  </div>
);

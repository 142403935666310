import classNames from 'classnames';
import { Body } from './Text';
import { isMobileSafari } from 'react-device-detect';

export type CheckboxInputProps = JSX.IntrinsicElements['input'] & {
  className?: string;
  hasError?: boolean;
  label: string;
};

export const CheckboxInput = ({
  className,
  hasError,
  label,
  value,
  ...rest
}: CheckboxInputProps) => {
  return (
    <div>
      <label className="flex flex-row items-center gap-3">
        <input
          className={classNames(
            'rounded-sm bg-main text-brand',
            'cursor-pointer border-strong shadow-sm ring-offset-main transition-colors focus:ring-[2px] focus:ring-main focus:ring-offset-0',
            className,
            hasError &&
              'border-0 bg-main outline-0 ring-[2px] ring-danger ring-offset-main focus:ring-offset-0',
            hasError && isMobileSafari && 'ring-1',
            isMobileSafari && 'focus:ring-1'
          )}
          type="checkbox"
          value={value ?? 'false'}
          checked={Boolean(value)}
          {...rest}
        />

        <Body
          color="primary"
          weight="medium"
          className="cursor-pointer select-none"
        >
          {label}
        </Body>
      </label>
    </div>
  );
};

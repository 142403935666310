import { compact } from 'lodash';
import {
  ListItem,
  PropertyList,
  PropertyListProps,
} from 'shared/components/PropertyList';
import { formatAmount, formatPercentage } from 'shared/lib/formatting';
import { BankProgramCurrentFeePlanGetResponse } from 'src/build/operations';

export type BankFeePlanPropertyListProps = {
  bankFeePlan: BankProgramCurrentFeePlanGetResponse;
} & Omit<PropertyListProps, 'items'>;

export const BankFeePlanPropertyList = (
  props: BankFeePlanPropertyListProps
) => {
  const { bankFeePlan, ...rest } = props;

  return (
    <PropertyList
      {...rest}
      items={compact<ListItem>([
        {
          label: 'Monthly Base Fee',
          value: formatAmount(bankFeePlan.monthly_base_fee, 'USD'),
        },

        {
          label: 'Future-Dated ACH Origination',
          value: formatAmount(
            parseFloat(bankFeePlan.ach_future_dated_origination_fee),
            'USD',
            true,
            3
          ),
        },
        {
          label: 'Same-Day ACH Origination',
          value: formatAmount(
            parseFloat(bankFeePlan.ach_same_day_origination_fee),
            'USD',
            true,
            3
          ),
        },
        {
          label: 'ACH Returns',
          value: formatAmount(
            parseFloat(bankFeePlan.ach_return_fee),
            'USD',
            true,
            3
          ),
        },
        {
          label: 'Wire Origination',
          value: formatAmount(
            parseFloat(bankFeePlan.wire_origination_fee),
            'USD',
            true,
            3
          ),
        },
        {
          label: 'Real-Time Payments Origination',
          value: formatAmount(
            parseFloat(bankFeePlan.real_time_payments_transfer_origination_fee),
            'USD',
            true,
            3
          ),
        },
        {
          label: 'Check Origination',
          value: formatAmount(
            parseFloat(bankFeePlan.check_origination_fee),
            'USD',
            true,
            3
          ),
        },

        {
          label: 'Card Volume Fee',
          value: formatPercentage(
            parseFloat(bankFeePlan.card_payment_fee_rate)
          ),
        },
        {
          label: 'Service Fee',
          value: formatPercentage(parseFloat(bankFeePlan.service_fee_rate)),
        },
      ])}
    />
  );
};

import { useMemo } from 'react';

import {
  PrestyledTableProps,
  Table,
  TableColumns,
} from 'shared/components/Table';
import { EntityGetResponseSetupSubmissionStructureCorporationAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItem } from 'src/build/operations';
import { ROUTES, buildPath } from 'src/lib/routes';

type Props =
  PrestyledTableProps<EntityGetResponseSetupSubmissionStructureCorporationAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItem>;

export const EntityCorporationBeneficialOwnersTable = (props: Props) => {
  const { getRowProps, ...rest } = props;

  const defaultRowProps = (
    datum: EntityGetResponseSetupSubmissionStructureCorporationAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItem
  ) => ({
    href: buildPath(ROUTES.BENEFICIAL_OWNER, {
      beneficialOwnerId: datum.entity_setup_beneficial_owner_submission.id,
    }),
    className: 'hover:bg-main-hover cursor-pointer transition-all group',
  });

  const columns = useMemo<
    TableColumns<EntityGetResponseSetupSubmissionStructureCorporationAnyOfEntitySetupCorporationBeneficialOwnerRelationshipsItem>
  >(() => {
    return [
      {
        header: 'Name',
        contents: (owner) => {
          return {
            text: owner.entity_setup_beneficial_owner_submission.submitted_name,
            textWeight: 'medium',
            textColor: 'emphasis',
          };
        },
      },
      {
        header: 'Prong',
        contents: (owner) => {
          return {
            text: owner.prong,
          };
        },
      },
      {
        header: 'ID verification status',
        contents: (owner) => {
          return {
            text: owner.entity_setup_beneficial_owner_submission
              .identity_verification_status,
            badgeColor:
              owner.entity_setup_beneficial_owner_submission
                .identity_verification_status === 'verified'
                ? 'green'
                : 'yellow',
          };
        },
      },
      {
        header: 'Archived?',
        contents: (owner) => {
          return {
            text: '' + owner.archived,
            badgeColor: owner.archived ? 'gray' : 'green',
          };
        },
      },
    ];
  }, []);

  return (
    <Table
      columns={columns}
      getRowProps={getRowProps ?? defaultRowProps}
      {...rest}
    />
  );
};

import { Box } from 'shared/components/Box';
import { EmptyState, EmptyStateStyle } from 'shared/components/EmptyState';
import React, { ReactElement, ReactNode } from 'react';
import { ErrorMessage } from 'shared/components/ErrorMessage';
import { TableFilter, TableFilters } from 'shared/components/Table/filters';
import { Identifiable, PrestyledTableProps } from 'shared/components/Table';
import { Body, Heading } from 'shared/components/Text';
import classNames from 'classnames';
import { IntersectionObserver } from 'shared/components/IntersectionObserver';
import { Icon } from 'shared/components/Icon';
import { Link as ReactRouterLink } from 'react-router-dom';

export interface AnyDisplayableError {
  title?: string;
  message?: string;
  detail?: string;
  code?: string;
}

type DetailViewProps = {
  style: 'detail';
  title: string;
  viewMoreHref: string;
};

type PrimaryListViewProps = {
  style: 'primary';
  filters: TableFilter[];
  fetchNextPage: () => void;
};

type BaseProps<
  T extends Identifiable,
  U extends { data: T[] } = PrestyledTableProps<T>,
> = Omit<U, 'data'> & {
  // Results from a list query
  data: undefined | T[] | { data: T[] } | { pages: { data: T[] }[] };
  error: AnyDisplayableError | null;
  hasNextPage?: boolean | undefined;

  // Empty state
  emptyTitle: string;
  emptySubtitle: string;

  // Table class
  table: React.FC<U>;

  // Right action
  action?: ReactNode;
};

type Props<
  T extends Identifiable,
  U extends { data: T[] } = PrestyledTableProps<T>,
> = BaseProps<T, U> & (DetailViewProps | PrimaryListViewProps);

export const TableViewAllLink = (props: { href: string }) => (
  <ReactRouterLink to={props.href}>
    <div
      className={classNames(
        'flex justify-between space-x-1',
        'py-2 transition-colors hover:bg-main-hover'
      )}
    >
      <Body color="primary" weight="medium" contents={'View all'} />
      <Icon name="caret_right" className="stroke-subtle" />
    </div>
  </ReactRouterLink>
);

export const TableStateWrapper = <
  T extends Identifiable,
  U extends { data: T[] } = PrestyledTableProps<T>,
>({
  data,
  error,
  hasNextPage,
  style,
  table,
  emptyTitle,
  emptySubtitle,
  action,
  ...rest
}: Props<T, U>) => {
  const dataHasLoaded = data !== undefined;

  const renderTableWhenEmpty = style === 'primary';
  const emptyStateStyle: EmptyStateStyle =
    style === 'primary' ? 'expanded' : 'minimized';

  const viewMoreHref = 'viewMoreHref' in rest ? rest.viewMoreHref : undefined;

  const titleComponent =
    'title' in rest ? <Heading>{rest.title}</Heading> : null;

  const filterComponent =
    'filters' in rest && rest.filters.length > 0 ? (
      <TableFilters filters={rest.filters} />
    ) : null;

  const fetchNextPage = 'fetchNextPage' in rest ? rest.fetchNextPage : () => {};

  const errorComponent = error ? (
    <Box center>
      <ErrorMessage
        message={
          error.title ??
          error.message ??
          error.detail ??
          error.code ??
          'Something went wrong loading this data.'
        }
      />
    </Box>
  ) : null;

  // No need to go further in this case
  if (errorComponent) {
    return (
      <div>
        {titleComponent}
        {errorComponent}
      </div>
    );
  }

  let flattenedData: T[] = [];
  if (data) {
    if (Array.isArray(data)) {
      flattenedData = data;
    } else if ('data' in data) {
      flattenedData = data.data;
    } else {
      flattenedData = data.pages.flatMap((p) => p.data);
    }
  }
  // @ts-expect-error TODO(sam) figure out this error, the code is ~ correct
  const renderedTable = React.createElement(table, {
    data: flattenedData,
    ...rest,
  });
  const shouldRenderTable = renderTableWhenEmpty || flattenedData.length > 0;

  // Render an empty state if
  let emptyState: ReactElement | null = null;
  if (dataHasLoaded && flattenedData.length === 0) {
    emptyState = (
      <Box center={emptyStateStyle === 'expanded'}>
        <EmptyState title={emptyTitle} style={emptyStateStyle}>
          {emptySubtitle}
        </EmptyState>
      </Box>
    );
  }

  return (
    <div>
      <div className="flex items-start gap-2 pb-3">
        <div className={classNames(action && 'max-w-[75%]', 'sm:max-w-none')}>
          {filterComponent}
          {titleComponent}
        </div>
        <div className="grow" />
        <div className="shrink-0">{action}</div>
      </div>
      {shouldRenderTable && renderedTable}
      {style === 'detail' && hasNextPage && viewMoreHref && (
        <TableViewAllLink href={viewMoreHref} />
      )}
      {style === 'primary' && (
        <IntersectionObserver onVisible={fetchNextPage} />
      )}
      {emptyState}
    </div>
  );
};

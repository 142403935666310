import { compact } from 'lodash';
import {
  ListItem,
  PropertyList,
  PropertyListProps,
} from 'shared/components/PropertyList';
import { formatISO8601Timestamp } from 'shared/lib/formatting';
import { PlatformUnusualActivityReportGetResponse } from 'src/build/operations';
import { increaseReviewStatusBadgeColor } from '../tables/PlatformUnusualActivityReportsTable';
import { useGroupPropertyRow } from 'src/lib/propertyListHelpers';

export type PlatformUnusualActivityReportPropertyListProps = {
  unusualActivityReport: PlatformUnusualActivityReportGetResponse;
} & Omit<PropertyListProps, 'items'>;

export const PlatformUnusualActivityReportPropertyList = (
  props: PlatformUnusualActivityReportPropertyListProps
) => {
  const { unusualActivityReport, ...rest } = props;
  const groupRow = useGroupPropertyRow(unusualActivityReport.group_id);

  return (
    <PropertyList
      {...rest}
      items={compact<ListItem>([
        {
          label: 'ID',
          value: unusualActivityReport.id,
          copyable: unusualActivityReport.id,
        },
        {
          label: 'Increase Review Status',
          value: unusualActivityReport.increase_review_status,
          badgeColor:
            increaseReviewStatusBadgeColor[
              unusualActivityReport.increase_review_status
            ],
        },
        groupRow,
        {
          label: 'Created By',
          value: unusualActivityReport.created_by_email ?? 'N/A',
        },
        {
          label: 'Created',
          value: formatISO8601Timestamp(
            unusualActivityReport.created_at,
            'full'
          ),
        },
        {
          label: 'Activity Began At',
          value: unusualActivityReport.activity_began_on
            ? formatISO8601Timestamp(
                unusualActivityReport.activity_began_on,
                'medium',
                'UTC'
              )
            : 'N/A',
        },
        {
          label: 'Activity Ended At',
          value: unusualActivityReport.activity_ended_on
            ? formatISO8601Timestamp(
                unusualActivityReport.activity_ended_on,
                'medium',
                'UTC'
              )
            : 'N/A',
        },
      ])}
    />
  );
};

import classNames from 'classnames';
import { isMobileSafari } from 'react-device-detect';

export type TextAreaProps = JSX.IntrinsicElements['textarea'] & {
  className?: string;
  hasError?: boolean;
  onKeyDown?: (event: React.KeyboardEvent<HTMLTextAreaElement>) => void;
} & { value?: string };

const TextArea = ({
  className,
  hasError,
  rows = 4,
  value,
  onKeyDown,
  ...rest
}: TextAreaProps) => {
  return (
    <textarea
      rows={rows}
      className={classNames(
        'block w-full rounded px-[12px] py-[8px] text-[14px] font-[440] leading-[20px] tracking-[.014em] text-strong shadow-xs outline-none placeholder:text-disabled',
        'focus:outline-0 focus:ring-[3px]',
        'disabled:bg-disabled disabled:opacity-50',
        'border border-strong bg-main transition-all',
        className,
        hasError
          ? 'border-danger-strong focus:border-danger-strong focus:ring-danger'
          : 'focus:border-stronger focus:ring-main',
        isMobileSafari && 'focus:ring-1'
      )}
      onKeyDown={onKeyDown}
      value={value ?? ''}
      {...rest}
    />
  );
};

export { TextArea };
